var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import "./locallink.scss";
import { useLocalLink } from "./useLocalLink";
var LocalLink = function (_a) {
    var path = _a.path, query = _a.query, className = _a.className, target = _a.target, children = _a.children, actionName = _a.actionName;
    var goSPAPath = useLocalLink(path, query);
    if (target)
        return (_jsx("a", __assign({ href: path, target: target, className: "LocalLink ".concat(className !== null && className !== void 0 ? className : ""), "data-dd-action-name": actionName }, { children: children })));
    return (_jsx("span", __assign({ onClick: goSPAPath, className: "LocalLink ".concat(className !== null && className !== void 0 ? className : ""), "data-dd-action-name": actionName }, { children: children })));
};
export default LocalLink;

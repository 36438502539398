import { lazy } from "react";
import { MENU_GROUP, PLATFORM } from "../../../common/constants";
import { globalEvents } from "../../../common/GlobalEvents";
import { PERMISSION } from "../../../components/rbac/permissions";
import { ROUTE_EVENTS } from "../../../components/routing/constants";
import { RESTAURANTS_PATH } from "./constants";
/* istanbul ignore next */ // no need to test lazy loading
var LazyComponents = {
    ManageRestaurants: lazy(function () {
        return import(
        /* webpackChunkName: "qc-manageRestaurants" */ "./views/manage-restaurants/ManageRestaurants");
    }),
    ViewRestaurant: lazy(function () {
        return import(
        /* webpackChunkName: "qc-viewRestaurant" */ "./views/restaurant/Restaurant");
    }),
    AddPOSDevice: lazy(function () {
        return import(
        /* webpackChunkName: "qc-addPOSDevice" */ "./views/restaurant/view-edit/device-information/AddPOSDevice");
    }),
};
export var restaurantsRoutes = [
    {
        label: "restaurants.menu.label",
        route: "",
        permissions: [
            PERMISSION.QC_RESTAURANT_CREATE,
            PERMISSION.QC_RESTAURANT_READ,
            PERMISSION.QC_RESTAURANT_UPDATE,
            PERMISSION.QC_RESTAURANT_DELETE,
            PERMISSION.QC_RESTAURANT_TOGGLE,
        ],
        icon: "documents",
        platforms: [PLATFORM.ADMIN_PANEL],
        group: MENU_GROUP.QUICK_COMMERCE,
        subRoutes: [
            {
                label: "restaurants.menu.item.manage.restaurants",
                route: RESTAURANTS_PATH.MANAGE_RESTAURANTS,
                component: LazyComponents.ManageRestaurants,
                permissions: [PERMISSION.QC_RESTAURANT_READ],
                platforms: [PLATFORM.ADMIN_PANEL],
            },
            {
                label: "restaurants.menu.item.restaurant",
                route: RESTAURANTS_PATH.VIEW_RESTAURANT,
                component: LazyComponents.ViewRestaurant,
                permissions: [PERMISSION.QC_RESTAURANT_READ],
                platforms: [PLATFORM.ADMIN_PANEL],
                hidden: true,
            },
            {
                label: "restaurants.page.device.add.title",
                route: RESTAURANTS_PATH.ADD_POS_DEVICE,
                component: LazyComponents.AddPOSDevice,
                permissions: [PERMISSION.QC_RESTAURANT_READ],
                platforms: [PLATFORM.ADMIN_PANEL],
                hidden: true,
            },
        ],
    },
];
export var initQCBrandsRestaurantsLocations = function () {
    globalEvents.emit(ROUTE_EVENTS.REGISTER, restaurantsRoutes);
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var flattenDataAttributes = function (_a) {
    var id = _a.id, type = _a.type, attributes = _a.attributes;
    return (__assign({ id: id, type: type }, attributes));
};

export var findData = function (data, find_id) { return data.filter(function (_a) {
    var id = _a.id;
    return id === find_id;
})[0]; };
export var mergeCBB = function (companies, brands, businesses) {
    var combined = [];
    companies.forEach(function (company) {
        var _a;
        var company_businesses = businesses.filter(function (_a) {
            var company_id = _a.company_id;
            return company_id + "" === company.id;
        });
        if (company_businesses.length) {
            company_businesses.forEach(function (_a) {
                var _b;
                var id = _a.id, brand_id = _a.brand_id, name = _a.name, tags = _a.tags, description = _a.description, gst_company_name = _a.gst_company_name, gst_inclusive = _a.gst_inclusive, gst_reg_no = _a.gst_reg_no, co_reg_no = _a.co_reg_no, uen = _a.uen;
                var foundBrand = findData(brands, brand_id + "");
                combined.push({
                    company: {
                        id: Number(company.id),
                        name: company.name,
                        tms_tenant_ids: (_b = company.tms_tenant_ids) !== null && _b !== void 0 ? _b : [],
                    },
                    brand: {
                        id: Number(foundBrand === null || foundBrand === void 0 ? void 0 : foundBrand.id),
                        name: foundBrand === null || foundBrand === void 0 ? void 0 : foundBrand.name,
                    },
                    business: {
                        id: Number(id),
                        name: name,
                        tags: tags,
                        description: description !== null && description !== void 0 ? description : "",
                        gst_company_name: gst_company_name !== null && gst_company_name !== void 0 ? gst_company_name : "",
                        gst_inclusive: gst_inclusive !== null && gst_inclusive !== void 0 ? gst_inclusive : true,
                        gst_reg_no: gst_reg_no !== null && gst_reg_no !== void 0 ? gst_reg_no : "",
                        co_reg_no: co_reg_no !== null && co_reg_no !== void 0 ? co_reg_no : "",
                        uen: uen !== null && uen !== void 0 ? uen : "",
                    },
                });
            });
        }
        else {
            combined.push({
                company: {
                    id: Number(company.id),
                    name: company.name,
                    tms_tenant_ids: (_a = company.tms_tenant_ids) !== null && _a !== void 0 ? _a : [],
                },
            });
        }
    });
    return combined;
};
export var getCompleteCBB = function (cbbs) {
    return cbbs.filter(function (_a) {
        var brand = _a.brand, business = _a.business;
        return !!brand && !!business;
    });
};

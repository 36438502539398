import { lazy } from "react";
import { MENU_GROUP, PLATFORM } from "../../common/constants";
import { globalEvents } from "../../common/GlobalEvents";
import { PERMISSION } from "../../components/rbac/permissions";
import { ROUTE_EVENTS } from "../../components/routing/constants";
import { SUPERADMIN_PATH } from "./constants";
/* istanbul ignore next */ // no need to test lazy loading
var LazyComponents = {
    DSERJob: lazy(function () { return import(/* webpackChunkName: "dserjob" */ "./dserjob/DSERJob"); }),
    SSRJob: lazy(function () { return import(/* webpackChunkName: "ssrjob" */ "./ssrjob/SSRJob"); }),
    KPOSVersions: lazy(function () {
        return import(
        /* webpackChunkName: "kposversions" */ "./kposversions/KPOSVersions");
    }),
    DeletedDSERs: lazy(function () {
        return import(
        /* webpackChunkName: "deleteddsers" */ "./deleteddsers/DeletedDSERs");
    }),
    FSFeatures: lazy(function () { return import(/* webpackChunkName: "fsfeatures" */ "./fsfeatures/FSFeatures"); }),
    LinkPayPatch: lazy(function () {
        return import(
        /* webpackChunkName: "linkpaypatch" */ "./linkpaypatch/LinkPayPatch");
    }),
    PromoReportJob: lazy(function () {
        return import(
        /* webpackChunkName: "promoreportjob" */ "./promoreportjob/PromotionsJobTrigger");
    }),
    RBACManage: lazy(function () {
        return import(
        /* webpackChunkName: "rolesandpermissions" */ "./rolesandpermissions/RolesAndPermissions");
    }),
    UploadAssets: lazy(function () { return import(/* webpackChunkName: "uploadassets" */ "./assets/UploadAssets"); }),
};
var SUPPORT_ALLOWED = [PERMISSION.FS_SUPPORT];
var PLATFORM_ACCESS = [PLATFORM.ADMIN_PANEL];
/* istanbul ignore next */ // no need to test route config
export var superAdminRoutes = [
    {
        label: "superdamin.menu.label",
        route: "",
        permissions: SUPPORT_ALLOWED,
        icon: "gear",
        platforms: PLATFORM_ACCESS,
        group: MENU_GROUP.SUPER_ADMIN,
        subRoutes: [
            {
                label: "superadmin.menu.item.dserjob",
                route: SUPERADMIN_PATH.DSER_JOB,
                component: LazyComponents.DSERJob,
                permissions: SUPPORT_ALLOWED,
                platforms: PLATFORM_ACCESS,
            },
            {
                label: "superadmin.menu.item.deleteddsers",
                route: SUPERADMIN_PATH.DELETED_DSERS,
                component: LazyComponents.DeletedDSERs,
                permissions: SUPPORT_ALLOWED,
                platforms: PLATFORM_ACCESS,
            },
            {
                label: "superadmin.menu.item.ssrjob",
                route: SUPERADMIN_PATH.SSR_JOB,
                component: LazyComponents.SSRJob,
                permissions: SUPPORT_ALLOWED,
                platforms: PLATFORM_ACCESS,
            },
            {
                label: "superadmin.menu.item.promoreportjob",
                route: SUPERADMIN_PATH.PROMO_REPORT_JOB,
                component: LazyComponents.PromoReportJob,
                permissions: SUPPORT_ALLOWED,
                platforms: PLATFORM_ACCESS,
            },
            {
                label: "superadmin.menu.item.linkpaypatch",
                route: SUPERADMIN_PATH.LINKPAY_PATCHING,
                component: LazyComponents.LinkPayPatch,
                permissions: SUPPORT_ALLOWED,
                platforms: PLATFORM_ACCESS,
            },
            {
                label: "superadmin.menu.item.kposversions",
                route: SUPERADMIN_PATH.KPOS_VERSIONS,
                component: LazyComponents.KPOSVersions,
                permissions: SUPPORT_ALLOWED,
                platforms: PLATFORM_ACCESS,
            },
            {
                label: "superadmin.menu.item.fsfeatures",
                route: SUPERADMIN_PATH.FS_FEATURES,
                component: LazyComponents.FSFeatures,
                permissions: [PERMISSION.SYSTEM_MAINTENANCE],
                platforms: PLATFORM_ACCESS,
            },
            {
                label: "superadmin.menu.item.rbac",
                route: SUPERADMIN_PATH.RBAC_MANAGE,
                component: LazyComponents.RBACManage,
                permissions: [PERMISSION.SYSTEM_MAINTENANCE],
                platforms: PLATFORM_ACCESS,
                dev_only: true,
            },
            {
                label: "superadmin.assets.title",
                route: SUPERADMIN_PATH.UPLOAD_ASSETS,
                component: LazyComponents.UploadAssets,
                permissions: SUPPORT_ALLOWED,
                platforms: PLATFORM_ACCESS,
            },
        ],
    },
];
export var initSuperAdmin = function () {
    globalEvents.emit(ROUTE_EVENTS.REGISTER, superAdminRoutes);
};

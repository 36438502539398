var _a;
export var DEFAULT_LOCATION_AVAILABLE = "Y";
export var LOCATION_INCLUDED_KEY;
(function (LOCATION_INCLUDED_KEY) {
    LOCATION_INCLUDED_KEY["LOCATION_UNITS"] = "location_units";
})(LOCATION_INCLUDED_KEY || (LOCATION_INCLUDED_KEY = {}));
export var LOCATION_OPERATOR;
(function (LOCATION_OPERATOR) {
    LOCATION_OPERATOR["KOPITIAM"] = "KOPITIAM";
    LOCATION_OPERATOR["HAWKER_CENTER"] = "FPG_HAWKER_CENTER";
})(LOCATION_OPERATOR || (LOCATION_OPERATOR = {}));
export var LOCATION_OPERATOR_DISPLAY = (_a = {},
    _a[LOCATION_OPERATOR.KOPITIAM] = "Kopitiam",
    _a[LOCATION_OPERATOR.HAWKER_CENTER] = "FPG Hawker Center",
    _a);

import { lazy } from "react";
import { MENU_GROUP, PLATFORM } from "../../../common/constants";
import { globalEvents } from "../../../common/GlobalEvents";
import { PERMISSION } from "../../../components/rbac/permissions";
import { ROUTE_EVENTS } from "../../../components/routing/constants";
import { PROMOTIONS_PATH } from "./constants";
/* istanbul ignore next */ // no need to test lazy loading
var LazyComponents = {
    PromotionsList: lazy(function () {
        return import(
        /* webpackChunkName: "fs-promotionslist" */ "./promotionslist/PromotionsList");
    }),
    AddCampaign: lazy(function () {
        return import(
        /* webpackChunkName: "fs-addcampaign" */ "./addcampaign/AddCampaign");
    }),
    CampaignDetail: lazy(function () {
        return import(
        /* webpackChunkName: "fs-campaigndetail" */ "./campaigndetail/CampaignDetail");
    }),
    // anchor - for code generator
};
var permissions = [
    PERMISSION.FS_PROMOTIONS_VIEW,
    PERMISSION.FS_PROMOTIONS_UPDATE,
    PERMISSION.FS_PROMOTIONS_CREATE,
    PERMISSION.FS_PROMOTIONS_AUDIT,
];
export var promotionsRoutes = [
    {
        label: "promotions.menu.label",
        route: "",
        permissions: permissions,
        platforms: [PLATFORM.ADMIN_PANEL],
        icon: "/assets/icons/PromotionsIcon.svg",
        group: MENU_GROUP.FOOD_SERVICES,
        subRoutes: [
            {
                label: "promotions.menu.item.managepromotioncampaigns",
                route: PROMOTIONS_PATH.PROMOTIONS_LIST,
                component: LazyComponents.PromotionsList,
                permissions: permissions,
                platforms: [PLATFORM.ADMIN_PANEL],
            },
            {
                label: "promotions.menu.item.addcampaign",
                route: PROMOTIONS_PATH.ADD_CAMPAIGN,
                component: LazyComponents.AddCampaign,
                permissions: permissions,
                platforms: [PLATFORM.ADMIN_PANEL],
                hidden: true,
            },
            {
                label: "",
                route: PROMOTIONS_PATH.CAMPAIGN_DETAIL,
                component: LazyComponents.CampaignDetail,
                permissions: permissions,
                platforms: [PLATFORM.ADMIN_PANEL],
                hidden: true,
            },
            // anchor - for code generator
        ],
    },
];
export var initFSPromotions = function () {
    globalEvents.emit(ROUTE_EVENTS.REGISTER, promotionsRoutes);
};

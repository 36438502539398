import { useCallback, useEffect, useRef } from "react";
import { delayRun } from "../timers";
export var useDelayedRun = function (timer) {
    var cancelFn = useRef(null);
    useEffect(function () {
        return function () {
            var _a;
            (_a = cancelFn.current) === null || _a === void 0 ? void 0 : _a.call(cancelFn);
            cancelFn.current = null;
        };
    }, []);
    return useCallback(function (delayedFn) {
        var _a;
        (_a = cancelFn.current) === null || _a === void 0 ? void 0 : _a.call(cancelFn);
        cancelFn.current = delayRun(delayedFn, timer);
    }, []);
};

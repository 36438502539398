/**
 * Contains all roles used
 */
import { PERMISSION_PREFIX } from "./constants";
import { isSuperAdmin, isUserManager } from "./helpers";
export var PERMISSION;
(function (PERMISSION) {
    PERMISSION["ALPHA"] = "alpha";
    PERMISSION["BETA"] = "beta";
    PERMISSION["SUPER_ADMIN"] = "@dm1N:5up3r";
    PERMISSION["SYSTEM_MAINTENANCE"] = "fs:system:maintenance";
    PERMISSION["USER_MANAGEMENT"] = "users:manage";
    PERMISSION["USER_VIEW"] = "users:view";
    PERMISSION["USER_AUDIT"] = "users:audit";
    PERMISSION["CONTENT_MANAGEMENT"] = "content:manage";
    PERMISSION["QR_CODE"] = "fs:qrcode";
    PERMISSION["TENANT_USER_MANAGEMENT"] = "users:tenant:manage";
    PERMISSION["TENANT_USER_VIEW"] = "users:tenant:view";
    PERMISSION["RBAC_MANAGEMENT"] = "rbac:manage";
    PERMISSION["ADMIN_PANEL_DSER_VIEW"] = "read:dser";
    PERMISSION["ADMIN_PANEL_DSER_UPDATE"] = "update:dser";
    PERMISSION["ADMIN_PANEL_DSER_MANAGE"] = "manage:dser";
    PERMISSION["ADMIN_PANEL_DSER_DELETE"] = "fs:delete:dser";
    PERMISSION["ADMIN_PANEL_CATALOGUE_BULKEDITPRICE"] = "fs:catalogue:bulkeditprice";
    PERMISSION["FS_REPORT_VIEW"] = "fs:reports:view";
    PERMISSION["FS_REPORTS_AUDIT"] = "fs:reports:audit";
    PERMISSION["FS_TRANSACTIONS_MANAGE"] = "fs:transactions:manage";
    PERMISSION["FS_TRANSACTIONS_AUDIT"] = "fs:transactions:audit";
    PERMISSION["FS_TRANSACTIONS_VIEW"] = "fs:transactions:view";
    PERMISSION["FS_REPORTS_SHIFT_VIEW"] = "fs:reports:shift:view";
    PERMISSION["FS_DOCUMENTS_VIEW"] = "fs:documents:view";
    PERMISSION["FS_DOCUMENTS_MANAGE"] = "fs:documents:manage";
    PERMISSION["FS_DSER_REGEN_LIMIT_BYPASS"] = "fs:dser:regen:limit";
    PERMISSION["FS_CATALOGUE_MANAGE"] = "manage:catalogue";
    PERMISSION["FS_CATALOGUE_VIEW"] = "view:catalogue";
    PERMISSION["FS_CATALOGUE_MANAGE_MASTER"] = "fs:manage:catalogue:master";
    PERMISSION["FS_CATALOGUE_MMI_ACTIVATION"] = "fs:activation:mmi";
    PERMISSION["FS_CATALOGUE_MENU_ACTIVATION"] = "fs:activation:menu";
    PERMISSION["FS_CATALOGUE_MC_ACTIVATION"] = "fs:activation:mc";
    PERMISSION["FS_PROMOTIONS_VIEW"] = "fs:view:promotion";
    PERMISSION["FS_PROMOTIONS_CREATE"] = "fs:create:promotion";
    PERMISSION["FS_PROMOTIONS_UPDATE"] = "fs:update:promotion";
    PERMISSION["FS_PROMOTIONS_AUDIT"] = "fs:view:promotionaudit";
    PERMISSION["FS_FPGEVOUCHER_VIEW"] = "fs:fpgevoucher:view";
    PERMISSION["FS_FPGEVOUCHER_EDIT"] = "fs:fpgevoucher:update";
    PERMISSION["FS_FPGEVOUCHER_CREATE"] = "fs:fpgevoucher:create";
    PERMISSION["FS_FPGEVOUCHER_DELETE"] = "fs:fpgevoucher:delete";
    PERMISSION["FS_COMPANIES_VIEW"] = "fs:view:companies";
    PERMISSION["FS_COMPANIES_CREATE"] = "fs:create:companies";
    PERMISSION["FS_COMPANIES_UPDATE"] = "fs:update:companies";
    PERMISSION["FS_DELIVERY_SALES_REPORT_VIEW"] = "fs:dsr:view";
    PERMISSION["FS_DELIVERY_SALES_REPORT_UPDATE"] = "fs:dsr:update";
    PERMISSION["FS_DELIVERY_SALES_REPORT_MANAGE"] = "fs:dsr:manage";
    PERMISSION["FS_SUPPORT"] = "fs:support";
    PERMISSION["FS_ALL_LOCATIONS_ACCESS"] = "fs:access:alllocations";
    PERMISSION["FS_LOCATION_MANAGE"] = "fs:locations:manage";
    PERMISSION["FS_LOCATION_VIEW"] = "fs:locations:view";
    PERMISSION["FS_BB_LIMITED_VIEW"] = "fs:locations:bb:view:limited";
    PERMISSION["FS_BB_LIMITED_MANAGE"] = "fs:locations:bb:manage:limited";
    PERMISSION["FS_DECLARATION_CASHDEPOSIT_CREATE"] = "fs:declaration:cashdeposit:create";
    PERMISSION["FS_DECLARATION_CASHDEPOSIT_READ"] = "fs:declaration:cashdeposit:read";
    PERMISSION["FS_DECLARATION_CASHDEPOSIT_UPDATE"] = "fs:declaration:cashdeposit:update";
    PERMISSION["FS_DECLARATION_CASHDEPOSIT_UPDATEBYPASS"] = "fs:declaration:cashdeposit:update:bypass";
    PERMISSION["FS_ACCESS_GROUP_FBM"] = "fs:group:fbm";
    PERMISSION["FS_ACCESS_GROUP_CCYY"] = "fs:group:ccyy";
    PERMISSION["FS_MESSAGING_KPOS_VIEW"] = "fs:messaging:kpos:view";
    PERMISSION["FS_MESSAGING_KPOS_MANAGE"] = "fs:messaging:kpos:manage";
    PERMISSION["FS_MESSAGING_USER_VIEW"] = "fs:messaging:user:view";
    PERMISSION["FS_MESSAGING_USER_MANAGE"] = "fs:messaging:user:manage";
    PERMISSION["QC_MENU_CREATE"] = "qc:menu:create";
    PERMISSION["QC_MENU_READ"] = "qc:menu:read";
    PERMISSION["QC_MENU_UPDATE"] = "qc:menu:update";
    PERMISSION["QC_MENU_DELETE"] = "qc:menu:delete";
    PERMISSION["QC_MENU_TOGGLE"] = "qc:menu:toggle";
    PERMISSION["QC_RESTAURANT_CREATE"] = "qc:restaurant:create";
    PERMISSION["QC_RESTAURANT_READ"] = "qc:restaurant:read";
    PERMISSION["QC_RESTAURANT_UPDATE"] = "qc:restaurant:update";
    PERMISSION["QC_RESTAURANT_DELETE"] = "qc:restaurant:delete";
    PERMISSION["QC_RESTAURANT_TOGGLE"] = "qc:restaurant:toggle";
    PERMISSION["QC_KOPITIAM_CREATE"] = "qc:kopitiam:create";
    PERMISSION["QC_KOPITIAM_READ"] = "qc:kopitiam:read";
    PERMISSION["QC_KOPITIAM_UPDATE"] = "qc:kopitiam:update";
    PERMISSION["QC_KOPITIAM_DELETE"] = "qc:kopitiam:delete";
    PERMISSION["QC_KOPITIAM_TOGGLE"] = "qc:kopitiam:toggle";
    PERMISSION["QC_BRAND_CREATE"] = "qc:brand:create";
    PERMISSION["QC_BRAND_READ"] = "qc:brand:read";
    PERMISSION["QC_BRAND_UPDATE"] = "qc:brand:update";
    PERMISSION["QC_BRAND_DELETE"] = "qc:brand:delete";
    PERMISSION["QC_BRAND_TOGGLE"] = "qc:brand:toggle";
    PERMISSION["QC_REPORTS_READ"] = "qc:reports:read";
})(PERMISSION || (PERMISSION = {}));
export var hasOnlyQCPermissions = function (userpermissions) {
    if (isSuperAdmin(userpermissions))
        return false;
    if (isUserManager(userpermissions))
        return false;
    for (var _i = 0, userpermissions_1 = userpermissions; _i < userpermissions_1.length; _i++) {
        var permission = userpermissions_1[_i];
        if (!permission.startsWith(PERMISSION_PREFIX.QC_ADMINPANEL))
            return false;
    }
    return true;
};
export var PRERELEASE_PERMISSIONS = [
    PERMISSION.ALPHA,
    PERMISSION.BETA,
];
export var MMI_RESTRICTED_ACCESS = [
    PERMISSION.FS_ACCESS_GROUP_FBM,
    PERMISSION.FS_ACCESS_GROUP_CCYY,
];
export var LMI_RESTRICTED_ACCESS = [
    PERMISSION.FS_ACCESS_GROUP_FBM,
];

import { useMemo } from "react";
import { MENU_GROUP } from "../../../../common/constants";
import { useSideBarNavItemCount } from "./useSideBarNavItemCount";
export var useSideBarCount = function () {
    var dashboard = useSideBarNavItemCount(MENU_GROUP.DASHBOARD);
    var content = useSideBarNavItemCount(MENU_GROUP.CONTENT_MANAGEMENT);
    var maintenance = useSideBarNavItemCount(MENU_GROUP.SUPER_ADMIN);
    var usermanagement = useSideBarNavItemCount(MENU_GROUP.USER_MANAGEMENT);
    var foodservices = useSideBarNavItemCount(MENU_GROUP.FOOD_SERVICES);
    var approval = useSideBarNavItemCount(MENU_GROUP.APPROVAL);
    var qc = useSideBarNavItemCount(MENU_GROUP.QUICK_COMMERCE);
    var help = useSideBarNavItemCount(MENU_GROUP.HELP);
    return useMemo(function () {
        var _a;
        return _a = {},
            _a[MENU_GROUP.DASHBOARD] = dashboard,
            _a[MENU_GROUP.CONTENT_MANAGEMENT] = content,
            _a[MENU_GROUP.SUPER_ADMIN] = maintenance,
            _a[MENU_GROUP.USER_MANAGEMENT] = usermanagement,
            _a[MENU_GROUP.FOOD_SERVICES] = foodservices,
            _a[MENU_GROUP.APPROVAL] = approval,
            _a[MENU_GROUP.QUICK_COMMERCE] = qc,
            _a[MENU_GROUP.HELP] = help,
            _a;
    }, [
        dashboard,
        content,
        maintenance,
        usermanagement,
        foodservices,
        qc,
        help,
        approval,
    ]);
};

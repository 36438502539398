var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { COLORS } from "../../common/constants";
var DEFAULT_SVG_SIZE = 30;
export var ContentIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? DEFAULT_SVG_SIZE : _b, _c = _a.fillColor, fillColor = _c === void 0 ? COLORS.OFFWHITE : _c;
    var sizeString = size.toString();
    return (_jsx("svg", __assign({ width: size, height: size, viewBox: "0 0 ".concat(sizeString, " ").concat(sizeString), xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M23.3333 2H4.66667C3.18667 2 2 3.2 2 4.66667V23.3333C2 24.8 3.18667 26 4.66667 26H23.3333C24.8 26 26 24.8 26 23.3333V4.66667C26 3.2 24.8133 2 23.3333 2ZM24 24H4V5H24V24ZM19.3333 13.6118H8.66667C7.93333 13.6118 7.33333 13.4 7.33333 12.6667C7.33333 11.9333 7.93333 11.6814 8.66667 11.6814H19.3333C20.0667 11.6814 20.6667 11.9333 20.6667 12.6667C20.6667 13.4 20.0667 13.6118 19.3333 13.6118ZM14 19H8.66667C7.93333 19 7.33333 18.7333 7.33333 18C7.33333 17.2667 7.93333 17 8.66667 17H14C14.7333 17 15.3333 17.2667 15.3333 18C15.3333 18.7333 14.7333 19 14 19Z", fill: fillColor }) })));
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from "axios";
import { throttle } from "lodash-es";
import { useCallback, useEffect, useRef, useState } from "react";
import { doNothing } from "../../common/helpers";
import { useIsMounted } from "../../common/hooks/useIsMounted";
import { useJWTHeader } from "./useJWTHeader";
/**
 *
 * @param url     URL to make request to
 * @param key     a value used to trigger a request. Normally used for retries. When this value changes, a new call is made
 * @param config
 * @returns
 */
export var useAxiosGet = function (url, key, config, noJWT, manualTrigger) {
    if (key === void 0) { key = 0; }
    var isMounted = useIsMounted();
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState(null), response = _b[0], setResponse = _b[1];
    var _c = useState(null), getError = _c[0], setGetError = _c[1];
    var getUrl = useRef("");
    var throttledSetLoading = useRef(throttle(function (val) { return isMounted() && setLoading(val); }, 300));
    var requestConfig = useJWTHeader(config, noJWT);
    var getData = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var controller, _a, data, config_1, error_1;
        var _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    getUrl.current = "";
                    setResponse(null);
                    setGetError(null);
                    if (!url)
                        return [2 /*return*/, doNothing];
                    controller = new AbortController();
                    throttledSetLoading.current(true);
                    _e.label = 1;
                case 1:
                    _e.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, axios.get(url, requestConfig)];
                case 2:
                    _a = _e.sent(), data = _a.data, config_1 = _a.config;
                    getUrl.current = (_b = config_1 === null || config_1 === void 0 ? void 0 : config_1.url) !== null && _b !== void 0 ? _b : "";
                    setResponse(data);
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _e.sent();
                    getUrl.current = (_d = (_c = error_1.config) === null || _c === void 0 ? void 0 : _c.url) !== null && _d !== void 0 ? _d : "";
                    setResponse(null);
                    setGetError(error_1);
                    return [3 /*break*/, 5];
                case 4:
                    throttledSetLoading.current(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/, controller.abort];
            }
        });
    }); }, [url, requestConfig]);
    useEffect(function () {
        if (manualTrigger)
            return;
        getData();
    }, [getData, key]);
    return {
        getUrl: getUrl.current,
        response: response,
        getError: getError,
        loading: loading,
        getData: getData,
    };
};

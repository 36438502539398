var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var addJWTInConfig = function (oldconfig, jwt) {
    if (!jwt)
        return oldconfig || {};
    return __assign(__assign({}, (oldconfig || {})), { headers: __assign(__assign({}, oldconfig === null || oldconfig === void 0 ? void 0 : oldconfig.headers), { Authorization: "Bearer ".concat(jwt) }) });
};
export var objectToArray = function (params) {
    var values = [];
    var keys = Object.keys(params);
    keys.forEach(function (key) {
        values.push(key);
        values.push(params[key]);
    });
    return values;
};
export var getErrorCodeFromAxiosError = function (error) {
    var _a, _b, _c, _d, _e, _f;
    return (_f = (_e = (_d = (_c = (_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.errors) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.code) !== null && _e !== void 0 ? _e : error.message) !== null && _f !== void 0 ? _f : "";
};

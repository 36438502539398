import { FILTER_KEY, QCContextActionTypes, } from "../../components/context/qc/types";
import { mockedSingleSelectFilters } from "./online-menus/__mocks__/data";
export var getFilteredItemsByKeyId = function (items, key, id) {
    return items.filter(function (item) { var _a; return ((_a = item[key]) === null || _a === void 0 ? void 0 : _a.id) === parseInt(id, 10); });
};
export var getFilteredItems = function (defaultItems, filters) {
    var _a = filters.brands, brands = _a === void 0 ? [] : _a, _b = filters.locations, locations = _b === void 0 ? [] : _b, _c = filters.restaurants, restaurants = _c === void 0 ? [] : _c, _d = filters.activities, activities = _d === void 0 ? [] : _d;
    var results = [];
    var filtered = false;
    var _loop_1 = function (key) {
        if (filters[key].length > 0) {
            var itemsToFilter_1 = results.length > 0 || filtered ? results : defaultItems;
            var itemResults_1 = [];
            switch (key) {
                case "brands":
                    brands.forEach(function (id) {
                        var selected = getFilteredItemsByKeyId(itemsToFilter_1, "brand", id);
                        itemResults_1.push.apply(itemResults_1, selected);
                    });
                    break;
                case "locations":
                    locations.forEach(function (id) {
                        var selected = getFilteredItemsByKeyId(itemsToFilter_1, "location", id);
                        itemResults_1.push.apply(itemResults_1, selected);
                    });
                    break;
                case "restaurants":
                    restaurants.forEach(function (id) {
                        var selected = getFilteredItemsByKeyId(itemsToFilter_1, "restaurant", id);
                        itemResults_1.push.apply(itemResults_1, selected);
                    });
                    break;
                case "activities":
                    activities
                        .map(function (activity) { return activity !== "0"; })
                        .forEach(function (activity) {
                        var selected = itemsToFilter_1.filter(function (item) { return item.online === activity; });
                        itemResults_1.push.apply(itemResults_1, selected);
                    });
                    break;
                default:
                    break;
            }
            filtered = true;
            results = itemResults_1;
        }
    };
    for (var key in filters) {
        _loop_1(key);
    }
    return results.map(function (res) { return res.id; });
};
export var stringToNumber = function (strToParse) {
    return strToParse && strToParse.length > 0 ? parseInt(strToParse, 10) : 0;
};
export var updateFilterContext = function (key, selections, dispatch) {
    var BRAND = FILTER_KEY.BRAND, RESTAURANT = FILTER_KEY.RESTAURANT, LOCATION = FILTER_KEY.LOCATION, ACTIVITY = FILTER_KEY.ACTIVITY;
    var action = {
        type: QCContextActionTypes.SET_MENUS_SELECTED_FILTERS,
        payload: {},
    };
    switch (key) {
        case BRAND:
            action.payload = { menus: { filters: { brands: selections } } };
            break;
        case LOCATION:
            action.payload = { menus: { filters: { locations: selections } } };
            break;
        case RESTAURANT:
            action.payload = {
                menus: { filters: { restaurants: selections } },
            };
            break;
        case ACTIVITY:
            action.payload = {
                menus: { filters: { activities: selections } },
            };
            break;
        default:
            return;
    }
    dispatch(action);
};
export var getDefaultSingleSelectFilterByKey = function (key) {
    return mockedSingleSelectFilters.filter(function (filter) { return filter.key === key; })[0];
};

import { globalEvents } from "../../common/GlobalEvents";
import { ROUTE_EVENTS } from "../../components/routing/constants";
import AuthPage from "./auth/AuthPage";
import { AUTH_PATH } from "./constants";
import LoginPage from "./login/LoginPage";
import LogoutPage from "./logout/LogoutPage";
export var authRoutes = [
    {
        label: "auth and login",
        permissions: [],
        platforms: [],
        icon: "",
        subRoutes: [
            {
                label: AUTH_PATH.LOGIN_PAGE,
                route: AUTH_PATH.LOGIN_PAGE,
                permissions: [],
                icon: "",
                component: LoginPage,
                hidden: true,
                platforms: [],
            },
            {
                label: AUTH_PATH.LOGOUT_PAGE,
                route: AUTH_PATH.LOGOUT_PAGE,
                permissions: [],
                icon: "",
                component: LogoutPage,
                hidden: true,
                platforms: [],
            },
            {
                label: AUTH_PATH.AUTH_PAGE,
                route: AUTH_PATH.AUTH_PAGE,
                permissions: [],
                component: AuthPage,
                icon: "",
                hidden: true,
                platforms: [],
            },
        ],
    },
];
export var initAuth = function () {
    globalEvents.emit(ROUTE_EVENTS.REGISTER, authRoutes);
};

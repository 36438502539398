import { useEffect, useState } from "react";
export var BUILD_LOCAL = "local";
export var useBuildVersion = function () {
    var _a = useState(BUILD_LOCAL), build = _a[0], setBuild = _a[1];
    useEffect(function () {
        try {
            if (!!process.env.BUILD_VERSION) {
                setBuild(process.env.BUILD_VERSION);
            }
        }
        catch (e) {
            // empty
        }
    }, []);
    return build;
};

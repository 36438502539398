import { useEffect, useState } from "react";
import { useEventTriggeredTimeUpdate } from "../../../../common/hooks/useEventTriggeredTimeUpdate";
import { jsonApiAdapter } from "../../../../common/jsonapi/jsonapi";
import { useUserInfo, useUserPermissions } from "../../../context/hooks";
import { useAxiosGet } from "../../../hooks/useAxiosGet";
import { isSuperAdmin } from "../../../rbac/helpers";
import { FS_GATEWAY_HOST } from "../../fs/constants";
import { ROLELISTING_EVENT } from "./constant";
import { filterOutHiddenRoles } from "./helpers";
var BACKOFFICE_ROLES_API = "".concat(FS_GATEWAY_HOST).concat(process.env.BACKOFFICE_ROLES_API || "");
export var useRoleListing = function () {
    var checked = useUserInfo().checked;
    var key = useEventTriggeredTimeUpdate(ROLELISTING_EVENT.REFRESH);
    var userPerms = useUserPermissions();
    var _a = useAxiosGet(checked ? BACKOFFICE_ROLES_API : "", key), response = _a.response, getError = _a.getError;
    var _b = useState([]), roles = _b[0], setRoles = _b[1];
    useEffect(function () {
        if (!response)
            return;
        var isAdmin = isSuperAdmin(userPerms);
        var newList = jsonApiAdapter(response);
        var filteredList = isAdmin ? newList : filterOutHiddenRoles(newList);
        setRoles(filteredList);
    }, [response, userPerms]);
    useEffect(function () {
        if (!getError)
            return;
        setRoles([]);
    }, [getError]);
    return roles;
};

import { useEffect } from "react";
import { useSideBarNavItemCount } from "./useSideBarNavItemCount";
import { useSideBarNewItemCountValue } from "./useSideBarNewItemCountValue";
export var useSideNavLinkCount = function (title, new_item_check, updateParentCount) {
    var _a = useSideBarNavItemCount(title, updateParentCount), totalCount = _a.totalCount, updateNavItemCount = _a.updateNavItemCount;
    var value = useSideBarNewItemCountValue(new_item_check);
    useEffect(function () {
        updateNavItemCount(title, value);
    }, [value]);
    return totalCount;
};

import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { getURLParams } from "../../../../components/routing/helpers";
import { STATIC_PAGE_PARAM } from "../../constants";
export var useGetPageId = function () {
    var params = useParams();
    return useMemo(function () {
        var urlParams = getURLParams();
        return (params[STATIC_PAGE_PARAM.ID] || urlParams[STATIC_PAGE_PARAM.ID] || "");
    }, [params]);
};

import { useEffect, useState } from "react";
import { useUserInfo, useUserPermissions } from "../context/hooks";
import { ERROR_PATH } from "../errors/constants";
import { hasPartnerCentreAccess, isSuperAdmin } from "../rbac/helpers";
import { useRouting } from "./hooks";
export var usePCNotAUserCheck = function () {
    var _a = useUserInfo(), checked = _a.checked, platform_access = _a.platform_access;
    var permissions = useUserPermissions();
    var replacePath = useRouting().replacePath;
    var _b = useState(false), isPCUser = _b[0], setIsPCUser = _b[1];
    useEffect(function () {
        if (!checked)
            return;
        if (isSuperAdmin(permissions) || hasPartnerCentreAccess(platform_access)) {
            setIsPCUser(true);
            return;
        }
        replacePath(ERROR_PATH.PAGE_PC_UNAUTHORISED);
    }, [checked, platform_access, permissions, replacePath]);
    return {
        isPCUser: isPCUser,
    };
};

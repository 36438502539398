var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { MENU_GROUP } from "../../../common/constants";
import { hasRequiredPermission } from "../../rbac/helpers";
import { PERMISSION } from "../../rbac/permissions";
import { isRouteAllowedForPlatform } from "../../routing/helpers";
export var isAlphaLink = function (permissions) {
    return permissions.includes(PERMISSION.ALPHA);
};
export var isBetaLink = function (permissions) {
    return permissions.includes(PERMISSION.BETA);
};
export var routesToNavItems = function (routeData) {
    return routeData.map(function (_a) {
        var label = _a.label, route = _a.route, icon = _a.icon, subRoutes = _a.subRoutes, hidden = _a.hidden, permissions = _a.permissions, group = _a.group, platforms = _a.platforms, new_item_check = _a.new_item_check, dev_only = _a.dev_only;
        return ({
            title: label,
            route: route,
            icon: icon,
            hidden: hidden,
            permissions: permissions,
            platforms: platforms,
            group: group,
            items: subRoutes ? routesToNavItems(subRoutes) : undefined,
            new_item_check: new_item_check !== null && new_item_check !== void 0 ? new_item_check : "",
            dev_only: dev_only !== null && dev_only !== void 0 ? dev_only : false,
        });
    });
};
export var filterNavItemsByPermissions = function (navitems, userpermissions) {
    return navitems
        .filter(function (_a) {
        var permissions = _a.permissions;
        if (!permissions.length)
            return true;
        return hasRequiredPermission(permissions, userpermissions);
    })
        .map(function (item) { return (__assign(__assign({}, item), { items: item.items
            ? filterNavItemsByPermissions(item.items, userpermissions)
            : undefined })); });
};
export var filterNavItemsByPlatform = function (navitems, platform) {
    return navitems
        .filter(function (_a) {
        var platforms = _a.platforms;
        return isRouteAllowedForPlatform(platforms, platform);
    })
        .map(function (item) { return (__assign(__assign({}, item), { items: item.items
            ? filterNavItemsByPlatform(item.items, platform)
            : undefined })); });
};
export var filterNavItemsByEnv = function (navitems, isDev) {
    return navitems
        .filter(function (_a) {
        var dev_only = _a.dev_only;
        return !dev_only || (isDev && dev_only);
    })
        .map(function (item) { return (__assign(__assign({}, item), { items: item.items ? filterNavItemsByEnv(item.items, isDev) : undefined })); });
};
export var sortByMenuGroups = function (navdata) {
    return navdata.sort(function (a, b) {
        if (a.group === b.group)
            return 0;
        if (!a.group)
            return -1;
        if (!b.group)
            return 1;
        if (a.group === MENU_GROUP.FOOD_SERVICES &&
            b.group === MENU_GROUP.QUICK_COMMERCE)
            return -1;
        if (a.group === MENU_GROUP.QUICK_COMMERCE &&
            b.group === MENU_GROUP.FOOD_SERVICES)
            return 1;
        return 0;
    });
};

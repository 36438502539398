import { InMemoryCache } from "@apollo/client";
export var catalogCache = new InMemoryCache();
export var resetCatalogCache = function (typeName, typeId) {
    var id = null;
    if (typeName) {
        id = getCatalogCachedObjectIdByTypeName(typeName, typeId);
    }
    var options = id ? { id: id } : {};
    catalogCache.evict(options);
    catalogCache.gc();
};
export var TYPENAME;
(function (TYPENAME) {
    TYPENAME["CATEGORIES"] = "food_supplier_categories";
    TYPENAME["CUSTOMISATIONS"] = "food_supplier_customisations";
    TYPENAME["CUSTOMISATION_OPTIONS"] = "food_supplier_customisation_options";
    TYPENAME["MENU_ITEMS"] = "food_supplier_menu_items";
    TYPENAME["MENUS"] = "food_supplier_menus";
    TYPENAME["RESTAURANTS"] = "food_suppliers";
    TYPENAME["LOCATIONS"] = "locations";
    TYPENAME["BRANDS"] = "brands";
})(TYPENAME || (TYPENAME = {}));
export var getCatalogCachedObjectIdByTypeName = function (typeName, id) {
    var _a, _b;
    var cachedObjects = catalogCache.extract(true);
    for (var key in cachedObjects) {
        var matchedByTypeName = ((_a = cachedObjects[key]) === null || _a === void 0 ? void 0 : _a.__typename) === typeName;
        var matchedId = id ? ((_b = cachedObjects[key]) === null || _b === void 0 ? void 0 : _b.id) === id : true;
        var foundByConditions = matchedByTypeName && matchedId;
        if (foundByConditions) {
            return key;
        }
    }
    return null;
};
export var orderCache = new InMemoryCache();

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMemo } from "react";
import { useBackOfficeConfig } from "../../config/useBackOfficeConfig";
import { isAccessPermission, mergeAccessConfigs } from "./accesspermhelpers";
export var useAccessPermissionConfigValue = function () {
    var permissions = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        permissions[_i] = arguments[_i];
    }
    var accessperms = useMemo(function () {
        return permissions.filter(function (perm) { return isAccessPermission(perm); });
    }, [permissions]);
    var configs = useBackOfficeConfig.apply(void 0, accessperms);
    return useMemo(function () {
        return configs.reduce(function (combined, _a) {
            var _b;
            var name = _a.name, config = _a.config;
            var parsed = JSON.parse(config);
            return {
                config: mergeAccessConfigs(combined.config, parsed),
                group: __assign(__assign({}, combined.group), (_b = {}, _b[name] = parsed, _b)),
            };
        }, { config: {}, group: {} });
    }, [configs]);
};

import moment from "moment";
import { YESTERDAY } from "../../common/constants";
export var SUPERADMIN_PATH;
(function (SUPERADMIN_PATH) {
    SUPERADMIN_PATH["DSER_JOB"] = "/maintenance/dserjob";
    SUPERADMIN_PATH["SSR_JOB"] = "/maintenance/ssrjob";
    SUPERADMIN_PATH["KPOS_STATUS"] = "/maintenance/kposstatus";
    SUPERADMIN_PATH["KPOS_VERSIONS"] = "/maintenance/kposinformation";
    SUPERADMIN_PATH["MISMATCHED_ORDERS"] = "/maintenance/mismatchedorders";
    SUPERADMIN_PATH["KPOS_ISSUES_REPORT"] = "/maintenance/kposissuesreport";
    SUPERADMIN_PATH["DELETED_DSERS"] = "/maintenance/deleteddsers";
    SUPERADMIN_PATH["FS_FEATURES"] = "/maintenance/fsfeatures";
    SUPERADMIN_PATH["LINKPAY_PATCHING"] = "/maintenance/linkpaypatch";
    SUPERADMIN_PATH["PROMO_REPORT_JOB"] = "/maintenance/promotionsreportjob";
    SUPERADMIN_PATH["RBAC_MANAGE"] = "/maintenance/rbac";
    SUPERADMIN_PATH["UPLOAD_ASSETS"] = "/maintenance/uploadassets";
})(SUPERADMIN_PATH || (SUPERADMIN_PATH = {}));
export var DATE_SEPARATOR = "-";
export var DATE_FORMAT = "DD".concat(DATE_SEPARATOR, "MM").concat(DATE_SEPARATOR, "YYYY");
export var MIN_DATE = moment("2022-03-01T08:00:00.000Z");
export var MAX_DATE = YESTERDAY;

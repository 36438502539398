import { useCallback, useEffect, useMemo } from "react";
import { useHistory } from "react-router";
import { LOCALSTORAGE } from "../../common/constants";
import { getLocalStorage, setLocalStorage, } from "../../common/localstoragehelpers";
import { combineQueryStrings } from "../../common/stringutils";
import { AUTH_PATH } from "../../pages/auth/constants";
import { DASHBOARD_PATH } from "../../pages/dashboard/constants";
import { useIsUserInfoChecked, useRouteListingsData } from "../context/hooks";
import { ERROR_PATH } from "../errors/constants";
import { euiClickHandler, getURLParams, routeNotMatch, trimTrailingSlash, } from "./helpers";
/**
 * Expose commonly used functionality
 * @returns
 */
export var useRouting = function () {
    var history = useHistory();
    var goBack = useCallback(function () { return history.goBack(); }, [history]);
    var goForward = useCallback(function () { return history.goForward(); }, [history]);
    var pathname = history.location.pathname;
    var pushPath = useCallback(function (path) { return history.push(path); }, [history]);
    var replacePath = useCallback(function (path) { return history.replace(path); }, [history]);
    return {
        goBack: goBack,
        goForward: goForward,
        pathname: trimTrailingSlash(pathname),
        pushPath: pushPath,
        replacePath: replacePath,
    };
};
export var useNoMatch = function () {
    var _a = useRouting(), pathname = _a.pathname, replacePath = _a.replacePath;
    var routesListing = useRouteListingsData();
    var checked = useIsUserInfoChecked();
    useEffect(function () {
        if (!checked)
            return;
        if (routeNotMatch(routesListing, pathname, true)) {
            replacePath(ERROR_PATH.PAGE_404);
        }
    }, [replacePath, pathname, routesListing, checked]);
};
export var useSPARoute = function () {
    var history = useHistory();
    var navigateToSPARoute = useCallback(function (route) { return euiClickHandler(route, history); }, [history]);
    return {
        navigateToSPARoute: navigateToSPARoute,
    };
};
export var useGoSPARouteWithQueryString = function (url) {
    var queryValues = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        queryValues[_i - 1] = arguments[_i];
    }
    var navigateToSPARoute = useSPARoute().navigateToSPARoute;
    return useCallback(function (evt) {
        var query = combineQueryStrings.apply(void 0, queryValues);
        navigateToSPARoute("".concat(url).concat(!!query ? "/?" : "").concat(query))(evt);
    }, [navigateToSPARoute, url, queryValues]);
};
export var useFallbackPathWhenNoParams = function (fallback) {
    var queryParams = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        queryParams[_i - 1] = arguments[_i];
    }
    var navigateToSPARoute = useSPARoute().navigateToSPARoute;
    useEffect(function () {
        var params = getURLParams();
        for (var _i = 0, queryParams_1 = queryParams; _i < queryParams_1.length; _i++) {
            var param = queryParams_1[_i];
            if (!params[param]) {
                navigateToSPARoute(fallback);
                return;
            }
        }
    }, []);
};
export var useDetectLandingPageBeforeAuth = function () {
    useEffect(function () {
        var _a = window.location, pathname = _a.pathname, search = _a.search;
        if (pathname === AUTH_PATH.LOGIN_PAGE)
            return;
        if (pathname === AUTH_PATH.LOGOUT_PAGE)
            return;
        if (pathname === AUTH_PATH.AUTH_PAGE)
            return;
        setLocalStorage(LOCALSTORAGE.LANDING_PAGE, "".concat(pathname).concat(search));
    }, []);
};
export var useGoToLastLandingPage = function () {
    var landingPage = useMemo(function () {
        var path = trimTrailingSlash(getLocalStorage(LOCALSTORAGE.LANDING_PAGE));
        return path || DASHBOARD_PATH.DASHBOARD;
    }, []);
    var navigateToSPARoute = useSPARoute().navigateToSPARoute;
    return useCallback(function () {
        navigateToSPARoute(landingPage)();
    }, [landingPage]);
};
export var useGoSPARouteWithURLParams = function (path) {
    var paramNames = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        paramNames[_i - 1] = arguments[_i];
    }
    var navigateToSPARoute = useSPARoute().navigateToSPARoute;
    return useCallback(function (evt) {
        var params = getURLParams();
        var queryVals = paramNames.reduce(function (arr, name) {
            arr.push(name);
            arr.push(params[name]);
            return arr;
        }, []);
        var query = combineQueryStrings.apply(void 0, queryVals);
        navigateToSPARoute("".concat(path).concat(!!query ? "/?" : "").concat(query))(evt);
    }, [navigateToSPARoute, path, paramNames]);
};

import { useMemo } from "react";
import { useFSBooleanFlag } from "../context/fs/useFSBooleanFlag";
import { useIsSuperAdmin } from "../rbac/hooks/useIsSuperAdmin";
import { useIsSystemMaintainer } from "../rbac/hooks/useIsSystemMaintainer";
var MAINTENANCE_MODE = "maintenance_mode";
export var useInMaintenanceMode = function () {
    var checkFlag = useFSBooleanFlag();
    var isSuperAdmin = useIsSuperAdmin();
    var isSystemMaintainer = useIsSystemMaintainer();
    return useMemo(function () {
        if (isSuperAdmin)
            return false;
        if (isSystemMaintainer)
            return false;
        return checkFlag(MAINTENANCE_MODE, false);
    }, [checkFlag, isSuperAdmin, isSystemMaintainer]);
};

import { datadogRum } from "@datadog/browser-rum";
import moment from "moment";
import { LOCAL_TIMEZONE, PLATFORM } from "../../common/constants";
import { DATADOG_IGNORED_ERRORS } from "./constants";
var PROD = "prod";
/* istanbul ignore next */
export var startDatadog = function () {
    var env = process.env.PLATFORM_MODE === PLATFORM.DEVELOPMENT ? "dev" : PROD;
    var build = "";
    try {
        if (!!process.env.BUILD_VERSION) {
            build = process.env.BUILD_VERSION;
        }
    }
    catch (e) {
        // empty
    }
    if (!build)
        return;
    datadogRum.init({
        applicationId: process.env.DATADOG_APPLICATIONID,
        clientToken: process.env.DATADOG_CLIENTTOKEN,
        site: process.env.DATADOG_SITE,
        service: process.env.DATADOG_SERVICE,
        env: env,
        // Specify a version number to identify the deployed version of your application in Datadog
        version: build,
        sampleRate: 100,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: "mask-user-input",
        allowedTracingUrls: [process.env.CLOUD_RUN_GATEWAY_HOST],
        beforeSend: function (event) {
            // discard a RUM error
            // https://docs.datadoghq.com/real_user_monitoring/guide/enrich-and-control-rum-data/?tab=event#discard-a-frontend-error
            if (event.type === "error") {
                for (var _i = 0, DATADOG_IGNORED_ERRORS_1 = DATADOG_IGNORED_ERRORS; _i < DATADOG_IGNORED_ERRORS_1.length; _i++) {
                    var ignored_error = DATADOG_IGNORED_ERRORS_1[_i];
                    if (event.error.message.includes(ignored_error))
                        return false;
                }
            }
        },
    });
    datadogRum.startSessionReplayRecording();
};
export var getDataDog2WeekTimeStamps = function () {
    var end = moment().utcOffset(LOCAL_TIMEZONE);
    var start = moment(end).add(-14, "days");
    return {
        start: start,
        end: end,
    };
};

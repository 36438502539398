var _a;
import { makeContext } from "../../../../../../components/context/helpers";
import { getSelectionMonthListing } from "./helpers";
export var DSR_STALL_TYPE;
(function (DSR_STALL_TYPE) {
    DSR_STALL_TYPE["DDDF"] = "0";
    DSR_STALL_TYPE["NON3DF"] = "1";
    DSR_STALL_TYPE["ALL"] = "2";
    DSR_STALL_TYPE["WANG"] = "3";
})(DSR_STALL_TYPE || (DSR_STALL_TYPE = {}));
export var STALL_TYPE_I18N_MAP = (_a = {},
    _a[DSR_STALL_TYPE.DDDF] = "reports.dser.table.3df",
    _a[DSR_STALL_TYPE.WANG] = "reports.dser.table.wang",
    _a[DSR_STALL_TYPE.NON3DF] = "reports.dser.table.non3df",
    _a[DSR_STALL_TYPE.ALL] = "reports.dser.allstalltypes",
    _a);
export var DSR_STATUS;
(function (DSR_STATUS) {
    DSR_STATUS["NOTSUBMITTED"] = "0";
    DSR_STATUS["SUBMITTED"] = "1";
    DSR_STATUS["ALL"] = "2";
})(DSR_STATUS || (DSR_STATUS = {}));
var MONTHS_LISTING = getSelectionMonthListing();
export var initialDSERViewContextData = {
    selections: {
        locations: [],
        months: MONTHS_LISTING,
    },
    filter: {
        location: "",
        stallType: DSR_STALL_TYPE.ALL,
        status: DSR_STATUS.ALL,
        monthYear: MONTHS_LISTING[0],
    },
    reportSummaries: [],
    export: null,
};
export var DSERViewContextProvider = makeContext(initialDSERViewContextData);
export var FILTER_KEY;
(function (FILTER_KEY) {
    FILTER_KEY["LOCATION"] = "location_id";
    FILTER_KEY["STALL_TYPE"] = "stall_type";
    FILTER_KEY["STATUS"] = "status";
})(FILTER_KEY || (FILTER_KEY = {}));

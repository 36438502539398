import { useEffect } from "react";
import { COLORS } from "../../../common/constants";
import { FS_ANNOUNCEMENTS } from "../../../pages/superadmin/fsfeatures/announcements/constants";
import { useFSConfigMsg } from "../../context/fs/useFSConfigMsg";
import { useI18nStringArray } from "../../i18n/hooks";
import { useInMaintenanceMode } from "../../maintenance/useInMaintenaceMode";
import { announceSimpleModalMessage } from "./helpers";
export var useLoginAlert = function () {
    var loginMsg = useFSConfigMsg(FS_ANNOUNCEMENTS.LOGIN);
    var title = useI18nStringArray("common.announcement")[0];
    var maintenance = useInMaintenanceMode();
    useEffect(function () {
        if (!loginMsg)
            return;
        if (maintenance)
            return;
        announceSimpleModalMessage({
            title: title,
            text: loginMsg,
            buttonColor: COLORS.GREENPEA500,
        });
    }, [loginMsg, maintenance]);
};

export var REPORTS_PATH;
(function (REPORTS_PATH) {
    REPORTS_PATH["DAILY_SALES_REPORT_SUMMARY"] = "/reports/dailysalesentry";
    REPORTS_PATH["DAILY_SALES_REPORT_ENTRY"] = "/reports/dailysalesentry/edit";
    REPORTS_PATH["DAILY_SALES_REPORT_AUDIT"] = "/reports/dailysalesentry/audit";
    REPORTS_PATH["DAILY_SALES_REPORT_BY_STALL"] = "/reports/dailysalesentry/stall";
    REPORTS_PATH["DAILY_SALES_REPORT_BY_HOUR"] = "/reports/dailysalesentry/hourly";
    REPORTS_PATH["DAILY_SALES_REPORT_BY_DATES"] = "/reports/dailysalesentry/dates";
    REPORTS_PATH["SALES_SUMMARY"] = "/reports/salessummary";
    REPORTS_PATH["PROMOTIONS"] = "/reports/promotions";
    REPORTS_PATH["ITEM_SALES_REPORT"] = "/reports/itemsales";
    REPORTS_PATH["PRODUCT_CATEGORY_REPORT"] = "/reports/productcategory";
    REPORTS_PATH["ITEMISED_RECEIPT"] = "/reports/itemisedreceipt";
    REPORTS_PATH["VOUCHERS_DAILY"] = "/reports/vouchers/daily";
    REPORTS_PATH["VOUCHERS_BYSTALL"] = "/reports/vouchers/stall";
    REPORTS_PATH["DELIVERYSALES_DAILY"] = "/reports/deliverysales/daily";
    REPORTS_PATH["DELIVERYSALES_DAILY_DETAILS"] = "/reports/deliverysales/daily/details";
    REPORTS_PATH["DELIVERYSALES_MONTHLY"] = "/reports/deliverysales/monthly";
    REPORTS_PATH["PAYMENT_METHOD_RECEIPT"] = "/reports/paymentmethodreceipt";
    REPORTS_PATH["SHIFT_REPORT"] = "/reports/shiftreport";
    // anchor - for code generator
})(REPORTS_PATH || (REPORTS_PATH = {}));
export var DATE_SEPARATOR = "-";
export var MONTH_FORMAT = "MM".concat(DATE_SEPARATOR, "YYYY");
export var MONTH_DISPLAY_FORMAT = "MMM YYYY";
export var DATE_FORMAT = "DD".concat(DATE_SEPARATOR, "MM").concat(DATE_SEPARATOR, "YYYY");
export var DATE_DISPLAY_FORMAT = "D MMM YYYY";
export var DATE_TIME_FORMAT = "D MMM YYYY, h:mm A";
// specify the starting month of reports here
export var REPORTS_START = {
    MONTH: 11,
    YEAR: 2021,
};
export var REPORTS_QUERY_PARAM;
(function (REPORTS_QUERY_PARAM) {
    REPORTS_QUERY_PARAM["COMPANY"] = "company_id";
    REPORTS_QUERY_PARAM["LOCATION"] = "location_id";
    REPORTS_QUERY_PARAM["BRAND"] = "brand_id";
    REPORTS_QUERY_PARAM["BUSINESS"] = "business_id";
    REPORTS_QUERY_PARAM["BBID"] = "bbid";
    REPORTS_QUERY_PARAM["MONTH"] = "month";
    REPORTS_QUERY_PARAM["YEAR"] = "year";
    REPORTS_QUERY_PARAM["MONTHYEAR"] = "month_year";
    REPORTS_QUERY_PARAM["STALL_TYPE"] = "stall_type";
    REPORTS_QUERY_PARAM["STATUS"] = "status";
    REPORTS_QUERY_PARAM["BUSINESS_DATE"] = "business_date";
    REPORTS_QUERY_PARAM["DATE"] = "date";
    REPORTS_QUERY_PARAM["END_DATE"] = "end_date";
    REPORTS_QUERY_PARAM["FROM"] = "from";
    REPORTS_QUERY_PARAM["TO"] = "to";
})(REPORTS_QUERY_PARAM || (REPORTS_QUERY_PARAM = {}));
export var MAX_DAYS_ALLOWED_FOR_DSER_EDITING = 1;
export var DISPLAY_DATE_PICKER_FORMAT = "ddd, DD MMM YYYY";
export var ONLINE_CHANNEL_TYPES = ["FAIRPRICE"];
export var CHANNEL_TYPE;
(function (CHANNEL_TYPE) {
    CHANNEL_TYPE["Online"] = "online";
    CHANNEL_TYPE["OnSite"] = "onsite";
})(CHANNEL_TYPE || (CHANNEL_TYPE = {}));
export var SALES_SUMMARY_QUERY_PARAM;
(function (SALES_SUMMARY_QUERY_PARAM) {
    SALES_SUMMARY_QUERY_PARAM["BUSINESS_BRANCH"] = "business_branch_ids";
    SALES_SUMMARY_QUERY_PARAM["CHANNEL"] = "channels";
    SALES_SUMMARY_QUERY_PARAM["START_DATE"] = "start_date";
    SALES_SUMMARY_QUERY_PARAM["END_DATE"] = "end_date";
    SALES_SUMMARY_QUERY_PARAM["BRAND_IDS"] = "brand_ids";
    SALES_SUMMARY_QUERY_PARAM["BUSINESS_IDS"] = "business_ids";
    SALES_SUMMARY_QUERY_PARAM["LOCATION_IDS"] = "location_ids";
})(SALES_SUMMARY_QUERY_PARAM || (SALES_SUMMARY_QUERY_PARAM = {}));

import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useMemo } from "react";
import { PLATFORM } from "../../../common/constants";
import { usePlatform } from "../../../components/context/hooks";
export var useLoginFlow = function () {
    var _a = useAuth0(), isAuthenticated = _a.isAuthenticated, loginWithRedirect = _a.loginWithRedirect, isLoading = _a.isLoading;
    var platform = usePlatform();
    var showFPSplash = useMemo(function () {
        if (isLoading)
            return false;
        if (platform !== PLATFORM.PARTNER_CENTER)
            return false;
        return !isAuthenticated;
    }, [platform, isAuthenticated, isLoading]);
    useEffect(function () {
        if (isLoading)
            return;
        if (showFPSplash)
            return;
        loginWithRedirect({ prompt: "select_account" });
    }, [showFPSplash, isAuthenticated, loginWithRedirect, isLoading]);
    return {
        showFPSplash: showFPSplash,
    };
};

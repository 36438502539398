import { useEffect } from "react";
import { useI18nStringArray } from "../../components/i18n/hooks";
import { useIsLocalDevelopment } from "../../components/rbac/hooks/useIsLocalDevelopment";
var DEV_TAG = "(DEV)";
export var usePageTitle = function (title, includeTitle) {
    if (includeTitle === void 0) { includeTitle = true; }
    var _a = useI18nStringArray("page.title", title), defaultTitle = _a[0], i18ntitle = _a[1];
    var isDev = useIsLocalDevelopment();
    useEffect(function () {
        var finaltitle = includeTitle && i18ntitle ? "".concat(i18ntitle, " - ").concat(defaultTitle) : i18ntitle;
        var display = finaltitle || defaultTitle;
        document.title = isDev ? "".concat(DEV_TAG, " ").concat(display) : display;
        return function () {
            document.title = isDev ? "".concat(DEV_TAG, " ").concat(defaultTitle) : defaultTitle;
        };
    }, [i18ntitle, defaultTitle, includeTitle, isDev]);
};

import moment from "moment";
import { IGNORED_PAGES, PLATFORM_URL, STATIC_PAGE_CONTENT, STATIC_PAGE_PARAM, } from "./constants";
import { STATIC_PAGE_STATUS } from "./display/constants";
import { STATIC_PAGE_PLATFORM } from "./listing/api/types";
export var convertPageNameToId = function (page_name) {
    return encodeURIComponent(page_name.replace(/ /g, "_"));
};
export var convertIdToPageName = function (id) {
    return decodeURIComponent(id).replace(/_/g, " ");
};
export var pageNameToURL = function (platform, page_name) {
    if (!page_name)
        return "";
    var id = convertPageNameToId(page_name);
    if (platform === STATIC_PAGE_PLATFORM.ADMIN_PANEL) {
        return "".concat(PLATFORM_URL.ADMIN_PANEL, "?").concat(STATIC_PAGE_PARAM.ID, "=").concat(id);
    }
    return "".concat(PLATFORM_URL.PARTNER_CENTRE).concat(id);
};
export var isPagePublic = function (platform) {
    return platform === STATIC_PAGE_PLATFORM.PARTNER_CENTRE;
};
export var getSelectedContentAsset = function (assets) {
    var asset = 0, time = 0;
    assets.forEach(function (_a, idx) {
        var updated_at = _a.updated_at, content_type = _a.content_type;
        if (STATIC_PAGE_CONTENT.includes(content_type)) {
            var updated_time = moment(updated_at).valueOf();
            if (updated_time > time) {
                time = updated_time;
                asset = idx;
            }
        }
    });
    return asset;
};
export var getSelectedContent = function (data) {
    var _a;
    var idx = getSelectedContentAsset(data.static_content_assets);
    return (_a = data.static_content_assets[idx]) !== null && _a !== void 0 ? _a : null;
};
export var getStaticContentURL = function (data) {
    var _a, _b;
    if (!data)
        return "";
    return (_b = (_a = getSelectedContent(data)) === null || _a === void 0 ? void 0 : _a.content) !== null && _b !== void 0 ? _b : "";
};
export var isPageOutOfDisplayPeriod = function (_a, timeNow) {
    var start = _a.start, end = _a.end;
    if (!!start && moment(start).isAfter(timeNow))
        return true;
    if (!!end && moment(end).isBefore(timeNow))
        return true;
    return false;
};
export var getPageStatus = function (data, platform) {
    if (!data.is_enabled)
        return STATIC_PAGE_STATUS.NOT_ALLOWED;
    if (platform !== STATIC_PAGE_PLATFORM.ADMIN_PANEL &&
        data.platform !== platform)
        return STATIC_PAGE_STATUS.NOT_ALLOWED;
    var content = getSelectedContent(data);
    if (!content)
        return STATIC_PAGE_STATUS.ERROR;
    if (isPageOutOfDisplayPeriod(content, moment()))
        return STATIC_PAGE_STATUS.NOT_ALLOWED;
    return STATIC_PAGE_STATUS.ALLOWED;
};
export var isIgnoredPage = function (_a) {
    var id = _a.id;
    return IGNORED_PAGES.includes(id);
};
export var excludeIgnoredPages = function (listing) {
    return listing.filter(function (page) { return !isIgnoredPage(page); });
};

import { lazy } from "react";
import { MENU_GROUP } from "../../common/constants";
import { globalEvents } from "../../common/GlobalEvents";
import { ROUTE_EVENTS } from "../../components/routing/constants";
import { DASHBOARD_PATH } from "./constants";
/* istanbul ignore next */ // no need to test lazy loading
var LazyComponents = {
    Dashboard: lazy(function () { return import(/* webpackChunkName: "homepage" */ "./dashboard/Dashboard"); }),
};
/* istanbul ignore next */ // no need to test route config
export var dashboardRoutes = [
    {
        label: "dashboard.menu.label",
        icon: "/assets/icons/DashboardIcon.svg",
        group: MENU_GROUP.DASHBOARD,
        permissions: [],
        platforms: [],
        subRoutes: [
            {
                label: "dashboard.menu.item.main",
                route: DASHBOARD_PATH.DASHBOARD,
                component: LazyComponents.Dashboard,
                permissions: [],
                platforms: [],
            },
        ],
    },
];
export var initDashboard = function () {
    globalEvents.emit(ROUTE_EVENTS.REGISTER, dashboardRoutes);
};

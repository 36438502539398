var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../displaypage.scss";
import "../../editor.scss";
import { EuiPage, EuiPageBody, EuiPageContent, EuiPageHeader, EuiText, } from "@elastic/eui";
import { Editor } from "react-draft-wysiwyg";
import { useGetEditorStateFromMediaService } from "../../editor/api/useGetEditorStateFromMediaService";
import { getStaticContentURL } from "../../helpers";
import { STATIC_PAGE_STATUS } from "../constants";
import { useGetStaticPage } from "../hooks/useGetStaticPage";
var StaticPage = function () {
    var _a = useGetStaticPage(), data = _a.data, status = _a.status, page_name = _a.page_name;
    var contentUrl = getStaticContentURL(data);
    var msContent = useGetEditorStateFromMediaService(contentUrl).msContent;
    return (_jsx(EuiPage, __assign({ className: "PublicPage" }, { children: _jsxs(EuiPageBody, __assign({ className: "StaticPageWrapper" }, { children: [_jsx(EuiPageHeader, { children: _jsx("img", { src: "/assets/FPLogo.png", width: "200" }) }), _jsx(EuiPageContent, __assign({ hasShadow: false }, { children: status === STATIC_PAGE_STATUS.ALLOWED ? (_jsx(Editor, { contentState: msContent !== null && msContent !== void 0 ? msContent : undefined, wrapperClassName: "StaticPageWrapper", editorClassName: "StaticDisplay", readOnly: true, toolbarHidden: true })) : (_jsxs(EuiText, __assign({ className: "Status" }, { children: ["Cannot find page ", page_name] }))) }))] })) })));
};
export default StaticPage;

import { datadogRum } from "@datadog/browser-rum";
import { useEffect } from "react";
import { useUserInfo, useUserPermissions } from "../context/hooks";
export var useUpdateDatadogSessionUser = function () {
    var _a = useUserInfo(), name = _a.name, email = _a.email, checked = _a.checked;
    var permissions = useUserPermissions();
    useEffect(function () {
        if (!checked)
            return;
        datadogRum === null || datadogRum === void 0 ? void 0 : datadogRum.setUser({
            name: name,
            email: email,
            permissions: permissions,
        });
    }, [name, email, checked, permissions]);
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useCallback, useEffect, useMemo, useState } from "react";
var NO_ITEM = {};
export var useSideBarNavItemCount = function (label, updateParent) {
    var _a = useState(NO_ITEM), count = _a[0], setCount = _a[1];
    var totalCount = useMemo(function () {
        return Object.keys(count).reduce(function (total, label) {
            var _a;
            return total + ((_a = count[label]) !== null && _a !== void 0 ? _a : 0);
        }, 0);
    }, [count]);
    var updateNavItemCount = useCallback(function (label, count) {
        setCount(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[label] = count || 0, _a)));
        });
    }, []);
    useEffect(function () {
        updateParent === null || updateParent === void 0 ? void 0 : updateParent(label, totalCount);
    }, [totalCount]);
    return {
        updateNavItemCount: updateNavItemCount,
        totalCount: totalCount,
    };
};

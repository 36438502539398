import { PLATFORM } from "../../common/constants";
import { getLocalStorage } from "../../common/localstoragehelpers";
import { getSelectableLangData } from "../i18n/helpers";
import { DEFAULT_LANG_OPTION, LANG_SELECTABLES, } from "../layout/header/constants";
import { LANG_COOKIE } from "../layout/header/language/constants";
import { makeContext } from "./helpers";
var getDevelopmentPlatform = function () {
    try {
        return process.env.LOGIN_PLATFORM;
    }
    catch (e) {
        // EMPTY
    }
    return PLATFORM.ADMIN_PANEL;
};
var PLATFORM_MODE = process.env.PLATFORM_MODE || PLATFORM.DEVELOPMENT;
var DEFAULT_PLATFORM = PLATFORM_MODE === PLATFORM.DEVELOPMENT
    ? getDevelopmentPlatform()
    : PLATFORM_MODE;
export var PLATFORM_ACCESS;
(function (PLATFORM_ACCESS) {
    PLATFORM_ACCESS[PLATFORM_ACCESS["ADMIN_PANEL"] = 0] = "ADMIN_PANEL";
    PLATFORM_ACCESS[PLATFORM_ACCESS["PARTNER_CENTRE"] = 1] = "PARTNER_CENTRE";
    PLATFORM_ACCESS[PLATFORM_ACCESS["ALL"] = 2] = "ALL";
    PLATFORM_ACCESS[PLATFORM_ACCESS["KPOS"] = 3] = "KPOS";
})(PLATFORM_ACCESS || (PLATFORM_ACCESS = {}));
export var initialGeneralData = {
    isLoggedIn: false,
    language: getSelectableLangData(getLocalStorage(LANG_COOKIE, LANG_SELECTABLES[DEFAULT_LANG_OPTION].lang)),
    userInfo: {
        name: "",
        user_type: "",
        email: "",
        accessibles: {
            locations: [],
            brands: [],
            businesses: [],
        },
        checked: false,
        platform_access: PLATFORM_ACCESS.ADMIN_PANEL,
        kpos: null,
        roles: [],
        server_time: "",
        accessconfig: {},
    },
    permissions: [],
    auth0: {
        id: "",
        picture: "",
        accessToken: "",
        idToken: "",
    },
    platform: DEFAULT_PLATFORM,
    fs_config: null,
    fs_props: null,
    allaccessconfigs: {},
    loggedon: false,
};
export var initialRouteData = [];
export var initialRoutesListing = [];
var GeneralDataContext = makeContext(initialGeneralData);
var RoutesContext = makeContext(initialRouteData);
// route listing is a 1D array of all valid routes of the app
var RoutesListingContext = makeContext(initialRoutesListing);
export var AllContext = {
    GeneralDataContext: GeneralDataContext !== null && GeneralDataContext !== void 0 ? GeneralDataContext : {},
    RoutesContext: RoutesContext !== null && RoutesContext !== void 0 ? RoutesContext : {},
    RoutesListingContext: RoutesListingContext !== null && RoutesListingContext !== void 0 ? RoutesListingContext : {},
};

import { jsx as _jsx } from "react/jsx-runtime";
import { COLORS } from "../../../common/constants";
import { globalEvents } from "../../../common/GlobalEvents";
import { getI18nText } from "../../i18n/helpers";
import I18nText from "../../i18n/I18nText";
import { ALERT_EVENT, ALERT_TYPE, TOAST_COLOR, TOAST_ICON } from "./constants";
import FailureModal from "./types/FailureModal";
import SuccessModal from "./types/SuccessModal";
export var hideAlerts = function () {
    globalEvents.emit(ALERT_EVENT.HIDE);
};
export var announceMessage = function (title, content) {
    globalEvents.emit(ALERT_EVENT.SHOW, {
        type: ALERT_TYPE.MESSAGE,
        title: title,
        content: content,
    });
};
export var newToast = function (_a) {
    var title = _a.title, content = _a.content, color = _a.color, iconType = _a.iconType;
    return ({
        id: Date.now() + "",
        title: title,
        color: color,
        iconType: iconType,
        text: content,
    });
};
export var successToast = function (title, content) {
    globalEvents.emit(ALERT_EVENT.SHOW, {
        type: ALERT_TYPE.TOAST,
        title: title,
        content: content,
        iconType: TOAST_ICON.SUCCESS,
        color: TOAST_COLOR.SUCCESS,
    });
};
export var announceSimpleModal = function (_a) {
    var content = _a.content, _b = _a.buttonColor, buttonColor = _b === void 0 ? COLORS.GREENPEA500 : _b, _c = _a.onClick, onClick = _c === void 0 ? hideAlerts : _c, buttonLabel = _a.buttonLabel, _d = _a.title, title = _d === void 0 ? "" : _d, meta = _a.meta;
    globalEvents.emit(ALERT_EVENT.SHOW, {
        type: ALERT_TYPE.MESSAGE,
        title: title,
        content: content,
        buttons: [
            {
                label: buttonLabel !== null && buttonLabel !== void 0 ? buttonLabel : "common.ok",
                type: "text",
                backgroundColor: buttonColor,
                color: COLORS.SNOWWHITE,
                onClick: onClick,
            },
        ],
        meta: meta,
    });
};
export var announceSimpleModalMessage = function (_a) {
    var text = _a.text, buttonColor = _a.buttonColor, _b = _a.onClick, onClick = _b === void 0 ? hideAlerts : _b, _c = _a.title, title = _c === void 0 ? "" : _c, meta = _a.meta;
    announceSimpleModal({
        title: title,
        content: _jsx(I18nText, { token: text }),
        buttonColor: buttonColor,
        onClick: onClick,
        meta: meta,
    });
};
export var announceSimpleOptionsMessage = function (_a) {
    var text = _a.text, buttons = _a.buttons, _b = _a.title, title = _b === void 0 ? "" : _b, meta = _a.meta;
    globalEvents.emit(ALERT_EVENT.SHOW, {
        type: ALERT_TYPE.OPTION,
        title: title,
        content: typeof text === "string" ? _jsx(I18nText, { token: text }) : text,
        buttons: buttons.map(function (_a) {
            var label = _a.label, buttonColor = _a.buttonColor, onClick = _a.onClick;
            return ({
                label: getI18nText(label),
                type: "text",
                backgroundColor: buttonColor,
                color: COLORS.SNOWWHITE,
                onClick: onClick !== null && onClick !== void 0 ? onClick : hideAlerts,
            });
        }),
        meta: meta,
    });
};
export var announceFatalErrorMessage = function (_a) {
    var title = _a.title, content = _a.content, meta = _a.meta;
    globalEvents.emit(ALERT_EVENT.SHOW, {
        type: ALERT_TYPE.MESSAGE_ONLY,
        title: title,
        content: content,
        buttons: [],
        meta: meta,
    });
};
export var announceSimpleSuccessModal = function (_a) {
    var text = _a.text, buttonColor = _a.buttonColor, _b = _a.title, title = _b === void 0 ? "" : _b, meta = _a.meta;
    announceSimpleModal({
        title: title,
        content: _jsx(SuccessModal, { text: text }),
        buttonColor: buttonColor,
        meta: meta,
    });
};
export var announceSimpleFailureModal = function (_a) {
    var text = _a.text, buttonColor = _a.buttonColor, buttonLabel = _a.buttonLabel, _b = _a.title, title = _b === void 0 ? "" : _b, _c = _a.onClick, onClick = _c === void 0 ? hideAlerts : _c, meta = _a.meta;
    announceSimpleModal({
        title: title,
        content: _jsx(FailureModal, { text: text }),
        buttonColor: buttonColor,
        buttonLabel: buttonLabel,
        onClick: onClick,
        meta: meta,
    });
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { EuiEmptyPrompt, EuiLoadingSpinner, EuiPageTemplate, } from "@elastic/eui";
var LoaderSplashScreen = function () { return (_jsx(EuiPageTemplate, __assign({ template: "centeredContent", pageContentProps: { paddingSize: "none" }, style: {
        position: "fixed",
        height: "100vh",
        width: "100%",
        overflow: "none",
    } }, { children: _jsx(EuiEmptyPrompt, { body: _jsx(EuiLoadingSpinner, { size: "xl" }), style: {
            backgroundColor: "#ffffff",
            margin: 0,
            padding: 0,
        } }) }))); };
export default LoaderSplashScreen;

import { COLORS } from "../../common/constants";
import { reloadWindow } from "../../common/helpers";
import { announceSimpleModalMessage } from "../modals/alerts/helpers";
export var showOutdatedBuildModal = function () {
    setTimeout(function () {
        announceSimpleModalMessage({
            text: "error.outdatedversion",
            buttonColor: COLORS.SHIRAZ500,
            onClick: reloadWindow,
        });
    }, 1000);
};

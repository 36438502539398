export var ModalStatus;
(function (ModalStatus) {
    ModalStatus["Success"] = "success";
    ModalStatus["Error"] = "error";
})(ModalStatus || (ModalStatus = {}));
export var ModalType;
(function (ModalType) {
    ModalType["Alerts"] = "alerts";
    ModalType["Info"] = "info";
})(ModalType || (ModalType = {}));
export var ModalInfoType;
(function (ModalInfoType) {
    ModalInfoType["PriceHistory"] = "PriceHistory";
})(ModalInfoType || (ModalInfoType = {}));

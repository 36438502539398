var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect, useMemo } from "react";
import { useUpdateFSConfigApi } from "../api/fs/configuration/useUpdateFSConfigApi";
import { useFSConfigJSON } from "../context/fs/useFSConfigJSON";
export var useUpdateLatestBuild = function (latest_build) {
    var oldConfig = useFSConfigJSON();
    var newConfig = useMemo(function () {
        if (!latest_build)
            return undefined;
        if (!oldConfig)
            return undefined;
        return __assign(__assign({}, oldConfig), { latest_build: latest_build });
    }, [latest_build, oldConfig]);
    var putData = useUpdateFSConfigApi(newConfig).putData;
    useEffect(function () {
        if (!newConfig)
            return;
        putData();
    }, [putData, newConfig]);
};

import { lazy } from "react";
import { MENU_GROUP, PLATFORM } from "../../../common/constants";
import { globalEvents } from "../../../common/GlobalEvents";
import { PERMISSION } from "../../../components/rbac/permissions";
import { ROUTE_EVENTS } from "../../../components/routing/constants";
import { OUTLETS_PATH } from "./constants";
/* istanbul ignore next */ // no need to test lazy loading
var LazyComponents = {
    OutletsList: lazy(function () { return import(/* webpackChunkName: "OutletList" */ "./OutletsList"); }),
    OutletsDetail: lazy(function () { return import(/* webpackChunkName: "OutletDetail" */ "./OutletsDetail"); }),
    StallDetail: lazy(function () { return import(/* webpackChunkName: "fs-stalldetail" */ "./StallDetail"); }),
    AddingPOSDevice: lazy(function () {
        return import(/* webpackChunkName: "fs-addingposdevice" */ "./AddingPOSDevices");
    }),
    AddingStall: lazy(function () { return import(/* webpackChunkName: "fs-addingstall" */ "./AddingStall"); }),
    AddLocationUnit: lazy(function () {
        return import(/* webpackChunkName: "fs-addlocationunit" */ "./AddLocationUnit");
    }),
    AddOutlets: lazy(function () {
        return import(/* webpackChunkName: "fs-addoutlets" */ "./AddOutlets/AddOutlets");
    }),
    // anchor - for code generator
};
/* istanbul ignore next */ // no need to test routes
export var outletsRoutes = [
    {
        label: "outlets.menu.group",
        route: "",
        permissions: [
            PERMISSION.FS_LOCATION_MANAGE,
            PERMISSION.FS_LOCATION_VIEW,
            PERMISSION.FS_BB_LIMITED_MANAGE,
            PERMISSION.FS_BB_LIMITED_VIEW,
        ],
        icon: "mapMarker",
        platforms: [PLATFORM.ADMIN_PANEL],
        group: MENU_GROUP.FOOD_SERVICES,
        subRoutes: [
            {
                label: "outlets.menu.label",
                route: OUTLETS_PATH.OUTLETS_LIST,
                component: LazyComponents.OutletsList,
                group: MENU_GROUP.FOOD_SERVICES,
                permissions: [
                    PERMISSION.FS_LOCATION_MANAGE,
                    PERMISSION.FS_LOCATION_VIEW,
                    PERMISSION.FS_BB_LIMITED_VIEW,
                ],
                platforms: [PLATFORM.ADMIN_PANEL],
            },
            {
                label: "",
                route: OUTLETS_PATH.OUTLETS_DETAIL,
                component: LazyComponents.OutletsDetail,
                permissions: [
                    PERMISSION.FS_LOCATION_MANAGE,
                    PERMISSION.FS_LOCATION_VIEW,
                    PERMISSION.FS_BB_LIMITED_VIEW,
                ],
                hidden: true,
                group: MENU_GROUP.FOOD_SERVICES,
                platforms: [PLATFORM.ADMIN_PANEL],
            },
            {
                label: "",
                route: OUTLETS_PATH.STALL_DETAIL,
                component: LazyComponents.StallDetail,
                permissions: [
                    PERMISSION.FS_LOCATION_MANAGE,
                    PERMISSION.FS_LOCATION_VIEW,
                    PERMISSION.FS_BB_LIMITED_VIEW,
                ],
                hidden: true,
                platforms: [PLATFORM.ADMIN_PANEL],
            },
            {
                label: "",
                route: OUTLETS_PATH.ADDING_POS_DEVICE,
                component: LazyComponents.AddingPOSDevice,
                permissions: [
                    PERMISSION.FS_LOCATION_MANAGE,
                    PERMISSION.FS_BB_LIMITED_MANAGE,
                ],
                hidden: true,
                platforms: [PLATFORM.ADMIN_PANEL],
            },
            {
                label: "",
                route: OUTLETS_PATH.ADDING_STALL,
                component: LazyComponents.AddingStall,
                permissions: [PERMISSION.FS_LOCATION_MANAGE],
                hidden: true,
                platforms: [],
            },
            {
                label: "outlets.addlocationunit.label",
                route: OUTLETS_PATH.ADD_LOCATION_UNIT,
                component: LazyComponents.AddLocationUnit,
                permissions: [PERMISSION.FS_LOCATION_MANAGE],
                hidden: true,
                platforms: [PLATFORM.ADMIN_PANEL],
            },
            {
                label: "",
                route: OUTLETS_PATH.ADD_OUTLETS,
                component: LazyComponents.AddOutlets,
                permissions: [PERMISSION.FS_LOCATION_MANAGE],
                hidden: true,
                platforms: [],
            },
            // anchor - for code generator
        ],
    },
];
export var initFSOutlets = function () {
    globalEvents.emit(ROUTE_EVENTS.REGISTER, outletsRoutes);
};

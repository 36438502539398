var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DSR_STALL_TYPE } from "../../../../pages/fs/reports/dailysalesentry/view/context/constants";
import { flattenDataAttributes } from "../helpers";
import { BUSINESS_TAG } from "./types";
export var businessDTOAdapter = function (_a) {
    var data = _a.data;
    return data.map(function (business) {
        return __assign({}, flattenDataAttributes(business));
    });
};
export var businessToSelection = function (businesses) {
    return businesses.map(function (_a) {
        var id = _a.id, name = _a.name;
        return ({
            value: id + "",
            inputDisplay: name,
        });
    });
};
export var checkBusinessType = function (tags) {
    var is3df = tags.includes(BUSINESS_TAG.DDDF);
    var isWang = tags.includes(BUSINESS_TAG.WANG);
    if (is3df)
        return DSR_STALL_TYPE.DDDF;
    if (isWang)
        return DSR_STALL_TYPE.WANG;
    return DSR_STALL_TYPE.NON3DF;
};

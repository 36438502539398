import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { globalEvents } from "../../../common/GlobalEvents";
import { useFSConfigJSON } from "../../context/fs/useFSConfigJSON";
import { DEFAULT_SESSION_TIMEOUT, SESSION_TRACKER } from "./constants";
export var useSessionTracker = function () {
    var idleTime = useRef(DEFAULT_SESSION_TIMEOUT);
    var config = useFSConfigJSON();
    useEffect(function () {
        if (!(config === null || config === void 0 ? void 0 : config.idle_auto_logout))
            return;
        idleTime.current = config.idle_auto_logout * 60 * 1000;
    }, [config]);
    var timestamp = useRef(moment());
    var _a = useState(false), invalidSession = _a[0], setInvalidSession = _a[1];
    var ping = useCallback(function () {
        timestamp.current = moment();
    }, []);
    var check = useCallback(function () {
        var current = moment();
        if (current.diff(timestamp.current, "ms") >= idleTime.current) {
            setInvalidSession(true);
        }
    }, []);
    useEffect(function () {
        globalEvents.on(SESSION_TRACKER.PING, ping);
        globalEvents.on(SESSION_TRACKER.CHECK, check);
        return function () {
            globalEvents.off(SESSION_TRACKER.PING, ping);
            globalEvents.off(SESSION_TRACKER.CHECK, check);
        };
    }, []);
    return invalidSession;
};

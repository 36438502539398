import { BOOLEAN_FLAG } from "../../fs/constants";
import { useFSCheckBooleanFlag } from "../../fs/useFSCheckBooleanFlag";
import { useFSDictionaryStorage } from "../hooks";
import { DICTIONARY_KEY } from "../types";
import { BRANDS_API, BRANDS_API_V2 } from "./constants";
import { brandDTOAdapter } from "./helpers";
var BrandsDictionary = function () {
    var useNewApi = useFSCheckBooleanFlag(BOOLEAN_FLAG.NEW_ACL_API_1, true);
    useFSDictionaryStorage(useNewApi ? BRANDS_API_V2 : BRANDS_API, DICTIONARY_KEY.BRANDS, brandDTOAdapter);
    return null;
};
export default BrandsDictionary;

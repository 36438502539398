var _a;
import { useMemo } from "react";
import { jsonApiAdapter } from "../../../../common/jsonapi/jsonapi";
import { FS_GATEWAY_HOST } from "../../../../components/api/fs/constants";
import { useAxiosGet } from "../../../../components/hooks/useAxiosGet";
import { STATIC_PAGE_PARAM } from "../../constants";
import { convertIdToPageName, getPageStatus } from "../../helpers";
import { STATIC_PAGE_PLATFORM } from "../../listing/api/types";
import { STATIC_PAGE_STATUS } from "../constants";
import { useStaticPagePlatform } from "../hooks/useStaticPagePlatform";
var STATIC_PAGE_DETAILS = (_a = {},
    _a[STATIC_PAGE_PLATFORM.ADMIN_PANEL] = "".concat(FS_GATEWAY_HOST).concat(process.env.STATIC_PAGE_DETAILS || ""),
    _a[STATIC_PAGE_PLATFORM.PARTNER_CENTRE] = "".concat(FS_GATEWAY_HOST).concat(process.env.STATIC_PAGE_PUBLIC_DETAILS || ""),
    _a);
export var useGetStaticContent = function (directId, key) {
    if (key === void 0) { key = 0; }
    var platform = useStaticPagePlatform();
    var page_name = useMemo(function () { return convertIdToPageName(directId); }, [directId]);
    var url = useMemo(function () {
        if (!directId)
            return "";
        var paramId = encodeURIComponent(page_name);
        return "".concat(STATIC_PAGE_DETAILS[platform], "?").concat(STATIC_PAGE_PARAM.ID, "=").concat(paramId, "&published=true");
    }, [platform, page_name]);
    var _a = useAxiosGet(url, key), response = _a.response, getError = _a.getError;
    var data = useMemo(function () {
        if (!response)
            return null;
        return jsonApiAdapter(response);
    }, [response]);
    var status = useMemo(function () {
        if (getError)
            return STATIC_PAGE_STATUS.ERROR;
        if (!data)
            return STATIC_PAGE_STATUS.NIL;
        return getPageStatus(data, platform);
    }, [getError, data, platform]);
    return {
        data: data,
        status: status,
        page_name: page_name,
    };
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import ContextProvider from "../ContextProvider";
import { useIsUserLoggedIn } from "../hooks";
import BrandsDictionary from "./brands/BrandsDictionary";
import BusinessDictionary from "./business/BusinessDictionary";
import BusinessBranchesDictionary from "./businessbranches/BusinessBranchesDictionary";
import CompanyBrandBusinessDictionary from "./cbb/CompanyBrandBusinessDictionary";
import CompanyDictionary from "./companies/CompanyDictionary";
import { DictionaryContext, initialDictionaryData } from "./constants";
import LocationsDictionary from "./locations/LocationsDictionary";
var DictionaryContextProvider = function (_a) {
    var children = _a.children;
    var loggedIn = useIsUserLoggedIn();
    return (_jsxs(ContextProvider, __assign({ initialState: initialDictionaryData, Context: DictionaryContext }, { children: [loggedIn && (_jsxs(_Fragment, { children: [_jsx(LocationsDictionary, {}), _jsx(BusinessDictionary, {}), _jsx(BrandsDictionary, {}), _jsx(CompanyDictionary, {}), _jsx(BusinessBranchesDictionary, {}), _jsx(CompanyBrandBusinessDictionary, {})] })), children] })));
};
export default DictionaryContextProvider;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { intersectList, mergeStringArray } from "../../../../common/helpers";
import { ACCESS_PERMISSION_PREFIX, ACCESS_PERMISSION_PROP } from "./constants";
export var accessPermissionName = function (permission) {
    return "".concat(ACCESS_PERMISSION_PREFIX).concat(permission);
};
export var removeAccessPermissionPrefix = function (permission) {
    return permission.split(ACCESS_PERMISSION_PREFIX).join("");
};
export var isAccessPermission = function (permission) {
    return permission.startsWith(ACCESS_PERMISSION_PREFIX);
};
var mergeAccess = function (key, a1, a2) { var _a, _b; return mergeStringArray((_a = a1[key]) !== null && _a !== void 0 ? _a : [], (_b = a2[key]) !== null && _b !== void 0 ? _b : []); };
export var mergeAccessConfigs = function (curr, add) {
    return Object.keys(ACCESS_PERMISSION_PROP).reduce(function (config, key) {
        var _a;
        var prop = ACCESS_PERMISSION_PROP[key];
        return __assign(__assign({}, config), (_a = {}, _a[prop] = mergeAccess(prop, curr, add), _a));
    }, {});
};
export var getAccessGroupsForPermissions = function (configs, permissions) {
    return permissions.reduce(function (groups, perm) {
        var _a;
        return configs[perm]
            ? __assign(__assign({}, groups), (_a = {}, _a[perm] = configs[perm], _a)) : groups;
    }, {});
};
export var hasMatchingConfig = function (allowaccessconfig, useraccessconfig) {
    var nospec = true;
    var gotmatch = Object.keys(ACCESS_PERMISSION_PROP).reduce(function (match, key) {
        var _a, _b, _c;
        var prop = ACCESS_PERMISSION_PROP[key];
        nospec = nospec && !((_a = allowaccessconfig[prop]) === null || _a === void 0 ? void 0 : _a.length);
        if (!((_b = allowaccessconfig[prop]) === null || _b === void 0 ? void 0 : _b.length))
            return match;
        return (intersectList(allowaccessconfig[prop], (_c = useraccessconfig[prop]) !== null && _c !== void 0 ? _c : [])
            .length > 0 && match);
    }, true);
    return nospec ? false : gotmatch;
};
export var packageAsPermissionConfig = function (brands, businesses) {
    var _a;
    return _a = {},
        _a[ACCESS_PERMISSION_PROP.BRAND_IDS] = brands,
        _a[ACCESS_PERMISSION_PROP.BUSINESS_IDS] = businesses,
        _a;
};

import { ModalType } from "../../../pages/qc/components/modal/types";
export var initialQCContextState = {
    modal: {
        data: {
            title: "",
            message: "",
            confirmationText: "",
            discardText: "",
            onConfirm: function () {
                //
            },
            onDiscard: function () {
                //
            },
            type: ModalType.Alerts,
        },
        visible: false,
    },
    newCategories: [],
    newCategoryError: "",
    newMenuItem: {
        name: "",
        nameCn: "",
        description: "",
        price: 0.0,
        containerFee: 0.0,
        customisationPrice: 0.0,
        detailImg: "",
        listingImg: "",
        categoryId: "0",
        available: true,
        promotionalItem: false,
        fst: {
            id: 1,
            sapMaterialCode: "100",
            price: 100,
            name: "test",
        },
    },
    newMenuItemError: "",
    refetch: {
        menuItemById: false,
    },
    menuItemsData: [],
    customisations: {
        items: [],
        pagedItems: [],
    },
    menus: {
        items: [],
        pagedItems: [],
        activeMenuId: "1",
    },
    categoriesToDelete: [],
    menuToCopy: [],
    restaurants: {
        items: [],
        pagedItems: [],
    },
    imageUpdateJobs: {
        items: [],
        pagedItems: [],
        search: "",
    },
    userManagement: {
        selections: {
            brands: [],
            locations: [],
        },
    },
};
export var initialQCStoreContext = {
    state: initialQCContextState,
    dispatch: function () {
        //
    },
};
export var ONLINE_ACTIVITIES = [
    {
        id: 1,
        name: "Online",
    },
    {
        id: 0,
        name: "Offline",
    },
];

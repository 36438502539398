import { useMemo } from "react";
import { useAccessToken } from "../context/hooks";
import { addJWTInConfig } from "./helpers";
export var useJWTHeader = function (config, noJWT) {
    var jwt = useAccessToken();
    return useMemo(function () {
        if (!!noJWT)
            return config;
        return addJWTInConfig(config, jwt);
    }, [config, jwt, noJWT]);
};

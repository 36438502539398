var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { BUSINESS_TAG } from "../business/types";
import { locationLabel } from "../locations/helpers";
export var createNewBusinessBranchData = function () { return ({
    business_branch_id: 0,
    location: {
        id: 0,
        name: "",
        description: "",
        unit_id: 0,
        unit_no: "",
        unit_name: "",
        unit_wing_section: "",
        portfolio: null,
        branding: null,
        h24: false,
        branding_logo: "",
    },
    business: {
        id: 0,
        name: "",
        description: "",
        tags: [],
        gst_company_name: "",
        gst_inclusive: true,
        gst_reg_no: "",
        co_reg_no: "",
        uen: "",
    },
    brand: {
        id: 0,
        name: "",
    },
    company: {
        id: 0,
        name: "",
        tms_tenant_ids: [],
    },
    cost_centre: "",
    plant_location: "",
    profit_centre: "",
    extended_ops_hours: false,
    receipt_template: 0,
    storage_location: "",
    created_at: "",
    updated_at: "",
    deleted_at: "",
    updated_by: "",
    additional_sap_mapping: {},
    business_date: "",
    capita_machine_id: "",
    daily_cash_variance_threshold: 0,
    monthly_cash_variance_threshold: 0,
    tms_cash_refund: true,
    tms_merchant_id: "",
    tms_contract_id: "",
    uen: "",
    gst_company_name: "",
    gst_reg_no: "",
    co_reg_no: "",
    uid: "",
    restaurant_mode: false,
    no_of_tables: 0,
    discounts: [],
    loyalties: [],
    bestseller: false,
    qty_of_receipt: 1,
    queue_printing: false,
    business_branch_sales_platform: {
        pos_no: "",
        partner_names: [],
    },
    blind_shortage_alert: false,
    tax_configs: [],
    external_operator: null,
    shift_management: false,
    partial_payment: false,
}); };
export var findInfo = function (arr, find_id) {
    var _a;
    return (_a = arr.filter(function (_a) {
        var id = _a.id;
        return id + "" === find_id;
    })) === null || _a === void 0 ? void 0 : _a[0];
};
export var compileBusinessBranchDataFromLocations = function (locations) {
    if (!(locations === null || locations === void 0 ? void 0 : locations.length))
        return [];
    var bb = [];
    locations.forEach(function (_a) {
        var id = _a.id, units = _a.units, name = _a.name, description = _a.description, portfolio = _a.portfolio, branding = _a.branding, h24 = _a.h24, branding_logo = _a.branding_logo;
        units === null || units === void 0 ? void 0 : units.forEach(function (_a) {
            var business_ids = _a.business_ids, business_branch_ids = _a.business_branch_ids, unit_name = _a.name, unit_no = _a.unit_no, wing_section = _a.wing_section, unit_id = _a.id;
            business_branch_ids.forEach(function (business_branch_id, idx) {
                var newbb = createNewBusinessBranchData();
                newbb.business_branch_id = business_branch_id;
                newbb.business.id = business_ids[idx];
                newbb.location.unit_name = unit_name;
                newbb.location.unit_id = parseInt(unit_id, 10);
                newbb.location.unit_no = unit_no;
                newbb.location.unit_wing_section = wing_section;
                newbb.location.id = parseInt(id, 10);
                newbb.location.name = name;
                newbb.location.description = description;
                newbb.location.portfolio = portfolio;
                newbb.location.branding = branding;
                newbb.location.h24 = !!h24;
                newbb.location.branding_logo = branding_logo !== null && branding_logo !== void 0 ? branding_logo : "";
                bb.push(newbb);
            });
        });
    });
    return bb;
};
export var fillInBusinessInfo = function (businessBranch, businesses) {
    if (!(businesses === null || businesses === void 0 ? void 0 : businesses.length))
        return [];
    if (!(businessBranch === null || businessBranch === void 0 ? void 0 : businessBranch.length))
        return [];
    var i = 0;
    while (i < businessBranch.length) {
        var business = findInfo(businesses, businessBranch[i].business.id + "");
        if (!business) {
            businessBranch.splice(i, 1);
            continue;
        }
        businessBranch[i].business.name = business.name;
        businessBranch[i].business.description = business.description || "";
        businessBranch[i].business.tags = business.tags;
        businessBranch[i].brand.id = business.brand_id;
        businessBranch[i].company.id = business.company_id;
        i++;
    }
    return businessBranch;
};
export var fillInNameInfo = function (key, bb, infolist) {
    if (!(infolist === null || infolist === void 0 ? void 0 : infolist.length))
        return [];
    if (!(bb === null || bb === void 0 ? void 0 : bb.length))
        return [];
    var i = 0;
    while (i < bb.length) {
        var info = findInfo(infolist, bb[i][key].id + "");
        if (!info) {
            bb.splice(i, 1);
            continue;
        }
        bb[i][key] = __assign(__assign(__assign({}, bb[i][key]), info), { id: Number(info.id) });
        i++;
    }
    return bb;
};
export var findBrandNameFromBusinessBranches = function (business_branches, brand_id) {
    var _a;
    if (!brand_id)
        return "";
    if (!business_branches.length)
        return "";
    return (((_a = business_branches.filter(function (_a) {
        var id = _a.brand.id;
        return id + "" === brand_id;
    })[0]) === null || _a === void 0 ? void 0 : _a.brand.name) || "");
};
export var findBusinessNameFromBusinessBranches = function (business_branches, business_id) {
    var _a;
    if (!business_id)
        return "";
    if (!business_branches.length)
        return "";
    return (((_a = business_branches.filter(function (_a) {
        var id = _a.business.id;
        return id + "" === business_id;
    })[0]) === null || _a === void 0 ? void 0 : _a.business.name) || "");
};
export var findLocationNameFromBusinessBranches = function (business_branches, location_id) {
    var _a;
    if (!location_id)
        return "";
    if (!business_branches.length)
        return "";
    var location = (_a = business_branches.filter(function (_a) {
        var id = _a.location.id;
        return id + "" === location_id;
    })[0]) === null || _a === void 0 ? void 0 : _a.location;
    return location ? locationLabel(location) : "";
};
export var findCompanyNameFromBUsinessBranches = function (business_branches, company_id) {
    var _a;
    if (!company_id)
        return "";
    if (!business_branches.length)
        return "";
    return (((_a = business_branches.filter(function (_a) {
        var id = _a.company.id;
        return id + "" === company_id;
    })[0]) === null || _a === void 0 ? void 0 : _a.company.name) || "");
};
export var filterBusinessBranch = function (business_branches, companies, brands, businesses, locations, bbids) {
    var bb = __spreadArray([], business_branches, true);
    if (companies.length)
        bb = bb.filter(function (_a) {
            var id = _a.company.id;
            return companies.includes(id);
        });
    if (brands === null || brands === void 0 ? void 0 : brands.length)
        bb = bb.filter(function (_a) {
            var id = _a.brand.id;
            return brands.includes(id);
        });
    if (businesses === null || businesses === void 0 ? void 0 : businesses.length)
        bb = bb.filter(function (_a) {
            var id = _a.business.id;
            return businesses.includes(id);
        });
    if (locations === null || locations === void 0 ? void 0 : locations.length)
        bb = bb.filter(function (_a) {
            var id = _a.location.id;
            return locations.includes(id);
        });
    if (bbids === null || bbids === void 0 ? void 0 : bbids.length)
        bb = bb.filter(function (_a) {
            var business_branch_id = _a.business_branch_id;
            return bbids.includes(business_branch_id);
        });
    return bb;
};
export var filterActiveBusinessBranches = function (bb) {
    return bb.filter(function (_a) {
        var deleted_at = _a.deleted_at;
        return !deleted_at;
    });
};
export var filterDeletedBusinessBranches = function (bb) {
    return bb.filter(function (_a) {
        var deleted_at = _a.deleted_at;
        return !!deleted_at;
    });
};
export var filterBusinessBranchByLocationAndStallNo = function (business_branches, location_id, stall_no) {
    var _a;
    return (_a = business_branches.filter(function (_a) {
        var _b = _a.location, id = _b.id, unit_no = _b.unit_no;
        return id === location_id && unit_no === stall_no;
    })) === null || _a === void 0 ? void 0 : _a[0];
};
export var is3DFBusinessBranch = function (_a) {
    var tags = _a.business.tags;
    return tags.includes(BUSINESS_TAG.DDDF);
};
export var isWangBusinessBranch = function (_a) {
    var tags = _a.business.tags;
    return tags.includes(BUSINESS_TAG.WANG);
};
export var isNon3DFBusinessBranch = function (_a) {
    var tags = _a.business.tags;
    return !tags.includes(BUSINESS_TAG.DDDF) && !tags.includes(BUSINESS_TAG.WANG);
};
export var onlyNonExpiredTaxes = function (taxes) { var _a; return (_a = taxes === null || taxes === void 0 ? void 0 : taxes.filter(function (_a) {
    var expired = _a.expired;
    return !expired;
})) !== null && _a !== void 0 ? _a : []; };
export var filterByContractId = function (bb, contractId) {
    return contractId
        ? bb.filter(function (_a) {
            var tms_contract_id = _a.tms_contract_id;
            return tms_contract_id === contractId;
        })
        : [];
};

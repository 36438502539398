import { useMemo } from "react";
import { jsonApiAdapter } from "../../../common/jsonapi/jsonapi";
import { combineQueryStrings } from "../../../common/stringutils";
import { useAxiosGet } from "../../hooks/useAxiosGet";
import { useGenericAxiosErrorToast } from "../../modals/alerts/hooks";
import { FS_GATEWAY_HOST } from "../fs/constants";
import { BACKOFFICE_CONFIG_PARAM } from "./constants";
var BACKOFFICE_CONFIGS_API = "".concat(FS_GATEWAY_HOST).concat(process.env.BACKOFFICE_CONFIGS_API || "");
export var useBackOfficeConfig = function () {
    var config = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        config[_i] = arguments[_i];
    }
    var url = useMemo(function () {
        if (!config.length)
            return "";
        var query = combineQueryStrings(BACKOFFICE_CONFIG_PARAM, config.join(","));
        if (!query)
            return "";
        return "".concat(BACKOFFICE_CONFIGS_API, "?").concat(query);
    }, [config]);
    var _a = useAxiosGet(url), response = _a.response, getError = _a.getError;
    useGenericAxiosErrorToast(url, getError);
    return useMemo(function () {
        if (!response)
            return [];
        return jsonApiAdapter(response);
    }, [response]);
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./Alert.scss";
import { EuiFlexGroup, EuiFlexItem, EuiIcon } from "@elastic/eui";
import React from "react";
import { COLORS } from "../../../../common/constants";
import I18nText from "../../../../components/i18n/I18nText";
var Alert = function (_a) {
    var content = _a.content, title = _a.title, isSuccess = _a.isSuccess, _b = _a.showIcon, showIcon = _b === void 0 ? true : _b;
    return (_jsxs(EuiFlexGroup, __assign({ direction: "column", justifyContent: "center", alignItems: "center", gutterSize: "m", className: "MenusAlert" }, { children: [showIcon && (_jsx(EuiFlexItem, { children: isSuccess ? (_jsx(EuiIcon, { type: "checkInCircleFilled", color: COLORS.GREENPEA500, size: "xxl", className: "MenusAlertIcon" })) : (_jsx(EuiIcon, { className: "MenusAlertIcon", type: "/assets/icons/Alert.svg" })) })), _jsx(EuiFlexItem, { children: _jsx("h2", __assign({ className: "MenusAlertTitle" }, { children: _jsx(I18nText, { token: title }) })) }), _jsx(EuiFlexItem, { children: _jsx(I18nText, { className: "MenusAlertText", token: content }) })] })));
};
export default Alert;

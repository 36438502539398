var _a;
import { useEffect } from "react";
import { globalEvents } from "../../../../common/GlobalEvents";
import { useDelayedRun } from "../../../../common/hooks/useDelayedRun";
import { FS_GATEWAY_HOST } from "../../../api/fs/constants";
import { useUserInfo } from "../../../context/hooks";
import { useAxiosGetJsonApi } from "../../../hooks/useAxiosGetJsonApi";
import { useLogout } from "../../../layout/sidenav/logout/hooks";
import { SESSION_TRACKER } from "../../sessiontracker/constants";
import { announceLogout, serverVsLocalTimeWithinVariance } from "./helpers";
var SERVERTIME_CHECK = 5 * 60 * 1000; // ms
var SERVERTIME_API = "".concat(FS_GATEWAY_HOST).concat((_a = process.env.SERVERTIME_API) !== null && _a !== void 0 ? _a : "");
var payload = {
    url: SERVERTIME_API,
    manualTrigger: true,
    showLoader: false,
};
export var useServerTime = function () {
    var checked = useUserInfo().checked;
    var logout = useLogout();
    var timedRun = useDelayedRun(SERVERTIME_CHECK);
    var _a = useAxiosGetJsonApi(payload), getData = _a.getData, getError = _a.getError, jsonApi = _a.jsonApi;
    useEffect(function () {
        if (!checked)
            return;
        if (!jsonApi)
            return;
        if (!serverVsLocalTimeWithinVariance(jsonApi.servertime)) {
            announceLogout("error.time.mismatch", logout);
            return;
        }
        globalEvents.emit(SESSION_TRACKER.CHECK);
        timedRun(getData);
    }, [jsonApi]);
    useEffect(function () {
        if (!checked)
            return;
        if (!getError)
            return;
        announceLogout("error.session.expired", logout);
    }, [getError]);
    useEffect(function () {
        if (!checked)
            return;
        timedRun(getData);
    }, [checked]);
};

import { useCallback } from "react";
import { DEFAULT_FS_BOOLEAN_FLAG_VALUE } from "./constants";
import { useFSConfigJSON } from "./useFSConfigJSON";
export var useFSBooleanFlag = function () {
    var json = useFSConfigJSON();
    return useCallback(function (flag, defaultValue) {
        if (defaultValue === void 0) { defaultValue = DEFAULT_FS_BOOLEAN_FLAG_VALUE; }
        if (!flag)
            return defaultValue;
        if (json && typeof json[flag] === "boolean") {
            return json[flag];
        }
        return defaultValue;
    }, [json]);
};

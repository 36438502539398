import { useCallback, useEffect, useState } from "react";
export var useGetEditorStateFromMediaService = function (url) {
    var _a = useState(null), msContent = _a[0], setMsContent = _a[1];
    var _b = useState(false), msError = _b[0], setMSError = _b[1];
    var getResponse = useCallback(function () {
        fetch(url)
            .then(function (res) {
            if (res.ok) {
                return res.json();
            }
            throw res;
        })
            .then(function (out) {
            setMsContent(out);
        })
            .catch(function (err) {
            setMSError(true);
        });
    }, [url]);
    useEffect(function () {
        if (!url)
            return;
        getResponse();
    }, [url]);
    return {
        msContent: msContent,
        msError: msError,
    };
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { EuiCheckbox } from "@elastic/eui";
import moment from "moment";
import React from "react";
import { COLORS } from "../../../../../common/constants";
import { globalEvents } from "../../../../../common/GlobalEvents";
import { centsToDollarsDisplay, dollarsToCents, } from "../../../../../common/stringutils";
import { QCContextActionTypes, } from "../../../../../components/context/qc/types";
import { ALERT_EVENT, ALERT_TYPE, } from "../../../../../components/modals/alerts/constants";
import Alert from "../../../components/alert/Alert";
import { FAIRPRICE } from "../../../constants";
import { stringToNumber } from "../../../helpers";
import { getMenuNumber } from "../../../online-menus/views/manage-menus/helpers";
import { QC_ONLINE_MENU_ITEMS_CUSTOMISATIONS_BULK_URL, QC_ONLINE_MENU_ITEMS_URL, } from "../../api/constants";
import { PriceHistoryLabels } from "./types";
export var convertActiveTimeToArray = function (activeTime) {
    return Object.keys(activeTime).map(function (day) {
        return __assign({ day: day }, activeTime[day]);
    });
};
export var getNonEmptyActiveTimeArray = function (activeTime) {
    return activeTime ? convertActiveTimeToArray(activeTime) : [];
};
export var isTimeslotIsEmptyInvalid = function (activeTime) {
    var activeTimeArray = getNonEmptyActiveTimeArray(activeTime);
    return activeTimeArray.every(function (timeArray) {
        var _a;
        if (!timeArray.closed)
            return !!((_a = timeArray.timings) === null || _a === void 0 ? void 0 : _a.length) || timeArray.is24Hours;
        return true;
    });
};
export var getTimeslotClassName = function (isDisabled) {
    return isDisabled ? "TimeslotGroup TimeslotGroupDisabled" : "TimeslotGroup";
};
export var emitAlert = function (title, content, buttonLabel, isSuccess, onClick, cancelLabel, onCancelClick, defaultGreen) {
    if (cancelLabel === void 0) { cancelLabel = ""; }
    var MESSAGE = ALERT_TYPE.MESSAGE, OPTION = ALERT_TYPE.OPTION;
    var SHOW = ALERT_EVENT.SHOW, HIDE = ALERT_EVENT.HIDE;
    var GREENPEA500 = COLORS.GREENPEA500, SHIRAZ500 = COLORS.SHIRAZ500, OFFWHITE = COLORS.OFFWHITE;
    var withCancel = cancelLabel.length > 0;
    var type = withCancel ? OPTION : MESSAGE;
    var buttons = [
        {
            label: buttonLabel,
            type: "text",
            backgroundColor: isSuccess || defaultGreen || withCancel ? GREENPEA500 : SHIRAZ500,
            color: OFFWHITE,
            onClick: onClick,
        },
    ];
    if (withCancel) {
        buttons.push({
            label: cancelLabel,
            type: "text",
            backgroundColor: SHIRAZ500,
            color: OFFWHITE,
            onClick: function () {
                onCancelClick === null || onCancelClick === void 0 ? void 0 : onCancelClick();
                globalEvents.emit(HIDE);
            },
        });
    }
    globalEvents.emit(SHOW, {
        type: type,
        content: (_jsx(Alert, { title: title, isSuccess: isSuccess, content: content, showIcon: !withCancel })),
        buttons: buttons,
    });
};
export var categoryExists = function (categories, index) { return categories.indexOf(categories[index]) > -1; };
export var handleCloseModal = function (dispatch) {
    return function () {
        dispatch({
            type: QCContextActionTypes.SET_MODAL_VISIBLE,
            payload: {
                modal: {
                    visible: false,
                },
            },
        });
    };
};
export var setContextCategoryError = function (dispatch, errorText) {
    if (errorText === void 0) { errorText = ""; }
    dispatch({
        type: QCContextActionTypes.SET_CATEGORY_ERROR,
        payload: { newCategoryError: errorText },
    });
};
export var getMoneyValue = function (value) {
    var result = parseFloat(value).toFixed(2);
    return isNaN(+result) ? "0.00" : result;
};
export var getIntegerStringFromString = function (value, maxValue) {
    if (maxValue === void 0) { maxValue = 100; }
    var resultInt = parseInt(value, 10);
    if (resultInt > maxValue)
        resultInt = maxValue;
    if (resultInt < 0)
        resultInt = 0;
    return isNaN(resultInt) ? "0" : "".concat(resultInt);
};
export var getSelectedPlatformFullfillment = function (platforms, selectedPlatforms) {
    var _a;
    return (((_a = selectedPlatforms === null || selectedPlatforms === void 0 ? void 0 : selectedPlatforms.find(function (_a) {
        var id = _a.id;
        return platforms.id === id;
    })) === null || _a === void 0 ? void 0 : _a.fulfillmentMethod) || []);
};
export var getArrayOfIFoodSupplierFullfilment = function (menu) {
    return menu.platforms
        ? menu.platforms.map(function (platform) {
            return {
                foodSupplierId: menu.restaurant.id,
                platformId: platform.id,
                fsPlatform: {
                    fullfilmentMethods: getSelectedPlatformFullfillment(platform, menu.selectedPlatforms),
                },
            };
        })
        : [
            {
                foodSupplierId: menu.restaurant.id,
                platformId: FAIRPRICE,
                fsPlatform: {
                    fullfilmentMethods: [],
                },
            },
        ];
};
export var getInfoBlocksRow = function (param) {
    return [
        {
            titleLabel: "onlineCatalogs.menu.item.profile.brandAssigned",
            context: _jsx("span", { children: param.brand }),
            id: "infoClockRowKey-".concat(param.generatedId()),
        },
        {
            titleLabel: "onlineCatalogs.menu.item.profile.locationAssigned",
            context: _jsx("span", { children: param.location }),
            id: "infoClockRowKey-".concat(param.generatedId()),
        },
        {
            titleLabel: "onlineCatalogs.menu.item.profile.restaurantAssigned",
            context: _jsx("span", { children: param.restaurant }),
            id: "infoClockRowKey-".concat(param.generatedId()),
        },
        {
            titleLabel: "onlineCatalogs.menu.item.profile.setAsDefaultMenu",
            context: (_jsx(EuiCheckbox, { className: "DefaultCheckBox", id: "defaultMenuId", onChange: param.checkDefaultMenu.set, checked: param.checkDefaultMenu.get, disabled: !param.isEditable || param.isDisabled })),
            unnecessaryField: true,
            id: "infoClockRowKey-".concat(param.generatedId()),
        },
    ];
};
export var changeSelectedFulfillmentMethod = function (platforms, chosen, selectedPlatforms) {
    var newPlatformForSelect = {
        id: platforms.id,
        name: platforms.name,
        fulfillmentMethod: chosen,
    };
    if (selectedPlatforms) {
        var index = selectedPlatforms.findIndex(function (platformSelected) {
            return platformSelected.id === newPlatformForSelect.id;
        });
        if (index !== -1) {
            selectedPlatforms[index] = newPlatformForSelect;
        }
        else {
            selectedPlatforms = [newPlatformForSelect];
        }
    }
    else {
        selectedPlatforms = [newPlatformForSelect];
    }
    return selectedPlatforms;
};
export var getStructuredMenuItemFormData = function (formData) {
    if (formData) {
        var name = formData.name, nameCn = formData.nameCn, fee = formData.fee, detailImage = formData.detailImage, price = formData.price, listingImage = formData.listingImage, description = formData.description, activeHours = formData.activeHours, promotionalItem = formData.promotionalItem, fsId = formData.fsId, customisationPrice = formData.customisationPrice, available = formData.available, hidden = formData.hidden, sapMaterialCode = formData.sapMaterialCode, categoryId = formData.categoryId, unavailableUntil = formData.unavailableUntil;
        return {
            name: name,
            nameCn: nameCn,
            description: description !== null && description !== void 0 ? description : "",
            containerFee: dollarsToCents(fee),
            price: dollarsToCents(price),
            customisationPrice: dollarsToCents(customisationPrice),
            image: {
                url: detailImage,
            },
            heroBannerImage: {
                url: listingImage,
            },
            activeHours: activeHours,
            promotionalItem: promotionalItem,
            fst: { id: stringToNumber(fsId), sapMaterialCode: sapMaterialCode },
            available: available,
            hidden: hidden,
            categoryId: categoryId,
            unavailableUntil: unavailableUntil,
        };
    }
    return null;
};
export var parseCustomisationNameFromError = function (error) {
    if (error) {
        var nameLength = "name:".length;
        var start = error.indexOf("name:") + nameLength;
        var end = error.indexOf(")");
        return error.slice(start, end);
    }
    return null;
};
export var validateInputTime = function (start, end) {
    if (!start.length || !end.length)
        return true;
    var startTime = moment(start, "h:mm");
    var endTime = moment(end, "h:mm");
    return !startTime.isBefore(endTime);
};
export var getTimeWithoutSeconds = function (time) {
    var _a = time.split(":"), hh = _a[0], mm = _a[1];
    return "".concat(hh, ":").concat(mm);
};
export var getMenuItemCustomisationsOrderPayload = function (item) {
    var _a;
    if (!item)
        return null;
    var customisations = ((_a = item.customisations) === null || _a === void 0 ? void 0 : _a.map(function (_a, index) {
        var id = _a.id;
        return ({
            id: id,
            displayOrder: getMenuNumber(index, 0),
        });
    })) || [];
    return {
        customisations: customisations,
    };
};
export var getMenuItemCustomisationsReorderURL = function (menuItemCustomizations) {
    return menuItemCustomizations.length > 0
        ? QC_ONLINE_MENU_ITEMS_CUSTOMISATIONS_BULK_URL
        : QC_ONLINE_MENU_ITEMS_URL;
};
export var hasNoTimeOrDays = function (startTime, endTime, selectedDays) {
    var _a;
    return !startTime.length ||
        !endTime.length ||
        !selectedDays ||
        !((_a = Object.keys(selectedDays)) === null || _a === void 0 ? void 0 : _a.length);
};
export var getPriceHistoryLabel = function (requestLabels) {
    switch (requestLabels) {
        case PriceHistoryLabels.PRICE:
            return "onlineCatalogs.page.menu.menuItems.item.view.profile.price.label";
        case PriceHistoryLabels.CUSTUMISATION_PRICE:
            return "onlineCatalogs.page.menu.menuItems.item.view.profile.customisationPrice.label";
        case PriceHistoryLabels.CONTAINER_FEE:
            return "onlineCatalogs.page.menu.menuItems.item.view.profile.fee.label";
        default:
            return "";
    }
};
export var formatPriceHistory = function (priceHistoryKey, request) {
    var _a, _b, _c, _d;
    var previousPrice = "$".concat(centsToDollarsDisplay((_b = (_a = request === null || request === void 0 ? void 0 : request[priceHistoryKey]) === null || _a === void 0 ? void 0 : _a["previous"]) !== null && _b !== void 0 ? _b : 0));
    var newPrice = "$".concat(centsToDollarsDisplay((_d = (_c = request === null || request === void 0 ? void 0 : request[priceHistoryKey]) === null || _c === void 0 ? void 0 : _c["new"]) !== null && _d !== void 0 ? _d : 0));
    return "".concat(previousPrice, " - ").concat(newPrice);
};

import { useMemo } from "react";
import { useAxiosPut } from "../../../hooks/useAxiosPut";
import { useGenericAxiosErrorToast } from "../../../modals/alerts/hooks";
import { FS_GATEWAY_HOST } from "../constants";
import { FS_CONFIG } from "./constants";
var BACKOFFICE_CONFIGS_API = "".concat(FS_GATEWAY_HOST).concat(process.env.BACKOFFICE_CONFIGS_API || "");
export var useUpdateFSConfigApi = function (config) {
    var payload = useMemo(function () {
        if (!config)
            return null;
        return {
            name: FS_CONFIG.FEATURE_FLAGS,
            config: JSON.stringify(config),
        };
    }, [config]);
    var _a = useAxiosPut(payload ? BACKOFFICE_CONFIGS_API : "", payload), response = _a.response, putData = _a.putData, putError = _a.putError;
    useGenericAxiosErrorToast(BACKOFFICE_CONFIGS_API, putError);
    return {
        response: response,
        putData: putData,
        putError: putError,
    };
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ApiHandler from "./api/ApiHandler";
import { AllContext, initialGeneralData, initialRouteData, initialRoutesListing, } from "./constants";
import ContextProvider from "./ContextProvider";
import DictionaryContextProvider from "./dictionaries/DictionaryContextProvider";
import QCContextProvider from "./qc/QCContextProvider";
import UserIdentPoolProvider from "./userident/UserIdentPoolProvider";
var BackOfficeContext = function (_a) {
    var children = _a.children;
    return (_jsx(ContextProvider, __assign({ initialState: initialRouteData, Context: AllContext.RoutesContext }, { children: _jsx(ContextProvider, __assign({ initialState: initialRoutesListing, Context: AllContext.RoutesListingContext }, { children: _jsx(ContextProvider, __assign({ initialState: initialGeneralData, Context: AllContext.GeneralDataContext }, { children: _jsx(DictionaryContextProvider, { children: _jsx(QCContextProvider, { children: _jsxs(UserIdentPoolProvider, { children: [_jsx(ApiHandler, {}), children] }) }) }) })) })) })));
};
export default BackOfficeContext;

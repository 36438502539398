var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { COLORS } from "../../common/constants";
var DEFAULT_SVG_WIDTH = 18;
var DEFAULT_SVG_HEIGHT = 20;
export var FSIcon = function (_a) {
    var _b = _a.width, width = _b === void 0 ? DEFAULT_SVG_WIDTH : _b, _c = _a.height, height = _c === void 0 ? DEFAULT_SVG_HEIGHT : _c, _d = _a.fillColor, fillColor = _d === void 0 ? COLORS.OFFWHITE : _d, _e = _a.className, className = _e === void 0 ? "" : _e;
    var scaleWidth = width / DEFAULT_SVG_WIDTH;
    var scaleHeight = height / DEFAULT_SVG_HEIGHT;
    return (_jsx("svg", __assign({ className: className, width: width, height: height, viewBox: "0 0 ".concat(width, " ").concat(height), xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", __assign({ fill: fillColor, transform: "scale(".concat(scaleWidth, ",").concat(scaleHeight, ")") }, { children: _jsx("path", { d: "M13 4V10C13 11.1 13.9 12 15 12H16V19C16 19.55 16.45 20 17 20C17.55 20 18 19.55 18 19V1.13C18 0.48 17.39 8.9407e-08 16.76 0.15C14.6 0.68 13 2.51 13 4ZM8 7H6V1C6 0.45 5.55 0 5 0C4.45 0 4 0.45 4 1V7H2V1C2 0.45 1.55 0 1 0C0.45 0 0 0.45 0 1V7C0 9.21 1.79 11 4 11V19C4 19.55 4.45 20 5 20C5.55 20 6 19.55 6 19V11C8.21 11 10 9.21 10 7V1C10 0.45 9.55 0 9 0C8.45 0 8 0.45 8 1V7Z" }) })) })));
};

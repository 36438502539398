import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { EuiGlobalToastList } from "@elastic/eui";
import { ALERT_TYPE, TOAST_DISPLAY_TIME } from "./constants";
import { useAlertController, useAlertControllerListeners } from "./hooks";
import Message from "./types/Message";
import MessageOnly from "./types/MessageOnly";
import Options from "./types/Options";
import { useLoginAlert } from "./useLoginAlert";
var AlertController = function () {
    var _a = useAlertController(), alertParam = _a.alertParam, showAlert = _a.showAlert, hideAlert = _a.hideAlert, toasts = _a.toasts, dismissToast = _a.dismissToast;
    useAlertControllerListeners(showAlert, hideAlert);
    useLoginAlert();
    var type = alertParam.type;
    return (_jsxs(_Fragment, { children: [_jsx(EuiGlobalToastList, { toasts: toasts, dismissToast: dismissToast, toastLifeTimeMs: TOAST_DISPLAY_TIME }), type === ALERT_TYPE.MESSAGE_ONLY && _jsx(MessageOnly, { param: alertParam }), type === ALERT_TYPE.MESSAGE && _jsx(Message, { param: alertParam }), type === ALERT_TYPE.OPTION && _jsx(Options, { param: alertParam })] }));
};
export default AlertController;

export var imageJobStatusTableColumns = [
    "onlineCatalogs.page.imageUpdateJobStatus.table.column.no",
    "onlineCatalogs.page.imageUpdateJobStatus.table.column.jobId",
    "onlineCatalogs.page.imageUpdateJobStatus.table.column.jobType",
    "onlineCatalogs.page.imageUpdateJobStatus.table.column.createdBy",
    "onlineCatalogs.page.imageUpdateJobStatus.table.column.createdOn",
    "onlineCatalogs.page.imageUpdateJobStatus.table.column.status",
];
export var mockedQueryImageUpdateJobs = [
    {
        job_no: 1,
        job_type: "MENU_ITEM",
        submitted_at: "test",
        submitted_by: "user",
        is_job_complete: true,
        job_has_error: true,
    },
    {
        job_no: 2,
        job_type: "MENU_ITEM_2",
        submitted_at: "test2",
        submitted_by: "user2",
        is_job_complete: false,
        job_has_error: false,
    },
];
export var mockedImageUpdateJobs = [
    {
        jobNo: 1,
        jobType: "MENU_ITEM",
        submittedAt: "testZ",
        submittedBy: "user",
        isJobComplete: true,
        hasError: true,
    },
    {
        jobNo: 2,
        jobType: "MENU_ITEM_2",
        submittedAt: "test2Z",
        submittedBy: "user2",
        isJobComplete: false,
        hasError: false,
    },
];
export var mockedJobIds = [
    {
        job_no: 1,
    },
    {
        job_no: 2,
    },
];
export var mockedJobIdsResult = [
    {
        id: 1,
        name: "1",
    },
    {
        id: 2,
        name: "2",
    },
];

export var GETDATAPOOL;
(function (GETDATAPOOL) {
    GETDATAPOOL["GET"] = "GetDataPoolEvent.Get";
    GETDATAPOOL["SAVE"] = "GetDataPoolEvent.Save";
    GETDATAPOOL["INIT"] = "GetDataPoolEvent.Init";
})(GETDATAPOOL || (GETDATAPOOL = {}));
export var GETDATA_STATUS;
(function (GETDATA_STATUS) {
    GETDATA_STATUS[GETDATA_STATUS["PENDING"] = 0] = "PENDING";
    GETDATA_STATUS[GETDATA_STATUS["DONE"] = 1] = "DONE";
    GETDATA_STATUS[GETDATA_STATUS["STALE"] = 2] = "STALE";
})(GETDATA_STATUS || (GETDATA_STATUS = {}));

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import "./UserQRCode.scss";
import { PLATFORM } from "../../../common/constants";
import I18nText from "../../i18n/I18nText";
import { PERMISSION } from "../../rbac/permissions";
import RequirePermissions from "../../rbac/RequirePermissions";
import RequirePlatform from "../../rbac/RequirePlatform";
import { showUserQRCodeModal } from "./helpers";
var QRCODE = [PERMISSION.QR_CODE];
var UserQRCode = function () {
    return (_jsx(RequirePlatform, __assign({ platform: PLATFORM.ADMIN_PANEL }, { children: _jsx(RequirePermissions, __assign({ permissions: QRCODE }, { children: _jsx("button", __assign({ onClick: showUserQRCodeModal, className: "UserQRCode" }, { children: _jsx(I18nText, { token: "profile.viewqrcode" }) })) })) })));
};
export default UserQRCode;

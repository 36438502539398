var _a;
import { useEffect, useState } from "react";
import { useEventTriggeredTimeUpdate } from "../../../../common/hooks/useEventTriggeredTimeUpdate";
import { jsonApiAdapter } from "../../../../common/jsonapi/jsonapi";
import { FS_GATEWAY_HOST } from "../../../api/fs/constants";
import { useAxiosGet } from "../../../hooks/useAxiosGet";
import { useGenericAxiosErrorToast } from "../../../modals/alerts/hooks";
import { DICTIONARY_EVENT } from "../constants";
export var BUSINESS_BRANCHES_INFO_API = "".concat(FS_GATEWAY_HOST).concat((_a = process.env.BUSINESS_BRANCHES_INFO_API_V4) !== null && _a !== void 0 ? _a : "");
export var useBusinessBranchesInfoAPI = function () {
    var _a = useState([]), info = _a[0], setInfo = _a[1];
    var base = useEventTriggeredTimeUpdate(DICTIONARY_EVENT.REFRESH);
    var _b = useAxiosGet(BUSINESS_BRANCHES_INFO_API, base), response = _b.response, getError = _b.getError;
    useGenericAxiosErrorToast(BUSINESS_BRANCHES_INFO_API, getError);
    useEffect(function () {
        if (!response)
            return;
        var data = jsonApiAdapter(response);
        setInfo(data);
    }, [response]);
    return info;
};

import { lazy } from "react";
import { MENU_GROUP, PLATFORM } from "../../../common/constants";
import { globalEvents } from "../../../common/GlobalEvents";
import { PERMISSION } from "../../../components/rbac/permissions";
import { ROUTE_EVENTS } from "../../../components/routing/constants";
import { OPERATIONS_PATH } from "./constants";
/* istanbul ignore next */ // no need to test lazy loading
var LazyComponents = {
    DailyCashDepositListing: lazy(function () {
        return import(
        /* webpackChunkName: "fsdailycashdepositlisting" */ "./dailycashdeposit/listing/DailyCashDepositListing");
    }),
    DailyCashDepositAdd: lazy(function () {
        return import(
        /* webpackChunkName: "fsdailycashdepositadd" */ "./dailycashdeposit/add/AddCashDeposit");
    }),
    DailyCashDepositEdit: lazy(function () {
        return import(
        /* webpackChunkName: "fsdailycashdepositedit" */ "./dailycashdeposit/edit/EditCashDeposit");
    }),
    KPOSDayEnd: lazy(function () {
        return import(/* webpackChunkName: "fskposdayend" */ "./kposdayend/KPOSDayEnd");
    }),
};
/* istanbul ignore next */ // no need to test routes
export var operationsRoutes = [
    {
        label: "fs.tasks.menu.title",
        route: "",
        permissions: [],
        icon: "",
        group: MENU_GROUP.FOOD_SERVICES,
        platforms: [],
        subRoutes: [
            {
                label: "fs.kposdayend.title",
                route: OPERATIONS_PATH.KPOS_DAY_END,
                permissions: [PERMISSION.FS_MESSAGING_KPOS_VIEW],
                icon: "documents",
                platforms: [PLATFORM.ADMIN_PANEL],
                group: MENU_GROUP.FOOD_SERVICES,
                component: LazyComponents.KPOSDayEnd,
            },
            {
                label: "fs.declarations.dailycashdeposit.title",
                route: OPERATIONS_PATH.DAILY_CASH_DEPOSIT,
                permissions: [PERMISSION.FS_DECLARATION_CASHDEPOSIT_READ],
                icon: "documents",
                platforms: [PLATFORM.ADMIN_PANEL],
                group: MENU_GROUP.FOOD_SERVICES,
                component: LazyComponents.DailyCashDepositListing,
            },
            {
                label: "",
                route: OPERATIONS_PATH.DAILY_CASH_DEPOSIT_ADD,
                permissions: [PERMISSION.FS_DECLARATION_CASHDEPOSIT_CREATE],
                icon: "",
                platforms: [PLATFORM.ADMIN_PANEL],
                group: MENU_GROUP.FOOD_SERVICES,
                component: LazyComponents.DailyCashDepositAdd,
                hidden: true,
            },
            {
                label: "",
                route: OPERATIONS_PATH.DAILY_CASH_DEPOSIT_EDIT,
                permissions: [
                    PERMISSION.FS_DECLARATION_CASHDEPOSIT_UPDATE,
                    PERMISSION.FS_DECLARATION_CASHDEPOSIT_UPDATEBYPASS,
                ],
                icon: "",
                platforms: [PLATFORM.ADMIN_PANEL],
                group: MENU_GROUP.FOOD_SERVICES,
                component: LazyComponents.DailyCashDepositEdit,
                hidden: true,
            },
        ],
    },
];
export var initFSOperations = function () {
    globalEvents.emit(ROUTE_EVENTS.REGISTER, operationsRoutes);
};

export var BusinessBranchObjectKeys;
(function (BusinessBranchObjectKeys) {
    BusinessBranchObjectKeys["Location"] = "location";
    BusinessBranchObjectKeys["business"] = "business";
    BusinessBranchObjectKeys["brand"] = "brand";
    BusinessBranchObjectKeys["company"] = "company";
})(BusinessBranchObjectKeys || (BusinessBranchObjectKeys = {}));
export var SPONSOR;
(function (SPONSOR) {
    SPONSOR["TENANT"] = "Tenant";
    SPONSOR["FS"] = "Food Services";
    SPONSOR["MARKETING"] = "Marketing";
    SPONSOR["FAIRPRICE_GROUP"] = "FairPrice Group";
    SPONSOR["OUTLET"] = "Outlet";
    SPONSOR["EMPTY"] = "";
})(SPONSOR || (SPONSOR = {}));
export var BB_DISCOUNT_TYPE;
(function (BB_DISCOUNT_TYPE) {
    BB_DISCOUNT_TYPE["FP_APP_DISCOUNT"] = "fp_app_discount";
})(BB_DISCOUNT_TYPE || (BB_DISCOUNT_TYPE = {}));
export var LOYALTIES_TYPE;
(function (LOYALTIES_TYPE) {
    LOYALTIES_TYPE["LINKPOINTS"] = "linkpoint_awarded";
})(LOYALTIES_TYPE || (LOYALTIES_TYPE = {}));
export var PARTNER_NAMES;
(function (PARTNER_NAMES) {
    PARTNER_NAMES["GRABFOOD"] = "grabfood";
    PARTNER_NAMES["DELIVEROO"] = "deliveroo";
    PARTNER_NAMES["FOODPANDA"] = "foodpanda";
})(PARTNER_NAMES || (PARTNER_NAMES = {}));
export var KNOWN_TAX_TYPES;
(function (KNOWN_TAX_TYPES) {
    KNOWN_TAX_TYPES["GST"] = "GST";
    KNOWN_TAX_TYPES["SERVICE_CHARGE"] = "servicecharge";
})(KNOWN_TAX_TYPES || (KNOWN_TAX_TYPES = {}));

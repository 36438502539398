var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useMemo, useReducer, } from "react";
import { initialQCContextState, initialQCStoreContext } from "./constants";
import ModalController from "./controllers/ModalController";
import { qcReducer } from "./reducer";
export var QCContext = createContext(initialQCStoreContext);
var QCContextProvider = function (_a) {
    var children = _a.children;
    var _b = useReducer(qcReducer, initialQCContextState), state = _b[0], dispatch = _b[1];
    var value = useMemo(function () { return ({ state: state, dispatch: dispatch }); }, [state]);
    return (_jsxs(QCContext.Provider, __assign({ value: value }, { children: [_jsx(ModalController, {}), children] })));
};
export default QCContextProvider;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Redirect, Route, Switch } from "react-router-dom";
import { ERROR_PATH } from "./constants";
import Error403 from "./Error403";
import Error404 from "./Error404";
import ErrorPCNotAUser from "./ErrorPCNotAUser";
var ErrorPage = function () {
    return (_jsxs(Switch, { children: [_jsx(Redirect, { from: ERROR_PATH.PAGE_ERROR, exact: true, to: ERROR_PATH.PAGE_404 }), _jsx(Route, { path: ERROR_PATH.PAGE_404, component: Error404 }), _jsx(Route, { path: ERROR_PATH.PAGE_UNAUTHORISED, component: Error403 }), _jsx(Route, { path: ERROR_PATH.PAGE_PC_UNAUTHORISED, component: ErrorPCNotAUser })] }));
};
export default ErrorPage;

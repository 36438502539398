import { useMemo } from "react";
import { useLang } from "../context/hooks";
import { LANG } from "../layout/header/types";
import { combineENCNMappings } from "./encn/helpers";
import { useENCNConfig } from "./encn/useENCNConfig";
export var useSelectedI118nMapping = function (json) {
    var lang = useLang().lang;
    var combine = useENCNConfig();
    return useMemo(function () {
        var _a;
        if (lang === LANG.CN) {
            return combineENCNMappings(json, combine);
        }
        return (_a = json[LANG.EN]) !== null && _a !== void 0 ? _a : {};
    }, [combine, lang, json]);
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./Error.scss";
import { EuiFlexGroup, EuiFlexItem, EuiPage } from "@elastic/eui";
import { useMemo } from "react";
import { usePageTitle } from "../../common/hooks/usePageTitle";
import { DASHBOARD_PATH } from "../../pages/dashboard/constants";
import { useUserInfo } from "../context/hooks";
import I18nText from "../i18n/I18nText";
var ErrorDisplay = function (_a) {
    var code = _a.code, message = _a.message;
    var chars = useMemo(function () { return code.split(""); }, [code]);
    var _b = useUserInfo(), name = _b.name, email = _b.email;
    usePageTitle(code);
    return (_jsx(EuiPage, __assign({ className: "ErrorPage" }, { children: _jsxs(EuiFlexGroup, __assign({ direction: "column", alignItems: "center" }, { children: [_jsx(EuiFlexItem, { children: _jsx("div", { children: chars.map(function (char, idx) { return (_jsx("span", __assign({ className: idx % 2 == 0 ? "Red" : "Blue" }, { children: char }), idx)); }) }) }), _jsxs(EuiFlexItem, { children: [!!email && (_jsx(I18nText, { token: "error.greetings", values: { name: name, email: email } })), _jsx(I18nText, { token: message })] }), _jsx(EuiFlexItem, { children: _jsx("a", __assign({ href: DASHBOARD_PATH.DASHBOARD, target: "_top" }, { children: _jsx(I18nText, { token: "error.backtodashboard" }) })) })] })) })));
};
export default ErrorDisplay;

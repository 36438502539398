var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useCallback, useEffect, useState } from "react";
import { globalEvents } from "../../../../common/GlobalEvents";
import { ALERT_EVENT, OK_BUTTON } from "../constants";
export var useMessageResponse = function (hideModal, _a) {
    var button0 = _a[0];
    var _b = __assign(__assign(__assign({}, button0), OK_BUTTON), { label: button0.label || OK_BUTTON.label, type: button0.label ? button0.type : OK_BUTTON.type, backgroundColor: button0.backgroundColor || OK_BUTTON.backgroundColor, color: button0.color || OK_BUTTON.color }), label = _b.label, backgroundColor = _b.backgroundColor, color = _b.color, onClick = _b.onClick, type = _b.type;
    var close = useCallback(function () {
        if (onClick)
            onClick();
        hideModal();
    }, [hideModal, onClick]);
    return [
        {
            label: label,
            type: type,
            backgroundColor: backgroundColor,
            color: color,
            onClick: close,
            iconType: "",
            disabled: false,
        },
    ];
};
export var useModalButtons = function (buttons) {
    var _a = useState(buttons), btns = _a[0], setBtns = _a[1];
    var updateButtons = useCallback(function (newButtons) {
        setBtns(newButtons);
    }, []);
    useEffect(function () {
        globalEvents.on(ALERT_EVENT.MODIFY_MODAL_BUTTONS, updateButtons);
        return function () {
            globalEvents.off(ALERT_EVENT.MODIFY_MODAL_BUTTONS, updateButtons);
        };
    }, []);
    return btns;
};

var _a;
import { DICTIONARY_KEY } from "../types";
var dictCache = (_a = {},
    _a[DICTIONARY_KEY.BRANDS] = {},
    _a[DICTIONARY_KEY.BUSINESSESS] = {},
    _a[DICTIONARY_KEY.LOCATIONS] = {},
    _a);
export var getDictCache = function (dictionary, key) {
    return dictCache[dictionary][key];
};
export var setDictCache = function (dictionary, key, value) {
    dictCache[dictionary][key] = value;
};

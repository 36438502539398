export var ONLINE_CATALOGS_PATH;
(function (ONLINE_CATALOGS_PATH) {
    ONLINE_CATALOGS_PATH["MANAGE_MENUS"] = "/qc/online-catalogs/menus";
    ONLINE_CATALOGS_PATH["VIEW_MENU"] = "/qc/online-catalogs/menus/item";
    ONLINE_CATALOGS_PATH["EDIT_MENU"] = "/qc/online-catalogs/menus/item/edit";
    ONLINE_CATALOGS_PATH["COPY_MENU"] = "/qc/online-catalogs/menus/item/copy";
    ONLINE_CATALOGS_PATH["ADD_MENU"] = "/qc/online-catalogs/menus/item/add";
    ONLINE_CATALOGS_PATH["ADD_CATEGORY"] = "/qc/online-catalogs/categories/add";
    ONLINE_CATALOGS_PATH["VIEW_CATEGORY"] = "/qc/online-catalogs/categories/view";
    ONLINE_CATALOGS_PATH["EDIT_CATEGORY"] = "/qc/online-catalogs/categories/view/edit";
    ONLINE_CATALOGS_PATH["VIEW_MENU_ITEM"] = "/qc/online-catalogs/menu-item";
    ONLINE_CATALOGS_PATH["ADD_MENU_ITEM"] = "/qc/online-catalogs/menu-items/add";
    ONLINE_CATALOGS_PATH["EDIT_MENU_ITEMS"] = "/qc/online-catalogs/menu-items/edit";
    ONLINE_CATALOGS_PATH["DELETE_MENU_ITEMS"] = "/qc/online-catalogs/menu-items/delete";
    ONLINE_CATALOGS_PATH["ADD_MENU_ITEM_CUSTOMISATION"] = "/qc/online-catalogs/menu-item/item/customisation/add";
    ONLINE_CATALOGS_PATH["MANAGE_CUSTOMISATIONS"] = "/qc/online-catalog/customisations";
    ONLINE_CATALOGS_PATH["DELETE_CATEGORIES"] = "/qc/online-catalogs/categories/delete";
    ONLINE_CATALOGS_PATH["VIEW_CUSTOMISATION"] = "/qc/online-catalog/customisation/view";
    ONLINE_CATALOGS_PATH["ADD_CUSTOMISATION"] = "/qc/online-catalog/customisation/add";
    ONLINE_CATALOGS_PATH["ADD_CUSTOMISATION_OPTION"] = "/qc/online-catalog/customisation/options/add";
    ONLINE_CATALOGS_PATH["BULK_UPDATE_IMAGE"] = "/qc/online-catalog/bulk-upload-image";
    ONLINE_CATALOGS_PATH["IMAGE_UPDATE_JOBS"] = "/qc/online-catalog/jobs";
    ONLINE_CATALOGS_PATH["VIEW_JOB_STATUS"] = "/qc/online-catalog/jobs/item";
    ONLINE_CATALOGS_PATH["MENU_ITEM_ASSIGNED"] = "/qc/online-catalog/customisation/menu-item-assigned";
    ONLINE_CATALOGS_PATH["BREAKOUT"] = "/qc/moved";
})(ONLINE_CATALOGS_PATH || (ONLINE_CATALOGS_PATH = {}));
export var ONLINE_CATALOGS_QUERY_PARAMS;
(function (ONLINE_CATALOGS_QUERY_PARAMS) {
    ONLINE_CATALOGS_QUERY_PARAMS["FOOD_SUPPLIER_MENU_ID"] = "food_supplier_menu_id";
    ONLINE_CATALOGS_QUERY_PARAMS["FOOD_SUPPLIER_CATEGORY_ID"] = "food_supplier_category_id";
    ONLINE_CATALOGS_QUERY_PARAMS["FOOD_SUPPLIER_MENU_ITEM_ID"] = "food_supplier_menu_item_id";
    ONLINE_CATALOGS_QUERY_PARAMS["FOOD_SUPPLIER_CUSTOMISATION_ID"] = "food_supplier_customisation_id";
    ONLINE_CATALOGS_QUERY_PARAMS["FOOD_SUPPLIER_ID"] = "food_supplier_id";
    ONLINE_CATALOGS_QUERY_PARAMS["FOOD_SUPPLIER_BRAND_ID"] = "food_supplier_brand_id";
    ONLINE_CATALOGS_QUERY_PARAMS["TABID"] = "tabId";
    ONLINE_CATALOGS_QUERY_PARAMS["JOD_ID"] = "job_id";
})(ONLINE_CATALOGS_QUERY_PARAMS || (ONLINE_CATALOGS_QUERY_PARAMS = {}));
export var NON_CONSTANT = "non";
export var FAIRPRICE = "FAIRPRICE";
export var GROUP_CONST = "Group";
export var MENUS_PER_PAGE = 10;
export var HTTP_ERROR;
(function (HTTP_ERROR) {
    HTTP_ERROR[HTTP_ERROR["CODE_409"] = 409] = "CODE_409";
    HTTP_ERROR[HTTP_ERROR["CODE_406"] = 406] = "CODE_406";
})(HTTP_ERROR || (HTTP_ERROR = {}));
export var menuOnlineActivityDateFormat = "D MMM YYYY, h:mm A";
export var TAB_ID;
(function (TAB_ID) {
    TAB_ID["ABOUT"] = "about";
    TAB_ID["CATEGORIES"] = "categories";
    TAB_ID["MENU_ITEMS"] = "menuItems";
})(TAB_ID || (TAB_ID = {}));
export var DAYS = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
];
export var DAY_SORTER = {
    sunday: 1,
    monday: 2,
    tuesday: 3,
    wednesday: 4,
    thursday: 5,
    friday: 6,
    saturday: 7,
};
export var WEEK_DAY_INDEXES = [2, 3, 4, 5, 6];
export var TIME_PLACEHOLDER = "HH:MM";
export var PRICE_PLACEHOLDER = "0.00";
export var PRICE_INPUT_MIN = 0.0;
export var PRICE_INPUT_MAX = 9999.99;
export var PRICE_INPUT_STEP = 0.01;
export var MENU_ITEM_ADD_CUSTOMISATION_TABLE_COLUMNS = [
    "onlineCatalogs.page.manageMenus.filters.location_id.all",
    "onlineCatalogs.page.menu.menuItems.item.view.customisations.table.column.name",
    "onlineCatalogs.page.menu.menuItems.item.view.customisations.table.add.column.minMax",
    "onlineCatalogs.page.menu.menuItems.item.view.customisations.table.add.column.options",
    "onlineCatalogs.page.menu.menuItems.item.view.customisations.table.column.price",
];
export var CATEGORIES_TABLE_COLUMNS = [
    "onlineCatalogs.page.menu.categories.table.column.name",
    "onlineCatalogs.page.menu.categories.table.column.itemsCount",
    "onlineCatalogs.page.menu.categories.table.column.availability",
    "",
];
export var COPY_MENU_TABLE_COLUMNS = [
    "onlineCatalogs.menu.copyMenu.table.column.brand",
    "onlineCatalogs.menu.copyMenu.table.column.location",
    "onlineCatalogs.menu.copyMenu.table.column.restaurantId",
    "onlineCatalogs.menu.copyMenu.table.column.restaurantName",
    "onlineCatalogs.menu.copyMenu.table.column.status",
];
export var MENU_ITEMS_TABLE_COLUMNS = [
    "onlineCatalogs.page.menu.menuItems.table.column.number",
    "onlineCatalogs.page.menu.menuItems.table.column.category",
    "onlineCatalogs.page.menu.menuItems.table.column.name",
    "onlineCatalogs.page.menu.menuItems.table.column.description",
    "onlineCatalogs.page.menu.menuItems.table.column.fee",
    "onlineCatalogs.page.menu.menuItems.table.column.price",
    "onlineCatalogs.page.menu.menuItems.form.add.customisationPrice.withdollar.label",
    "onlineCatalogs.page.menu.menuItems.table.column.customisation",
    "onlineCatalogs.page.menu.menuItems.table.column.image.count",
    "onlineCatalogs.page.menu.menuItems.table.column.availability",
    "onlineCatalogs.page.menu.menuItems.table.column.hidden",
];
export var MENU_ITEMS_TABLE_COLUMNS_WITH_CHECKBOX = [
    "",
    "onlineCatalogs.page.menu.menuItems.table.column.number",
    "onlineCatalogs.page.menu.menuItems.table.column.category",
    "onlineCatalogs.page.menu.menuItems.table.column.name",
    "onlineCatalogs.page.menu.menuItems.table.column.description",
    "onlineCatalogs.page.menu.menuItems.table.column.fee",
    "onlineCatalogs.page.menu.menuItems.table.column.price",
    "onlineCatalogs.page.menu.menuItems.form.add.customisationPrice.withdollar.label",
    "onlineCatalogs.page.menu.menuItems.table.column.customisation",
    "onlineCatalogs.page.menu.menuItems.table.column.image.count",
    "onlineCatalogs.page.menu.menuItems.table.column.availability",
    "onlineCatalogs.page.menu.menuItems.table.column.hidden",
];
export var CATEGORY_MENU_ITEMS_TABLE_COLUMNS = [
    "onlineCatalogs.page.menu.menuItems.table.column.number",
    "onlineCatalogs.page.menu.menuItems.table.column.name",
    "onlineCatalogs.page.menu.menuItems.table.column.fee",
    "onlineCatalogs.page.menu.menuItems.table.column.price",
    "onlineCatalogs.page.menu.menuItems.table.column.customisationPrice",
    "onlineCatalogs.page.menu.menuItems.table.column.customisation",
    "onlineCatalogs.page.menu.menuItems.table.column.availability",
    "onlineCatalogs.page.menu.menuItems.table.column.hidden",
];
export var MENU_ITEM_CUSTOMISATION_TABLE_COLUMNS = [
    "onlineCatalogs.page.menu.menuItems.table.column.number",
    "onlineCatalogs.page.menu.menuItems.item.view.customisations.table.column.name",
    "onlineCatalogs.page.menu.menuItems.item.view.customisations.table.column.minMax",
    "onlineCatalogs.page.menu.menuItems.item.view.customisations.table.column.option",
    "onlineCatalogs.page.menu.menuItems.item.view.customisations.table.column.price",
];
export var CUSTOMISATION_OPTIONS_TABLE_COLUMNS = [
    "onlineCatalogs.page.customisation.options.table.column.number",
    "onlineCatalogs.page.customisation.options.table.column.name",
    "onlineCatalogs.page.customisation.options.table.column.nameCn",
    "onlineCatalogs.page.customisation.options.table.column.price",
    "onlineCatalogs.page.customisation.options.table.column.fee",
    "onlineCatalogs.page.customisation.options.table.column.availability",
];
export var CATALOGUE_HISTORY_TABLE_COLUMNS = [
    "onlineCatalogs.page.manageMenus.table.column.number",
    "onlineCatalogs.page.menu.menuItems.catalogs.history.table.price.edited",
    "onlineCatalogs.page.menu.menuItems.catalogs.history.table.revised.price",
    "onlineCatalogs.page.menu.menuItems.catalogs.history.table.last.updated",
    "onlineCatalogs.page.menu.menuItems.catalogs.history.table.updated.by",
];
export var RESTAURANT_TABLE_COLUMNS = [
    "onlineCatalogs.page.manageMenus.table.column.number",
    "restaurants.page.manageRestaurant.table.column.brand",
    "restaurants.page.manageRestaurant.table.column.location",
    "restaurants.page.manageRestaurant.table.column.restaurandId",
    "restaurants.page.manageRestaurant.table.column.restaurantName",
    "restaurants.page.manageRestaurant.table.column.status",
];
export var TIME_PATTERN = "^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$";
export var initialTimeSlot = {
    monday: {
        timings: [],
    },
    tuesday: {
        timings: [],
    },
    wednesday: {
        timings: [],
    },
    thursday: {
        timings: [],
    },
    friday: {
        timings: [],
    },
    saturday: {
        timings: [],
    },
    sunday: {
        timings: [],
    },
};
export var MANAGE_CUSTOMISATIONS_TABLE_COLUMNS = [
    "onlineCatalogs.page.manageCustomisations.table.column.number",
    "onlineCatalogs.page.manageCustomisations.table.column.brand",
    "onlineCatalogs.page.manageCustomisations.table.column.location",
    "onlineCatalogs.page.manageCustomisations.table.column.restaurant",
    "onlineCatalogs.page.manageCustomisations.table.column.name",
    "onlineCatalogs.page.manageCustomisations.table.column.options",
    "onlineCatalogs.page.manageCustomisations.table.column.availability",
    "",
];
export var ADD_CUSTOMISATION_MENU_ITEMS_TABLE_COLUMNS = [
    "common.all",
    "onlineCatalogs.page.customisation.add.options.items.table.column.category",
    "onlineCatalogs.page.customisation.add.options.items.table.column.name",
    "onlineCatalogs.page.customisation.add.options.items.table.column.fee",
];
export var MENU_ITEMS_ASSIGNED_TABLE_COLUMNS = [
    "onlineCatalogs.page.menuItemsAssigned.table.column.no",
    "onlineCatalogs.page.menuItemsAssigned.table.column.category",
    "onlineCatalogs.page.menuItemsAssigned.table.column.menuItemName",
];
export var FS_ID_MAX_LENGTH = 10;
export var QC_INPUT_HEIGHT = 52;
export var QC_GLOBAL_EVENT;
(function (QC_GLOBAL_EVENT) {
    QC_GLOBAL_EVENT["EDIT_CUSTOMISATION_OPTION"] = "EDIT_CUSTOMISATION_OPTION";
    QC_GLOBAL_EVENT["EDIT_CUSTOMISATION"] = "EDIT_CUSTOMISATION";
    QC_GLOBAL_EVENT["VIEW_MENU_ITEM"] = "VIEW_MENU_ITEM";
    QC_GLOBAL_EVENT["VIEW_RESTAURANT"] = "VIEW_RESTAURANT";
    QC_GLOBAL_EVENT["EDIT_RESTAURANT"] = "EDIT_RESTAURANT";
})(QC_GLOBAL_EVENT || (QC_GLOBAL_EVENT = {}));
export var USE_OLD_QC_FLAG = "use_old_qc";

export var STORE_CONCEPT;
(function (STORE_CONCEPT) {
    STORE_CONCEPT["DF"] = "3DF";
    STORE_CONCEPT["SRC"] = "SRC";
    STORE_CONCEPT["TENANT"] = "TENANT";
    STORE_CONCEPT["MARKETPLACE"] = "MARKETPLACE";
})(STORE_CONCEPT || (STORE_CONCEPT = {}));
export var PLATFORM_NAME;
(function (PLATFORM_NAME) {
    PLATFORM_NAME["FAIRPICE"] = "FAIRPICE";
    PLATFORM_NAME["FOODPANDA"] = "FOODPANDA";
    PLATFORM_NAME["DELIVEROO"] = "DELIVEROO";
    PLATFORM_NAME["GRAB"] = "GRAB";
})(PLATFORM_NAME || (PLATFORM_NAME = {}));
export var TAG_GROUP;
(function (TAG_GROUP) {
    TAG_GROUP["DIETARY"] = "Dietary preference";
    TAG_GROUP["CUISINE"] = "Cuisine";
})(TAG_GROUP || (TAG_GROUP = {}));
export var FULFILLMENT_METHOD;
(function (FULFILLMENT_METHOD) {
    FULFILLMENT_METHOD["DINE_IN"] = "dine-in";
    FULFILLMENT_METHOD["SELF_COLLECT"] = "self_collect";
    FULFILLMENT_METHOD["HAVING_HERE"] = "having_here";
})(FULFILLMENT_METHOD || (FULFILLMENT_METHOD = {}));

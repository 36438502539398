var _a;
import { decryptAESData } from "../../../../common/encryption";
var KEY = (_a = process.env.BACKOFFICE_PROPS_KEY) !== null && _a !== void 0 ? _a : "";
export var decryptProps = function (keys, encrypted) {
    var props = {};
    var decrypted = decryptAESData(encrypted !== null && encrypted !== void 0 ? encrypted : "", KEY);
    var parsed = !!decrypted ? JSON.parse(decrypted) : {};
    keys.forEach(function (key) {
        var _a;
        props[key] = (_a = parsed[key]) !== null && _a !== void 0 ? _a : "";
    });
    return props;
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useContext, useEffect, useMemo, useState } from "react";
import { useEventTriggeredTimeUpdate } from "../../../common/hooks/useEventTriggeredTimeUpdate";
import { useAxiosGet } from "../../hooks/useAxiosGet";
import { hasOnlyQCPermissions } from "../../rbac/permissions";
import { useUserPermissions } from "../hooks";
import { DICTIONARY_EVENT, DictionaryContext, MAX_API_ATTEMPTS, } from "./constants";
import { announceDictionaryError } from "./error_announce";
export var useGetRequestWithAttempts = function (key, url, base) {
    var _a = useState(base), attempt = _a[0], setAttempt = _a[1];
    var _b = useAxiosGet(url, attempt), response = _b.response, getError = _b.getError;
    useEffect(function () {
        setAttempt(base);
    }, [base]);
    useEffect(function () {
        if (attempt === base + MAX_API_ATTEMPTS) {
            announceDictionaryError(key, JSON.stringify(getError));
        }
    }, [base, attempt]);
    useEffect(function () {
        if (!getError)
            return;
        setAttempt(function (prev) { return (prev < base + MAX_API_ATTEMPTS ? prev + 1 : prev); });
    }, [getError]);
    return {
        response: response,
    };
};
export var useRequireFSDictionaryContext = function () {
    var permissions = useUserPermissions();
    return useMemo(function () { return !hasOnlyQCPermissions(permissions); }, [permissions]);
};
export var useFSDictionaryStorage = function (getApi, dictionaryKey, adapter) {
    var base = useEventTriggeredTimeUpdate(DICTIONARY_EVENT.REFRESH);
    var _a = useContext(DictionaryContext), setContext = _a[1];
    var requireFSDict = useRequireFSDictionaryContext();
    var response = useGetRequestWithAttempts(dictionaryKey, requireFSDict ? getApi : "", base).response;
    useEffect(function () {
        if (!response)
            return;
        setContext(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), { raw: __assign(__assign({}, prev.raw), (_a = {}, _a[dictionaryKey] = adapter ? adapter(response) : response, _a)) }));
        });
    }, [response]);
};

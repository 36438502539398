export var RBAC_EVENT;
(function (RBAC_EVENT) {
    RBAC_EVENT["CHECK_PERMISSIONS"] = "RBACEvent.CheckPermissions";
})(RBAC_EVENT || (RBAC_EVENT = {}));
export var PERMISSION_PREFIX;
(function (PERMISSION_PREFIX) {
    PERMISSION_PREFIX["FS_ADMINPANEL"] = "fs:";
    PERMISSION_PREFIX["QC_ADMINPANEL"] = "qc:";
    PERMISSION_PREFIX["KPOS"] = "kpos:";
})(PERMISSION_PREFIX || (PERMISSION_PREFIX = {}));
export var ROLE_PREFIX;
(function (ROLE_PREFIX) {
    ROLE_PREFIX["FS"] = "fs_";
    ROLE_PREFIX["QC"] = "qc_";
    ROLE_PREFIX["KPOS"] = "kpos_";
    ROLE_PREFIX["SUPER_ADMIN"] = "super_admin";
})(ROLE_PREFIX || (ROLE_PREFIX = {}));

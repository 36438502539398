var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { flattenDataAttributes } from "../helpers";
import { BRAND_INCLUDED_KEYS, } from "./types";
var flattenIncludedBusinesses = function (business) { return flattenDataAttributes(business); };
var locateIncludedBusiness = function (included) {
    return function (_a) {
        var id = _a.id;
        return included.filter(function (include) { return include.id === id; })[0];
    };
};
export var brandDTOAdapter = function (dto) {
    var data = dto.data, included = dto.included;
    var locateFn = locateIncludedBusiness(included);
    return data.map(function (brand) {
        var _a;
        var brandBusinesses = ((_a = brand.relationships) === null || _a === void 0 ? void 0 : _a[BRAND_INCLUDED_KEYS.BUSINESSES].data) || [];
        var businesses = brandBusinesses
            .map(locateFn)
            .map(flattenIncludedBusinesses);
        return __assign(__assign({}, flattenDataAttributes(brand)), { businesses: businesses });
    });
};
export var getBrandsOfBusinesses = function (brands, checkBusinesses) {
    var uniqueBrands = brands.reduce(function (brandBusiness, _a) {
        var id = _a.id, businesses = _a.businesses;
        if (businesses.filter(function (_a) {
            var business_id = _a.id;
            return checkBusinesses.includes(business_id);
        }).length > 0) {
            brandBusiness.add(id);
        }
        return brandBusiness;
    }, new Set());
    return Array.from(uniqueBrands);
};

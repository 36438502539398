var _a, _b, _c;
import { useRegisterNewItemRequest } from "../../../../components/layout/SideNavBar/navitemcount/useRegisterNewItemRequest";
import { useSetSideBarNewItemCountValue } from "../../../../components/layout/SideNavBar/navitemcount/useSetSideBarNewItemCount";
import { documentsRoutes } from "../DocumentsBoot";
import { NEW_DOCUMENTS_ALERT } from "../tenant/alerts/constants";
import { useCheckNewDocumentApi } from "./useCheckNewDocumentApi";
var DOC_VIEWER = (_c = (_b = (_a = documentsRoutes[0]) === null || _a === void 0 ? void 0 : _a.subRoutes) === null || _b === void 0 ? void 0 : _b[0].permissions) !== null && _c !== void 0 ? _c : [];
export var useDocumentsNewItems = function () {
    var _a = useCheckNewDocumentApi(), getNewItemCount = _a.getNewItemCount, count = _a.count;
    useSetSideBarNewItemCountValue(NEW_DOCUMENTS_ALERT, count);
    useRegisterNewItemRequest(NEW_DOCUMENTS_ALERT, getNewItemCount, DOC_VIEWER);
};

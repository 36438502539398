var _a, _b;
import { FS_GATEWAY_HOST } from "../../../api/fs/constants";
export var LOCATIONS_API = "".concat(FS_GATEWAY_HOST).concat((_a = process.env.LOCATIONS_API) !== null && _a !== void 0 ? _a : "");
export var LOCATIONS_API_V2 = "".concat(FS_GATEWAY_HOST).concat((_b = process.env.LOCATIONS_API_V2) !== null && _b !== void 0 ? _b : "");
export var LOCATION_CLUSTER;
(function (LOCATION_CLUSTER) {
    LOCATION_CLUSTER["CLUSTER1"] = "Cluster 1 West";
    LOCATION_CLUSTER["CLUSTER2"] = "Cluster 2 North";
    LOCATION_CLUSTER["CLUSTER3"] = "Cluster 3 East";
    LOCATION_CLUSTER["CLUSTER4"] = "Cluster 4 North East";
})(LOCATION_CLUSTER || (LOCATION_CLUSTER = {}));
export var LOCATION_STATUS;
(function (LOCATION_STATUS) {
    LOCATION_STATUS["ACTIVE"] = "Active";
    LOCATION_STATUS["PERMANENTLY_CLOSED"] = "Permanently Closed";
    LOCATION_STATUS["RENOVATION"] = "Closed for Renovation";
})(LOCATION_STATUS || (LOCATION_STATUS = {}));

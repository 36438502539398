import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { usePlatform } from "../../components/context/hooks";
var RequirePlatform = function (_a) {
    var platform = _a.platform, children = _a.children;
    var currentPlatform = usePlatform();
    if (platform !== currentPlatform)
        return null;
    return _jsx(_Fragment, { children: children });
};
export default RequirePlatform;

import { useCallback, useEffect, useRef } from "react";
import { globalEvents } from "../GlobalEvents";
export var useGlobalEvent = function (event, handler) {
    var handlerFn = useRef(handler);
    var callback = useCallback(function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        handlerFn.current.apply(handlerFn, args);
    }, []);
    useEffect(function () {
        handlerFn.current = handler;
    }, [handler]);
    useEffect(function () {
        globalEvents.on(event, callback);
        return function () {
            globalEvents.off(event, callback);
        };
    }, []);
};

import { lazy } from "react";
import { MENU_GROUP, PLATFORM } from "../../common/constants";
import { globalEvents } from "../../common/GlobalEvents";
import { PERMISSION } from "../../components/rbac/permissions";
import { ROUTE_EVENTS } from "../../components/routing/constants";
import { ONLINE_CATALOGS_PATH } from "./constants";
var LazyComponents = {
    ManageMenus: lazy(function () {
        return import(
        /* webpackChunkName: "qc-onlineCatalogsManageMenus" */ "./online-menus/views/manage-menus/ManageMenus");
    }),
    EditMenu: lazy(function () {
        return import(
        /* webpackChunkName: "qc-onlineCatalogsEditMenu" */ "./online-menus/views/menu/EditMenu");
    }),
    CopyMenu: lazy(function () {
        return import(
        /* webpackChunkName: "qc-onlineCatalogsCopyMenu" */ "./online-menus/views/menu/about/copy-menu/CopyMenu");
    }),
    ViewMenu: lazy(function () {
        return import(
        /* webpackChunkName: "qc-onlineCatalogsViewMenu" */ "./online-menus/views/menu/ViewMenu");
    }),
    AddMenu: lazy(function () {
        return import(
        /* webpackChunkName: "qc-onlineCatalogsAddMenu" */ "./online-menus/views/add-menu/AddMenu");
    }),
    AddCategory: lazy(function () {
        return import(
        /* webpackChunkName: "qc-onlineCatalogsMenuAddCategory" */ "./online-menus/views/menu/categories/add/AddCategory");
    }),
    ViewCategory: lazy(function () {
        return import(
        /* webpackChunkName: "qc-onlineCatalogsMenuViewCategory" */ "./online-menus/views/menu/categories/view/ViewCategory");
    }),
    EditCategory: lazy(function () {
        return import(
        /* webpackChunkName: "qc-onlineCatalogsMenuEditCategory" */ "./online-menus/views/menu/categories/view/EditCategory");
    }),
    ViewMenuItem: lazy(function () {
        return import(
        /* webpackChunkName: "qc-onlineCatalogsMenuViewMenuItem" */ "./online-menus/views/menu/menu-items/view/ViewMenuItem");
    }),
    AddMenuItem: lazy(function () {
        return import(
        /* webpackChunkName: "qc-onlineCatalogsMenuAddItem" */ "./online-menus/views/menu/menu-items/add/AddMenuItem");
    }),
    EditMenuItems: lazy(function () {
        return import(
        /* webpackChunkName: "qc-onlineCatalogsEditMenuItems" */ "./online-menus/views/menu/menu-items/EditMenuItems");
    }),
    DeleteMenuItems: lazy(function () {
        return import(
        /* webpackChunkName: "qc-onlineCatalogsDeleteMenuItems" */ "./online-menus/views/menu/menu-items/DeleteMenuItems");
    }),
    AddMenuItemCustomisation: lazy(function () {
        return import(
        /* webpackChunkName: "qc-onlineCatalogsMenuAddMenuItemCustomisation" */ "./online-menus/views/menu/menu-items/customisation/add/AddMenuItemCustomisation");
    }),
    ManageCustomisations: lazy(function () {
        return import(
        /* webpackChunkName: "qc-onlineCatalogsManageCustomisations" */ "./online-customisations/views/manage-customisations/ManageCustomisations");
    }),
    DeleteCategories: lazy(function () {
        return import(
        /* webpackChunkName: "qc-onlineCatalogsDeleteCategories" */ "./online-menus/views/menu/categories/DeleteCategories");
    }),
    ViewCustomisation: lazy(function () {
        return import(
        /* webpackChunkName: "qc-onlineCatalogsViewCustomisation" */ "./online-customisations/views/customisation/ViewCustomisation");
    }),
    AddCustomisation: lazy(function () {
        return import(
        /* webpackChunkName: "qc-onlineCatalogsAddCustomisation" */ "./online-customisations/views/customisation/add/Add");
    }),
    MenuItemAssigned: lazy(function () {
        return import(
        /* webpackChunkName: "qc-onlineCatalogsMenuItemAssigned" */ "./online-customisations/views/customisation/assign-to-menu-item/AssignedToMenuItems");
    }),
    AddCustomisationOption: lazy(function () {
        return import(
        /* webpackChunkName: "qc-onlineCatalogsAddCustomisationOption" */ "./online-customisations/views/customisation/about/options/add/AddOption");
    }),
    BulkUpdateImage: lazy(function () {
        return import(
        /* webpackChunkName: "qc-onlineCatalogsBulkUpdateImage" */ "./bulk-update-image/views/BulkUpdateImage");
    }),
    ImageUpdateJobStatus: lazy(function () {
        return import(
        /* webpackChunkName: "qc-onlineCatalogsImageUpdateJobs" */ "./image-update-jobs/views/manage-jobs/ManageImageUpdateJobs");
    }),
    JobStatus: lazy(function () {
        return import(
        /* webpackChunkName: "qc-onlineCatalogsBulkUpdateImageJobStatus" */ "./image-update-jobs/views/job-status/view/JobStatus");
    }),
};
export var onlineCatalogsRoutes = [
    {
        label: "onlineCatalogs.menu.label",
        route: "",
        permissions: [
            PERMISSION.QC_MENU_CREATE,
            PERMISSION.QC_MENU_DELETE,
            PERMISSION.QC_MENU_READ,
            PERMISSION.QC_MENU_TOGGLE,
            PERMISSION.QC_MENU_UPDATE,
        ],
        icon: "/assets/icons/CatalogIcon.svg",
        platforms: [PLATFORM.ADMIN_PANEL],
        group: MENU_GROUP.QUICK_COMMERCE,
        subRoutes: [
            {
                label: "onlineCatalogs.menu.item.manageMenus",
                route: ONLINE_CATALOGS_PATH.MANAGE_MENUS,
                component: LazyComponents.ManageMenus,
                permissions: [PERMISSION.QC_MENU_READ],
                platforms: [PLATFORM.ADMIN_PANEL],
            },
            {
                label: "onlineCatalogs.menu.item.manageCustomisations",
                route: ONLINE_CATALOGS_PATH.MANAGE_CUSTOMISATIONS,
                component: LazyComponents.ManageCustomisations,
                permissions: [PERMISSION.QC_MENU_READ],
                platforms: [PLATFORM.ADMIN_PANEL],
            },
            {
                label: "onlineCatalogs.menu.item.editMenu",
                route: ONLINE_CATALOGS_PATH.EDIT_MENU,
                component: LazyComponents.EditMenu,
                permissions: [PERMISSION.QC_MENU_DELETE, PERMISSION.QC_MENU_UPDATE],
                platforms: [PLATFORM.ADMIN_PANEL],
                hidden: true,
            },
            {
                label: "onlineCatalogs.menu.item.editMenu",
                route: ONLINE_CATALOGS_PATH.COPY_MENU,
                component: LazyComponents.CopyMenu,
                permissions: [PERMISSION.QC_MENU_DELETE, PERMISSION.QC_MENU_UPDATE],
                platforms: [PLATFORM.ADMIN_PANEL],
                hidden: true,
            },
            {
                label: "",
                route: ONLINE_CATALOGS_PATH.ADD_MENU,
                component: LazyComponents.AddMenu,
                permissions: [PERMISSION.QC_MENU_CREATE],
                platforms: [PLATFORM.ADMIN_PANEL],
                hidden: true,
            },
            {
                label: "onlineCatalogs.menu.item.viewMenu",
                route: ONLINE_CATALOGS_PATH.VIEW_MENU,
                component: LazyComponents.ViewMenu,
                permissions: [PERMISSION.QC_MENU_READ],
                platforms: [PLATFORM.ADMIN_PANEL],
                hidden: true,
            },
            {
                label: "onlineCatalogs.categories.add",
                route: ONLINE_CATALOGS_PATH.ADD_CATEGORY,
                component: LazyComponents.AddCategory,
                permissions: [PERMISSION.QC_MENU_CREATE],
                platforms: [PLATFORM.ADMIN_PANEL],
                hidden: true,
            },
            {
                label: "onlineCatalogs.categories.view",
                route: ONLINE_CATALOGS_PATH.VIEW_CATEGORY,
                component: LazyComponents.ViewCategory,
                permissions: [PERMISSION.QC_MENU_READ],
                platforms: [PLATFORM.ADMIN_PANEL],
                hidden: true,
            },
            {
                label: "onlineCatalogs.categories.edit",
                route: ONLINE_CATALOGS_PATH.EDIT_CATEGORY,
                component: LazyComponents.EditCategory,
                permissions: [PERMISSION.QC_MENU_DELETE, PERMISSION.QC_MENU_UPDATE],
                platforms: [PLATFORM.ADMIN_PANEL],
                hidden: true,
            },
            {
                label: "onlineCatalogs.menuItem.view",
                route: ONLINE_CATALOGS_PATH.VIEW_MENU_ITEM,
                component: LazyComponents.ViewMenuItem,
                permissions: [PERMISSION.QC_MENU_READ],
                platforms: [PLATFORM.ADMIN_PANEL],
                hidden: true,
            },
            {
                label: "onlineCatalogs.categories.add",
                route: ONLINE_CATALOGS_PATH.ADD_MENU_ITEM,
                component: LazyComponents.AddMenuItem,
                permissions: [PERMISSION.QC_MENU_CREATE],
                platforms: [PLATFORM.ADMIN_PANEL],
                hidden: true,
            },
            {
                label: "onlineCatalogs.menuItem.view",
                route: ONLINE_CATALOGS_PATH.ADD_MENU_ITEM_CUSTOMISATION,
                component: LazyComponents.AddMenuItemCustomisation,
                permissions: [PERMISSION.QC_MENU_READ],
                platforms: [PLATFORM.ADMIN_PANEL],
                hidden: true,
            },
            {
                label: "",
                route: ONLINE_CATALOGS_PATH.EDIT_MENU_ITEMS,
                component: LazyComponents.EditMenuItems,
                permissions: [PERMISSION.QC_MENU_READ],
                platforms: [PLATFORM.ADMIN_PANEL],
                hidden: true,
            },
            {
                label: "onlineCatalogs.menuItem.delete",
                route: ONLINE_CATALOGS_PATH.DELETE_MENU_ITEMS,
                component: LazyComponents.DeleteMenuItems,
                permissions: [PERMISSION.QC_MENU_DELETE],
                platforms: [PLATFORM.ADMIN_PANEL],
                hidden: true,
            },
            {
                label: "",
                route: ONLINE_CATALOGS_PATH.DELETE_CATEGORIES,
                component: LazyComponents.DeleteCategories,
                permissions: [PERMISSION.QC_MENU_READ],
                platforms: [PLATFORM.ADMIN_PANEL],
                hidden: true,
            },
            {
                label: "",
                route: ONLINE_CATALOGS_PATH.VIEW_CUSTOMISATION,
                component: LazyComponents.ViewCustomisation,
                permissions: [PERMISSION.QC_MENU_READ],
                platforms: [PLATFORM.ADMIN_PANEL],
                hidden: true,
            },
            {
                label: "",
                route: ONLINE_CATALOGS_PATH.ADD_CUSTOMISATION,
                component: LazyComponents.AddCustomisation,
                permissions: [PERMISSION.QC_MENU_CREATE],
                platforms: [PLATFORM.ADMIN_PANEL],
                hidden: true,
            },
            {
                label: "",
                route: ONLINE_CATALOGS_PATH.ADD_CUSTOMISATION_OPTION,
                component: LazyComponents.AddCustomisationOption,
                permissions: [PERMISSION.QC_MENU_CREATE],
                platforms: [PLATFORM.ADMIN_PANEL],
                hidden: true,
            },
            {
                label: "onlineCatalogs.page.bulkUpdateImage.header.title",
                route: ONLINE_CATALOGS_PATH.BULK_UPDATE_IMAGE,
                component: LazyComponents.BulkUpdateImage,
                permissions: [PERMISSION.QC_MENU_CREATE],
                platforms: [PLATFORM.ADMIN_PANEL],
            },
            {
                label: "onlineCatalogs.page.imageUpdateJobStatus.header.title",
                route: ONLINE_CATALOGS_PATH.IMAGE_UPDATE_JOBS,
                component: LazyComponents.ImageUpdateJobStatus,
                permissions: [PERMISSION.QC_MENU_CREATE],
                platforms: [PLATFORM.ADMIN_PANEL],
            },
            {
                label: "onlineCatalogs.page.bulkUpdateImage.jobStatus.view",
                route: ONLINE_CATALOGS_PATH.VIEW_JOB_STATUS,
                component: LazyComponents.JobStatus,
                permissions: [PERMISSION.QC_MENU_CREATE],
                platforms: [PLATFORM.ADMIN_PANEL],
                hidden: true,
            },
            {
                label: "onlineCatalogs.page.manageCustomisations.tabs.menuItems.button",
                route: ONLINE_CATALOGS_PATH.MENU_ITEM_ASSIGNED,
                component: LazyComponents.MenuItemAssigned,
                permissions: [PERMISSION.QC_MENU_CREATE, PERMISSION.ALPHA],
                platforms: [PLATFORM.ADMIN_PANEL],
                hidden: true,
            },
        ],
    },
];
export var initOnlineCatalogs = function () {
    globalEvents.emit(ROUTE_EVENTS.REGISTER, onlineCatalogsRoutes);
};

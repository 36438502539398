var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useCallback, useMemo } from "react";
import { onlyNonExpiredTaxes } from "./helpers";
import { useBusinessBranchesInfoAPI } from "./useBusinessBranchesInfoAPI";
export var useMergeBusinessBranchInfo = function (businessBranches) {
    var allInfo = useBusinessBranchesInfoAPI();
    var findInfo = useCallback(function (bbid) { return allInfo.filter(function (_a) {
        var id = _a.id;
        return id === bbid;
    })[0]; }, [allInfo]);
    return useMemo(function () {
        if (!allInfo.length)
            return businessBranches;
        if (!businessBranches.length)
            return businessBranches;
        return businessBranches.map(function (data) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13;
            var bbinfo = (_a = findInfo(data.business_branch_id + "")) !== null && _a !== void 0 ? _a : {};
            return __assign(__assign({}, data), { uid: (_b = bbinfo.uid) !== null && _b !== void 0 ? _b : "", cost_centre: (_c = bbinfo.cost_centre) !== null && _c !== void 0 ? _c : "", plant_location: (_d = bbinfo.plant_location) !== null && _d !== void 0 ? _d : "", profit_centre: (_e = bbinfo.profit_centre) !== null && _e !== void 0 ? _e : "", receipt_template: (_f = bbinfo.receipt_template) !== null && _f !== void 0 ? _f : 0, storage_location: (_g = bbinfo.storage_location) !== null && _g !== void 0 ? _g : "", created_at: (_h = bbinfo.created_at) !== null && _h !== void 0 ? _h : "", updated_at: (_j = bbinfo.updated_at) !== null && _j !== void 0 ? _j : "", deleted_at: (_k = bbinfo.deleted_at) !== null && _k !== void 0 ? _k : "", updated_by: (_l = bbinfo.updated_by) !== null && _l !== void 0 ? _l : "", additional_sap_mapping: bbinfo.additional_sap_mapping
                    ? JSON.parse(bbinfo.additional_sap_mapping)
                    : {}, business_date: (_m = bbinfo.business_date) !== null && _m !== void 0 ? _m : "", capita_machine_id: (_o = bbinfo.capita_machine_id) !== null && _o !== void 0 ? _o : "", daily_cash_variance_threshold: (_p = bbinfo.daily_cash_variance_threshold) !== null && _p !== void 0 ? _p : 0, monthly_cash_variance_threshold: (_q = bbinfo.monthly_cash_variance_threshold) !== null && _q !== void 0 ? _q : 0, tms_cash_refund: (_r = bbinfo.tms_cash_refund) !== null && _r !== void 0 ? _r : true, tms_merchant_id: (_s = bbinfo.tms_merchant_id) !== null && _s !== void 0 ? _s : "", tms_contract_id: (_t = bbinfo.tms_contract_id) !== null && _t !== void 0 ? _t : "", uen: (_u = bbinfo.uen) !== null && _u !== void 0 ? _u : "", gst_company_name: (_v = bbinfo.gst_company_name) !== null && _v !== void 0 ? _v : "", no_of_tables: (_w = bbinfo.no_of_tables) !== null && _w !== void 0 ? _w : 0, extended_ops_hours: (_x = bbinfo.extended_ops_hours) !== null && _x !== void 0 ? _x : false, restaurant_mode: (_y = bbinfo.restaurant_mode) !== null && _y !== void 0 ? _y : false, gst_reg_no: (_z = bbinfo.gst_reg_no) !== null && _z !== void 0 ? _z : "", co_reg_no: (_0 = bbinfo.co_reg_no) !== null && _0 !== void 0 ? _0 : "", discounts: (_1 = bbinfo.discounts) !== null && _1 !== void 0 ? _1 : [], loyalties: (_2 = bbinfo.loyalties) !== null && _2 !== void 0 ? _2 : [], bestseller: (_3 = bbinfo.bestseller) !== null && _3 !== void 0 ? _3 : false, qty_of_receipt: (_4 = bbinfo.qty_of_receipt) !== null && _4 !== void 0 ? _4 : 1, queue_printing: (_5 = bbinfo.queue_printing) !== null && _5 !== void 0 ? _5 : false, business_branch_sales_platform: {
                    pos_no: (_7 = (_6 = bbinfo.business_branch_sales_platform) === null || _6 === void 0 ? void 0 : _6.pos_no) !== null && _7 !== void 0 ? _7 : "",
                    partner_names: (_9 = (_8 = bbinfo.business_branch_sales_platform) === null || _8 === void 0 ? void 0 : _8.partner_names.slice()) !== null && _9 !== void 0 ? _9 : [],
                }, tax_configs: onlyNonExpiredTaxes(bbinfo.tax_configs), external_operator: (_10 = bbinfo.external_operator) !== null && _10 !== void 0 ? _10 : null, blind_shortage_alert: (_11 = bbinfo.blind_shortage_alert) !== null && _11 !== void 0 ? _11 : false, shift_management: (_12 = bbinfo.shift_management) !== null && _12 !== void 0 ? _12 : false, partial_payment: (_13 = bbinfo.partial_payment) !== null && _13 !== void 0 ? _13 : false });
        });
    }, [businessBranches, allInfo]);
};

export var HELP_PATH;
(function (HELP_PATH) {
    HELP_PATH["ADMIN_PANEL"] = "/help/adminpanel";
    HELP_PATH["KPOS"] = "/help/kpos";
    HELP_PATH["PARTNER_CENTER"] = "/help/partnercenter";
})(HELP_PATH || (HELP_PATH = {}));
export var HELP_CONTENT;
(function (HELP_CONTENT) {
    HELP_CONTENT["ADMIN_PANEL"] = "ap help content";
    HELP_CONTENT["KPOS"] = "kpos help content";
    HELP_CONTENT["PARTNER_CENTER"] = "pc help content";
})(HELP_CONTENT || (HELP_CONTENT = {}));

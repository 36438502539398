var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useCallback } from "react";
import { useLang } from "../../../context/hooks";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { getSelectableLangData } from "../../../i18n/helpers";
import { DEFAULT_LANG_OPTION, LANG_SELECTABLES } from "../constants";
import { LANG } from "../types";
import { LANG_COOKIE as LANG_CONFIG } from "./constants";
export var useLangSelector = function () {
    var _a = useLang(), lang = _a.lang, setGeneralData = _a.setGeneralData;
    var _b = useLocalStorage(LANG_CONFIG, LANG_SELECTABLES[DEFAULT_LANG_OPTION].lang), setLangCookie = _b[1];
    var setLang = useCallback(function (evt) {
        var selectedLang = evt.target.value;
        setGeneralData(function (prev) { return (__assign(__assign({}, prev), { language: getSelectableLangData(selectedLang) })); });
        setLangCookie(selectedLang);
    }, []);
    var handleLangToggle = useCallback(function () {
        var nextLang = lang === LANG.CN ? LANG.EN : LANG.CN;
        setGeneralData(function (prev) { return (__assign(__assign({}, prev), { language: getSelectableLangData(nextLang) })); });
        setLangCookie(nextLang);
    }, [lang]);
    return {
        lang: lang,
        setLang: setLang,
        handleLangToggle: handleLangToggle,
    };
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EuiButton, EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiModalFooter, } from "@elastic/eui";
import I18nText from "../../../i18n/I18nText";
var AlertModalButtons = function (_a) {
    var buttons = _a.buttons;
    if (!(buttons === null || buttons === void 0 ? void 0 : buttons.length))
        return null;
    return (_jsx(EuiModalFooter, { children: _jsx(EuiFlexGroup, __assign({ direction: "column" }, { children: buttons.map(function (_a, idx) {
                var label = _a.label, onClick = _a.onClick, backgroundColor = _a.backgroundColor, color = _a.color, type = _a.type, disabled = _a.disabled;
                return (_jsxs(EuiFlexItem, __assign({ grow: true }, { children: [type === "icon" && (_jsx(EuiButtonIcon, { iconType: label, onClick: onClick, className: "Alert ".concat(disabled ? "Disabled" : ""), iconSize: "l", style: !disabled
                                ? {
                                    backgroundColor: backgroundColor,
                                    color: color,
                                }
                                : undefined, "aria-label": "button", disabled: disabled, "data-test-subj": "AlertModal-Button-".concat(label) })), type !== "icon" && (_jsx(EuiButton, __assign({ onClick: onClick, fullWidth: true, className: "Alert ".concat(disabled ? "Disabled" : ""), style: !disabled
                                ? {
                                    backgroundColor: backgroundColor,
                                    color: color,
                                }
                                : undefined, disabled: disabled, "data-test-subj": "AlertModal-Button-".concat(label) }, { children: _jsx(I18nText, { token: label }) })))] }), idx));
            }) })) }));
};
export default AlertModalButtons;

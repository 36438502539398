export var QCContextActionTypes;
(function (QCContextActionTypes) {
    QCContextActionTypes["SET_MODAL_DATA"] = "SET_MODAL_DATA";
    QCContextActionTypes["SET_MODAL_VISIBLE"] = "SET_MODAL_VISIBLE";
    QCContextActionTypes["UPDATE_CATEGORY_DATA"] = "UPDATE_CATEGORY_DATA";
    QCContextActionTypes["ADD_CATEGORY_DATA"] = "ADD_CATEGORY_DATA";
    QCContextActionTypes["RESET_CATEGORY_DATA"] = "RESET_CATEGORY_DATA";
    QCContextActionTypes["SET_CATEGORY_ERROR"] = "SET_CATEGORY_ERROR";
    QCContextActionTypes["ADD_MENU_ITEM_DATA"] = "ADD_MENU_ITEM_DATA";
    QCContextActionTypes["SET_MENU_ITEM_ERROR"] = "SET_MENU_ITEM_ERROR";
    QCContextActionTypes["REFETCH_MENU_ITEM_BY_ID"] = "REFETCH_MENU_ITEM_BY_ID";
    QCContextActionTypes["ADD_MENU_ITEM_DETAILS_DATA"] = "ADD_MENU_ITEM_DETAILS_DATA";
    QCContextActionTypes["SET_CUSTOMISATIONS_PAGED_ITEMS"] = "SET_CUSTOMISATIONS_PAGED_ITEMS";
    QCContextActionTypes["SET_CUSTOMISATIONS_DEFAULTS"] = "SET_CUSTOMISATIONS_DEFAULTS";
    QCContextActionTypes["SET_CUSTOMISATIONS_SEARCH_QUERY"] = "SET_CUSTOMISATIONS_SEARCH_QUERY";
    QCContextActionTypes["SET_CUSTOMISATIONS_SELECTED_FILTERS"] = "SET_CUSTOMISATIONS_SELECTED_FILTERS";
    QCContextActionTypes["SET_MENUS_FILTER_SELECTIONS"] = "SET_MENUS_FILTER_SELECTIONS";
    QCContextActionTypes["SET_CUSTOMISATIONS_FILTER_SELECTIONS"] = "SET_CUSTOMISATIONS_FILTER_SELECTIONS";
    QCContextActionTypes["SET_CATEGORIES_TO_DELETE"] = "SET_CATEGORIES_TO_DELETE";
    QCContextActionTypes["SET_MENUS_SELECTED_FILTERS"] = "SET_MENUS_SELECTED_FILTERS";
    QCContextActionTypes["SET_MENUS_DEFAULTS"] = "SET_MENUS_DEFAULTS";
    QCContextActionTypes["SET_MENUS_PAGED_ITEMS"] = "SET_MENUS_PAGED_ITEMS";
    QCContextActionTypes["SET_MENUS_SEARCH_QUERY"] = "SET_MENUS_SEARCH_QUERY";
    QCContextActionTypes["SET_MENU_TO_COPY"] = "SET_MENU_TO_COPY";
    QCContextActionTypes["SET_RESTAURANTS_FILTERED_ITEMS"] = "SET_RESTAURANTS_FILTERED_ITEMS";
    QCContextActionTypes["SET_RESTAURANTS_DEFAULT"] = "SET_RESTAURANTS_DEFAULT";
    QCContextActionTypes["SET_RESTAURANTS_SEARCH_QUERY"] = "SET_RESTAURANTS_SEARCH_QUERY";
    QCContextActionTypes["SET_RESTAURANTS_SELECTED_FILTERS"] = "SET_RESTAURANTS_SELECTED_FILTERS";
    QCContextActionTypes["SET_RESTAURANTS_FILTER_SELECTIONS"] = "SET_RESTAURANTS_FILTER_SELECTIONS";
    QCContextActionTypes["SET_MENUS_MENU_ITEMS_FILTER_CATEGORIES"] = "SET_MENUS_MENU_ITEMS_FILTER_CATEGORIES";
    QCContextActionTypes["SET_MENUS_ACTIVE_ID"] = "SET_MENUS_ACTIVE_ID";
    QCContextActionTypes["SET_IMAGE_UPDATE_JOBS_LIST"] = "SET_IMAGE_UPDATE_JOBS_LIST";
    QCContextActionTypes["SET_IMAGE_UPDATE_JOBS_SEARCH_QUERY"] = "SET_IMAGE_UPDATE_JOBS_SEARCH_QUERY";
    QCContextActionTypes["SET_IMAGE_UPDATE_JOBS_FILTER_SELECTION"] = "SET_IMAGE_UPDATE_JOBS_FILTER_SELECTION";
    QCContextActionTypes["SET_IMAGE_UPDATE_JOBS_SELECTED_FILTERS"] = "SET_IMAGE_UPDATE_JOBS_SELECTED_FILTERS";
    QCContextActionTypes["SET_IMAGE_UPDATE_JOBS_FILTER_SELECTIONS"] = "SET_IMAGE_UPDATE_JOBS_FILTER_SELECTIONS";
    QCContextActionTypes["SET_USER_MANAGEMENT_DATA"] = "SET_USER_MANAGEMENT_DATA";
})(QCContextActionTypes || (QCContextActionTypes = {}));
export var FILTER_KEY;
(function (FILTER_KEY) {
    FILTER_KEY["BRAND"] = "brand_id";
    FILTER_KEY["RESTAURANT"] = "restaurant_id";
    FILTER_KEY["LOCATION"] = "location_id";
    FILTER_KEY["ACTIVITY"] = "activity_id";
    FILTER_KEY["JOB_ID"] = "job_id";
    FILTER_KEY["CATEGORY"] = "category";
})(FILTER_KEY || (FILTER_KEY = {}));

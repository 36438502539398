import { randString } from "../../../../../common/stringutils";
import { ROLE_PREFIX } from "../../../../../components/rbac/constants";
import { TENANT_ROLES } from "../constants";
var onlyQCName = function (name) { return name.startsWith(ROLE_PREFIX.QC); };
var onlyKPOSName = function (name) { return name.startsWith(ROLE_PREFIX.KPOS); };
var onlyFSName = function (name) { return !onlyQCName(name) && !onlyKPOSName(name); };
var filterRoles = function (roles, allRoles, onlyName) {
    if (!allRoles.length)
        return [];
    return roles.filter(function (role) {
        var rolename = allRoles.filter(function (_a) {
            var id = _a.id;
            return id === role + "";
        })[0].name;
        return onlyName(rolename);
    });
};
export var getFSRoles = function (roles, allRoles) {
    return filterRoles(roles, allRoles, onlyFSName);
};
export var getQCRoles = function (roles, allRoles) {
    return filterRoles(roles, allRoles, onlyQCName);
};
export var getKPOSRoles = function (roles, allRoles) {
    return filterRoles(roles, allRoles, onlyKPOSName);
};
export var filterFSRoles = function (roles) {
    return roles.filter(function (_a) {
        var name = _a.name;
        return onlyFSName(name);
    });
};
export var filterQCRoles = function (roles) {
    return roles.filter(function (_a) {
        var name = _a.name;
        return onlyQCName(name);
    });
};
export var filterKPOSRoles = function (roles) {
    return roles.filter(function (_a) {
        var name = _a.name;
        return onlyKPOSName(name);
    });
};
export var uniqueRoleIds = function (roles) {
    return Array.from(new Set(roles));
};
export var createNewKPOSAssignment = function () { return ({
    roles: [],
    business_branch_ids: [],
    location_ids: [],
    ident: randString(23),
}); };
export var generateRandomEmail = function () {
    return "".concat(randString(11), "@").concat(randString(23), ".").concat(randString(3));
};
export var createNewKPOSData = function () { return ({
    passcode: "",
    confirmpasscode: "",
    assignments: [createNewKPOSAssignment()],
}); };
export var filterFSTenantRoles = function (roles, tenant_roles) {
    var allowed = tenant_roles !== null && tenant_roles !== void 0 ? tenant_roles : TENANT_ROLES;
    return roles.filter(function (_a) {
        var name = _a.name;
        return allowed.includes(name);
    });
};
export var filterUserTypeRoles = function (roles, user_type_roles) {
    return user_type_roles
        ? roles.filter(function (_a) {
            var name = _a.name;
            return user_type_roles.includes(name);
        })
        : roles;
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EuiFlexGroup, EuiSpacer } from "@elastic/eui";
import QRCode from "react-qr-code";
import I18nText from "../../i18n/I18nText";
import { useUserQRCodeData } from "./useUserQRCodeData";
var STYLE = { height: "auto", maxWidth: "100%", width: "100%" };
var UserQRCodeGenerator = function () {
    var _a = useUserQRCodeData(), payload = _a.payload, time = _a.validTill;
    return (_jsxs(EuiFlexGroup, __assign({ direction: "column", gutterSize: "none" }, { children: [_jsx(QRCode, { value: payload, style: STYLE }), _jsx(EuiSpacer, { size: "l" }), _jsx(I18nText, { token: "profile.qrcode.expire", values: { time: time } })] })));
};
export default UserQRCodeGenerator;

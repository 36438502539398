import { BOOLEAN_FLAG } from "../../fs/constants";
import { useFSCheckBooleanFlag } from "../../fs/useFSCheckBooleanFlag";
import { useFSDictionaryStorage } from "../hooks";
import { DICTIONARY_KEY } from "../types";
import { BUSINESS_API, BUSINESS_API_V2 } from "./constants";
import { businessDTOAdapter } from "./helpers";
var BusinessDictionary = function () {
    var useNewApi = useFSCheckBooleanFlag(BOOLEAN_FLAG.NEW_ACL_API_1, true);
    useFSDictionaryStorage(useNewApi ? BUSINESS_API_V2 : BUSINESS_API, DICTIONARY_KEY.BUSINESSESS, businessDTOAdapter);
    return null;
};
export default BusinessDictionary;

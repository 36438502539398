var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEuiI18n } from "@elastic/eui";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TODAY } from "../../common/constants";
import { globalEvents } from "../../common/GlobalEvents";
import { useLang, usePlatform } from "../context/hooks";
import { useAxiosGet } from "../hooks/useAxiosGet";
import { DEFAULT_LANG_OPTION, LANG_SELECTABLES, } from "../layout/header/constants";
import { announceMessage } from "../modals/alerts/helpers";
import { I18N_EVENT } from "./constants";
import { applyLangClassToHTML, replaceI18nToken, resolveLinkedMapping, toLowerCaseKeys, } from "./helpers";
var NODATA = {};
export var useRevertToDefaultLang = function () {
    var setGeneralData = useLang().setGeneralData;
    return useCallback(function () {
        setGeneralData(function (prev) { return (__assign(__assign({}, prev), { language: __assign({}, LANG_SELECTABLES[DEFAULT_LANG_OPTION]) })); });
    }, [setGeneralData]);
};
export var useLangData = function (lang) {
    var _a = useState(0), retry = _a[0], setRetry = _a[1];
    var url = useMemo(function () {
        return lang ? "/assets/data/".concat(lang, ".json?t=").concat(TODAY.format("DD-MM-YYYY")) : "";
    }, [lang]);
    var _b = useAxiosGet(url, retry, undefined, true), response = _b.response, getError = _b.getError;
    var revert = useRevertToDefaultLang();
    useEffect(function () {
        if (getError) {
            if (retry < 3) {
                setRetry(function (prev) { return prev + 1; });
                return;
            }
            announceMessage("Error loading language", "There was a problem loading ".concat(lang, " data. Please reload the browser."));
            revert();
        }
    }, [getError, revert, retry]);
    return useMemo(function () { return ({
        mapping: response || NODATA,
    }); }, [response]);
};
export var usePlatformLangData = function (lang) {
    var platform = usePlatform();
    var _a = useState(0), retry = _a[0], setRetry = _a[1];
    var url = useMemo(function () {
        return lang
            ? "/assets/data/".concat(lang, "-").concat(platform, ".json?t=").concat(TODAY.format("DD-MM-YYYY"))
            : "";
    }, [lang, platform]);
    var _b = useAxiosGet(url, retry, undefined, true), response = _b.response, getError = _b.getError;
    useEffect(function () {
        if (getError) {
            if (retry < 3) {
                setRetry(function (prev) { return prev + 1; });
                return;
            }
        }
    }, [getError, retry]);
    return useMemo(function () { return ({
        platform_specific_mapping: response || NODATA,
    }); }, [response]);
};
export var useGetResolvedLangDataForPlatform = function (lang) {
    var mapping = useLangData(lang).mapping;
    var platform_specific_mapping = usePlatformLangData(lang).platform_specific_mapping;
    var combinedMapping = useMemo(function () {
        return toLowerCaseKeys(__assign(__assign({}, mapping), platform_specific_mapping));
    }, [mapping, platform_specific_mapping]);
    return useMemo(function () { return (lang ? resolveLinkedMapping(combinedMapping) : NODATA); }, [combinedMapping, lang]);
};
export var useI18nContext = function (mapping) {
    var lang = useLang().lang;
    var i18n = useMemo(function () { return ({
        mapping: mapping,
        formatNumber: function (value) {
            return new Intl.NumberFormat(lang).format(value);
        },
    }); }, [lang, mapping]);
    useEffect(function () {
        applyLangClassToHTML(lang);
    }, [lang]);
    return {
        i18n: i18n,
        mapping: mapping,
    };
};
export var useGetTextFromMapping = function (mapping) {
    return useCallback(function (key) {
        var value = mapping[key];
        if (value === undefined)
            return key;
        while (value !== mapping[value] && mapping[value] !== undefined) {
            value = mapping[value];
        }
        return value;
    }, [mapping]);
};
export var useAllowNonI18nHookAccess = function (mapping) {
    var getText = useGetTextFromMapping(mapping);
    useEffect(function () {
        globalEvents.on(I18N_EVENT.GET_TEXT, getText);
        return function () {
            globalEvents.off(I18N_EVENT.GET_TEXT, getText);
        };
    }, [getText]);
};
export var useI18nString = function (keys) {
    var text = useEuiI18n(keys.map(function (key) { return key.toLowerCase(); }), keys);
    var getText = useCallback(function (key, values) {
        var idx = keys.indexOf(key);
        return replaceI18nToken(text[idx] || key, values);
    }, [text, keys]);
    return {
        getText: getText,
    };
};
export var useI18nStringArray = function () {
    var keys = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        keys[_i] = arguments[_i];
    }
    var getText = useI18nString(keys).getText;
    return useMemo(function () { return keys.map(function (key) { return getText(key); }); }, [getText, keys]);
};
/**
 * This will return a map of keys with their respective i18n values.
 * Note that map key will be the last value of the key split using . eg. abc.def.xyz <- xyz will be used
 * So make sure that the keys should not clash.
 * @param keys
 * @returns
 */
export var useI18nStringMap = function () {
    var keys = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        keys[_i] = arguments[_i];
    }
    var getText = useI18nString(keys).getText;
    return useMemo(function () {
        return keys.reduce(function (map, key) {
            var keysplit = key.split(".");
            map[keysplit[keysplit.length - 1]] = getText(key);
            return map;
        }, {});
    }, [getText, keys]);
};
export var useTranslatedTableColumnHeaders = function (config) {
    var keys = useMemo(function () { return config.map(function (_a) {
        var name = _a.name;
        return name;
    }); }, [config]);
    var texts = useI18nStringArray.apply(void 0, keys);
    return useMemo(function () {
        return config.map(function (column, idx) { return (__assign(__assign({}, column), { name: texts[idx] === keys[idx] ? column.field : texts[idx] })); });
    }, [config, texts]);
};
export var useI18nToken = function (token, values) {
    var tokentext = useI18nStringArray(token)[0];
    return useMemo(function () {
        return replaceI18nToken(tokentext, values);
    }, [tokentext, values]);
};

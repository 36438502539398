export var STATIC_PAGE_PLATFORM;
(function (STATIC_PAGE_PLATFORM) {
    STATIC_PAGE_PLATFORM[STATIC_PAGE_PLATFORM["ALL"] = 0] = "ALL";
    STATIC_PAGE_PLATFORM[STATIC_PAGE_PLATFORM["ADMIN_PANEL"] = 1] = "ADMIN_PANEL";
    STATIC_PAGE_PLATFORM[STATIC_PAGE_PLATFORM["PARTNER_CENTRE"] = 2] = "PARTNER_CENTRE";
})(STATIC_PAGE_PLATFORM || (STATIC_PAGE_PLATFORM = {}));
export var STATIC_PAGE_LISTING_EVENT;
(function (STATIC_PAGE_LISTING_EVENT) {
    STATIC_PAGE_LISTING_EVENT["API_FAILED"] = "StaticPageListingEvent.ApiFailed";
})(STATIC_PAGE_LISTING_EVENT || (STATIC_PAGE_LISTING_EVENT = {}));

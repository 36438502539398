var _a, _b;
import { makeContext } from "../helpers";
import { DICTIONARY_KEY } from "./types";
export var API_HOST = process.env.CLOUD_RUN_GATEWAY_HOST || "";
export var MAX_API_ATTEMPTS = 2;
export var initialDictionaryData = (_a = {},
    _a[DICTIONARY_KEY.COMPANIES] = [],
    _a[DICTIONARY_KEY.BRANDS] = [],
    _a[DICTIONARY_KEY.LOCATIONS] = [],
    _a[DICTIONARY_KEY.BUSINESSESS] = [],
    _a[DICTIONARY_KEY.BUSINESS_BRANCHES] = [],
    _a[DICTIONARY_KEY.CBB] = [],
    _a.raw = (_b = {},
        _b[DICTIONARY_KEY.COMPANIES] = [],
        _b[DICTIONARY_KEY.BRANDS] = [],
        _b[DICTIONARY_KEY.LOCATIONS] = [],
        _b[DICTIONARY_KEY.BUSINESSESS] = [],
        _b[DICTIONARY_KEY.BUSINESS_BRANCHES] = [],
        _b),
    _a);
export var DictionaryContext = makeContext(initialDictionaryData);
export var DICTIONARY_ERROR_ANNOUNCEMENT_DELAY = 1000; // need this delay because dictionary is one of the first things to request. I18n and AlertController may not be ready yet.
export var DICTIONARY_EVENT;
(function (DICTIONARY_EVENT) {
    DICTIONARY_EVENT["REFRESH"] = "DictionaryEvent.Refresh";
})(DICTIONARY_EVENT || (DICTIONARY_EVENT = {}));

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { useContext, useEffect, useMemo } from "react";
import { mergeStringArray } from "../../../common/helpers";
import { jsonApiAdapter } from "../../../common/jsonapi/jsonapi";
import { getQCUserByEmail } from "../../../pages/usermanagement/edit/api/helpers";
import { getQCAllData } from "../../../pages/usermanagement/userlist/api/helpers/getQCAllData";
import { useFSConfigs } from "../../api/fs/configuration/useFSConfigs";
import { FS_GATEWAY_HOST } from "../../api/fs/constants";
import { useFSConfigProps } from "../../api/fs/props/useFSConfigProps";
import { ACCESS_PERMISSION_PROP } from "../../api/rbac/accesspermissions/constants";
import { useAccessPermissionConfigValue } from "../../api/rbac/accesspermissions/useAccessPermissionConfigValue";
import { getErrorCodeFromAxiosError } from "../../hooks/helpers";
import { useAxiosPost } from "../../hooks/useAxiosPost";
import { useI18nToken } from "../../i18n/hooks";
import { announceFatalErrorMessage } from "../../modals/alerts/helpers";
import { useGenericAxiosErrorToast } from "../../modals/alerts/hooks";
import { hasRequiredPermission } from "../../rbac/helpers";
import { useLoadAllAccessConfigs } from "../../rbac/hooks/useLoadAllAccessConfigs";
import { PERMISSION } from "../../rbac/permissions";
import { AllContext } from "../constants";
import { DictionaryContext } from "../dictionaries/constants";
import { useExpandedAccessPermissionsConfigs } from "../filtering/userAccessibles";
import { mapOnlyIDs } from "../helpers";
import { useUserInfo, useUserPermissions } from "../hooks";
import { useQCStateContext } from "../qc/hooks";
import { QCContextActionTypes } from "../qc/types";
import { DEFAULT_USER_LOGON_ERROR, KNOWN_USER_LOGIN_ERROR } from "./constants";
import { combinePermissions, getDataFromBOUser, getUserBrands, getUserBusinesses, getUserKPOSAssignments, getUserLocations, getUserRoles, is404Error, } from "./helpers";
var BACKOFFICE_USER_GET_API = "".concat(FS_GATEWAY_HOST).concat((_a = process.env.BACKOFFICE_USER_GET_API) !== null && _a !== void 0 ? _a : "");
var NO_PERMISSION = [];
export var useHandleBadUserInfoError = function (url, errorObj) {
    var finalError = useMemo(function () {
        if (!errorObj)
            return undefined;
        if (is404Error(errorObj === null || errorObj === void 0 ? void 0 : errorObj.toJSON()["message"]))
            return undefined;
        return errorObj;
    }, [errorObj]);
    useGenericAxiosErrorToast(url, finalError);
};
export var useBackofficeUserInfo = function () {
    var _a = useContext(AllContext.GeneralDataContext), _b = _a[0], isLoggedIn = _b.isLoggedIn, _c = _b.userInfo, email = _c.email, isChecked = _c.checked, permissions = _b.permissions, loggedon = _b.loggedon, setGeneraData = _a[1];
    var payload = useMemo(function () {
        if (!isLoggedIn)
            return null;
        if (!loggedon)
            return null;
        if (!email)
            return null;
        return {
            email: email,
        };
    }, [email, isLoggedIn, loggedon]);
    var _d = useAxiosPost(payload ? BACKOFFICE_USER_GET_API : "", payload), response = _d.response, postError = _d.postError, postData = _d.postData;
    var userJson = useMemo(function () {
        if (!response)
            return null;
        return jsonApiAdapter(response);
    }, [response]);
    var uniquePermissions = useMemo(function () {
        if (!userJson)
            return NO_PERMISSION;
        var permissionInfo = getDataFromBOUser(userJson, email).permissionInfo;
        return combinePermissions(permissionInfo, permissions);
    }, [userJson, email]);
    var _e = useAccessPermissionConfigValue.apply(void 0, uniquePermissions), accessConfigs = _e.config, accessGroups = _e.group;
    var accessconfig = useExpandedAccessPermissionsConfigs(accessGroups);
    var allaccessconfigs = useLoadAllAccessConfigs();
    useEffect(function () {
        postData();
    }, [postData]);
    useEffect(function () {
        var _a, _b, _c;
        if (!userJson)
            return;
        var _d = getDataFromBOUser(userJson, email), platform_access = _d.platform_access, user_type = _d.user_type, server_time = _d.server_time;
        var locations = mergeStringArray(getUserLocations(userJson, email), (_a = accessConfigs[ACCESS_PERMISSION_PROP.LOCATION_IDS]) !== null && _a !== void 0 ? _a : []);
        var brands = mergeStringArray(getUserBrands(userJson, email), (_b = accessConfigs[ACCESS_PERMISSION_PROP.BRAND_IDS]) !== null && _b !== void 0 ? _b : []);
        var businesses = mergeStringArray(getUserBusinesses(userJson, email), (_c = accessConfigs[ACCESS_PERMISSION_PROP.BUSINESS_IDS]) !== null && _c !== void 0 ? _c : []);
        var kpos = getUserKPOSAssignments(userJson, email);
        var roles = getUserRoles(userJson, email);
        setGeneraData(function (prev) { return (__assign(__assign({}, prev), { permissions: uniquePermissions, userInfo: __assign(__assign({}, prev.userInfo), { platform_access: platform_access, user_type: user_type, accessibles: __assign(__assign({}, prev.userInfo.accessibles), { locations: locations, brands: brands, businesses: businesses }), checked: true, kpos: kpos, roles: roles, server_time: server_time, accessconfig: accessconfig }), allaccessconfigs: allaccessconfigs })); });
    }, [
        userJson,
        email,
        uniquePermissions,
        accessConfigs,
        accessconfig,
        allaccessconfigs,
    ]);
    var genericError = useI18nToken(DEFAULT_USER_LOGON_ERROR, { email: email });
    useEffect(function () {
        var _a;
        if (!postError)
            return;
        if (isChecked)
            return;
        setGeneraData(function (prev) { return (__assign(__assign({}, prev), { userInfo: __assign(__assign({}, prev.userInfo), { checked: true }) })); });
        var errorCode = getErrorCodeFromAxiosError(postError);
        var content = (_a = KNOWN_USER_LOGIN_ERROR[errorCode]) !== null && _a !== void 0 ? _a : genericError;
        announceFatalErrorMessage({
            content: content,
        });
    }, [postError, isChecked]);
};
export var useAllowAllFSAccessiblesCheck = function () {
    var userPermissions = useUserPermissions();
    var allAccess = useMemo(function () {
        return hasRequiredPermission([PERMISSION.FS_ALL_LOCATIONS_ACCESS], userPermissions);
    }, [userPermissions]);
    var _a = useContext(DictionaryContext)[0], locations = _a.locations, brands = _a.brands, businesses = _a.businesses;
    var _b = useContext(AllContext.GeneralDataContext), setUserAccessibles = _b[1];
    useEffect(function () {
        if (!allAccess)
            return;
        setUserAccessibles(function (prev) { return (__assign(__assign({}, prev), { userInfo: __assign(__assign({}, prev.userInfo), { accessibles: __assign(__assign({}, prev.userInfo.accessibles), { locations: mapOnlyIDs(locations), brands: mapOnlyIDs(brands), businesses: mapOnlyIDs(businesses) }) }) })); });
    }, [allAccess, locations, brands, businesses]);
};
export var useFSConfigurations = function () {
    var _a = useContext(AllContext.GeneralDataContext), setFSFlags = _a[1];
    var config = useFSConfigs();
    var fs_props = useFSConfigProps();
    useEffect(function () {
        var _a;
        var fs_config = (_a = config === null || config === void 0 ? void 0 : config.config) !== null && _a !== void 0 ? _a : null;
        setFSFlags(function (prev) { return (__assign(__assign({}, prev), { fs_config: fs_config, fs_props: fs_props })); });
    }, [config, fs_props]);
};
export var useQCInitialData = function () {
    var dispatch = useQCStateContext().dispatch;
    useEffect(function () {
        getQCAllData()
            .then(function (_a) {
            var brands = _a.brands, locations = _a.locations;
            dispatch({
                type: QCContextActionTypes.SET_USER_MANAGEMENT_DATA,
                payload: {
                    userManagement: {
                        selections: { brands: brands, locations: locations },
                    },
                },
            });
        })
            .catch(function (e) { return console.log("Error on getQCAllData, ", e); });
    }, []);
};
export var useQCUserData = function () {
    var dispatch = useQCStateContext().dispatch;
    var email = useUserInfo().email;
    useEffect(function () {
        if (!(email === null || email === void 0 ? void 0 : email.length))
            return;
        getQCUserByEmail(email)
            .then(function (_a) {
            var id = _a.id;
            // not all backoffice users will have QC assignments. So if they don't, they will not have a record in QC and getQCUserByEmail() will return no data, triggering an exception
            // instead of doing that, it will return id = -1
            if (id < 0)
                return;
            dispatch({
                type: QCContextActionTypes.SET_USER_MANAGEMENT_DATA,
                payload: {
                    userManagement: { currentUser: { id: id } },
                },
            });
        })
            .catch(function (e) { return console.log("Error on getQCAllData, ", e); });
    }, [email]);
};

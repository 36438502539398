var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EuiPage } from "@elastic/eui";
import BootStrapper from "../components/boot/BootStrapper";
import BackOfficeContext from "../components/context/BackOfficeContext";
import I18nContext from "../components/i18n/I18nContext";
import LoaderOverlay from "../components/loader/LoaderOverlay";
import AlertController from "../components/modals/alerts/AlertController";
import { useDetectLandingPageBeforeAuth } from "../components/routing/hooks";
import Routes from "../components/routing/Routes";
import SplitIO from "../components/splitio/SplitIO";
import { useReadyDelay } from "./hooks";
var BackOffice = function () {
    /**
     * Enforce a delay to wait for all routes to be registered
     * This is required especially when user tries to access a subroute directly
     */
    var ready = useReadyDelay(100);
    useDetectLandingPageBeforeAuth();
    return (_jsx(EuiPage, __assign({ paddingSize: "none", direction: "column", className: "FixedHeader" }, { children: _jsx(SplitIO, { children: _jsx(BackOfficeContext, { children: _jsxs(I18nContext, { children: [_jsx(AlertController, {}), _jsx(BootStrapper, {}), ready && _jsx(Routes, {}), _jsx(LoaderOverlay, {})] }) }) }) })));
};
export default BackOffice;

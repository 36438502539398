import { useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import { globalEvents } from "../../../common/GlobalEvents";
import { SESSION_TRACKER } from "./constants";
var CLICK_DEBOUNCE = 1000;
var globalClick = function () {
    globalEvents.emit(SESSION_TRACKER.PING);
};
export var useTrackGlobalClicks = function () {
    var debouncedClick = useDebouncedCallback(globalClick, CLICK_DEBOUNCE);
    useEffect(function () {
        window.document.body.addEventListener("click", debouncedClick, true);
        return function () {
            window.document.body.removeEventListener("click", debouncedClick, true);
        };
    }, []);
};

import moment from "moment";
import { TODAY } from "../../../common/constants";
import { stringEnumsToArray } from "../../../common/stringutils";
import { MONTH_FORMAT } from "../../../components/input/monthselect/helpers";
export var DOCUMENTS_PATH;
(function (DOCUMENTS_PATH) {
    DOCUMENTS_PATH["INVOICES"] = "/fs/documents/invoices";
    DOCUMENTS_PATH["UPLOAD"] = "/fs/documents/upload";
})(DOCUMENTS_PATH || (DOCUMENTS_PATH = {}));
export var DOCUMENT_TYPE;
(function (DOCUMENT_TYPE) {
    DOCUMENT_TYPE["SD"] = "SD";
    DOCUMENT_TYPE["ADV"] = "ADV";
    DOCUMENT_TYPE["TSOA"] = "TSOA";
    DOCUMENT_TYPE["FI"] = "FI";
})(DOCUMENT_TYPE || (DOCUMENT_TYPE = {}));
export var DOCUMENT_TYPES = stringEnumsToArray(DOCUMENT_TYPE);
export var DOC_MIN_MONTH = "01-2022";
export var DOC_MAX_MONTH = moment(TODAY)
    .add(1, "month")
    .format(MONTH_FORMAT);

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./Error.scss";
import { EuiButton, EuiFlexGroup, EuiPage, EuiSpacer } from "@elastic/eui";
import { usePageTitle } from "../../common/hooks/usePageTitle";
import I18nText from "../i18n/I18nText";
import { useLogout } from "../layout/sidenav/logout/hooks";
var ErrorPCNotAUser = function () {
    usePageTitle("fperror.unauthorised.title");
    var logout = useLogout();
    return (_jsx(EuiPage, __assign({ className: "PCNotAUser" }, { children: _jsxs(EuiFlexGroup, __assign({ direction: "column", gutterSize: "none", alignItems: "center", justifyContent: "center" }, { children: [_jsx("img", { src: "/assets/svg/kopitiamlogo.svg", className: "KopitiamLogo" }), _jsx(EuiSpacer, { size: "l" }), _jsx(I18nText, { token: "fperror.unauthorised.title", className: "ErrorTitle" }), _jsx(EuiSpacer, { size: "xl" }), _jsx(I18nText, { token: "fperror.unauthorised.message1" }), _jsx(EuiSpacer, { size: "l" }), _jsx(EuiButton, __assign({ className: "BlueButton Wide", onClick: logout }, { children: _jsx(I18nText, { token: "fperror.unauthorised.button" }) })), _jsx(EuiSpacer, { size: "xxl", className: "Line" }), _jsx(I18nText, { token: "fperror.unauthorised.or", className: "Or" }), _jsx(EuiSpacer, { size: "l" }), _jsx(I18nText, { token: "fperror.unauthorised.message2", className: "Help" })] })) })));
};
export default ErrorPCNotAUser;

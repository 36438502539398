import { useCallback, useEffect, useRef } from "react";
import { globalEvents } from "../../../common/GlobalEvents";
import { GETDATAPOOL, GETDATA_STATUS } from "../constants";
export var useDataPool = function () {
    var pool = useRef({});
    var getCache = useCallback(function (get) {
        var _a;
        return (_a = pool.current[get]) !== null && _a !== void 0 ? _a : null;
    }, []);
    var initCache = useCallback(function (get) {
        pool.current[get] = {
            data: null,
            status: GETDATA_STATUS.PENDING,
            validuntil: "",
        };
    }, []);
    var saveCache = useCallback(function (_a) {
        var get = _a.get, value = _a.value, validuntil = _a.validuntil;
        pool.current[get] = {
            data: value,
            status: GETDATA_STATUS.DONE,
            validuntil: validuntil,
        };
    }, []);
    useEffect(function () {
        globalEvents.on(GETDATAPOOL.GET, getCache);
        globalEvents.on(GETDATAPOOL.SAVE, saveCache);
        globalEvents.on(GETDATAPOOL.INIT, initCache);
        return function () {
            globalEvents.off(GETDATAPOOL.GET, getCache);
            globalEvents.off(GETDATAPOOL.SAVE, saveCache);
            globalEvents.off(GETDATAPOOL.INIT, initCache);
        };
    }, []);
};

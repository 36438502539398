import { useCallback } from "react";
import { useQCDispatch } from "../../../../components/context/qc/hooks";
import { QCContextActionTypes } from "../../../../components/context/qc/types";
export var useSetModalVisibility = function (visibility) {
    var dispatch = useQCDispatch();
    return useCallback(function () {
        dispatch({
            type: QCContextActionTypes.SET_MODAL_VISIBLE,
            payload: {
                modal: {
                    visible: visibility,
                },
            },
        });
    }, [visibility]);
};
export var useModalClose = function (onDiscard, onConfirm) {
    var dispatch = useQCDispatch();
    return useCallback(function () {
        onDiscard === null || onDiscard === void 0 ? void 0 : onDiscard();
        onConfirm === null || onConfirm === void 0 ? void 0 : onConfirm();
        dispatch({
            type: QCContextActionTypes.SET_MODAL_VISIBLE,
            payload: {
                modal: {
                    visible: false,
                },
            },
        });
    }, []);
};

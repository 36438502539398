var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useContext } from "react";
import { AllContext } from "./constants";
export var useLang = function () {
    var _a = useContext(AllContext.GeneralDataContext), lang = _a[0].language.lang, setGeneralData = _a[1];
    return {
        lang: lang,
        setGeneralData: setGeneralData,
    };
};
export var useGeneralContext = function () {
    var data = useContext(AllContext.GeneralDataContext)[0];
    return __assign({}, data);
};
export var useUserInfo = function () {
    return useGeneralContext().userInfo;
};
export var useUserPermissions = function () {
    return useGeneralContext().permissions;
};
export var useIsUserLoggedIn = function () {
    return useGeneralContext().isLoggedIn;
};
export var useRouteListingsData = function () {
    var routelistings = useContext(AllContext.RoutesListingContext)[0];
    return routelistings;
};
export var usePlatform = function () {
    return useGeneralContext().platform;
};
export var useAccessToken = function () {
    return useGeneralContext().auth0.accessToken;
};
export var useIsUserInfoChecked = function () {
    return useGeneralContext().userInfo.checked;
};
export var useIdToken = function () {
    return useGeneralContext().auth0.idToken;
};

import moment from "moment";
import { DATE_FORMAT } from "./constants";
export var getTimeYesterday = function () {
    return moment().subtract(1, "days").startOf("day");
};
/* istanbul ignore next */
export var convertStringToMoment = function (date, format) {
    if (format === void 0) { format = DATE_FORMAT; }
    return moment(date, format);
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect, useState } from "react";
import { LANG } from "../layout/header/types";
import { useGetResolvedLangDataForPlatform } from "./hooks";
export var useI18nJson = function () {
    var _a = useState({}), json = _a[0], setJson = _a[1];
    var en = useGetResolvedLangDataForPlatform(LANG.EN);
    var cn = useGetResolvedLangDataForPlatform(LANG.CN);
    useEffect(function () {
        var langJson = {};
        if (!!Object.keys(en).length)
            langJson[LANG.EN] = en;
        if (!!Object.keys(cn).length)
            langJson[LANG.CN] = cn;
        setJson(function (prev) { return (__assign(__assign({}, prev), langJson)); });
    }, [en, cn]);
    return json;
};

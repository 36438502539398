var _a;
export var daysOfTheWeek = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
];
export var API_QUERY_KEY;
(function (API_QUERY_KEY) {
    API_QUERY_KEY["FOOD_SUPPLIER_ID"] = "foodSupplierID";
})(API_QUERY_KEY || (API_QUERY_KEY = {}));
export var QC_HOST = process.env.QC_API_HOST || "";
export var QC_ONLINE_MENU_CATEGORIES_ADD_URL = "".concat(QC_HOST).concat(process.env.QC_ONLINE_MENU_CATEGORIES_ADD_API || "");
export var QC_ONLINE_MENU_URL = "".concat(QC_HOST).concat((_a = process.env.QC_MENUS_API) !== null && _a !== void 0 ? _a : "");
export var QC_ONLINE_MENU_ADD_URL = "".concat(QC_HOST).concat(process.env.QC_ADD_MENUS_API || "");
export var QC_ONLINE_MENU_EDIT_URL = "".concat(QC_HOST).concat(process.env.QC_ONLINE_MENU_EDIT_FULLFILMENTMETHOD);
export var QC_ONLINE_MENU_ITEMS_URL = "".concat(QC_HOST).concat(process.env.QC_MENU_ITEMS_API || "");
export var QC_ONLINE_MENU_UPDATE_CATEGORIES_URL = "".concat(QC_HOST).concat(process.env.QC_MENU_UPDATE_CATEGORIES || "");
export var QC_ONLINE_MENU_ITEMS_BULK_URL = "".concat(QC_ONLINE_MENU_ITEMS_URL, "/bulk");
export var QC_ONLINE_MENU_ITEMS_CUSTOMISATIONS_BULK_URL = "".concat(QC_HOST).concat(process.env.QC_CUSTOMISATIONS_BULK_API || "");
export var QC_ONLINE_MENU_UPDATE_CATEGORY_URL = "".concat(QC_HOST).concat(process.env.QC_MENU_UPDATE_CATEGORY || "");
export var QC_FOOD_SUPPLIER_API = "".concat(QC_HOST).concat(process.env.QC_FOOD_SUPPLIER_API || "");

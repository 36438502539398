var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EuiCheckbox, EuiFlexGroup } from "@elastic/eui";
import React from "react";
import I18nText from "../../../../components/i18n/I18nText";
var Header = function (_a) {
    var columns = _a.columns, _b = _a.showCheckBox, showCheckBox = _b === void 0 ? false : _b, onSelectAll = _a.onSelectAll, _c = _a.checked, checked = _c === void 0 ? false : _c, _d = _a.indeterminate, indeterminate = _d === void 0 ? false : _d, _e = _a.hideFirstAllHeader, hideFirstAllHeader = _e === void 0 ? false : _e;
    return (_jsx("header", __assign({ className: "TableRow TableHeader" }, { children: columns.map(function (column, index) {
            if (showCheckBox) {
                return (_jsx("div", __assign({ className: "TableHeaderDivItemWithCheckbox ".concat(hideFirstAllHeader && index === 1 ? " NumberColumn" : "") }, { children: onSelectAll && index === 0 ? (_jsxs(EuiFlexGroup, __assign({ className: "HeaderItem WithCheckbox" }, { children: [_jsx("div", __assign({ className: "CheckBoxItem" }, { children: _jsx(EuiCheckbox, { indeterminate: indeterminate, id: "HeaderCheckbox-".concat(column), onChange: onSelectAll, checked: checked }) })), !hideFirstAllHeader && (_jsx("p", { children: _jsx(I18nText, { token: "common.all" }) }))] }))) : (_jsx("p", { children: _jsx(I18nText, { token: column }) })) }), column));
            }
            return (_jsx("p", { children: _jsx(I18nText, { token: column }) }, column));
        }) })));
};
export default Header;

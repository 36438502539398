var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useCallback, useEffect, useState } from "react";
import { globalEvents } from "../../../common/GlobalEvents";
import { getI18nText } from "../../i18n/helpers";
import { ALERT_EVENT, ALERT_TYPE, NULL_ALERT, TOAST_COLOR, TOAST_ICON, } from "./constants";
import { newToast } from "./helpers";
export var useToastManager = function () {
    var _a = useState([]), toasts = _a[0], setToasts = _a[1];
    var addToast = useCallback(function (param) {
        setToasts(function (prev) { return __spreadArray(__spreadArray([], prev, true), [newToast(param)], false); });
    }, []);
    var dismissToast = useCallback(function (removedToast) {
        setToasts(function (prev) { return prev.filter(function (toast) { return toast.id !== removedToast.id; }); });
    }, []);
    var dismissAllToasts = useCallback(function () {
        setToasts([]);
    }, []);
    return {
        toasts: toasts,
        addToast: addToast,
        dismissToast: dismissToast,
        dismissAllToasts: dismissAllToasts,
    };
};
export var useAlertController = function () {
    var _a = useState(NULL_ALERT), alertParam = _a[0], setAlertParam = _a[1];
    var _b = useToastManager(), toasts = _b.toasts, addToast = _b.addToast, dismissToast = _b.dismissToast, dismissAllToasts = _b.dismissAllToasts;
    var showAlert = useCallback(function (param) {
        var type = param.type;
        if (type === ALERT_TYPE.TOAST) {
            addToast(param);
            return;
        }
        setAlertParam(param);
    }, []);
    var hideAlert = useCallback(function () {
        // delay this call so that the component gets unmounted only after all other trailing functions are finished.
        // this prevents any useEffect no-op errors, esp for cleanups
        window.setTimeout(function () {
            setAlertParam(NULL_ALERT);
        }, 200);
    }, []);
    return {
        alertParam: alertParam,
        showAlert: showAlert,
        hideAlert: hideAlert,
        toasts: toasts,
        dismissToast: dismissToast,
        dismissAllToasts: dismissAllToasts,
    };
};
export var useAlertControllerListeners = function (show, hide) {
    useEffect(function () {
        globalEvents.on(ALERT_EVENT.SHOW, show);
        globalEvents.on(ALERT_EVENT.HIDE, hide);
        return function () {
            globalEvents.off(ALERT_EVENT.SHOW, show);
            globalEvents.off(ALERT_EVENT.HIDE, hide);
        };
    }, [show]);
};
export var useGenericAxiosErrorToast = function (url, errorObj, errorsHandler) {
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (!errorObj || !url)
            return;
        var errorURL = errorObj.config.url || "";
        if (url.indexOf(errorURL) < 0)
            return;
        var errorCode = "";
        var errorDetail = "";
        var errorMsg = "";
        if ((_b = (_a = errorObj.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.errors) {
            errorCode = ((_c = errorObj.response.data.errors[0]) === null || _c === void 0 ? void 0 : _c.code) || "";
            errorDetail = ((_d = errorObj.response.data.errors[0]) === null || _d === void 0 ? void 0 : _d.detail) || "";
            errorMsg = ((_e = errorObj.response.data.errors[0]) === null || _e === void 0 ? void 0 : _e.message) || "";
        }
        else {
            errorCode = ((_f = errorObj.response) === null || _f === void 0 ? void 0 : _f.data.code) || "";
            errorDetail = ((_g = errorObj.response) === null || _g === void 0 ? void 0 : _g.data.detail) || "";
            errorMsg = ((_h = errorObj.response) === null || _h === void 0 ? void 0 : _h.data.message) || "";
        }
        var hasErrorMsg = "".concat(errorCode).concat(errorMsg).concat(errorDetail).length > 0;
        if (errorsHandler && Object.keys(errorsHandler).length > 0) {
            if (errorsHandler[errorCode] &&
                typeof errorsHandler[errorCode] === "function") {
                return errorsHandler[errorCode]();
            }
        }
        globalEvents.emit(ALERT_EVENT.SHOW, {
            type: ALERT_TYPE.TOAST,
            title: hasErrorMsg
                ? "[".concat(errorCode, "]: ").concat(getI18nText(errorMsg || errorDetail))
                : errorURL,
            content: hasErrorMsg ? "" : errorURL,
            iconType: TOAST_ICON.ALERT,
            color: TOAST_COLOR.ALERT,
        });
    }, [url, errorObj]);
};

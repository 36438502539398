import { useMemo } from "react";
import { useIsUserInfoChecked } from "../../../context/hooks";
import { useBackOfficeConfig } from "../../config/useBackOfficeConfig";
import { FS_PROPS } from "./types";
export var useFSConfigProps = function () {
    var userChecked = useIsUserInfoChecked();
    var config = useBackOfficeConfig(userChecked ? FS_PROPS.PROPS : "");
    return useMemo(function () {
        var _a, _b;
        var fsprops = (_a = config.filter(function (_a) {
            var name = _a.name;
            return name === FS_PROPS.PROPS;
        })[0]) !== null && _a !== void 0 ? _a : null;
        return (_b = fsprops === null || fsprops === void 0 ? void 0 : fsprops.config) !== null && _b !== void 0 ? _b : null;
    }, [config]);
};

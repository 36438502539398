import { lazy } from "react";
import { MENU_GROUP, PLATFORM } from "../../common/constants";
import { globalEvents } from "../../common/GlobalEvents";
import { PERMISSION } from "../../components/rbac/permissions";
import { ROUTE_EVENTS } from "../../components/routing/constants";
import { STATICPAGE_PATH } from "./constants";
/* istanbul ignore next */ // no need to test lazy loading
var LazyComponents = {
    PageListing: lazy(function () { return import(/* webpackChunkName: "pagelisting" */ "./listing/PageListing"); }),
    PageEditor: lazy(function () { return import(/* webpackChunkName: "pageedit" */ "./editor/StaticPageEditor"); }),
    PageAdd: lazy(function () { return import(/* webpackChunkName: "pageeadd" */ "./add/CreateNewStaticPage"); }),
    PrivatePage: lazy(function () {
        return import(
        /* webpackChunkName: "privatepage" */ "./display/private/PrivatePage");
    }),
};
var CMS_PERMISSIONS = [PERMISSION.CONTENT_MANAGEMENT];
var PLATFORM_ACCESS = [PLATFORM.ADMIN_PANEL];
/* istanbul ignore next */ // no need to test route config
export var staticPageRoutes = [
    {
        label: "staticpage.editor.contentmanagement",
        icon: "pencil",
        permissions: CMS_PERMISSIONS,
        platforms: PLATFORM_ACCESS,
        group: MENU_GROUP.CONTENT_MANAGEMENT,
        subRoutes: [
            {
                label: "staticpage.editor.contentmanagement",
                route: STATICPAGE_PATH.PAGE_LISTING,
                permissions: CMS_PERMISSIONS,
                platforms: PLATFORM_ACCESS,
                component: LazyComponents.PageListing,
            },
            {
                label: "staticpage.editor.pageeditor",
                route: STATICPAGE_PATH.EDIT,
                permissions: CMS_PERMISSIONS,
                platforms: PLATFORM_ACCESS,
                component: LazyComponents.PageEditor,
                hidden: true,
            },
            {
                label: "staticpage.editor.newstaticpage",
                route: STATICPAGE_PATH.ADD,
                permissions: CMS_PERMISSIONS,
                platforms: PLATFORM_ACCESS,
                component: LazyComponents.PageAdd,
                hidden: true,
            },
            {
                label: "staticpage.content.privatepage",
                route: STATICPAGE_PATH.PRIVATE_PAGES,
                permissions: [],
                platforms: PLATFORM_ACCESS,
                component: LazyComponents.PrivatePage,
                hidden: true,
            },
        ],
    },
];
export var initStaticPage = function () {
    globalEvents.emit(ROUTE_EVENTS.REGISTER, staticPageRoutes);
};

import { jsx as _jsx } from "react/jsx-runtime";
import { useRouteListingsData } from "../context/hooks";
import ContentRoute from "./ContentRoute";
import CustomSwitch from "./CustomSwitch";
var RouteGenerator = function () {
    var routesListingContext = useRouteListingsData();
    return (_jsx(CustomSwitch, { children: routesListingContext.map(function (_a) {
            var route = _a.route, component = _a.component, label = _a.label, layout = _a.layout;
            return component ? (_jsx(ContentRoute, { exact: true, label: label, path: route, component: component, layout: layout, checkPermission: true }, "CR".concat(route))) : null;
        }) }));
};
export default RouteGenerator;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { flattenDataAttributes } from "../helpers";
export var companiesDTOAdapter = function (_a) {
    var data = _a.data;
    return data.map(function (company) {
        return __assign({}, flattenDataAttributes(company));
    });
};

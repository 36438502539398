var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { NotificationIcon } from "../../../assets/svg/NotificationIcon";
import { ProfileIcon } from "../../../assets/svg/ProfileIcon";
import { COLORS, MENU_GROUP } from "../../../common/constants";
import LanguageToggle from "../header/language/LanguageToggle";
import { useSideNavMenuContent } from "./hooks";
import SideNavGroup from "./SideNavGroup";
import styled from "./SideNavMenu.styles";
import SideNavProfile from "./SideNavProfile";
var HIDE = { display: "none" };
var SHOW = { display: "block" };
export var SideNavMenu = function (_a) {
    var className = _a.className, current = _a.current, changeMenu = _a.changeMenu, navItemCountUpdater = _a.navItemCountUpdater, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    var menus = useSideNavMenuContent(current);
    var setIsShown = useCallback(function () { return changeMenu(MENU_GROUP.PROFILE); }, []);
    var renderMenus = useMemo(function () {
        return menus === null || menus === void 0 ? void 0 : menus.map(function (item) { return (_jsx(SideNavGroup, { data: item, updateParentCount: navItemCountUpdater }, item.title)); });
    }, [menus, navItemCountUpdater]);
    return (_jsx("nav", __assign({ className: className, style: disabled ? HIDE : SHOW }, { children: _jsxs("div", __assign({ className: "SideNavMenu" }, { children: [_jsxs("div", __assign({ className: "SideNavTop" }, { children: [_jsx("div", __assign({ className: "SideNavTopIcon" }, { children: _jsx(NotificationIcon, { fillColor: COLORS.MINESHAFT300 }) })), _jsx("div", __assign({ className: "SideNavTopIcon" }, { children: _jsx(LanguageToggle, {}) })), _jsx("div", __assign({ className: "SideNavTopIcon", onClick: setIsShown }, { children: _jsx(ProfileIcon, { fillColor: current === MENU_GROUP.PROFILE
                                    ? COLORS.FPBLUE500
                                    : COLORS.MINESHAFT300 }) }))] })), current === MENU_GROUP.PROFILE ? _jsx(SideNavProfile, {}) : renderMenus, _jsx("div", { className: "SideNaveFooter" })] })) })));
};
export default styled(SideNavMenu);

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import "./index.scss";
import { Auth0Provider } from "@auth0/auth0-react";
import * as ReactDOM from "react-dom";
import { PLATFORM } from "../common/constants";
import { startDatadog } from "../components/datadog/datadog";
import { AUTH_PATH } from "./auth/constants";
import BackOffice from "./BackOffice";
/* istanbul ignore next */ // not necessary to test this
var auth0_domain = process.env.AUTH0_DOMAIN || "";
var auth0_clientid = process.env.AUTH0_CLIENTID || "";
var auth0_audience = process.env.AUTH0_AUDIENCE || "";
try {
    if (process.env.LOGIN_PLATFORM === PLATFORM.PARTNER_CENTER) {
        auth0_domain = process.env.FP_AUTH0_DOMAIN || "";
        auth0_clientid = process.env.FP_AUTH0_CLIENTID || "";
        auth0_audience = process.env.FP_AUTH0_AUDIENCE || "";
    }
}
catch (e) {
    // EMPTY
}
/* istanbul ignore next */ // not necessary to test this
startDatadog();
/* istanbul ignore next */ // not necessary to test this
ReactDOM.render(_jsx(Auth0Provider, __assign({ domain: auth0_domain, clientId: auth0_clientid, redirectUri: "".concat(window.location.origin).concat(AUTH_PATH.AUTH_PAGE), audience: auth0_audience, scope: "read:current_user update:current_user_metadata" }, { children: _jsx(BackOffice, {}) })), document.getElementById("root"));

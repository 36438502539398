var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/**
 * Helper function to serialize a standard JSON:API data structure.
 * Does NOT support cyclic relationships
 * For meta data, it can be extracted directly from the DTO without the adapter
 * @param jsonApiDTO
 * @returns
 */
export var jsonApiAdapter = function (jsonApiDTO) {
    var included = jsonApiDTO.included, data = jsonApiDTO.data;
    var includedHash = getIncludedHash(included);
    if (Array.isArray(data)) {
        return data.map(function (single) {
            return processIncludedRelationships(single, includedHash);
        });
    }
    return processIncludedRelationships(data, includedHash);
};
var getHash = function (_a) {
    var id = _a.id, type = _a.type;
    return "[".concat(type, "]::[").concat(id, "]");
};
var getIncludedHash = function (included) {
    if (!included)
        return {};
    return included.reduce(function (hash, include) {
        hash[getHash(include)] = include;
        return hash;
    }, {});
};
var processIncludedRelationships = function (data, included) {
    var id = data.id, type = data.type, attributes = data.attributes, relationships = data.relationships;
    var resultData = __assign({ id: id, type: type }, attributes);
    if (relationships) {
        Object.keys(relationships).forEach(function (key) {
            var rdata = relationships[key].data;
            if (rdata) {
                if (Array.isArray(rdata)) {
                    resultData[key] = rdata.map(function (rel) {
                        return findIncludedTypeId(included, rel);
                    });
                }
                else {
                    resultData[key] = findIncludedTypeId(included, rdata);
                }
            }
        });
    }
    return resultData;
};
var findIncludedTypeId = function (included, relationship) {
    var data = included[getHash(relationship)];
    return processIncludedRelationships(data, included);
};

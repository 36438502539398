var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import "./loaderoverlay.scss";
import { EuiLoadingSpinner } from "@elastic/eui";
import { useLoaderOverlay } from "./useLoaderOverlay";
var LoaderOverlay = function () {
    var _a = useLoaderOverlay(), display = _a.display, lockId = _a.lockId;
    if (!display)
        return null;
    return (_jsx("div", __assign({ className: "LoaderOverlay ".concat(lockId) }, { children: _jsx(EuiLoadingSpinner, { size: "xl" }) })));
};
export default LoaderOverlay;

import { lazy } from "react";
import { MENU_GROUP, PLATFORM } from "../../common/constants";
import { globalEvents } from "../../common/GlobalEvents";
import { ROUTE_EVENTS } from "../../components/routing/constants";
import { HELP_PATH } from "./constants";
/* istanbul ignore next */ // no need to test lazy loading
var LazyComponents = {
    AdminPanel: lazy(function () { return import(/* webpackChunkName: "helpaap" */ "./AdminPanel"); }),
    Kpos: lazy(function () { return import(/* webpackChunkName: "helpkpos" */ "./Kpos"); }),
    PartnerCenter: lazy(function () { return import(/* webpackChunkName: "helppc" */ "./PartnerCenter"); }),
};
export var helpRoutes = [
    {
        label: "help.menu.label",
        route: "",
        permissions: [],
        icon: "",
        platforms: [],
        group: MENU_GROUP.HELP,
        subRoutes: [
            {
                label: "help.menu.adminpanel",
                route: HELP_PATH.ADMIN_PANEL,
                component: LazyComponents.AdminPanel,
                permissions: [],
                platforms: [PLATFORM.ADMIN_PANEL],
            },
            {
                label: "help.menu.kpos",
                route: HELP_PATH.KPOS,
                component: LazyComponents.Kpos,
                permissions: [],
                platforms: [PLATFORM.ADMIN_PANEL],
            },
            {
                label: "help.menu.partnercenter",
                route: HELP_PATH.PARTNER_CENTER,
                component: LazyComponents.PartnerCenter,
                permissions: [],
                platforms: [],
            },
        ],
    },
];
export var initHelp = function () {
    globalEvents.emit(ROUTE_EVENTS.REGISTER, helpRoutes);
};

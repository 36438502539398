var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getDictCache, setDictCache } from "../cache/DictionaryCache";
import { flattenDataAttributes } from "../helpers";
import { DICTIONARY_KEY } from "../types";
import { LOCATION_INCLUDED_KEY, } from "./types";
export var flattenIncludedLocationUnit = function (locunit) { return flattenDataAttributes(locunit); };
export var locateIncludedUnits = function (included) {
    return function (_a) {
        var id = _a.id;
        return included.filter(function (include) { return include.id === id; })[0];
    };
};
var sortByLocationName = function (loc1, loc2) {
    return loc1.name < loc2.name ? -1 : 1;
};
export var locationDTOAdapter = function (_a) {
    var data = _a.data, included = _a.included;
    var locateFn = locateIncludedUnits(included);
    return data
        .map(function (location) {
        var _a;
        var locationunits = ((_a = location.relationships) === null || _a === void 0 ? void 0 : _a[LOCATION_INCLUDED_KEY.LOCATION_UNITS].data) ||
            [];
        var units = locationunits
            .map(locateFn)
            .map(flattenIncludedLocationUnit);
        return __assign(__assign({}, flattenDataAttributes(location)), { units: units });
    })
        .sort(sortByLocationName);
};
var listDoesNotContainId = function (list, id) {
    return list.map(function (listid) { return listid + ""; }).indexOf(id) < 0;
};
export var filterLocationsByBusiness = function (locations, business) {
    return locations.filter(function (_a) {
        var units = _a.units;
        return (units.filter(function (_a) {
            var business_ids = _a.business_ids;
            return !listDoesNotContainId(business_ids, business);
        }).length > 0);
    });
};
export var locationLabel = function (_a) {
    var name = _a.name, description = _a.description;
    return "".concat(description, " - ").concat(name);
};
export var locationsToSelection = function (locations) {
    return locations.map(function (location) { return ({
        value: location.id,
        inputDisplay: locationLabel(location),
    }); });
};
export var filterLocationsByBusinessBranch = function (locations, business_branch_id) {
    return locations.filter(function (_a) {
        var units = _a.units;
        return (units.filter(function (_a) {
            var business_branch_ids = _a.business_branch_ids;
            return !listDoesNotContainId(business_branch_ids, business_branch_id);
        }).length > 0);
    });
};
export var getLocationFilterWithCache = function (locations, key, filter, filterfn) {
    var dictData = getDictCache(DICTIONARY_KEY.LOCATIONS, key);
    if (dictData)
        return dictData;
    var newFilteredData = filterfn(locations, filter);
    if (locations.length)
        setDictCache(DICTIONARY_KEY.LOCATIONS, key, newFilteredData);
    return newFilteredData;
};
export var getUnitWithBusinessBranchId = function (units, business_branch_id) {
    return (units.filter(function (_a) {
        var business_branch_ids = _a.business_branch_ids;
        return business_branch_ids.indexOf(business_branch_id) >= 0;
    })[0] || null);
};
export var listAContainsAnyInLIstB = function (listA, listB) {
    return listB.reduce(function (exists, b) {
        return listA.includes(b) || exists;
    }, false);
};
export var getLocationsFilteredByBusinesses = function (locations, businesses) {
    return locations.filter(function (_a) {
        var units = _a.units;
        return (units.filter(function (_a) {
            var business_ids = _a.business_ids;
            return listAContainsAnyInLIstB(business_ids.map(function (id) { return id + ""; }), businesses);
        }).length > 0);
    });
};

import { useEffect, useMemo, useState } from "react";
import { globalEvents } from "../../common/GlobalEvents";
import { useEventTriggeredTimeUpdate } from "../../common/hooks/useEventTriggeredTimeUpdate";
import { jsonApiAdapter } from "../../common/jsonapi/jsonapi";
import { LoaderEvent } from "../loader/constants";
import { useAxiosGet } from "./useAxiosGet";
export var useAxiosGetJsonApi = function (_a) {
    var url = _a.url, _b = _a.refreshEvent, refreshEvent = _b === void 0 ? "" : _b, _c = _a.showLoader, showLoader = _c === void 0 ? true : _c, _d = _a.manualTrigger, manualTrigger = _d === void 0 ? false : _d, _e = _a.config, config = _e === void 0 ? undefined : _e, _f = _a.noJWT, noJWT = _f === void 0 ? false : _f;
    var _g = useState(null), jsonApi = _g[0], setJsonApi = _g[1];
    var key = useEventTriggeredTimeUpdate(refreshEvent);
    var _h = useAxiosGet(url, key, config, noJWT, manualTrigger), response = _h.response, getError = _h.getError, getUrl = _h.getUrl, loading = _h.loading, getData = _h.getData;
    useEffect(function () {
        if (loading) {
            setJsonApi(null);
        }
        if (showLoader) {
            globalEvents.emit(loading ? LoaderEvent.SHOW_SPLASH : LoaderEvent.HIDE_SPLASH);
        }
    }, [loading]);
    useEffect(function () {
        if (getUrl !== url)
            return;
        if (!response)
            return;
        var data = jsonApiAdapter(response);
        setJsonApi(data);
    }, [response]);
    useEffect(function () {
        if (url !== getUrl)
            return;
        if (!getError)
            return;
        setJsonApi(null);
    }, [getError]);
    var meta = useMemo(function () {
        return response === null || response === void 0 ? void 0 : response.meta;
    }, [response]);
    return {
        jsonApi: jsonApi,
        getError: getError,
        loading: loading,
        getData: getData,
        meta: meta,
    };
};

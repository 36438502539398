import { COLORS } from "../../../common/constants";
export var ALERT_EVENT;
(function (ALERT_EVENT) {
    ALERT_EVENT["SHOW"] = "ALERT.SHOW";
    ALERT_EVENT["HIDE"] = "ALERT.HIDE";
    ALERT_EVENT["MODIFY_MODAL_BUTTONS"] = "ALERT.ModifyModalButton";
})(ALERT_EVENT || (ALERT_EVENT = {}));
export var ALERT_TYPE;
(function (ALERT_TYPE) {
    ALERT_TYPE["NONE"] = "";
    ALERT_TYPE["MESSAGE"] = "Alert.Message";
    ALERT_TYPE["MESSAGE_ONLY"] = "Alert.MessageOnly";
    // ERROR = "Alert.Error",
    ALERT_TYPE["OPTION"] = "Alert.Option";
    ALERT_TYPE["CONFIRMATION"] = "Alert.Confirmation";
    ALERT_TYPE["TOAST"] = "Alert.Toast";
})(ALERT_TYPE || (ALERT_TYPE = {}));
export var NULL_ALERT = {
    type: ALERT_TYPE.NONE,
    title: "",
    content: "",
};
export var OK_BUTTON = {
    label: "common.ok",
    type: "text",
    backgroundColor: COLORS.GREENPEA500,
    color: COLORS.SNOWWHITE,
};
export var CANCEL_BUTTON = {
    label: "common.cancel",
    type: "text",
    backgroundColor: COLORS.SHIRAZ500,
    color: COLORS.SNOWWHITE,
};
export var TOAST_DISPLAY_TIME = 7000;
export var TOAST_ICON;
(function (TOAST_ICON) {
    TOAST_ICON["ALERT"] = "alert";
    TOAST_ICON["SUCCESS"] = "check";
})(TOAST_ICON || (TOAST_ICON = {}));
export var TOAST_COLOR;
(function (TOAST_COLOR) {
    TOAST_COLOR["ALERT"] = "danger";
    TOAST_COLOR["SUCCESS"] = "success";
    TOAST_COLOR["NORMAL"] = "primary";
})(TOAST_COLOR || (TOAST_COLOR = {}));

export var DICTIONARY_KEY;
(function (DICTIONARY_KEY) {
    DICTIONARY_KEY["COMPANIES"] = "companies";
    DICTIONARY_KEY["BRANDS"] = "brands";
    DICTIONARY_KEY["LOCATIONS"] = "locations";
    DICTIONARY_KEY["BUSINESSESS"] = "businesses";
    DICTIONARY_KEY["BUSINESS_BRANCHES"] = "businessBranches";
    DICTIONARY_KEY["CBB"] = "allcompanybrandbusiness";
})(DICTIONARY_KEY || (DICTIONARY_KEY = {}));
export var PORTFOLIOS;
(function (PORTFOLIOS) {
    PORTFOLIOS["NO_PORTFOLIO"] = "<No Portfolio>";
    PORTFOLIOS["HEALTHCARE"] = "Healthcare";
    PORTFOLIOS["CM1"] = "CM1";
    PORTFOLIOS["CM2"] = "CM2";
    PORTFOLIOS["CWMA"] = "CWMA";
    PORTFOLIOS["HCU"] = "HCU";
    PORTFOLIOS["LARGE_FORMAT_KS"] = "Large Format - KS";
    PORTFOLIOS["LARGE_FORMAT_LPS"] = "Large Format - LPS";
})(PORTFOLIOS || (PORTFOLIOS = {}));

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../displaypage.scss";
import "../../editor.scss";
import { EuiPage, EuiPageBody, EuiPageContent } from "@elastic/eui";
import { Editor } from "react-draft-wysiwyg";
import I18nText from "../../../../components/i18n/I18nText";
import { useGetEditorStateFromMediaService } from "../../editor/api/useGetEditorStateFromMediaService";
import { getStaticContentURL } from "../../helpers";
import { STATIC_PAGE_STATUS } from "../constants";
var StaticPage = function (_a) {
    var data = _a.data, status = _a.status, pageName = _a.pageName;
    var contentUrl = getStaticContentURL(data);
    var msContent = useGetEditorStateFromMediaService(contentUrl).msContent;
    var showError = status === STATIC_PAGE_STATUS.ERROR ||
        status === STATIC_PAGE_STATUS.NOT_ALLOWED;
    return (_jsx(EuiPage, __assign({ className: "PrivatePage" }, { children: _jsx(EuiPageBody, __assign({ className: "StaticPageWrapper" }, { children: _jsxs(EuiPageContent, __assign({ hasShadow: false }, { children: [status === STATIC_PAGE_STATUS.ALLOWED && (_jsx(Editor, { contentState: msContent !== null && msContent !== void 0 ? msContent : undefined, wrapperClassName: "StaticPageWrapper", editorClassName: "StaticDisplay", readOnly: true, toolbarHidden: true })), showError && (_jsx(I18nText, { token: "staticpage.display.error", className: "Status", values: { pageName: pageName } }))] })) })) })));
};
export default StaticPage;

import { useMemo } from "react";
import { isAccessPermission } from "../../api/rbac/accesspermissions/accesspermhelpers";
import { useAccessPermissionConfigValue } from "../../api/rbac/accesspermissions/useAccessPermissionConfigValue";
import { usePermissionListing } from "../../api/rbac/permissions/usePermissionListing";
import { useExpandedAccessPermissionsConfigs } from "../../context/filtering/userAccessibles";
export var useLoadAllAccessConfigs = function () {
    var allpermissions = usePermissionListing();
    var allaccessconfigpermissions = useMemo(function () {
        return allpermissions
            .map(function (_a) {
            var name = _a.name;
            return name;
        })
            .filter(function (perm) { return isAccessPermission(perm); });
    }, [allpermissions]);
    var group = useAccessPermissionConfigValue.apply(void 0, allaccessconfigpermissions).group;
    return useExpandedAccessPermissionsConfigs(group);
};

import { useCallback, useEffect, useRef, useState } from "react";
import { globalEvents } from "../../common/GlobalEvents";
import { LoaderEvent } from "./constants";
var NO_ID = "";
export var useLoaderOverlay = function () {
    var _a = useState(false), display = _a[0], setDisplay = _a[1];
    var lockId = useRef(NO_ID);
    var turnOffOverlay = useCallback(function (id) {
        if (id === void 0) { id = NO_ID; }
        if (lockId.current !== id)
            return;
        setDisplay(false);
        lockId.current = NO_ID;
    }, []);
    var turnOnOverlay = useCallback(function (id) {
        if (id === void 0) { id = NO_ID; }
        if (id !== NO_ID)
            lockId.current = id;
        setDisplay(true);
    }, []);
    useEffect(function () {
        globalEvents.on(LoaderEvent.SHOW_SPLASH, turnOnOverlay);
        globalEvents.on(LoaderEvent.HIDE_SPLASH, turnOffOverlay);
        return function () {
            globalEvents.off(LoaderEvent.SHOW_SPLASH, turnOnOverlay);
            globalEvents.off(LoaderEvent.HIDE_SPLASH, turnOffOverlay);
        };
    }, []);
    return {
        display: display,
        lockId: lockId.current,
    };
};

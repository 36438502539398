var _a;
import { useMemo } from "react";
import { combineQueryStrings } from "../../../../common/stringutils";
import { useIsUserInfoChecked } from "../../../context/hooks";
import { useAxiosGetJsonApi } from "../../../hooks/useAxiosGetJsonApi";
import { FS_GATEWAY_HOST } from "../../fs/constants";
import { PERMISSIONLISTING_EVENT } from "./constants";
import { removeHiddenPermissions } from "./helpers";
var BACKOFFICE_PERMISSIONS_API = "".concat(FS_GATEWAY_HOST).concat((_a = process.env.BACKOFFICE_PERMISSIONS_API) !== null && _a !== void 0 ? _a : "");
export var usePermissionListing = function (include_api) {
    if (include_api === void 0) { include_api = false; }
    var roles = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        roles[_i - 1] = arguments[_i];
    }
    var checked = useIsUserInfoChecked();
    var url = useMemo(function () {
        if (!checked)
            return "";
        var query = combineQueryStrings("role_ids", roles.join(","), "include_api", include_api.toString());
        return "".concat(BACKOFFICE_PERMISSIONS_API, "?").concat(query);
    }, [roles, include_api, checked]);
    var jsonApi = useAxiosGetJsonApi({
        url: url,
        refreshEvent: PERMISSIONLISTING_EVENT.REFRESH,
    }).jsonApi;
    return useMemo(function () {
        return removeHiddenPermissions(jsonApi !== null && jsonApi !== void 0 ? jsonApi : []);
    }, [jsonApi]);
};

import { lazy } from "react";
import { MENU_GROUP, PLATFORM } from "../../../common/constants";
import { globalEvents } from "../../../common/GlobalEvents";
import { PERMISSION } from "../../../components/rbac/permissions";
import { ROUTE_EVENTS } from "../../../components/routing/constants";
import { TRANSACTIONS_PATH } from "./constants";
/* istanbul ignore next */ // no need to test lazy loading
var LazyComponents = {
    ManualComplete: lazy(function () {
        return import(
        /* webpackChunkName: "fsmanualcomplete" */ "./manualcomplete/ManualComplete");
    }),
};
/* istanbul ignore next */ // no need to test route config
export var transactionRoutes = [
    {
        label: "fs.transactions.management",
        route: "",
        permissions: [
            PERMISSION.FS_TRANSACTIONS_MANAGE,
            PERMISSION.FS_TRANSACTIONS_VIEW,
        ],
        icon: "documents",
        platforms: [PLATFORM.ADMIN_PANEL],
        group: MENU_GROUP.FOOD_SERVICES,
        subRoutes: [
            {
                label: "fs.transactions.manualcomplete.title",
                route: TRANSACTIONS_PATH.MANUAL_COMPLETE,
                component: LazyComponents.ManualComplete,
                permissions: [
                    PERMISSION.FS_TRANSACTIONS_MANAGE,
                    PERMISSION.FS_TRANSACTIONS_VIEW,
                ],
                platforms: [PLATFORM.ADMIN_PANEL],
            },
        ],
    },
];
export var initTransactionsManagement = function () {
    globalEvents.emit(ROUTE_EVENTS.REGISTER, transactionRoutes);
};

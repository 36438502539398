var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { PERMISSION } from "../rbac/permissions";
export var subRoutePermissionsToAlpha = function (subRoutes) {
    return subRoutes.map(function (subRoute) { return (__assign(__assign({}, subRoute), { permissions: [PERMISSION.ALPHA], subRoutes: subRoute.subRoutes
            ? subRoutePermissionsToAlpha(subRoute.subRoutes)
            : undefined })); });
};
export var checkRoute = function (route, checker, parent) {
    if (parent === void 0) { parent = ""; }
    var currentid = !!parent ? "".concat(parent, ">").concat(route.label) : route.label;
    if (!checker(currentid, true)) {
        route.permissions = [PERMISSION.ALPHA];
        if (route.subRoutes) {
            route.subRoutes = subRoutePermissionsToAlpha(route.subRoutes);
        }
        return __assign({}, route);
    }
    if (route.subRoutes) {
        route.subRoutes = route.subRoutes.map(function (subroute) {
            return checkRoute(subroute, checker, currentid);
        });
    }
    return __assign({}, route);
};

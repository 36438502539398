export var CATEGORIES_PATH;
(function (CATEGORIES_PATH) {
    CATEGORIES_PATH["MASTER_CATEGORIES"] = "/catalogue/categories";
    CATEGORIES_PATH["ADD_MASTER_CATEGORIES"] = "/catalogue/categories/add";
    CATEGORIES_PATH["EDIT_MASTER_CATEGORIES"] = "/catalogue/categories/edit/:fn";
    CATEGORIES_PATH["EDIT_MASTER_CATEGORIES_ABOUT"] = "/catalogue/categories/edit/about";
    CATEGORIES_PATH["EXPORT_MASTER_CATEGORIES"] = "/catalogue/categories/export";
    CATEGORIES_PATH["EDIT_LOCAL_PRICING_IN_BULK"] = "/catalogue/menuitems/editpricing";
    CATEGORIES_PATH["MENU_ITEMS"] = "/catalogue/menuitems";
    CATEGORIES_PATH["ADD_MENU_ITEM"] = "/catalogue/menuitems/add";
    CATEGORIES_PATH["EDIT_MENU_ITEM"] = "/catalogue/menuitems/edit";
    CATEGORIES_PATH["EXPORT_MASTER_MENU_ITEMS"] = "/catalogue/menuitems/export";
    CATEGORIES_PATH["MASTER_CATEGORIES_CUSTOMIZATIONS"] = "/catalogue/customizations";
    CATEGORIES_PATH["MASTER_CATEGORIES_CUSTOMIZATIONS_DETAIL"] = "/catalogue/customizations/detail";
    CATEGORIES_PATH["MASTER_CATEGORIES_CUSTOMIZATIONS_ADD"] = "/catalogue/customizations/add";
    CATEGORIES_PATH["MANAGE_LOCAL_CATALOGUE"] = "/catalogue/local/menus";
    CATEGORIES_PATH["EDIT_LOCAL_CATALOGUE"] = "/catalogue/local/menu";
    CATEGORIES_PATH["ADD_LOCAL_CATALOGUE"] = "/catalogue/local/menu/new";
    CATEGORIES_PATH["MANAGE_LOCAL_CATEGORY"] = "/catalogue/local/category";
    CATEGORIES_PATH["MANAGE_LOCAL_MENU_ITEM"] = "/catalogue/local/menuitem";
    CATEGORIES_PATH["MANAGE_LOCAL_CUSTOMIZATIONS"] = "/catalogue/customizations/local";
    // anchor - for code generator
})(CATEGORIES_PATH || (CATEGORIES_PATH = {}));
export var MASTER_QUERY_PARAMS;
(function (MASTER_QUERY_PARAMS) {
    MASTER_QUERY_PARAMS["COMPANY"] = "company_id";
    MASTER_QUERY_PARAMS["MENU"] = "menu_item_id";
    MASTER_QUERY_PARAMS["BRAND"] = "brand_id";
    MASTER_QUERY_PARAMS["BUSINESS"] = "business_id";
    MASTER_QUERY_PARAMS["CATEGORY"] = "category_id";
})(MASTER_QUERY_PARAMS || (MASTER_QUERY_PARAMS = {}));
export var MENU_ITEM_TYPES;
(function (MENU_ITEM_TYPES) {
    MENU_ITEM_TYPES["BEVERAGE"] = "beverage";
    MENU_ITEM_TYPES["NONBEVERAGE"] = "Non Beverage";
    MENU_ITEM_TYPES["OTHERS"] = "Others";
    MENU_ITEM_TYPES["VOUCHER"] = "voucher";
})(MENU_ITEM_TYPES || (MENU_ITEM_TYPES = {}));
export var ROUTING;
(function (ROUTING) {
    ROUTING["PAGEFROM"] = "page_from";
    ROUTING["MENUITEMS"] = "menuitems";
    ROUTING["EDITMENUITEM"] = "editmenuitem";
    ROUTING["MASTERCATEGORY"] = "mc";
    ROUTING["MMILISTING"] = "mmilisting";
})(ROUTING || (ROUTING = {}));
export var MMI_ITEM_TYPES = [
    MENU_ITEM_TYPES.BEVERAGE,
    MENU_ITEM_TYPES.NONBEVERAGE,
    MENU_ITEM_TYPES.OTHERS,
    MENU_ITEM_TYPES.VOUCHER,
];
export var ITEM_TYPE_OPTIONS = MMI_ITEM_TYPES.map(function (value) { return ({
    value: value,
    inputDisplay: value,
}); });

var _a;
import BoldTag from "./BoldTag";
import ColorTag from "./ColorTag";
import GlobalEventTag from "./GlobalEventTag";
import ItalicsTag from "./ItalicsTag";
import LITag from "./LITag";
import LocalLinkTag from "./LocalLinkTag";
import PTag from "./PTag";
import StaticContentTag from "./StaticContentTag";
import ULTag from "./ULTag";
import UnderlineTag from "./UnderlineTag";
import URLTag from "./URLTag";
export var TEXT_TAG;
(function (TEXT_TAG) {
    TEXT_TAG["NONE"] = "";
    TEXT_TAG["STATIC_CONTENT"] = "content";
    TEXT_TAG["BOLD"] = "b";
    TEXT_TAG["ITALICS"] = "i";
    TEXT_TAG["UNDERLINE"] = "u";
    TEXT_TAG["COLOR"] = "c";
    TEXT_TAG["UL"] = "ul";
    TEXT_TAG["LI"] = "li";
    TEXT_TAG["PARAGRAPH"] = "p";
    TEXT_TAG["GLOBAL_EVENT"] = "event";
    TEXT_TAG["URL"] = "url";
    TEXT_TAG["LOCALLINK"] = "locallink";
})(TEXT_TAG || (TEXT_TAG = {}));
export var TAG_COMPONENT = (_a = {},
    _a[TEXT_TAG.STATIC_CONTENT] = StaticContentTag,
    _a[TEXT_TAG.BOLD] = BoldTag,
    _a[TEXT_TAG.ITALICS] = ItalicsTag,
    _a[TEXT_TAG.UNDERLINE] = UnderlineTag,
    _a[TEXT_TAG.COLOR] = ColorTag,
    _a[TEXT_TAG.UL] = ULTag,
    _a[TEXT_TAG.LI] = LITag,
    _a[TEXT_TAG.PARAGRAPH] = PTag,
    _a[TEXT_TAG.GLOBAL_EVENT] = GlobalEventTag,
    _a[TEXT_TAG.URL] = URLTag,
    _a[TEXT_TAG.LOCALLINK] = LocalLinkTag,
    _a);

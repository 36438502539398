var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { PRERELEASE_PERMISSIONS } from "../rbac/permissions";
var getPrereleasePermissions = function (permissions) {
    return permissions.reduce(function (prerelease, permission) {
        if (PRERELEASE_PERMISSIONS.includes(permission)) {
            return __spreadArray(__spreadArray([], prerelease, true), [permission], false);
        }
        return prerelease;
    }, []);
};
var hasSubRouteWithNoPermissionRequirement = function (subRoutes) {
    return (subRoutes.filter(function (_a) {
        var permissions = _a.permissions, hidden = _a.hidden;
        return !hidden && !permissions.length;
    }).length > 0);
};
var getAllPermissions = function (routes) {
    var subRoutes = inheritPermissionsFromSubRoutes(routes);
    var isPublic = hasSubRouteWithNoPermissionRequirement(subRoutes);
    var permissions = isPublic
        ? []
        : Array.from(subRoutes.reduce(function (perms, _a) {
            var permissions = _a.permissions, hidden = _a.hidden;
            if (hidden)
                return perms;
            permissions.forEach(function (permission) {
                if (!PRERELEASE_PERMISSIONS.includes(permission)) {
                    perms.add(permission);
                }
            });
            return perms;
        }, new Set()));
    return {
        permissions: permissions,
        subRoutes: subRoutes,
    };
};
export var inheritPermissionsFromSubRoutes = function (routes) {
    return routes.map(function (route) {
        var _a;
        if ((_a = route.subRoutes) === null || _a === void 0 ? void 0 : _a.length) {
            var _b = getAllPermissions(route.subRoutes), permissions = _b.permissions, subRoutes = _b.subRoutes;
            return __assign(__assign({}, route), { permissions: __spreadArray(__spreadArray([], permissions, true), getPrereleasePermissions(route.permissions), true), subRoutes: subRoutes });
        }
        return __assign({}, route);
    });
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PLATFORM } from "../../../common/constants";
import { usePlatform } from "../../context/hooks";
import { BUILD_LOCAL, useBuildVersion } from "../../hooks/useBuildVersion";
import I18nText from "../../i18n/I18nText";
import UserQRCode from "../../qrcode/user/UserQRCode";
import PlatformSelector from "../header/platform/PlatformSelector";
import { useLogout, useSideNavContent, useUserTypeDisplay } from "./hooks";
import styled from "./SideNavProfile.styles";
export var SideNavProfile = function (_a) {
    var _b = _a.className, className = _b === void 0 ? "" : _b;
    var _c = useSideNavContent(), _d = _c.userInfo, name = _d.name, user_type = _d.user_type, email = _d.email, picture = _c.picture;
    var user_type_display = useUserTypeDisplay(user_type);
    var logoutHandler = useLogout();
    var platform = usePlatform();
    var build = useBuildVersion();
    return (_jsxs("div", __assign({ className: className }, { children: [_jsx("div", { className: "Divider" }), _jsxs("div", __assign({ className: "ProfileSection" }, { children: [_jsx("img", { className: "Avatar", src: picture, alt: "Avatar picture" }), _jsxs("div", __assign({ className: "Info" }, { children: [_jsx("div", __assign({ className: "Name" }, { children: name })), _jsx("div", __assign({ className: "Email" }, { children: email })), _jsx("div", { children: user_type_display })] })), _jsx(UserQRCode, {}), _jsx("div", __assign({ className: "Logout", onClick: logoutHandler }, { children: _jsx(I18nText, { token: "common.logout" }) })), process.env.PLATFORM_MODE === PLATFORM.DEVELOPMENT &&
                        build === BUILD_LOCAL ? (_jsx(PlatformSelector, {})) : (_jsx(I18nText, { token: "layout.header.platform.".concat(platform) }))] }))] })));
};
export default styled(SideNavProfile);

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import FSFeatureFlag from "../context/fs/FSFeatureFlag";
import GetDataPool from "../datapool/GetDataPool";
import ModuleStrapper from "./ModuleStrapper";
import RouteStrapper from "./RouteStrapper";
import { SERVERTIME_CHECK_FLAG } from "./server/servertime/constants";
import ServerTime from "./server/servertime/ServerTime";
import SessionTracker from "./sessiontracker/SessionTracker";
/**
 * Boot strapper needs to run RouteStrapper BEFORE ModuleStrapper. Do not change the order.
 * @returns
 */
var BootStrapper = function () {
    return (_jsxs(_Fragment, { children: [_jsx(GetDataPool, {}), _jsx(RouteStrapper, {}), _jsx(ModuleStrapper, {}), _jsxs(FSFeatureFlag, __assign({ flag: SERVERTIME_CHECK_FLAG, defaultValue: false }, { children: [_jsx(SessionTracker, {}), _jsx(ServerTime, {})] }))] }));
};
export default BootStrapper;

export var STATICPAGE_PATH;
(function (STATICPAGE_PATH) {
    STATICPAGE_PATH["PAGE_LISTING"] = "/content/listing";
    STATICPAGE_PATH["EDIT"] = "/content/edit";
    STATICPAGE_PATH["ADD"] = "/content/add";
    STATICPAGE_PATH["PUBLIC_PAGES"] = "/page";
    STATICPAGE_PATH["PRIVATE_PAGES"] = "/content/page";
})(STATICPAGE_PATH || (STATICPAGE_PATH = {}));
export var STATIC_CONTENT;
(function (STATIC_CONTENT) {
    STATIC_CONTENT["LOGINFAQ"] = "loginfaq";
})(STATIC_CONTENT || (STATIC_CONTENT = {}));
export var PLATFORM_URL = {
    ADMIN_PANEL: "https://".concat(process.env.AP_DOMAIN).concat(STATICPAGE_PATH.PRIVATE_PAGES),
    PARTNER_CENTRE: "https://".concat(process.env.PC_DOMAIN).concat(STATICPAGE_PATH.PUBLIC_PAGES, "/"),
};
export var STATIC_PAGE_ASSET_TYPE;
(function (STATIC_PAGE_ASSET_TYPE) {
    STATIC_PAGE_ASSET_TYPE["CONTENT"] = "CONTENT";
    STATIC_PAGE_ASSET_TYPE["ASSET"] = "ASSET";
    STATIC_PAGE_ASSET_TYPE["DRAFT"] = "DRAFT";
})(STATIC_PAGE_ASSET_TYPE || (STATIC_PAGE_ASSET_TYPE = {}));
export var STATIC_PAGE_CONTENT = [
    STATIC_PAGE_ASSET_TYPE.CONTENT,
    STATIC_PAGE_ASSET_TYPE.DRAFT,
];
export var STATIC_PAGE_PARAM;
(function (STATIC_PAGE_PARAM) {
    STATIC_PAGE_PARAM["ID"] = "id";
})(STATIC_PAGE_PARAM || (STATIC_PAGE_PARAM = {}));
export var ASSET_LIBRARY_PAGE = "StaticPageAssets";
export var IGNORED_PAGES = [ASSET_LIBRARY_PAGE];

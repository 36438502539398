var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useCallback, useContext } from "react";
import { AllContext } from "../../../context/constants";
import { useI18nString } from "../../../i18n/hooks";
export var usePlatformSelector = function () {
    var _a = useContext(AllContext.GeneralDataContext), platform = _a[0].platform, setGeneralContext = _a[1];
    var getPlatform = useCallback(function (evt) {
        var newplatform = evt.target.value;
        setGeneralContext(function (prev) { return (__assign(__assign({}, prev), { platform: newplatform })); });
    }, []);
    return {
        platform: platform,
        getPlatform: getPlatform,
    };
};
export var usePlatformSelectorText = function (platforms) {
    var getText = useI18nString(platforms.map(function (value) { return "layout.header.platform.".concat(value); })).getText;
    return {
        getText: getText,
    };
};

import { useMemo } from "react";
import { useRouteListingsData } from "../../../context/hooks";
export var useSideBarNewItemCountValue = function (check) {
    var routelistings = useRouteListingsData();
    return useMemo(function () {
        var _a, _b;
        if (!check)
            return 0;
        return ((_b = (_a = routelistings.filter(function (_a) {
            var new_item_check = _a.new_item_check;
            return new_item_check === check;
        })[0]) === null || _a === void 0 ? void 0 : _a.new_items) !== null && _b !== void 0 ? _b : 0);
    }, [routelistings, check]);
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import moment from "moment";
import { LOCAL_TIMEZONE } from "./constants";
import { randomNum } from "./numberutils";
export var getAvailableItems = function (items) { return items.filter(function (_a) {
    var available = _a.available, deleted_at = _a.deleted_at;
    return available && !deleted_at;
}); };
export var getDeactivatedItems = function (items) { return items.filter(function (_a) {
    var available = _a.available, deleted_at = _a.deleted_at;
    return !available || deleted_at;
}); };
var reMapDisplayOrder = function (item, index) { return (__assign(__assign({}, item), { display_order: index + 1 })); };
export var updateDisplayOrder = function (items) {
    var available = getAvailableItems(items).map(reMapDisplayOrder);
    var deactivated = getDeactivatedItems(items).map(reMapDisplayOrder);
    return __spreadArray(__spreadArray([], available, true), deactivated, true);
};
export var getDisplayOrder = function (items) {
    return items.map(function (_a) {
        var id = _a.id, display_order = _a.display_order;
        return ({
            id: id,
            display_order: display_order,
        });
    });
};
export var flatten2DArrayTo1D = function (arr) {
    return arr.reduce(function (prev, curr) { return __spreadArray(__spreadArray([], prev, true), curr, true); }, []);
};
export var duplicateObjectArray = function (arr) {
    return arr.map(function (obj) { return (__assign({}, obj)); });
};
export var arraysHaveMatchingItems = function (arr1, arr2) {
    if (arr1.length === 0 && arr2.length === 0)
        return true;
    return (arr2.filter(function (item) {
        return arr1.indexOf(item) >= 0;
    }).length > 0);
};
export var toLocalTime = function (iso, format) {
    if (!iso)
        return "";
    return moment(iso).utcOffset(LOCAL_TIMEZONE).format(format);
};
export var intersectList = function (listA, listB) {
    return listA.reduce(function (c, a) {
        if (listB.includes(a))
            c.push(a);
        return c;
    }, []);
};
export var stringArrayIsSame = function (listA, listB) {
    if (listA === void 0) { listA = []; }
    if (listB === void 0) { listB = []; }
    if (listA.length !== listB.length)
        return false;
    var intersected = intersectList(listA, listB);
    return intersected.length === listA.length;
};
export var triggerPrintDialog = function () {
    window.print();
};
export var getCurrentTime = function () { return moment(); };
export var convertToMMTOrNull = function (time) {
    return time ? moment(time) : null;
};
export var randomizeList = function (list) {
    return __spreadArray([], list, true).sort(function () { return 0.5 - randomNum(); });
};
export var stringToNumbersArray = function (str) { var _a; return (_a = str === null || str === void 0 ? void 0 : str.map(function (s) { return parseInt(s, 10); })) !== null && _a !== void 0 ? _a : []; };
/**
 * Checks email format
 * @see https://emailregex.com/
 * @param $str
 */
export function isEmail(email) {
    return RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email);
}
export var isArrayDifferent = function (arr1, arr2) {
    if (arr1.length !== arr2.length)
        return true;
    for (var _i = 0, arr1_1 = arr1; _i < arr1_1.length; _i++) {
        var item = arr1_1[_i];
        if (!arr2.includes(item))
            return true;
    }
    return false;
};
export var reloadWindow = function () {
    window.location.reload();
};
export var getLocationPath = function () { return window.location.pathname; };
export var doNothing = function () {
    // does nothing, intentionally empty
};
export var copyTextToClipboard = function (text) {
    navigator.clipboard.writeText(text);
};
export var bytesToMegaBytes = function (bytes) {
    return Math.floor(bytes / 1024 / 1024);
};
export var mergeStringArray = function (a, b) {
    var unique = new Set(__spreadArray(__spreadArray([], a, true), b, true));
    return Array.from(unique);
};
export var getUniqueIdObjects = function (list) {
    var uniqueIds = new Set();
    return list.reduce(function (uniquelist, item) {
        var length = uniqueIds.size;
        uniqueIds.add(item.id);
        if (uniqueIds.size > length) {
            return __spreadArray(__spreadArray([], uniquelist, true), [item], false);
        }
        return uniquelist;
    }, []);
};
export var splitToBatches = function (list, size) {
    if (size <= 0)
        return [];
    return list.reduce(function (batch, id, idx) {
        var count = Math.floor(idx / size);
        if (batch.length === count)
            batch.push([]);
        batch[count].push(id);
        return batch;
    }, []);
};
export var scrollToElement = function (className) {
    var elements = document.getElementsByClassName(className);
    if (elements.length > 0) {
        elements[0].scrollIntoView({ behavior: "smooth", block: "center" });
    }
};
export var filterNonDeletedItems = function (_a) {
    var deleted_at = _a.deleted_at;
    return !deleted_at;
};

import { lazy } from "react";
import { MENU_GROUP, PLATFORM } from "../../../common/constants";
import { globalEvents } from "../../../common/GlobalEvents";
import { ROUTE_EVENTS } from "../../../components/routing/constants";
import { APPROVALS_PATH } from "./constants";
/* istanbul ignore next */ // no need to test lazy loading
var LazyComponents = {
    MyApprovals: lazy(function () {
        return import(/* webpackChunkName: "fs-myapprovals" */ "./approver/MyApprovals");
    }),
    // anchor - for code generator
};
var PLATFORM_ACCESS = [PLATFORM.ADMIN_PANEL];
/* istanbul ignore next */ // no need to test routes
export var approvalsRoutes = [
    {
        label: "fs.approvals.myapprovals.title",
        route: APPROVALS_PATH.MY_APPROVALS,
        component: LazyComponents.MyApprovals,
        platforms: PLATFORM_ACCESS,
        group: MENU_GROUP.APPROVAL,
        permissions: [],
    },
    // anchor - for code generator
];
export var initApprovals = function () {
    globalEvents.emit(ROUTE_EVENTS.REGISTER, approvalsRoutes);
};

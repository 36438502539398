var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { COLORS } from "../../common/constants";
var DEFAULT_SVG_SIZE = 20;
export var NotificationIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? DEFAULT_SVG_SIZE : _b, _c = _a.fillColor, fillColor = _c === void 0 ? COLORS.OFFWHITE : _c, _d = _a.className, className = _d === void 0 ? "" : _d;
    var sizeString = size.toString();
    var scale = size / DEFAULT_SVG_SIZE;
    return (_jsx("svg", __assign({ className: className, width: size, height: size, viewBox: "0 0 ".concat(sizeString, " ").concat(sizeString), xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", __assign({ fill: fillColor, transform: "scale(".concat(scale, ",").concat(scale, ")") }, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 18C12 19.1046 11.1046 20 10 20C8.89543 20 8 19.1046 8 18H12ZM10 0C11.1046 0 12 0.89543 12 2C12 2.09502 11.9704 2.18186 11.9163 2.26053C14.8691 3.07954 17 5.76234 17 9V13C17 13.8838 18.1162 15 19 15L19.1332 15.007C20.2889 15.131 20.2889 16.869 19.1332 16.993L19 17H1L0.866825 16.993C-0.331748 16.8644 -0.287356 15 1 15L1.12183 14.9932C1.98292 14.899 3 13.8436 3 13V9C3 5.76234 5.13088 3.07954 8.08511 2.25876C8.0296 2.18186 8 2.09502 8 2C8 0.89543 8.89543 0 10 0ZM10 4C7.20154 4 5 6.20138 5 9V13C5 13.6628 4.77648 14.3282 4.40481 14.9208L4.352 15H15.647L15.5952 14.9208C15.2573 14.3821 15.0419 13.7832 15.0055 13.1808L15 13V9C15 6.20138 12.7985 4 10 4Z", fill: fillColor }) })) })));
};

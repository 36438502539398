var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { getLocationsFilteredByBusinesses } from "../dictionaries/locations/helpers";
var numberToString = function (id) { return id + ""; };
var filterById = function (arr, filterList) {
    return arr.filter(function (_a) {
        var id = _a.id;
        return filterList.includes(id);
    });
};
export var extractBusinessesFromLocations = function (locations) {
    var uniqueBusinessSet = locations.reduce(function (ubusiness, _a) {
        var units = _a.units;
        units === null || units === void 0 ? void 0 : units.forEach(function (_a) {
            var business_ids = _a.business_ids;
            business_ids.forEach(function (id) { return ubusiness.add(id); });
        });
        return ubusiness;
    }, new Set());
    return Array.from(uniqueBusinessSet).map(numberToString);
};
export var extractBrandsCompaniesFromBusinesses = function (businesses) {
    var _a = businesses.reduce(function (setData, _a) {
        var brand_id = _a.brand_id, company_id = _a.company_id;
        setData.companies.add(company_id);
        setData.brands.add(brand_id);
        return setData;
    }, {
        companies: new Set(),
        brands: new Set(),
    }), companies = _a.companies, brands = _a.brands;
    return {
        companies: Array.from(companies).map(numberToString),
        brands: Array.from(brands).map(numberToString),
    };
};
export var extractBusinessFromBrands = function (brands) {
    var businessSet = brands.reduce(function (setData, _a) {
        var brandBusinesses = _a.businesses;
        brandBusinesses.forEach(function (_a) {
            var id = _a.id;
            setData.add(id);
        });
        return setData;
    }, new Set());
    return Array.from(businessSet);
};
export var filterDictionaryByLocations = function (_a) {
    var companies = _a.companies, brands = _a.brands, businesses = _a.businesses, locations = _a.locations, locationFilter = _a.locationFilter;
    var filteredLocations = filterById(locations, locationFilter);
    var businessIds = extractBusinessesFromLocations(filteredLocations);
    var filteredBusinesses = filterById(businesses, businessIds);
    var _b = extractBrandsCompaniesFromBusinesses(filteredBusinesses), companyIds = _b.companies, brandIds = _b.brands;
    var filteredBrands = filterById(brands, brandIds);
    var filteredCompanies = filterById(companies, companyIds);
    return {
        filteredLocations: filteredLocations,
        filteredCompanies: filteredCompanies,
        filteredBrands: filteredBrands,
        filteredBusinesses: filteredBusinesses,
    };
};
export var getBrandIdsOfBusinesses = function (brands, businessids) {
    var foundBrands = businessids.reduce(function (found, businessid) {
        brands
            .filter(function (_a) {
            var businesses = _a.businesses;
            return businesses.map(function (_a) {
                var id = _a.id;
                return id;
            }).includes(businessid);
        })
            .forEach(function (_a) {
            var id = _a.id;
            return found.add(id);
        });
        return found;
    }, new Set());
    return Array.from(foundBrands);
};
export var filterDictionaryByBrandsBusiness = function (_a) {
    var companies = _a.companies, brands = _a.brands, businesses = _a.businesses, locations = _a.locations, brandFilter = _a.brandFilter, businessFilter = _a.businessFilter;
    // brands of specified businesses
    var brandsOfBusinessFilter = getBrandIdsOfBusinesses(brands, businessFilter !== null && businessFilter !== void 0 ? businessFilter : []);
    // brands specified without businesses, so give all businesses under these brands
    var brandsWithAllBusinesses = brandFilter.filter(function (brandid) { return !brandsOfBusinessFilter.includes(brandid); });
    var brandsThatAllowsAllBusinesses = filterById(brands, brandsWithAllBusinesses);
    var brandBusinessIds = extractBusinessFromBrands(brandsThatAllowsAllBusinesses);
    var filteredBrands = filterById(brands, __spreadArray(__spreadArray([], brandFilter, true), brandsOfBusinessFilter, true));
    // combine business ids
    var businessIds = __spreadArray(__spreadArray([], brandBusinessIds, true), businessFilter, true);
    var filteredBusinesses = filterById(businesses, businessIds);
    // get companies of businesses
    var companyIds = extractBrandsCompaniesFromBusinesses(filteredBusinesses).companies;
    var filteredCompanies = filterById(companies, companyIds);
    var filteredLocations = getLocationsFilteredByBusinesses(locations, businessIds);
    return {
        filteredLocations: filteredLocations,
        filteredCompanies: filteredCompanies,
        filteredBrands: filteredBrands,
        filteredBusinesses: filteredBusinesses,
    };
};
export var getAllIds = function (arr) {
    return arr.map(function (_a) {
        var id = _a.id;
        return id;
    });
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { contains } from "../../common/stringutils";
import { PERMISSION } from "../rbac/permissions";
import { inheritPermissionsFromSubRoutes } from "./routeInheritance";
export var flattenRoutes = function (routeStructure) {
    var flatten = [];
    routeStructure.forEach(function (route) {
        if (!!route.subRoutes) {
            flatten = __spreadArray(__spreadArray([], flatten, true), flattenRoutes(route.subRoutes), true);
        }
        else if (!!route.route && !!route.component) {
            flatten.push(__assign(__assign({}, route), { route: route.route }));
        }
    });
    return flatten;
};
export var isRouteAllowedForPlatform = function (platformsspec, platform) {
    if (!platformsspec.length)
        return true;
    return platformsspec.includes(platform);
};
export var routeToRouteListing = function (routes, platform, isLocal) {
    return routes
        .map(function (_a) {
        var route = _a.route, component = _a.component, permissions = _a.permissions, platforms = _a.platforms, label = _a.label, new_item_check = _a.new_item_check, dev_only = _a.dev_only, layout = _a.layout;
        return ({
            label: label,
            route: route,
            permissions: permissions !== null && permissions !== void 0 ? permissions : [],
            component: component,
            platforms: platforms !== null && platforms !== void 0 ? platforms : [],
            new_item_check: new_item_check !== null && new_item_check !== void 0 ? new_item_check : "",
            new_items: 0,
            dev_only: dev_only !== null && dev_only !== void 0 ? dev_only : false,
            layout: layout !== null && layout !== void 0 ? layout : true,
        });
    })
        .filter(function (_a) {
        var platforms = _a.platforms;
        return isRouteAllowedForPlatform(platforms, platform);
    })
        .filter(function (_a) {
        var dev_only = _a.dev_only;
        return !dev_only || (isLocal && dev_only);
    });
};
var getRouteSignature = function (_a) {
    var label = _a.label, route = _a.route, permissions = _a.permissions, platforms = _a.platforms, hidden = _a.hidden, subRoutes = _a.subRoutes;
    var routetext = route || "";
    var permissionstext = permissions ? permissions.join(",") : "";
    var platformstext = platforms ? platforms.join(",") : "";
    var hiddentext = hidden ? "1" : "0";
    var subroutestext = subRoutes ? subRoutes.length + "" : "0";
    return "".concat(label).concat(routetext).concat(platformstext).concat(permissionstext).concat(hiddentext).concat(subroutestext);
};
var hasRoute = function (currentRoutes, checkRoute) {
    var sig = getRouteSignature(checkRoute);
    for (var _i = 0, currentRoutes_1 = currentRoutes; _i < currentRoutes_1.length; _i++) {
        var cRoutes = currentRoutes_1[_i];
        if (getRouteSignature(cRoutes) === sig)
            return true;
    }
    return false;
};
export var checkAlphaPermissions = function (route) {
    var _a, _b;
    var permissions = ((_a = route.permissions) === null || _a === void 0 ? void 0 : _a.includes(PERMISSION.ALPHA))
        ? [PERMISSION.ALPHA]
        : route.permissions;
    return __assign(__assign({}, route), { permissions: permissions, subRoutes: (_b = route.subRoutes) === null || _b === void 0 ? void 0 : _b.map(function (subroute) {
            return checkAlphaPermissions(subroute);
        }) });
};
export var joinRoutes = function (oldRoutes, insertRoutes) {
    var newRoutes = __spreadArray([], oldRoutes, true);
    inheritPermissionsFromSubRoutes(insertRoutes).forEach(function (route) {
        if (!hasRoute(newRoutes, route)) {
            newRoutes.push(checkAlphaPermissions(route));
        }
    });
    return newRoutes;
};
export var findRouteMatches = function (routeListings, pathname) {
    return routeListings
        .filter(function (_a) {
        var route = _a.route;
        if (!route)
            return false;
        if (pathname.startsWith(route))
            return true;
        return matchRouteVariables(route, pathname);
    })
        .sort(function (a, b) {
        // sort to move the closest match to the front of the list
        var diffA = Math.abs(a.route.length - pathname.length);
        var diffB = Math.abs(b.route.length - pathname.length);
        return diffA - diffB;
    });
};
var matchRouteVariables = function (route, pathname) {
    var routeSplits = route.split("/");
    var pathSplits = pathname.split("/");
    var length = routeSplits.length;
    if (length !== pathSplits.length)
        return false;
    for (var i = 0; i < length; i++) {
        if (routeSplits[i] !== pathSplits[i]) {
            if (routeSplits[i][0] !== ":")
                return false;
        }
    }
    return true;
};
var matchedRoutesAllowPartial = function (matchList) {
    var partials = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        partials[_i - 1] = arguments[_i];
    }
    for (var _a = 0, matchList_1 = matchList; _a < matchList_1.length; _a++) {
        var route = matchList_1[_a].route;
        if (route && contains.apply(void 0, __spreadArray([route], partials, false)))
            return true;
    }
    return false;
};
export var routeNotMatch = function (routeListings, pathname, isRouting) {
    if (isRouting === void 0) { isRouting = false; }
    var match = findRouteMatches(routeListings, pathname);
    if (!match.length)
        return true;
    if (match[0].route === pathname)
        return false;
    if (isRouting && matchedRoutesAllowPartial(match, "?"))
        return false;
    if (!isRouting && matchedRoutesAllowPartial(match, "?", ":"))
        return false;
    return true;
};
/**
 * Click handling helper function for Elastic UI links
 * https://github.com/elastic/eui/blob/master/wiki/react-router.md#react-router-5x
 */
var isModifiedEvent = function (event) {
    return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
};
var isLeftClickEvent = function (event) { return event.button === 0; };
var isTargetBlank = function (event) {
    var target = event.target.getAttribute("target");
    return target && target !== "_self";
};
export var euiClickHandler = function (route, history) { return function (event) {
    if (event) {
        // using "any" as type for event because EUI defined its own event type and did not expose it.
        if (event.defaultPrevented)
            return;
        // Let the browser handle links that open new tabs/windows
        if (isModifiedEvent(event) ||
            !isLeftClickEvent(event) ||
            isTargetBlank(event)) {
            return;
        }
        // Prevent regular link behavior, which causes a browser refresh.
        event.preventDefault();
    }
    history.push(route);
}; };
export var getURLParams = function () {
    var search = window.location.search;
    if (!search.length)
        return {};
    var splits = search.substring(1).split("&");
    return splits.reduce(function (output, split) {
        var _a = split.split("="), key = _a[0], value = _a[1];
        output[key] = value;
        return output;
    }, {});
};
export var trimTrailingSlash = function (link) {
    var length = link.length;
    return link.substring(length - 1) === "/"
        ? link.substring(0, length - 1)
        : link;
};
export var insertUrlParamWithoutReload = function (key, value) {
    if (window.history.pushState) {
        var searchParams = new URLSearchParams(window.location.search);
        searchParams.set(key, value);
        var newurl = window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname +
            "?" +
            searchParams.toString();
        window.history.pushState({ path: newurl }, "", newurl);
    }
};

var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { lazy } from "react";
import { MENU_GROUP, PLATFORM } from "../../../common/constants";
import { globalEvents } from "../../../common/GlobalEvents";
import { PERMISSION } from "../../../components/rbac/permissions";
import { ROUTE_EVENTS } from "../../../components/routing/constants";
import { REPORTS_PATH } from "./constants";
/* istanbul ignore next */ // no need to test lazy loading
var LazyComponents = {
    DailySalesSummary: lazy(function () {
        return import(
        /* webpackChunkName: "fs-dailysalessummary" */ "./dailysalesentry/view/DailySales");
    }),
    DSERAudit: lazy(function () { return import(/* webpackChunkName: "dseraudit" */ "./dseraudit/DSERAudit"); }),
    DailySalesReportEdit: lazy(function () {
        return import(
        /* webpackChunkName: "fs-dailysalesentry" */ "./dailysalesentry/edit/DailySalesEntry");
    }),
    DailySalesReportByStall: lazy(function () {
        return import(
        /* webpackChunkName: "fs-dserbystall" */ "./dserbystall/DSERByStall");
    }),
    DailySalesReportByDates: lazy(function () {
        return import(
        /* webpackChunkName: "fs-dserbydates" */ "./dserbydates/DSERByDates");
    }),
    DailySalesReportByHourAP: lazy(function () {
        return import(
        /* webpackChunkName: "fs-dserbyhourap" */ "./dserbyhour/DSERByHourAP");
    }),
    DailySalesReportByHourPC: lazy(function () {
        return import(
        /* webpackChunkName: "fs-dserbyhourpc" */ "./dserbyhour/DSERByHourPC");
    }),
    SalesSummary: lazy(function () {
        return import(
        /* webpackChunkName: "fs-salessummary" */ "./salessummary/SalesSummary");
    }),
    PromotionsAdmin: lazy(function () {
        return import(
        /* webpackChunkName: "fs-promotionsadmin" */ "./promotions/PromotionsAdmin");
    }),
    PromotionsPartner: lazy(function () {
        return import(
        /* webpackChunkName: "fs-promotionspartner" */ "./promotions/PromotionsPartner");
    }),
    ItemSalesReport: lazy(function () {
        return import(
        /* webpackChunkName: "fs-itemssalesreport" */ "./itemsales/ItemSalesReport");
    }),
    ProductCategoryReport: lazy(function () {
        return import(
        /* webpackChunkName: "fs-productcategoryreport" */ "./productcategory/ProductCategoryReport");
    }),
    ItemisedReceiptReport: lazy(function () {
        return import(
        /* webpackChunkName: "fs-itemssalesreport" */ "./itemisedreceipt/ItemisedReceipt");
    }),
    VouchersDaily: lazy(function () {
        return import(
        /* webpackChunkName: "fs-vouchersdailyreport" */ "./vouchers/daily/VouchersDaily");
    }),
    VouchersByStall: lazy(function () {
        return import(
        /* webpackChunkName: "fs-vouchersbystallreport" */ "./vouchers/bystall/VouchersByStall");
    }),
    DeliverySalesDaily: lazy(function () {
        return import(
        /* webpackChunkName: "fs-deliverysalesdailyreport" */ "./deliverysales/daily/DailyDeliverySales");
    }),
    DeliverySalesDailyDetails: lazy(function () {
        return import(
        /* webpackChunkName: "fs-deliverysalesdailydetailsreport" */ "./deliverysales/dailydetails/DailyDeliverySalesDetails");
    }),
    DeliverySalesMonthly: lazy(function () {
        return import(
        /* webpackChunkName: "fs-deliverysalesmonthlyreport" */ "./deliverysales/monthly/MonthlyDeliverySales");
    }),
    PaymentMethodReceipt: lazy(function () {
        return import(
        /* webpackChunkName: "fs-paymentmethodreceiptreport" */ "./paymentmethodreceipt/PaymentMethodReceiptReport");
    }),
    ShiftReport: lazy(function () {
        return import(
        /* webpackChunkName: "fs-shiftreport" */ "./shiftreport/ShiftReport");
    }),
    // anchor - for code generator
};
var GENERIC_REPORT_VIEW = [PERMISSION.FS_REPORT_VIEW];
/* istanbul ignore next */ // no need to test route config
export var reportsRoutes = [
    {
        label: "reports.menu.label",
        route: "",
        permissions: [],
        icon: "documents",
        group: MENU_GROUP.FOOD_SERVICES,
        platforms: [],
        subRoutes: [
            {
                label: "reports.menu.item.dailysalesentry",
                route: REPORTS_PATH.DAILY_SALES_REPORT_SUMMARY,
                component: LazyComponents.DailySalesSummary,
                permissions: [
                    PERMISSION.ADMIN_PANEL_DSER_VIEW,
                    PERMISSION.ADMIN_PANEL_DSER_MANAGE,
                ],
                platforms: [PLATFORM.ADMIN_PANEL],
            },
            {
                label: "",
                route: REPORTS_PATH.DAILY_SALES_REPORT_ENTRY,
                component: LazyComponents.DailySalesReportEdit,
                permissions: [
                    PERMISSION.ADMIN_PANEL_DSER_VIEW,
                    PERMISSION.ADMIN_PANEL_DSER_UPDATE,
                    PERMISSION.ADMIN_PANEL_DSER_MANAGE,
                    PERMISSION.ADMIN_PANEL_DSER_DELETE,
                ],
                hidden: true,
                platforms: [PLATFORM.ADMIN_PANEL],
            },
            {
                label: "reports.menu.item.dseraudit",
                route: REPORTS_PATH.DAILY_SALES_REPORT_AUDIT,
                component: LazyComponents.DSERAudit,
                permissions: [PERMISSION.FS_REPORTS_AUDIT],
                platforms: [PLATFORM.ADMIN_PANEL],
            },
            {
                label: "reports.menu.item.dailysalesentrybystall",
                route: REPORTS_PATH.DAILY_SALES_REPORT_BY_STALL,
                component: LazyComponents.DailySalesReportByStall,
                permissions: __spreadArray([
                    PERMISSION.ADMIN_PANEL_DSER_VIEW,
                    PERMISSION.ADMIN_PANEL_DSER_MANAGE
                ], GENERIC_REPORT_VIEW, true),
                platforms: [PLATFORM.ADMIN_PANEL, PLATFORM.PARTNER_CENTER],
            },
            {
                label: "reports.menu.item.dserbulkcsvexport",
                route: REPORTS_PATH.DAILY_SALES_REPORT_BY_DATES,
                component: LazyComponents.DailySalesReportByDates,
                permissions: [
                    PERMISSION.ADMIN_PANEL_DSER_MANAGE,
                    PERMISSION.ADMIN_PANEL_DSER_VIEW,
                ],
                platforms: [PLATFORM.ADMIN_PANEL],
            },
            {
                label: "reports.menu.item.dailysalesentrybyhour",
                route: REPORTS_PATH.DAILY_SALES_REPORT_BY_HOUR,
                component: LazyComponents.DailySalesReportByHourAP,
                permissions: [
                    PERMISSION.ADMIN_PANEL_DSER_MANAGE,
                    PERMISSION.ADMIN_PANEL_DSER_VIEW,
                ],
                platforms: [PLATFORM.ADMIN_PANEL],
            },
            {
                label: "reports.menu.item.dailysalesentrybyhour",
                route: REPORTS_PATH.DAILY_SALES_REPORT_BY_HOUR,
                component: LazyComponents.DailySalesReportByHourPC,
                permissions: [
                    PERMISSION.ADMIN_PANEL_DSER_MANAGE,
                    PERMISSION.ADMIN_PANEL_DSER_VIEW,
                ],
                platforms: [PLATFORM.PARTNER_CENTER],
            },
            {
                label: "reports.menu.item.salessummary",
                route: REPORTS_PATH.SALES_SUMMARY,
                component: LazyComponents.SalesSummary,
                permissions: GENERIC_REPORT_VIEW,
                platforms: [PLATFORM.ADMIN_PANEL, PLATFORM.PARTNER_CENTER],
            },
            {
                label: "reports.menu.item.promotions.ap",
                route: REPORTS_PATH.PROMOTIONS,
                component: LazyComponents.PromotionsAdmin,
                permissions: GENERIC_REPORT_VIEW,
                platforms: [PLATFORM.ADMIN_PANEL],
            },
            {
                label: "reports.menu.item.promotions.pc",
                route: REPORTS_PATH.PROMOTIONS,
                component: LazyComponents.PromotionsPartner,
                permissions: GENERIC_REPORT_VIEW,
                platforms: [PLATFORM.PARTNER_CENTER],
            },
            {
                label: "reports.menu.item.itemsalesreport",
                route: REPORTS_PATH.ITEM_SALES_REPORT,
                component: LazyComponents.ItemSalesReport,
                permissions: GENERIC_REPORT_VIEW,
                platforms: [PLATFORM.ADMIN_PANEL, PLATFORM.PARTNER_CENTER],
            },
            {
                label: "reports.menu.item.productcategoryreport",
                route: REPORTS_PATH.PRODUCT_CATEGORY_REPORT,
                component: LazyComponents.ProductCategoryReport,
                permissions: GENERIC_REPORT_VIEW,
                platforms: [PLATFORM.ADMIN_PANEL, PLATFORM.PARTNER_CENTER],
            },
            {
                label: "reports.menu.item.itemisedreceipt",
                route: REPORTS_PATH.ITEMISED_RECEIPT,
                component: LazyComponents.ItemisedReceiptReport,
                permissions: GENERIC_REPORT_VIEW,
                platforms: [PLATFORM.ADMIN_PANEL, PLATFORM.PARTNER_CENTER],
            },
            {
                label: "reports.paymentmethodreceipt.menutitle",
                route: REPORTS_PATH.PAYMENT_METHOD_RECEIPT,
                component: LazyComponents.PaymentMethodReceipt,
                permissions: GENERIC_REPORT_VIEW,
                platforms: [PLATFORM.ADMIN_PANEL],
            },
            {
                label: "reports.vouchers.menutitle",
                permissions: GENERIC_REPORT_VIEW,
                platforms: [PLATFORM.ADMIN_PANEL, PLATFORM.PARTNER_CENTER],
                subRoutes: [
                    {
                        label: "reports.vouchers.daily.menutitle",
                        route: REPORTS_PATH.VOUCHERS_DAILY,
                        component: LazyComponents.VouchersDaily,
                        permissions: GENERIC_REPORT_VIEW,
                        platforms: [PLATFORM.ADMIN_PANEL, PLATFORM.PARTNER_CENTER],
                    },
                    {
                        label: "reports.vouchers.bystall.menutitle",
                        route: REPORTS_PATH.VOUCHERS_BYSTALL,
                        component: LazyComponents.VouchersByStall,
                        permissions: GENERIC_REPORT_VIEW,
                        platforms: [PLATFORM.ADMIN_PANEL, PLATFORM.PARTNER_CENTER],
                    },
                ],
            },
            {
                label: "reports.deliverysales.menutitle",
                permissions: [],
                platforms: [PLATFORM.ADMIN_PANEL, PLATFORM.PARTNER_CENTER],
                subRoutes: [
                    {
                        label: "reports.deliverysales.daily.menutitle",
                        route: REPORTS_PATH.DELIVERYSALES_DAILY,
                        component: LazyComponents.DeliverySalesDaily,
                        permissions: [PERMISSION.FS_DELIVERY_SALES_REPORT_VIEW],
                        platforms: [PLATFORM.ADMIN_PANEL],
                    },
                    {
                        label: "reports.deliverysales.daily.details.menutitle",
                        route: REPORTS_PATH.DELIVERYSALES_DAILY_DETAILS,
                        component: LazyComponents.DeliverySalesDailyDetails,
                        permissions: [PERMISSION.FS_DELIVERY_SALES_REPORT_VIEW],
                        platforms: [PLATFORM.ADMIN_PANEL],
                        hidden: true,
                    },
                    {
                        label: "reports.deliverysales.monthly.menutitle",
                        route: REPORTS_PATH.DELIVERYSALES_MONTHLY,
                        component: LazyComponents.DeliverySalesMonthly,
                        permissions: [PERMISSION.FS_DELIVERY_SALES_REPORT_VIEW],
                        platforms: [PLATFORM.ADMIN_PANEL, PLATFORM.PARTNER_CENTER],
                    },
                ],
            },
            {
                label: "reports.menu.item.shiftreport",
                route: REPORTS_PATH.SHIFT_REPORT,
                component: LazyComponents.ShiftReport,
                permissions: [PERMISSION.FS_REPORTS_SHIFT_VIEW],
                platforms: [PLATFORM.ADMIN_PANEL, PLATFORM.PARTNER_CENTER],
            },
            // anchor - for code generator
        ],
    },
];
export var initFSReports = function () {
    globalEvents.emit(ROUTE_EVENTS.REGISTER, reportsRoutes);
};

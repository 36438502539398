var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { createElement } from "react";
import { Route } from "react-router-dom";
import { usePageTitle } from "../../common/hooks/usePageTitle";
import Layout from "../layout/Layout";
import RoutePermission from "../rbac/RoutePermission";
var ContentRoute = function (_a) {
    var children = _a.children, component = _a.component, exact = _a.exact, checkPermission = _a.checkPermission, layout = _a.layout, _b = _a.label, label = _b === void 0 ? "" : _b, props = __rest(_a, ["children", "component", "exact", "checkPermission", "layout", "label"]);
    usePageTitle(label);
    return (_jsx(Route, __assign({ exact: exact }, props, { children: function (routeProps) {
            if (!routeProps.match)
                return null;
            if (children)
                return _jsx(_Fragment, { children: children });
            if (component) {
                return layout ? (_jsx(Layout, { children: _jsx(RoutePermission, __assign({ routeProps: routeProps, checkPermission: checkPermission }, { children: createElement(component) })) })) : (_jsx(RoutePermission, __assign({ routeProps: routeProps, checkPermission: checkPermission }, { children: createElement(component) })));
            }
            return null;
        } })));
};
export default ContentRoute;

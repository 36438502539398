var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { COLORS } from "../../common/constants";
var DEFAULT_SVG_SIZE = 30;
export var DashboardIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? DEFAULT_SVG_SIZE : _b, _c = _a.fillColor, fillColor = _c === void 0 ? COLORS.OFFWHITE : _c, _d = _a.className, className = _d === void 0 ? "" : _d;
    var sizeString = size.toString();
    var scale = size / DEFAULT_SVG_SIZE;
    return (_jsx("svg", __assign({ className: className, width: size, height: size, viewBox: "0 0 ".concat(sizeString, " ").concat(sizeString), xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", __assign({ fill: fillColor, transform: "scale(".concat(scale, ",").concat(scale, ")") }, { children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2.625 7.875H25.375C26.8247 7.875 28 6.69975 28 5.25V2.625C28 1.17525 26.8247 0 25.375 0H2.625C1.17525 0 0 1.17525 0 2.625V5.25C0 6.69975 1.17525 7.875 2.625 7.875ZM1.75 2.625C1.75 2.14175 2.14175 1.75 2.625 1.75H25.375C25.8582 1.75 26.25 2.14175 26.25 2.625V5.25C26.25 5.73325 25.8582 6.125 25.375 6.125H2.625C2.14175 6.125 1.75 5.73325 1.75 5.25V2.625ZM2.625 27.125H10.5C11.9497 27.125 13.125 25.9497 13.125 24.5V21.875C13.125 20.4253 11.9497 19.25 10.5 19.25H2.625C1.17525 19.25 0 20.4253 0 21.875V24.5C0 25.9497 1.17525 27.125 2.625 27.125ZM1.75 21.875C1.75 21.3918 2.14175 21 2.625 21H10.5C10.9832 21 11.375 21.3918 11.375 21.875V24.5C11.375 24.9832 10.9832 25.375 10.5 25.375H2.625C2.14175 25.375 1.75 24.9832 1.75 24.5V21.875Z", fill: fillColor }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2.625 17.5H10.5C11.9497 17.5 13.125 16.3247 13.125 14.875V12.25C13.125 10.8003 11.9497 9.625 10.5 9.625H2.625C1.17525 9.625 0 10.8003 0 12.25V14.875C0 16.3247 1.17525 17.5 2.625 17.5ZM1.75 12.25C1.75 11.7668 2.14175 11.375 2.625 11.375H10.5C10.9832 11.375 11.375 11.7668 11.375 12.25V14.875C11.375 15.3582 10.9832 15.75 10.5 15.75H2.625C2.14175 15.75 1.75 15.3582 1.75 14.875V12.25ZM17.5 27.125H25.375C26.8247 27.125 28 25.9497 28 24.5V12.25C28 10.8003 26.8247 9.625 25.375 9.625H17.5C16.0503 9.625 14.875 10.8003 14.875 12.25V24.5C14.875 25.9497 16.0503 27.125 17.5 27.125ZM16.625 12.25C16.625 11.7668 17.0168 11.375 17.5 11.375H25.375C25.8582 11.375 26.25 11.7668 26.25 12.25V24.5C26.25 24.9832 25.8582 25.375 25.375 25.375H17.5C17.0168 25.375 16.625 24.9832 16.625 24.5V12.25Z", fill: fillColor })] })) })));
};

var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { useCheckUserHasPermissions } from "./hooks";
import { PERMISSION } from "./permissions";
var RequirePermissions = function (_a) {
    var permissions = _a.permissions, fallback = _a.fallback, children = _a.children;
    var componentPermissions = useMemo(function () {
        if (!permissions.length)
            return permissions;
        return __spreadArray(__spreadArray([], permissions, true), [PERMISSION.SUPER_ADMIN], false);
    }, [permissions]);
    var userHasPermission = useCheckUserHasPermissions(componentPermissions);
    if (!userHasPermission)
        return _jsx(_Fragment, { children: fallback !== null && fallback !== void 0 ? fallback : null });
    return _jsx(_Fragment, { children: children });
};
export default RequirePermissions;

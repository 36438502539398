import { jsx as _jsx } from "react/jsx-runtime";
import FPLoginSplash from "./FPLoginSplash";
import { useLoginFlow } from "./useLoginFlow";
var LoginPage = function () {
    var showFPSplash = useLoginFlow().showFPSplash;
    if (showFPSplash)
        return _jsx(FPLoginSplash, {});
    return null;
};
export default LoginPage;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EuiModal, EuiModalBody } from "@elastic/eui";
import I18nText from "../../../i18n/I18nText";
import AlertModalButtons from "./AlertModalButtons";
import AlertModalHeader from "./AlertModalHeader";
import { useModalButtons } from "./hooks";
var AlertModal = function (_a) {
    var title = _a.title, content = _a.content, buttons = _a.buttons, hideModal = _a.hideModal, _b = _a.className, className = _b === void 0 ? "" : _b;
    var modalButtons = useModalButtons(buttons);
    return (_jsxs(EuiModal, __assign({ onClose: hideModal, className: "Alert ".concat(buttons && buttons.length ? "HasButtons" : "", " ").concat(className) }, { children: [_jsx(AlertModalHeader, { title: title }), _jsx(EuiModalBody, __assign({ className: "Body", "data-test-subj": "AlertModalBody" }, { children: typeof content === "string" ? _jsx(I18nText, { token: content }) : content })), _jsx(AlertModalButtons, { buttons: modalButtons })] })));
};
export default AlertModal;

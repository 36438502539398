import { useCallback, useEffect, useRef } from "react";
import { globalEvents } from "../../../../common/GlobalEvents";
import { useCheckUserHasPermissions } from "../../../rbac/hooks";
var NO_PERM_REQUIREMENT = [];
export var useRegisterNewItemRequest = function (event, handler, permissions) {
    var handlerFn = useRef(null);
    var hasPermission = useCheckUserHasPermissions(permissions !== null && permissions !== void 0 ? permissions : NO_PERM_REQUIREMENT);
    var callbackFn = useCallback(function () {
        var _a;
        (_a = handlerFn.current) === null || _a === void 0 ? void 0 : _a.call(handlerFn);
    }, []);
    useEffect(function () {
        handlerFn.current = hasPermission ? handler : null;
        if (hasPermission)
            callbackFn();
    }, [handler, hasPermission]);
    useEffect(function () {
        globalEvents.on(event, callbackFn);
        return function () {
            globalEvents.off(event);
        };
    }, []);
};

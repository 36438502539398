export var randomNum = function () {
    return window.crypto.getRandomValues(new Uint32Array(10))[0] / Math.pow(2, 32);
};
/**
 * Returns an integer between min and max (inclusive)
 * @param min
 * @param max
 * @returns
 */
export var randInt = function (min, max) {
    return Math.floor(randomNum() * (max - min + 1)) + min;
};
/**
 * Returns a floating point number between min (inclusive) and max (exclusive)
 * @param min
 * @param max
 * @returns
 */
export var randFloat = function (min, max) {
    return randomNum() * (max - min) + min;
};

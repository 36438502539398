import { jsx as _jsx } from "react/jsx-runtime";
import moment from "moment";
import { announceSimpleModal } from "../../modals/alerts/helpers";
import UserQRCodeGenerator from "./UserQRCodeGenerator";
var QR_CODE_EXPIRE_TIME_MINUTES = 55;
export var showUserQRCodeModal = function () {
    announceSimpleModal({
        content: _jsx(UserQRCodeGenerator, {}),
    });
};
export var getQRValidityTiming = function () {
    var createdAt = moment().toISOString();
    var validUntil = moment()
        .add(QR_CODE_EXPIRE_TIME_MINUTES, "minutes")
        .toISOString();
    return {
        createdAt: createdAt,
        validUntil: validUntil,
    };
};

import { useMemo } from "react";
import { PLATFORM } from "../../../../common/constants";
import { usePlatform } from "../../../../components/context/hooks";
import { STATIC_PAGE_PLATFORM } from "../../listing/api/types";
export var useStaticPagePlatform = function () {
    var platform = usePlatform();
    return useMemo(function () {
        var ADMIN_PANEL = STATIC_PAGE_PLATFORM.ADMIN_PANEL, PARTNER_CENTRE = STATIC_PAGE_PLATFORM.PARTNER_CENTRE;
        return platform === PLATFORM.ADMIN_PANEL ? ADMIN_PANEL : PARTNER_CENTRE;
    }, [platform]);
};

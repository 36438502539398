var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { padZeros } from "../../../../../../common/stringutils";
import { REPORTS_START } from "../../../constants";
import { getTimeYesterday } from "../../../helpers";
export var getYearMonthListing = function (year, startmonth, endmonth) {
    var months = [];
    for (var m = startmonth; m <= endmonth; m++) {
        months.push("".concat(padZeros(m, 2), "-").concat(year));
    }
    return months;
};
export var getMonthList = function (startYear, startMonth, endYear, endMonth) {
    var month = endMonth;
    var year = endYear;
    var months = [];
    while (year >= startYear) {
        months = __spreadArray(__spreadArray([], months, true), getYearMonthListing(year, year === startYear ? startMonth : 1, month).reverse(), true);
        month = 12;
        year--;
    }
    return months;
};
export var getSelectionMonthListing = function (startMonth, endMonth) {
    if (startMonth === void 0) { startMonth = ""; }
    if (endMonth === void 0) { endMonth = ""; }
    var yesterday = getTimeYesterday();
    var _a = endMonth ? endMonth.split("-") : ["", ""], em = _a[0], ey = _a[1];
    var month = parseInt(em || yesterday.format("M"), 10);
    var year = parseInt(ey || yesterday.format("YYYY"), 10);
    var _b = startMonth.split("-"), mm = _b[0], yyyy = _b[1];
    var start_month = startMonth ? Number(mm) : REPORTS_START.MONTH;
    var start_year = startMonth ? Number(yyyy) : REPORTS_START.YEAR;
    return getMonthList(start_year, start_month, year, month);
};
export var toMonthAndYear = function (date) {
    if (date === void 0) { date = ""; }
    var _a = date.split("-"), month = _a[0], year = _a[1];
    return {
        month: parseInt(month),
        year: parseInt(year),
    };
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { PLATFORM } from "../../common/constants";
import AuthPage from "../../pages/auth/auth/AuthPage";
import { AUTH_PATH } from "../../pages/auth/constants";
import LoginPage from "../../pages/auth/login/LoginPage";
import { STATICPAGE_PATH, STATIC_PAGE_PARAM, } from "../../pages/staticpage/constants";
import StaticPage from "../../pages/staticpage/display/public/StaticPage";
import BasePage from "../base/BasePage";
import { useIsUserLoggedIn } from "../context/hooks";
import { ERROR_PATH } from "../errors/constants";
import ErrorPage from "../errors/ErrorPage";
import MaintenanceCheck from "../maintenance/MaintenanceCheck";
import RequirePlatform from "../rbac/RequirePlatform";
import CheckPCNotAUser from "./CheckPCNotAUser";
import RouteTracker from "./RouteTracker";
var PUBLICPAGE_PATH = "".concat(STATICPAGE_PATH.PUBLIC_PAGES, "/:").concat(STATIC_PAGE_PARAM.ID);
var Routes = function () {
    var isLoggedIn = useIsUserLoggedIn();
    return (_jsxs(BrowserRouter, __assign({ basename: "" }, { children: [_jsx(RouteTracker, {}), _jsxs(Switch, { children: [_jsx(Route, { path: PUBLICPAGE_PATH, exact: true, component: StaticPage }), _jsx(Route, { path: ERROR_PATH.PAGE_ERROR, component: ErrorPage }), _jsx(Route, { path: AUTH_PATH.AUTH_PAGE, exact: true, component: AuthPage }), !isLoggedIn && (_jsxs(Route, { children: [_jsx(Redirect, { to: AUTH_PATH.LOGIN_PAGE }), _jsx(LoginPage, {})] })), _jsx(MaintenanceCheck, { children: _jsxs(_Fragment, { children: [_jsx(RequirePlatform, __assign({ platform: PLATFORM.PARTNER_CENTER }, { children: _jsx(CheckPCNotAUser, {}) })), _jsx(RequirePlatform, __assign({ platform: PLATFORM.ADMIN_PANEL }, { children: _jsx(BasePage, {}) }))] }) })] })] })));
};
export default Routes;

import { FILTER_KEY } from "../../../../../components/context/qc/types";
import { MENUS_PER_PAGE } from "../../../constants";
import { mockedFilters } from "../../__mocks__/data";
export var getMenuNumber = function (ind, currentPage) {
    return currentPage > 0 ? currentPage * MENUS_PER_PAGE + ind + 1 : ind + 1;
};
export var getDefaultFilterByKey = function (key) {
    return mockedFilters.filter(function (filter) { return filter.key === key; })[0];
};
export var getOptionsWithLabel = function (array) {
    if (array === void 0) { array = []; }
    return array.map(function (_a) {
        var id = _a.id, name = _a.name;
        return ({
            id: "".concat(id),
            label: name,
        });
    });
};
export var getStringNameById = function (selectedIds, dataArray, key) {
    var name = "";
    var BRAND = FILTER_KEY.BRAND, RESTAURANT = FILTER_KEY.RESTAURANT, LOCATION = FILTER_KEY.LOCATION, ACTIVITY = FILTER_KEY.ACTIVITY;
    if (selectedIds.length > 0) {
        var filterKey_1 = "";
        switch (key) {
            case BRAND:
                filterKey_1 = "brands";
                break;
            case RESTAURANT:
                filterKey_1 = "restaurants";
                break;
            case LOCATION:
                filterKey_1 = "locations";
                break;
            case ACTIVITY:
                break;
            default:
                break;
        }
        selectedIds.forEach(function (id) {
            var currentItem = dataArray.filter(function (item) { return item.id === parseInt(id, 10); })[0];
            if (name.length > 1 && key !== ACTIVITY) {
                name = "".concat(selectedIds.length, " ").concat(filterKey_1);
            }
            else {
                name = currentItem.name;
            }
        });
    }
    return name;
};
export var getAmPmTime = function (fullTime) {
    var _a = fullTime.split(":"), h = _a[0], m = _a[1];
    var amPm = "AM";
    var hours = parseInt(h, 10);
    if (parseInt(h, 10) >= 12) {
        amPm = "PM";
        hours -= 12;
    }
    return "".concat(hours, ":").concat(m, " ").concat(amPm);
};
export var getDayResult = function (dayResult, shortKey) {
    if (dayResult.length > 0) {
        return "".concat(dayResult, ", ").concat(shortKey);
    }
    return "".concat(shortKey);
};
export var getWeekDayResult = function (keys, shortKey) {
    var hasSunday = keys.indexOf(1) > -1;
    var hasSaturday = keys.indexOf(7) > -1;
    if (hasSaturday) {
        return "".concat(shortKey["weekDays"], ", ").concat(shortKey["saturday"]);
    }
    if (hasSunday) {
        return "".concat(shortKey["sunday"], ", ").concat(shortKey["weekDays"]);
    }
    return "".concat(shortKey["weekDays"]);
};

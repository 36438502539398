import { BUSINESS_TAG } from "../../../components/context/dictionaries/business/types";
export var OUTLETS_PATH;
(function (OUTLETS_PATH) {
    OUTLETS_PATH["OUTLETS_LIST"] = "/fs/outlet";
    OUTLETS_PATH["OUTLETS_DETAIL"] = "/fs/outlet/detail";
    OUTLETS_PATH["STALL_DETAIL"] = "/fs/outlets/stalldetail";
    OUTLETS_PATH["ADDING_POS_DEVICE"] = "/fs/outlets/addingposdevice";
    OUTLETS_PATH["ADDING_STALL"] = "/fs/outlets/addingstall";
    OUTLETS_PATH["ADD_LOCATION_UNIT"] = "/fs/outlet/addlocationunit";
    OUTLETS_PATH["ADD_OUTLETS"] = "/outlets/addoutlets";
    // anchor - for code generator
})(OUTLETS_PATH || (OUTLETS_PATH = {}));
export var OUTLETS_PARAM;
(function (OUTLETS_PARAM) {
    OUTLETS_PARAM["BBID"] = "business_branch_id";
})(OUTLETS_PARAM || (OUTLETS_PARAM = {}));
export var BUSINESSES_EXEMPTED_FROM_TMS_CHECK = [
    BUSINESS_TAG.WANG,
];

import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { FILTER_KEY, } from "../../../../components/context/qc/types";
import { TAB_ID } from "../../constants";
import { mockedImageUpdateJobs } from "../../image-update-jobs/__mocks__/data";
export var mockBrands = [
    {
        id: "1",
        label: "Brand 1",
    },
    {
        id: "2",
        label: "Brand 2",
    },
    {
        id: "3",
        label: "Brand 3",
    },
];
export var mockLocation = [
    {
        id: "1",
        label: "Location 1",
    },
    {
        id: "2",
        label: "Location 2",
    },
    {
        id: "3",
        label: "Location 3",
    },
    {
        id: "4",
        label: "Location 4",
    },
    {
        id: "5",
        label: "Location 5",
    },
    {
        id: "6",
        label: "Location 6",
    },
    {
        id: "7",
        label: "Location 7",
    },
];
export var mockRestaurants = [
    {
        id: "1",
        label: "Restaurant 1",
    },
    {
        id: "2",
        label: "Restaurant 2",
    },
    {
        id: "3",
        label: "Restaurant 3",
    },
    {
        id: "4",
        label: "Restaurant 4",
    },
];
export var mockedFilters = [
    {
        key: FILTER_KEY.BRAND,
        title: "onlineCatalogs.page.manageMenus.filters.brand.label",
        placeholder: "All",
        label: "Brand",
        onChange: function (selectedItems) { return console.log(selectedItems); },
        selected: ["1"],
        options: [],
        selectionText: "All",
        filterPlaceholder: "onlineCatalogs.page.manageMenus.filters.brand.placeholder",
    },
    {
        key: FILTER_KEY.LOCATION,
        title: "onlineCatalogs.page.manageMenus.filters.location.label",
        placeholder: "",
        label: "Location",
        onChange: function (selectedItems) { return console.log(selectedItems); },
        selected: [],
        options: [],
        selectionText: "All",
        filterPlaceholder: "onlineCatalogs.page.manageMenus.filters.location.placeholder",
    },
    {
        key: FILTER_KEY.RESTAURANT,
        title: "onlineCatalogs.page.manageMenus.filters.restaurants.label",
        placeholder: "",
        label: "Restaurants",
        onChange: function (selectedItems) { return console.log(selectedItems); },
        selected: [],
        options: [],
        selectionText: "All",
        filterPlaceholder: "onlineCatalogs.page.manageMenus.filters.restaurant.placeholder",
    },
    {
        key: FILTER_KEY.ACTIVITY,
        title: "onlineCatalogs.page.manageMenus.filters.activity.label",
        placeholder: "",
        label: "Online Activity",
        onChange: function (selectedItems) { return console.log(selectedItems); },
        selected: [],
        options: [
            {
                id: "1",
                label: "Online",
            },
            {
                id: "2",
                label: "Offline",
            },
        ],
        selectionText: "All",
        filterPlaceholder: "onlineCatalogs.page.manageMenus.filters.activity.placeholder",
    },
    {
        key: FILTER_KEY.JOB_ID,
        title: "onlineCatalogs.page.manageMenus.filters.job_id.label",
        placeholder: "",
        label: "Job ID",
        onChange: function (selectedItems) { return console.log(selectedItems); },
        selected: [],
        options: [],
        selectionText: "All",
        filterPlaceholder: "onlineCatalogs.page.manageMenus.filters.job_id.placeholder",
    },
    {
        key: FILTER_KEY.CATEGORY,
        title: "onlineCatalogs.page.manageMenus.filters.category.label",
        placeholder: "",
        label: "Category",
        onChange: function (selectedItems) { return console.log(selectedItems); },
        selected: [],
        options: [],
        selectionText: "All",
        filterPlaceholder: "onlineCatalogs.page.manageMenus.filters.category.placeholder",
    },
];
export var mockedSingleSelectFilters = [
    {
        key: FILTER_KEY.BRAND,
        title: "onlineCatalogs.page.manageMenus.filters.brand.label",
        label: "Brand",
        onChange: function (selectedItem) { return console.log(selectedItem); },
        selected: "",
        options: [],
        filterPlaceholder: "onlineCatalogs.page.manageMenus.filters.brand.placeholder",
    },
    {
        key: FILTER_KEY.LOCATION,
        title: "onlineCatalogs.page.manageMenus.filters.location.label",
        label: "Location",
        onChange: function (selectedItem) { return console.log(selectedItem); },
        options: [],
        selected: "",
        filterPlaceholder: "onlineCatalogs.page.manageMenus.filters.location.placeholder",
    },
];
export var mockActiveTime = {
    sunday: {
        closed: false,
        timings: [{ openTime: "11:00", closeTime: "11:30" }],
        is24Hours: false,
    },
    monday: {
        closed: false,
        timings: [{ openTime: "11:00", closeTime: "11:30" }],
        is24Hours: false,
    },
    tuesday: {
        closed: false,
        timings: [{ openTime: "11:00", closeTime: "11:30" }],
        is24Hours: false,
    },
    wednesday: {
        closed: false,
        timings: [{ openTime: "11:00", closeTime: "11:30" }],
        is24Hours: false,
    },
    thursday: {
        closed: false,
        timings: [{ openTime: "11:00", closeTime: "11:30" }],
        is24Hours: false,
    },
    friday: {
        closed: false,
        timings: [{ openTime: "11:00", closeTime: "11:30" }],
        is24Hours: false,
    },
    saturday: {
        closed: false,
        timings: [{ openTime: "11:00", closeTime: "11:30" }],
        is24Hours: false,
    },
};
export var mockActiveTimeArray = [
    { day: "sunday", closed: true, is24Hours: false, timings: [] },
    { day: "monday", closed: true, is24Hours: false, timings: [] },
    { day: "tuesday", closed: true, is24Hours: false, timings: [] },
    { day: "wednesday", closed: true, is24Hours: false, timings: [] },
    { day: "thursday", closed: true, is24Hours: false, timings: [] },
    { day: "friday", closed: true, is24Hours: false, timings: [] },
    { day: "saturday", closed: true, is24Hours: false, timings: [] },
];
export var mockActiveTimeEmpty = {
    sunday: {
        closed: true,
        timings: [],
        is24Hours: false,
    },
    monday: {
        closed: true,
        timings: [],
        is24Hours: false,
    },
    tuesday: {
        closed: true,
        timings: [],
        is24Hours: false,
    },
    wednesday: {
        closed: true,
        timings: [],
        is24Hours: false,
    },
    thursday: {
        closed: true,
        timings: [],
        is24Hours: false,
    },
    friday: {
        closed: true,
        timings: [],
        is24Hours: false,
    },
    saturday: {
        closed: true,
        timings: [],
        is24Hours: false,
    },
};
export var menusTableMockedData = [
    {
        id: 1,
        brand: { name: "MacDonalds", id: 1 },
        location: { name: "", id: 1 },
        restaurant: { name: "", id: 1, containerFee: "200" },
        name: "Breakfast",
        activeTime: mockActiveTime,
        online: true,
    },
    {
        id: 2,
        brand: { name: "MacDonalds", id: 2 },
        location: { name: "Denver", id: 2 },
        restaurant: { name: "", id: 2, containerFee: "200" },
        name: "Breakfast",
        activeTime: mockActiveTime,
        online: false,
    },
];
export var mockedPlatforms = [
    {
        id: "1",
        name: "Kopitiam",
        fulfillmentMethod: ["dine-in", "self_collect", "having_here"],
    },
    {
        id: "2",
        name: "Non Kopitiam",
        fulfillmentMethod: ["dine-in", "self_collect", "having_here"],
    },
];
export var mockedMenu = {
    id: 1,
    brand: { name: "MacDonalds", id: 1 },
    location: { name: "", id: 1 },
    restaurant: { name: "", id: 1, containerFee: "200" },
    name: "Breakfast",
    fees: {
        containerFee: "10",
        isDefault: true,
    },
    activeTime: {
        friday: {
            closed: false,
            is24Hours: false,
            timings: [{ openTime: "10:00:00", closeTime: "18:00:00" }],
        },
    },
    selectedPlatforms: mockedPlatforms,
    platforms: mockedPlatforms,
    online: true,
    createdAt: "2022-02-22T00:25:49.164Z",
    updatedAt: "2022-02-22T00:25:49.164Z",
};
export var mockTimings = [
    {
        openTime: "08:00",
        closeTime: "10:00",
    },
];
export var imageUpdateJobsMockedData = {
    pagedItems: mockedImageUpdateJobs,
    items: mockedImageUpdateJobs,
    search: "",
};
export var menusTableColumns = [
    "onlineCatalogs.page.manageMenus.table.column.number",
    "onlineCatalogs.page.manageMenus.filters.brand.label",
    "onlineCatalogs.page.manageMenus.filters.location.label",
    "onlineCatalogs.page.manageMenus.filters.restaurants.label",
    "onlineCatalogs.page.manageMenus.table.column.menu",
    "onlineCatalogs.page.manageMenus.table.column.time",
    "onlineCatalogs.page.manageMenus.filters.activity.label",
];
export var mockedContextState = {
    selections: {
        brands: [],
        restaurants: [],
        locations: [],
    },
    filters: {
        brands: ["1"],
        restaurants: ["1"],
        locations: ["1"],
        activities: ["1"],
    },
    search: "test",
    items: menusTableMockedData,
    pagedItems: menusTableMockedData,
    defaultItems: menusTableMockedData,
    imageUpdateJobs: imageUpdateJobsMockedData,
};
export var mockedFoodSuppliers = [
    {
        brand_id: 2,
        name: "test",
        id: 1,
        deleted_at: null,
        location: { name: "test", id: 1, deleted_at: null },
        brand: { name: "test", id: 1, deleted_at: null },
    },
    {
        brand_id: 3,
        name: "test2",
        id: 2,
        location: { name: "test", id: 21 },
        brand: { name: "test", id: 11 },
    },
];
export var mockedQueryBrandNonKopitiam = [
    {
        food_suppliers: [
            {
                brand_id: 1,
                name: "test1",
                id: 1,
                location: {
                    name: "location test 1",
                    id: 1,
                },
                brand: {
                    name: "brand 1",
                    id: 1,
                },
            },
            {
                brand_id: 2,
                name: "test2",
                id: 2,
                location: {
                    name: "location test 2",
                    id: 2,
                },
                brand: {
                    name: "brand 2",
                    id: 2,
                },
            },
        ],
    },
];
export var mockedQueryMenu = [
    {
        food_supplier: mockedFoodSuppliers[0],
        name: "test",
        id: 2,
        available: false,
        active_hours: {},
    },
];
export var mockedBrands = [
    {
        id: 2,
        name: "test brand",
    },
];
export var mockedDuplicatedNameId = [
    {
        id: 1,
        name: "1",
    },
    {
        id: 2,
        name: "2",
    },
    {
        id: 2,
        name: "2",
    },
    {
        id: 3,
        name: "",
    },
];
export var mockedMenuItems = [
    {
        category: { id: 1, name: "Test Category" },
        id: 1,
        name: "Menu Item 1",
        fee: 10,
        price: 200,
        description: "description 1",
        customisationPrice: 200,
        customisations: [
            { id: 1, name: "Customisation 1" },
            { id: 2, name: "Customisation 2" },
        ],
        available: true,
        hidden: true,
        menuItemImageCount: 1,
        heroBannerImageCount: 1,
    },
    {
        category: { id: 2, name: "Test Category 2" },
        id: 2,
        name: "Menu Item 2",
        fee: 11,
        price: 140,
        description: "description 2",
        customisations: [{ id: 3, name: "Customisation 3" }],
        available: false,
        hidden: false,
        customisationPrice: 200,
        menuItemImageCount: 0,
        heroBannerImageCount: 0,
    },
];
export var mockedMenuItemsFull = [
    {
        category: { id: 1, name: "Test Category" },
        id: 1,
        name: "Menu Item 1",
        nameCn: "Menu Item 1 CN",
        fee: 10,
        price: 200,
        customisations: [
            {
                id: 1,
                name: "",
                max: 1,
                min: 0,
                options: [],
                price: 100,
                available: true,
                customisation_price: 100,
            },
        ],
        available: true,
        description: "desc",
        createdAt: "",
        updatedAt: "",
        detailImage: { id: 1, url: "test.png" },
        listingImage: { id: 2, url: "testThumb.png" },
        activeTime: {},
        promotionalItem: true,
        fsId: 1,
        hidden: false,
        customisationPrice: 200,
    },
];
export var mockedMenuItemWithCustomisation = {
    category: { id: 1, name: "Test Category" },
    id: 1,
    name: "Cappuccino",
    fee: 10,
    price: 200,
    customisationPrice: 200,
    customisations: [
        {
            id: 1,
            name: "Amount of Sugar",
            max: 1,
            min: 0,
            options: [
                { name: "0%", id: 15 },
                { name: "20%", id: 16 },
            ],
            price: 10,
            available: true,
            customisation_price: 100,
        },
        {
            id: 2,
            name: "Amount of Milk Foam",
            max: 1,
            min: 0,
            options: [
                { name: "Less Milk Foam", id: 17 },
                { name: "No Milk Foam", id: 18 },
            ],
            price: 100,
            available: true,
            customisation_price: 100,
        },
    ],
    available: true,
    description: "desc",
    createdAt: "",
    updatedAt: "",
    detailImage: { id: 1, url: "test.png" },
    listingImage: { id: 2, url: "testThumb.png" },
    fsId: 1,
    hidden: false,
};
export var mockedQueryMenuItemsFull = [
    {
        available: true,
        fees: {
            containerFee: 10,
        },
        food_supplier_category: { id: 1, name: "Test Category" },
        id: 1,
        name: "Menu Item 1",
        name_cn: "Menu Item 1 CN",
        price: 200,
        created_at: "",
        updated_at: "",
        description: "desc",
        food_supplier_menu_item_customisations_all: [
            {
                food_supplier_customisation: {
                    id: 1,
                    name: "",
                    max_permitted: 1,
                    min_permitted: 0,
                    food_supplier_customisation_options: [],
                    base_price: 100,
                    available: true,
                    customisation_price: 100,
                },
            },
        ],
        food_supplier_menu_item_customisations: [
            {
                food_supplier_customisation: {
                    id: 1,
                    name: "",
                    max_permitted: 1,
                    min_permitted: 0,
                    food_supplier_customisation_options: [],
                    base_price: 100,
                    available: true,
                    customisation_price: 100,
                },
            },
        ],
        food_supplier_menu_item_images: [{ image_id: 1 }],
        food_supplier_menu_item_hero_banner_images: [{ image_id: 2 }],
        active_hours: {},
        promotional_item: true,
        food_supplier_menu_item_fst_mapping: { id: 1 },
        hidden: false,
        customisation_price: 200,
    },
];
export var mockedMenuCategories = [
    {
        id: 1,
        name: "Hot Drink",
        menuItems: [],
        available: true,
        createdAt: "2022-02-22T00:25:49.164Z",
        updatedAt: "2022-02-22T00:25:49.164Z",
    },
    {
        id: 2,
        name: "Value Set",
        menuItems: [],
        available: false,
        createdAt: "",
        updatedAt: "",
    },
    {
        id: 3,
        name: "",
        menuItems: [],
        available: false,
        createdAt: "",
        updatedAt: "",
    },
];
export var mockedIMenuItemFormData = {
    name: "test",
    fee: "20",
    price: "10",
    customisationPrice: "10",
    detailImage: "url",
    listingImage: "image-url",
    promotionalItem: true,
    fsId: "123",
    available: true,
    hidden: false,
    sapMaterialCode: "123",
    categoryId: 1,
};
export var mockedQueryMenuItems = [
    {
        available: true,
        fees: {
            containerFee: 22,
        },
        food_supplier_category: { id: 1, name: "test category" },
        id: 1,
        name: "test menu item",
        price: 200,
        description: "description 1",
        food_supplier_menu_item_customisations: [
            { food_supplier_customisation: { id: 1, name: "test customisation" } },
        ],
        food_supplier_menu_item_fst_mapping: { id: 1 },
        hidden: true,
        customisation_price: 200,
        food_supplier_menu_item_images_aggregate: {
            aggregate: {
                count: 1,
            },
        },
    },
    {
        available: false,
        fees: {},
        food_supplier_category: { id: 2, name: "test category2" },
        id: 1,
        name: "test menu item2",
        price: 40,
        description: "description 2",
        food_supplier_menu_item_customisations: [
            { food_supplier_customisation: { id: 2, name: "test customisation2" } },
            { food_supplier_customisation: { id: 3, name: "test customisation3" } },
        ],
        food_supplier_menu_item_fst_mapping: { id: 2 },
        hidden: false,
        customisation_price: 200,
    },
];
export var mockedQueryMenuCategories = [
    {
        id: 1,
        name: "test",
        available: true,
        created_at: "",
        updated_at: "",
    },
    {
        id: 2,
        name: "test 2",
        available: false,
        created_at: "",
        updated_at: "",
    },
    {
        id: 3,
        name: "test 3",
        available: true,
        created_at: "",
        updated_at: "",
    },
    {
        id: 4,
        name: "test 4",
        available: true,
        created_at: "",
        updated_at: "",
    },
];
export var mockMenuCategoriesOptions = [
    {
        id: "1",
        label: "Category 1",
    },
    {
        id: "2",
        label: "Category 2",
    },
    {
        id: "3",
        label: "Category 3",
    },
    {
        id: "4",
        label: "Category 4",
    },
];
export var mockMenuCategoriesOptionWithLocation = [
    {
        id: "1",
        label: "Category 1",
        location: "Location 1",
    },
    {
        id: "2",
        label: "Category 2",
        location: "Location 2",
    },
    {
        id: "3",
        label: "Category 3",
        location: "Location 3",
    },
    {
        id: "4",
        label: "Category 4",
        location: "Location 4",
    },
];
export var mockTimeslotData = {
    sunday: {
        closed: false,
        timings: [{ openTime: "11:00", closeTime: "11:30" }],
        is24Hours: false,
    },
    monday: {
        closed: false,
        timings: [{ openTime: "11:00", closeTime: "11:30" }],
        is24Hours: false,
    },
    tuesday: {
        closed: false,
        timings: [{ openTime: "11:00", closeTime: "11:30" }],
        is24Hours: false,
    },
    wednesday: {
        closed: false,
        timings: [{ openTime: "11:00", closeTime: "11:30" }],
        is24Hours: false,
    },
    thursday: {
        closed: false,
        timings: [{ openTime: "11:00", closeTime: "11:30" }],
        is24Hours: false,
    },
    friday: {
        closed: false,
        timings: [{ openTime: "11:00", closeTime: "11:30" }],
        is24Hours: false,
    },
    saturday: {
        closed: false,
        timings: [{ openTime: "11:00", closeTime: "11:30" }],
        is24Hours: false,
    },
};
export var mockDayData = [
    {
        closed: false,
        timings: [{ openTime: "11:00", closeTime: "14:00" }],
        is24Hours: false,
    },
    {
        closed: true,
        timings: [{ openTime: "11:00", closeTime: "14:00" }],
        is24Hours: false,
    },
];
export var mockTimeslotDatePcker = [
    {
        id: "1",
        data: {
            openTime: "11:00",
            closeTime: "14:00",
        },
        isInvalid: true,
    },
    {
        id: "2",
        data: {
            openTime: "11:00",
            closeTime: "14:00",
        },
        isInvalid: false,
    },
];
export var mockedTabData = [
    {
        id: TAB_ID.ABOUT,
        name: "About",
        content: _jsx(_Fragment, {}),
    },
    {
        id: TAB_ID.CATEGORIES,
        name: "Categories",
        content: _jsx(_Fragment, {}),
    },
    {
        id: TAB_ID.MENU_ITEMS,
        name: "MenuItems",
        content: _jsx(_Fragment, {}),
    },
];
export var mockMenuData = {
    name: "test",
    available: false,
    foodSupplier: { id: mockedFoodSuppliers[0].id },
    activeHours: mockActiveTime,
    fees: {
        containerFee: 10,
        isDefault: true,
    },
};
export var mockOnlineLocalAddMenu = {
    name: "test",
    brand: mockBrands,
    location: ["1"],
    restaurant: ["1"],
    activeTime: mockActiveTime,
};
export var mockFulfilmentValues = {
    "dine-in": { value: "Value 1" },
    self_collect: { value: "Value 2" },
    having_here: { value: "Value 3" },
};
export var mockEditMenuFullfilmentMethodData = {
    fulfillmentMethod: ["dine-in", "self_collect", "having_here"],
    platform: mockedPlatforms[0],
};
export var mockFoodSupplierFullfilment = {
    foodSupplierId: 1,
    platformId: "1",
    fsPlatform: {
        fullfilmentMethods: ["dine-in", "self_collect", "having_here"],
    },
};
export var mockRestaurantCustomisations = [
    {
        name: "Test",
        options: [
            { id: 2, name: "test customisation2" },
            { id: 3, name: "test customisation3" },
        ],
        price: 0,
        max: 2,
        min: 1,
        id: 1,
        available: true,
        customisation_price: 100,
    },
];
export var mockNewMenuItem = {
    name: "Test",
    nameCn: "Test CN",
    description: "test item",
    price: 1.05,
    containerFee: 0.5,
    customisationPrice: 0.5,
    detailImg: "",
    listingImg: "",
    categoryId: "1",
    available: true,
    promotionalItem: false,
    fst: {
        id: 1,
        sapMaterialCode: "100",
        price: 100,
        name: "test",
    },
};
export var mockNewMenuItemPayload = {
    available: true,
    categoryId: 1,
    price: 105,
    fees: {
        containerFee: 50,
    },
    customisationPrice: 50,
    name: "Test",
    description: "test item",
    image: {
        url: "",
    },
    heroBannerImage: {
        url: "",
    },
    fst: {
        id: 1,
        sapMaterialCode: "100",
        price: 10000,
        name: "test",
    },
};
export var mockEditMenuItemPayload = {
    available: true,
    categoryId: 1,
    price: 105,
    fees: {
        containerFee: 50,
    },
    name: "Test",
    description: "test item",
    image: {
        url: "",
    },
    heroBannerImage: {
        url: "",
    },
    activeHours: { monday: { timings: [{ openTime: "", closeTime: "" }] } },
};
export var mockedFoodSuppliersMultiSelect = [
    {
        id: "1",
        location: "1",
        label: "1",
    },
    {
        id: "2",
        location: "1",
        label: "",
    },
    {
        id: "3",
        location: "3",
        label: "3",
    },
];
export var mockPriceHistoryData = [
    {
        id: 1,
        key: 1,
        updatedAt: "2022-06-14T07:23:41.259373Z",
        updatedBy: "test",
        request: {
            containerFee: {
                previous: 100,
                new: 200,
            },
        },
    },
    {
        id: 2,
        key: 1,
        updatedAt: "2022-06-14T07:23:41.259373Z",
        updatedBy: "test",
        request: {
            price: {
                previous: 100,
                new: 200,
            },
        },
    },
];
export var mockedFoodSuppliersByBrandId = {
    foodSupplierInfo: {
        fsName: "testMenu",
        menuName: "testFS",
        location: "testLocation",
    },
    foodSuppliersByBrandId: [
        {
            available: true,
            brandName: "brand1",
            foodSupplierId: 1,
            foodSupplierName: "brand1",
            location: "testLoc",
            id: 1,
            name: "brand1",
        },
    ],
};
export var mockedFoodSupplierQueryResponse = {
    foodSupplierMenusByPK: {
        name: "testFS",
        food_supplier: {
            name: "testMenu",
            location: {
                name: "testLocation",
            },
        },
    },
    foodSuppliersArray: [
        {
            available: true,
            name: "brand1",
            id: 1,
            location: {
                name: "testLoc",
                id: 1,
            },
            brand: {
                id: 1,
                name: "brand1",
            },
            location_id: 1,
            brand_id: 1,
        },
    ],
};
export var mockedFoodSupplierByBrandIdAndMenuId = {
    food_supplier_menus_by_pk: {
        name: "testMenu",
        food_supplier: {
            name: "testFS",
            location: {
                name: "testLocation",
            },
        },
    },
    food_suppliers_by_brand_id: [
        {
            available: true,
            name: "test",
            id: 1,
            location: {
                name: "testLoc",
                id: 1,
            },
            brand: {
                id: 1,
                name: "brand1",
            },
            location_id: 1,
            brand_id: 1,
        },
    ],
};
export var mockedFoodSupplierByBrandId = [
    {
        foodSupplierId: 1,
        foodSupplierName: "test1",
        brandName: "testBrand1",
        available: true,
        location: "testLoc1",
        id: 1,
        name: "test1",
    },
    {
        foodSupplierId: 2,
        foodSupplierName: "test2",
        brandName: "testBrand2",
        available: true,
        location: "testLoc2",
        id: 1,
        name: "testLoc2",
    },
];
export var mockedFoodSuppliersAll = [
    {
        id: 1,
        name: "test",
        brand: {
            id: 1,
            name: "brand",
        },
        location: {
            id: 2,
            name: "location1",
        },
        restaurant: {
            id: 1,
            name: "test",
        },
        disabled: true,
    },
    {
        id: 2,
        name: "test2",
        brand: {
            id: 4,
            name: "brand2",
        },
        location: {
            id: 5,
            name: "location2",
        },
        restaurant: {
            id: 2,
            name: "test2",
        },
        disabled: true,
    },
];

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { LANG } from "../../layout/header/types";
export var combineENCNText = function (en, cn) {
    if (!en)
        return cn;
    if (!cn)
        return en;
    return "".concat(en, " ").concat(cn);
};
export var combineENCNMappings = function (json, combine) {
    var _a, _b;
    var cnMapping = (_a = json[LANG.CN]) !== null && _a !== void 0 ? _a : {};
    var enMapping = (_b = json[LANG.EN]) !== null && _b !== void 0 ? _b : {};
    if (!combine)
        return __assign(__assign({}, enMapping), cnMapping);
    var combinedKeys = __assign(__assign({}, cnMapping), enMapping);
    var combinedMapping = {};
    Object.keys(combinedKeys).forEach(function (key) {
        var enValue = enMapping[key] || "";
        var cnValue = cnMapping[key] || "";
        combinedMapping[key] = combineENCNText(enValue, cnValue);
    });
    return combinedMapping;
};

import { useMemo } from "react";
import { useRouteListingsData, useUserPermissions } from "../context/hooks";
import { trimTrailingSlash } from "../routing/helpers";
import { hasRequiredPermission, isSuperAdmin } from "./helpers";
export var useCheckUserHasPermissions = function (permissions) {
    var userpermissions = useUserPermissions();
    return useMemo(function () {
        if (!permissions.length)
            return true;
        return hasRequiredPermission(permissions, userpermissions);
    }, [userpermissions, permissions]);
};
export var useRoutePermission = function (path) {
    var listings = useRouteListingsData();
    var permissions = useUserPermissions();
    return useMemo(function () {
        if (isSuperAdmin(permissions))
            return true;
        var trimmedPath = trimTrailingSlash(path);
        var matchedRoutes = listings.filter(function (_a) {
            var route = _a.route;
            return route === trimmedPath;
        });
        if (matchedRoutes.length) {
            var route_allowed_permissions_1 = matchedRoutes[0].permissions;
            if (!route_allowed_permissions_1.length)
                return true;
            return (permissions.filter(function (permission) { return route_allowed_permissions_1.indexOf(permission) >= 0; }).length > 0);
        }
        return true;
    }, [permissions, listings, path]);
};

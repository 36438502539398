var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { randString } from "../../../../common/stringutils";
import { createNewKPOSData } from "../../add/context/hooks/helpers";
export var getKPOSAssignmentData = function (kpos) {
    return kpos !== null && kpos !== void 0 ? kpos : createNewKPOSData();
};
export var convertRawToData = function (raw) {
    var _a;
    var _b = (_a = raw.qcAssignments) !== null && _a !== void 0 ? _a : {}, _c = _b.brands, brands = _c === void 0 ? [] : _c, _d = _b.locations, locations = _d === void 0 ? [] : _d;
    return {
        name: raw.name,
        email: raw.email,
        platform_access: raw.platform_access,
        contact_no: raw.contact_no,
        user_type: raw.user_type,
        fs_assignments: __assign({}, raw.fs_assignments),
        qcAssignments: {
            brands: brands,
            locations: locations,
        },
        roles: raw.roles.map(function (_a) {
            var id = _a.id;
            return parseInt(id, 10);
        }),
        kpos: raw.kpos
            ? __assign(__assign({}, raw.kpos), { confirmpasscode: raw.kpos.passcode, assignments: raw.kpos.assignments.map(function (assign) { return (__assign(__assign({}, assign), { business_branch_ids: assign.business_branch_ids.map(function (id) { return id; }), location_ids: assign.location_ids.map(function (id) { return id; }), roles: assign.roles.map(function (id) { return id; }), ident: randString(23) })); }) }) : createNewKPOSData(),
        meta: {
            jira: "",
        },
    };
};
var getGroupKey = function (roles, locations) {
    var sorter = function (a, b) { return a - b; };
    roles.sort(sorter);
    locations.sort(sorter);
    return "".concat(roles.join(","), "-").concat(locations.join(","));
};
export var groupKPOSAssignments = function (assignments) {
    if (!(assignments === null || assignments === void 0 ? void 0 : assignments.length))
        return [];
    var grouping = {};
    assignments.forEach(function (_a) {
        var roles = _a.roles, location_ids = _a.location_ids, business_branch_ids = _a.business_branch_ids;
        var key = getGroupKey(roles, location_ids);
        if (grouping[key]) {
            grouping[key].business_branch_ids = __spreadArray(__spreadArray([], grouping[key].business_branch_ids, true), business_branch_ids, true);
        }
        else {
            grouping[key] = {
                ident: randString(23),
                roles: roles,
                location_ids: location_ids,
                business_branch_ids: business_branch_ids,
            };
        }
    });
    return Object.keys(grouping).map(function (key) { return grouping[key]; });
};

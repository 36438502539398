var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { lazy } from "react";
import { MENU_GROUP, PLATFORM } from "../../../common/constants";
import { globalEvents } from "../../../common/GlobalEvents";
import { PERMISSION } from "../../../components/rbac/permissions";
import { ROUTE_EVENTS } from "../../../components/routing/constants";
import { MASTER_CATALOGUE_MANAGE } from "./cataloguepermissions";
import { CATEGORIES_PATH } from "./constants";
/* istanbul ignore next */ // no need to test lazy loading
var LazyComponents = {
    PlaceHolderPage: lazy(function () { return import(/* webpackChunkName: "placeholderpage" */ "./PlaceHolderPage"); }),
    ViewMasterCategories: lazy(function () {
        return import(
        /* webpackChunkName: "fs-viewmastercategories" */ "./mastercategories/view/ViewMasterCategories");
    }),
    AddMasterCategory: lazy(function () {
        return import(
        /* webpackChunkName: "fs-addmastercategories" */ "./mastercategories/add/AddMasterCategory");
    }),
    EditMasterCategory: lazy(function () {
        return import(
        /* webpackChunkName: "fs-editmastercategories" */ "./mastercategories/edit/EditMasterCategory");
    }),
    ExportMasterCategories: lazy(function () {
        return import(
        /* webpackChunkName: "fs-exportmastercategories" */ "./mastercategories/export/ExportMasterCategories");
    }),
    ViewMasterMenuItems: lazy(function () {
        return import(
        /* webpackChunkName: "fs-viewmastermenuitems" */ "./mastermenuitems/view/ViewMasterMenuItems");
    }),
    AddMasterMenuItem: lazy(function () {
        return import(
        /* webpackChunkName: "fs-addmastermenuitem" */ "./mastermenuitems/add/AddMasterMenuItem");
    }),
    EditMasterMenuItem: lazy(function () {
        return import(
        /* webpackChunkName: "fs-editmastermenuitem" */ "./mastermenuitems/edit/EditMasterMenuItem");
    }),
    EditLocalPricingInBulk: lazy(function () {
        return import(
        /* webpackChunkName: "fs-editlocalpricinginbulk" */ "./mastermenuitems/editpricing/EditLocalPricingInBulk");
    }),
    ExportMasterMenuItems: lazy(function () {
        return import(
        /* webpackChunkName: "fs-exportmastermenuitems" */ "./mastermenuitems/export/ExportMasterMenuItems");
    }),
    ViewLocalMenus: lazy(function () {
        return import(
        /* webpackChunkName: "fs-viewlocalmenus" */ "./localmenus/view/ViewLocalMenus");
    }),
    LocalMenuDetails: lazy(function () {
        return import(
        /* webpackChunkName: "fs-localmenu" */ "./localmenus/edit/LocalMenuDetails");
    }),
    AddLocalMenu: lazy(function () {
        return import(
        /* webpackChunkName: "fs-addlocalmenu" */ "./localmenus/add/AddNewMenu");
    }),
    LocalCategory: lazy(function () {
        return import(
        /* webpackChunkName: "fs-localcategory" */ "./localcategory/view/LocalCategory");
    }),
    LocalMenuItem: lazy(function () {
        return import(
        /* webpackChunkName: "fs-localmenuitem" */ "./localmenuitem/LocalMenuItem");
    }),
    MasterCustomizationsList: lazy(function () {
        return import(
        /* webpackChunkName: "fs-mastercustomizationslist" */ "./MasterCustomizationsList/MasterCustomizationsList");
    }),
    MasterCustomizationDetail: lazy(function () {
        return import(
        /* webpackChunkName: "fs-mastercustomizationdetail" */ "./MasterCustomizationDetail/MasterCustomizationDetail");
    }),
    AddMasterCustomization: lazy(function () {
        return import(
        /* webpackChunkName: "fs-addmastercustomization" */ "./AddMasterCustomization/AddMasterCustomization");
    }),
    // anchor - for code generator
};
var PLATFORM_ACCESS = [PLATFORM.ADMIN_PANEL];
/* istanbul ignore next */ // no need to test routes
export var catalogueRoutes = [
    {
        label: "catalogs.menu.label",
        route: "",
        permissions: [PERMISSION.FS_CATALOGUE_VIEW, PERMISSION.FS_CATALOGUE_MANAGE],
        icon: "/assets/icons/CatalogIcon.svg",
        platforms: PLATFORM_ACCESS,
        group: MENU_GROUP.FOOD_SERVICES,
        subRoutes: [
            {
                label: "catalogs.menu.item.mastercategories",
                route: CATEGORIES_PATH.MASTER_CATEGORIES,
                component: LazyComponents.ViewMasterCategories,
                permissions: __spreadArray([
                    PERMISSION.FS_CATALOGUE_VIEW,
                    PERMISSION.FS_CATALOGUE_MANAGE
                ], MASTER_CATALOGUE_MANAGE, true),
                platforms: PLATFORM_ACCESS,
            },
            {
                label: "",
                route: CATEGORIES_PATH.ADD_MASTER_CATEGORIES,
                component: LazyComponents.AddMasterCategory,
                permissions: MASTER_CATALOGUE_MANAGE,
                hidden: true,
                platforms: PLATFORM_ACCESS,
            },
            {
                label: "",
                route: CATEGORIES_PATH.EDIT_MASTER_CATEGORIES,
                component: LazyComponents.EditMasterCategory,
                permissions: __spreadArray([
                    PERMISSION.FS_CATALOGUE_MANAGE
                ], MASTER_CATALOGUE_MANAGE, true),
                hidden: true,
                platforms: PLATFORM_ACCESS,
            },
            {
                label: "",
                route: CATEGORIES_PATH.EXPORT_MASTER_CATEGORIES,
                component: LazyComponents.ExportMasterCategories,
                permissions: [PERMISSION.FS_CATALOGUE_MANAGE],
                hidden: true,
                platforms: [PLATFORM.ADMIN_PANEL],
            },
            {
                label: "catalogs.menu.item.mastermenuitems",
                route: CATEGORIES_PATH.MENU_ITEMS,
                component: LazyComponents.ViewMasterMenuItems,
                permissions: __spreadArray([
                    PERMISSION.FS_CATALOGUE_VIEW,
                    PERMISSION.FS_CATALOGUE_MANAGE
                ], MASTER_CATALOGUE_MANAGE, true),
                platforms: PLATFORM_ACCESS,
            },
            {
                label: "",
                route: CATEGORIES_PATH.ADD_MENU_ITEM,
                component: LazyComponents.AddMasterMenuItem,
                permissions: MASTER_CATALOGUE_MANAGE,
                hidden: true,
                platforms: PLATFORM_ACCESS,
            },
            {
                label: "",
                route: CATEGORIES_PATH.EDIT_MENU_ITEM,
                component: LazyComponents.EditMasterMenuItem,
                permissions: __spreadArray([
                    PERMISSION.FS_CATALOGUE_MANAGE,
                    PERMISSION.FS_CATALOGUE_VIEW
                ], MASTER_CATALOGUE_MANAGE, true),
                hidden: true,
                platforms: PLATFORM_ACCESS,
            },
            {
                label: "",
                route: CATEGORIES_PATH.EXPORT_MASTER_MENU_ITEMS,
                component: LazyComponents.ExportMasterMenuItems,
                permissions: [PERMISSION.FS_CATALOGUE_MANAGE],
                hidden: true,
                platforms: PLATFORM_ACCESS,
            },
            {
                label: "",
                route: CATEGORIES_PATH.EDIT_LOCAL_PRICING_IN_BULK,
                component: LazyComponents.EditLocalPricingInBulk,
                permissions: [PERMISSION.FS_CATALOGUE_MANAGE],
                hidden: true,
                platforms: PLATFORM_ACCESS,
            },
            {
                label: "catalogs.menu.item.mastercustomizations",
                route: CATEGORIES_PATH.MASTER_CATEGORIES_CUSTOMIZATIONS,
                component: LazyComponents.MasterCustomizationsList,
                permissions: [PERMISSION.FS_CATALOGUE_VIEW],
                platforms: PLATFORM_ACCESS,
            },
            {
                label: "catalogs.menu.item.mastercustomizations",
                route: CATEGORIES_PATH.MASTER_CATEGORIES_CUSTOMIZATIONS_DETAIL,
                component: LazyComponents.MasterCustomizationDetail,
                permissions: [PERMISSION.FS_CATALOGUE_VIEW],
                hidden: true,
                platforms: PLATFORM_ACCESS,
            },
            {
                label: "catalogs.menu.item.addnewmastermenuitem",
                route: CATEGORIES_PATH.MASTER_CATEGORIES_CUSTOMIZATIONS_ADD,
                component: LazyComponents.AddMasterCustomization,
                permissions: [PERMISSION.FS_CATALOGUE_VIEW],
                hidden: true,
                platforms: PLATFORM_ACCESS,
            },
            {
                label: "catalogs.menu.item.masterlocalcatalog",
                route: CATEGORIES_PATH.MANAGE_LOCAL_CATALOGUE,
                component: LazyComponents.ViewLocalMenus,
                permissions: [
                    PERMISSION.FS_CATALOGUE_VIEW,
                    PERMISSION.FS_CATALOGUE_MANAGE,
                ],
                platforms: PLATFORM_ACCESS,
            },
            {
                label: "",
                route: CATEGORIES_PATH.EDIT_LOCAL_CATALOGUE,
                component: LazyComponents.LocalMenuDetails,
                permissions: [
                    PERMISSION.FS_CATALOGUE_MANAGE,
                    PERMISSION.FS_CATALOGUE_VIEW,
                ],
                hidden: true,
                platforms: PLATFORM_ACCESS,
            },
            {
                label: "",
                route: CATEGORIES_PATH.ADD_LOCAL_CATALOGUE,
                component: LazyComponents.AddLocalMenu,
                permissions: [PERMISSION.FS_CATALOGUE_MANAGE],
                hidden: true,
                platforms: PLATFORM_ACCESS,
            },
            {
                label: "",
                route: CATEGORIES_PATH.MANAGE_LOCAL_CATEGORY,
                component: LazyComponents.LocalCategory,
                permissions: [
                    PERMISSION.FS_CATALOGUE_MANAGE,
                    PERMISSION.FS_CATALOGUE_VIEW,
                ],
                hidden: true,
                platforms: PLATFORM_ACCESS,
            },
            {
                label: "",
                route: CATEGORIES_PATH.MANAGE_LOCAL_MENU_ITEM,
                component: LazyComponents.LocalMenuItem,
                permissions: [
                    PERMISSION.FS_CATALOGUE_MANAGE,
                    PERMISSION.FS_CATALOGUE_VIEW,
                ],
                hidden: true,
                platforms: PLATFORM_ACCESS,
            },
            {
                label: "catalogs.menu.item.masterlocalcustomizations",
                route: CATEGORIES_PATH.MANAGE_LOCAL_CUSTOMIZATIONS,
                component: LazyComponents.PlaceHolderPage,
                permissions: [
                    PERMISSION.FS_CATALOGUE_VIEW,
                    PERMISSION.FS_CATALOGUE_MANAGE,
                    PERMISSION.ALPHA,
                ],
                platforms: PLATFORM_ACCESS,
            },
            // anchor - for code generator
        ],
    },
];
export var initFSCatalogue = function () {
    globalEvents.emit(ROUTE_EVENTS.REGISTER, catalogueRoutes);
};

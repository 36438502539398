import { lazy } from "react";
import { MENU_GROUP, PLATFORM } from "../../common/constants";
import { globalEvents } from "../../common/GlobalEvents";
import { PERMISSION } from "../../components/rbac/permissions";
import { ROUTE_EVENTS } from "../../components/routing/constants";
import { USERMANAGEMENT_PATH } from "./constants";
/* istanbul ignore next */ // no need to test lazy loading
var LazyComponents = {
    UserList: lazy(function () { return import(/* webpackChunkName: "userlist" */ "./userlist/UserList"); }),
    UserAdd: lazy(function () { return import(/* webpackChunkName: "useradd" */ "./add/AddNewUser"); }),
    UserEdit: lazy(function () { return import(/* webpackChunkName: "useredit" */ "./edit/EditUser"); }),
};
var PLATFORM_ACCESS = [PLATFORM.ADMIN_PANEL];
/* istanbul ignore next */ // no need to test route config
export var userManagementRoutes = [
    {
        label: "usermanage.menu.label",
        route: "",
        permissions: [],
        icon: "users",
        platforms: PLATFORM_ACCESS,
        group: MENU_GROUP.USER_MANAGEMENT,
        subRoutes: [
            {
                label: "usermanage.menu.item.userlist",
                route: USERMANAGEMENT_PATH.USER_LIST,
                component: LazyComponents.UserList,
                permissions: [
                    PERMISSION.USER_MANAGEMENT,
                    PERMISSION.USER_VIEW,
                    PERMISSION.TENANT_USER_VIEW,
                    PERMISSION.TENANT_USER_MANAGEMENT,
                ],
                platforms: PLATFORM_ACCESS,
            },
            {
                label: "usermanage.userlist.addnewuser",
                route: USERMANAGEMENT_PATH.USER_ADD,
                component: LazyComponents.UserAdd,
                permissions: [
                    PERMISSION.USER_MANAGEMENT,
                    PERMISSION.TENANT_USER_MANAGEMENT,
                ],
                platforms: PLATFORM_ACCESS,
                hidden: true,
            },
            {
                label: "usermanage.userlist.edituser",
                route: USERMANAGEMENT_PATH.USER_EDIT,
                component: LazyComponents.UserEdit,
                permissions: [
                    PERMISSION.USER_MANAGEMENT,
                    PERMISSION.USER_VIEW,
                    PERMISSION.TENANT_USER_VIEW,
                    PERMISSION.TENANT_USER_MANAGEMENT,
                ],
                platforms: PLATFORM_ACCESS,
                hidden: true,
            },
        ],
    },
];
export var initUserManagement = function () {
    globalEvents.emit(ROUTE_EVENTS.REGISTER, userManagementRoutes);
};

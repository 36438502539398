import { jsx as _jsx } from "react/jsx-runtime";
import { COLORS } from "../../../common/constants";
import { globalEvents } from "../../../common/GlobalEvents";
import { ALERT_EVENT, ALERT_TYPE, } from "../../../components/modals/alerts/constants";
import ModalStaticContent from "./modal/ModalStaticContent";
export var announceSimpleStaticContentModal = function (id) {
    globalEvents.emit(ALERT_EVENT.SHOW, {
        type: ALERT_TYPE.MESSAGE,
        title: "",
        content: _jsx(ModalStaticContent, { id: id }),
        className: "StaticContent",
        buttons: [
            {
                label: "common.ok",
                type: "text",
                backgroundColor: COLORS.GREENPEA500,
                color: COLORS.SNOWWHITE,
            },
        ],
    });
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { COLORS } from "../../common/constants";
var DEFAULT_SVG_SIZE = 20;
export var HelpIcon = function (_a) {
    var _b = _a.width, width = _b === void 0 ? DEFAULT_SVG_SIZE : _b, _c = _a.height, height = _c === void 0 ? DEFAULT_SVG_SIZE : _c, _d = _a.fillColor, fillColor = _d === void 0 ? COLORS.OFFWHITE : _d, _e = _a.className, className = _e === void 0 ? "" : _e;
    var scaleWidth = width / DEFAULT_SVG_SIZE;
    var scaleHeight = height / DEFAULT_SVG_SIZE;
    return (_jsx("svg", __assign({ className: className, width: width, height: height, viewBox: "0 0 ".concat(width, " ").concat(height), xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", __assign({ fill: fillColor, transform: "scale(".concat(scaleWidth, ",").concat(scaleHeight, ")") }, { children: _jsx("path", { d: "M11.3889 15.2667C11.3889 16.0333 10.8 16.6667 10 16.6667C9.22222 16.6667 8.6 16.0444 8.6 15.2667C8.6 14.4778 9.22222 13.8778 10 13.8778C10.7889 13.8778 11.3889 14.4889 11.3889 15.2667ZM9.98889 3.33333C8.02222 3.33333 6.67778 4.61111 6.17778 6.1L8 6.86667C8.24444 6.12222 8.82222 5.22222 10 5.22222C11.8 5.22222 12.1556 6.91111 11.5222 7.81111C10.9222 8.66667 9.88889 9.24444 9.34444 10.2111C8.91111 10.9778 9 11.8667 9 12.4111H11.0222C11.0222 11.3778 11.1 11.1667 11.2667 10.8444C11.7 10.0444 12.5 9.66667 13.3444 8.43333C14.1 7.32222 13.8111 5.81111 13.3222 5.01111C12.7556 4.07778 11.6333 3.33333 9.98889 3.33333ZM17.7778 2.22222H2.22222V17.7778H17.7778V2.22222ZM17.7778 0C19 0 20 1 20 2.22222V17.7778C20 19 19 20 17.7778 20H2.22222C1 20 0 19 0 17.7778V2.22222C0 1 1 0 2.22222 0H17.7778Z" }) })) })));
};

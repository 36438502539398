var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { EuiHeaderSectionItem, EuiSelect } from "@elastic/eui";
import { PLATFORM } from "../../../../common/constants";
import { usePlatformSelector, usePlatformSelectorText } from "./hooks";
var PLATFORM_VALUES = [PLATFORM.ADMIN_PANEL, PLATFORM.PARTNER_CENTER];
var PlatformSelector = function () {
    var _a = usePlatformSelector(), platform = _a.platform, getPlatform = _a.getPlatform;
    var getText = usePlatformSelectorText(PLATFORM_VALUES).getText;
    return (_jsx(EuiHeaderSectionItem, __assign({ border: "none" }, { children: _jsx(EuiSelect, { options: PLATFORM_VALUES.map(function (value) { return ({
                value: value,
                text: getText("layout.header.platform.".concat(value)),
            }); }), value: platform, onChange: getPlatform }) })));
};
export default PlatformSelector;

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { isAlphaLink, isBetaLink } from "./helpers";
import NavItemTag from "./NavItemTag";
var NavTags = function (_a) {
    var permissions = _a.permissions;
    var alpha = isAlphaLink(permissions);
    var beta = isBetaLink(permissions);
    return (_jsxs(_Fragment, { children: [alpha && _jsx(NavItemTag, { className: "Alpha" }), !alpha && beta && _jsx(NavItemTag, { className: "Beta" })] }));
};
export default NavTags;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import "./styles.scss";
import { hideAlerts } from "../helpers";
import AlertModal from "./AlertModal";
var MessageOnly = function (_a) {
    var param = _a.param;
    return (_jsx(AlertModal, __assign({}, param, { hideModal: hideAlerts, buttons: [], className: "MessageOnly" })));
};
export default MessageOnly;

export var COMPANIES_PATH;
(function (COMPANIES_PATH) {
    COMPANIES_PATH["COMPANY_LISTING"] = "/fs/companieslisting";
    COMPANIES_PATH["COMPANY_DETAILS"] = "/fs/company/details";
    COMPANIES_PATH["NEW_COMPANY"] = "/fs/company/new";
    COMPANIES_PATH["NEW_BRAND_BUSINESS"] = "/fs/company/brand/new";
    COMPANIES_PATH["NEW_BUSINESS"] = "/fs/company/brand/business/new";
    // anchor - for code generator
})(COMPANIES_PATH || (COMPANIES_PATH = {}));
export var COMPANIES_PARAM;
(function (COMPANIES_PARAM) {
    COMPANIES_PARAM["ID"] = "company_id";
    COMPANIES_PARAM["BRAND_ID"] = "brand_id";
})(COMPANIES_PARAM || (COMPANIES_PARAM = {}));
export var ALLOW_COMPANY_WITH_NO_TENANT_ID = true;

import { useCallback, useEffect, useLayoutEffect, useState } from "react";
export function useLocalStorage(key, initialValue) {
    var _a = useState(initialValue), value = _a[0], setValue = _a[1];
    useLayoutEffect(function () {
        var initialValue;
        try {
            var localStorageValue = localStorage.getItem(key);
            initialValue =
                localStorageValue !== null
                    ? parseJSON(localStorageValue)
                    : initialValue;
            setValue(initialValue);
        }
        catch (error) {
            setValue(initialValue);
        }
    }, [key]);
    useEffect(function () {
        var onStorage = function (e) {
            var _a;
            if (e.key === key) {
                var newValue = (_a = e.newValue) !== null && _a !== void 0 ? _a : undefined;
                setValue(newValue);
            }
        };
        window.addEventListener("storage", onStorage);
        return function () {
            window.removeEventListener("storage", onStorage);
        };
    }, [key]);
    var set = useCallback(function (newValue) {
        try {
            setValue(newValue);
            if (typeof newValue === "undefined") {
                localStorage.removeItem(key);
            }
            else {
                localStorage.setItem(key, newValue);
            }
        }
        catch (error) {
            console.error("Set localStorage error");
        }
    }, [key]);
    var remove = useCallback(function () {
        try {
            setValue(undefined);
            localStorage.removeItem(key);
        }
        catch (error) {
            console.error("Remove localStorage error");
        }
    }, [key]);
    return [value, set, remove];
}
var parseJSON = function (value) {
    return !value ? undefined : JSON.parse(value);
};

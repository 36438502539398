var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EuiCollapsibleNavGroup } from "@elastic/eui";
import Bubble from "../../doodads/Bubble";
import I18nText from "../../i18n/I18nText";
import { useIsNavGroupInFocus, useIsNavGroupOpen } from "./hooks";
import { useSideBarNavItemCount } from "./navitemcount/useSideBarNavItemCount";
import styled from "./SideNavGroup.styles";
import SideNavLink from "./SideNavLink";
export var SideNavGroup = function (_a) {
    var _b = _a.className, className = _b === void 0 ? "" : _b, data = _a.data, updateParentCount = _a.updateParentCount;
    var title = data.title, items = data.items;
    var displayItems = (items === null || items === void 0 ? void 0 : items.filter(function (_a) {
        var hidden = _a.hidden;
        return !hidden;
    })) || [];
    var isGrpOpened = useIsNavGroupInFocus(items);
    var _c = useSideBarNavItemCount(title, updateParentCount), totalCount = _c.totalCount, updateNavItemCount = _c.updateNavItemCount;
    var _d = useIsNavGroupOpen(isGrpOpened), isOpen = _d.isOpen, setOpen = _d.setOpen;
    if (!items)
        return _jsx(SideNavLink, { data: data, updateParentCount: updateNavItemCount });
    if (!displayItems.length)
        return null;
    return (_jsxs("div", __assign({ className: "SideNavGroup ".concat(className) }, { children: [_jsx(EuiCollapsibleNavGroup, __assign({ title: _jsx("div", __assign({ className: "SideNavGroupTitle" }, { children: _jsx(I18nText, { token: title }) })), isCollapsible: true, initialIsOpen: isGrpOpened, onToggle: setOpen }, { children: displayItems.map(function (item) { return (_jsx(SideNavGroup, { data: item, updateParentCount: updateNavItemCount }, item.title)); }) })), !isOpen && totalCount > 0 && _jsx(Bubble, { className: "Count NoContent" })] })));
};
export default styled(SideNavGroup);

import { useMemo } from "react";
import { PLATFORM } from "../../../common/constants";
import { BUILD_LOCAL, useBuildVersion } from "../../hooks/useBuildVersion";
export var useIsLocalDevelopment = function () {
    var build = useBuildVersion();
    return useMemo(function () {
        return process.env.PLATFORM_MODE === PLATFORM.DEVELOPMENT &&
            build === BUILD_LOCAL;
    }, [build]);
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useContext, useEffect, useMemo } from "react";
import { DictionaryContext } from "../constants";
import { DICTIONARY_KEY } from "../types";
import { mergeCBB } from "./helpers";
export var useConstructCBB = function () {
    var _a = useContext(DictionaryContext), _b = _a[0].raw, businesses = _b.businesses, brands = _b.brands, companies = _b.companies, setContext = _a[1];
    var cbbData = useMemo(function () {
        if (!(businesses === null || businesses === void 0 ? void 0 : businesses.length))
            return [];
        if (!(companies === null || companies === void 0 ? void 0 : companies.length))
            return [];
        if (!(brands === null || brands === void 0 ? void 0 : brands.length))
            return [];
        return mergeCBB(companies, brands, businesses);
    }, [businesses, brands, companies]);
    useEffect(function () {
        setContext(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[DICTIONARY_KEY.CBB] = cbbData, _a)));
        });
    }, [cbbData]);
};

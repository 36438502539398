var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var BASE_TAG = { node: "", tag: "", value: "" };
var cleanUpTextTagArray = function (arr) {
    return arr.filter(function (_a) {
        var node = _a.node, tag = _a.tag;
        return !!node || !!tag;
    });
};
var preprocessStringArray = function (textArr) {
    return textArr.reduce(function (result, pre) {
        var splitpre = pre.split("]");
        if (splitpre.length === 2) {
            var tag = splitpre[0], node = splitpre[1];
            var tagsplit = tag.split(":");
            if (tagsplit.length === 2) {
                return __spreadArray(__spreadArray([], result, true), [
                    __assign(__assign({}, BASE_TAG), { tag: tagsplit[0], value: tagsplit[1] }),
                    __assign(__assign({}, BASE_TAG), { node: node }),
                ], false);
            }
            return __spreadArray(__spreadArray([], result, true), [__assign(__assign({}, BASE_TAG), { tag: tag }), __assign(__assign({}, BASE_TAG), { node: node })], false);
        }
        return __spreadArray(__spreadArray([], result, true), [__assign(__assign({}, BASE_TAG), { node: pre })], false);
    }, []);
};
var findNextTag = function (arr, curr, tag) {
    for (var i = curr + 1; i < arr.length; i++) {
        if (arr[i].tag === tag)
            return i;
    }
    return -1;
};
var extractTag = function (arr, from, to) {
    var extract = [];
    for (var i = from + 1; i < to; i++) {
        extract.push(__assign({}, arr[i]));
    }
    return extract;
};
var processTextTagArray = function (arr) {
    var result = [];
    var i = 0;
    while (i < arr.length) {
        var _a = arr[i], node = _a.node, tag = _a.tag, value = _a.value;
        if (!!node && typeof node === "string") {
            // normal text
            result.push({ node: node, tag: tag, value: value });
            i++;
        }
        else if (!!tag) {
            var nextTagIndex = findNextTag(arr, i, tag);
            if (nextTagIndex > -1) {
                // closing tag found
                var newArr = extractTag(arr, i, nextTagIndex);
                var processedNode = processTextTagArray(newArr);
                result.push(__assign(__assign({}, BASE_TAG), { node: processedNode, tag: tag, value: value }));
                i = nextTagIndex + 1;
            }
            else {
                // not a tag, convert to text
                result.push(__assign(__assign({}, BASE_TAG), { node: value ? "[".concat(tag, ":").concat(value, "]") : "[".concat(tag, "]") }));
                i++;
            }
        }
    }
    return cleanUpTextTagArray(result);
};
export var parseTextForTags = function (text) {
    try {
        var presplit = text.split("[");
        var split = preprocessStringArray(presplit);
        var cleansplit = cleanUpTextTagArray(split);
        return processTextTagArray(cleansplit);
    }
    catch (e) {
        // DO NOTHING
    }
    return [
        __assign(__assign({}, BASE_TAG), { node: text.toString() }),
    ];
};

var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useMemo } from "react";
import { useGoSPARouteWithQueryString } from "../../routing/hooks";
export var useLocalLink = function (path, query) {
    var querystrings = useMemo(function () {
        var strings = [];
        if (!!query) {
            var keys = Object.keys(query);
            keys.forEach(function (key) {
                strings.push(key);
                strings.push(query[key]);
            });
        }
        return strings;
    }, [query]);
    return useGoSPARouteWithQueryString.apply(void 0, __spreadArray([path], querystrings, false));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { EuiContext } from "@elastic/eui";
import { useAllowNonI18nHookAccess, useI18nContext } from "./hooks";
import { useI18nJson } from "./useI18nJson";
import { useSelectedI118nMapping } from "./useSelectedI18nMapping";
var I18nContext = function (_a) {
    var children = _a.children;
    var json = useI18nJson();
    var jsonmap = useSelectedI118nMapping(json);
    var _b = useI18nContext(jsonmap), i18n = _b.i18n, mapping = _b.mapping;
    useAllowNonI18nHookAccess(mapping);
    return _jsx(EuiContext, __assign({ i18n: i18n }, { children: children }));
};
export default I18nContext;

import { useEffect } from "react";
import { useLogout } from "../../layout/sidenav/logout/hooks";
import { announceLogout } from "../server/servertime/helpers";
export var useAutoLogout = function (invalidSession) {
    var logout = useLogout();
    useEffect(function () {
        if (!invalidSession)
            return;
        announceLogout("error.session.expired", logout);
    }, [invalidSession]);
};

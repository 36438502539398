import { useEffect, useState } from "react";
import { delayRun } from "../common/timers";
export var useReadyDelay = function (delay) {
    var _a = useState(false), ready = _a[0], setReady = _a[1];
    useEffect(function () {
        var cancel = delayRun(function () {
            setReady(true);
        }, delay);
        return function () {
            cancel();
        };
    }, [delay]);
    return ready;
};

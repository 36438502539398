var _a;
import { FS_GATEWAY_HOST } from "../../../../components/api/fs/constants";
import { useAxiosGetJsonApi } from "../../../../components/hooks/useAxiosGetJsonApi";
import { useGenericAxiosErrorToast } from "../../../../components/modals/alerts/hooks";
var DOCUMENT_NEWITEMS_API = "".concat(FS_GATEWAY_HOST).concat((_a = process.env.DOCUMENT_NEWITEMS_API) !== null && _a !== void 0 ? _a : "");
var PAYLOAD = {
    url: DOCUMENT_NEWITEMS_API,
    manualTrigger: true,
};
export var useCheckNewDocumentApi = function () {
    var _a = useAxiosGetJsonApi(PAYLOAD), jsonApi = _a.jsonApi, getData = _a.getData, getError = _a.getError;
    useGenericAxiosErrorToast(DOCUMENT_NEWITEMS_API, getError);
    return {
        getNewItemCount: getData,
        count: jsonApi ? jsonApi.count : 0,
    };
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { COLORS } from "../../common/constants";
var DEFAULT_SVG_SIZE = 20;
export var ProfileIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? DEFAULT_SVG_SIZE : _b, _c = _a.fillColor, fillColor = _c === void 0 ? COLORS.OFFWHITE : _c, _d = _a.className, className = _d === void 0 ? "" : _d;
    var sizeString = size.toString();
    var scale = size / DEFAULT_SVG_SIZE;
    return (_jsx("svg", __assign({ className: className, width: size, height: size, viewBox: "0 0 ".concat(sizeString, " ").concat(sizeString), xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", __assign({ fill: fillColor, transform: "scale(".concat(scale, ",").concat(scale, ")") }, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM10 18C8.49228 18 7.08197 17.5829 5.87817 16.8578C6.94347 16.29 8.31119 16 10 16C11.5708 16 12.9154 16.3371 14.0382 16.8954L14.0493 16.901C12.8616 17.5994 11.4776 18 10 18ZM2 10C2 12.145 2.84415 14.0927 4.21837 15.5293C5.70414 14.5035 7.64112 14 10 14C11.8906 14 13.5377 14.4129 14.9287 15.1046C15.2366 15.2577 15.5103 15.4124 15.7496 15.5626C17.1427 14.1229 18 12.1616 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10ZM14 8C14 10.2091 12.2091 12 10 12C7.79086 12 6 10.2091 6 8C6 5.79086 7.79086 4 10 4C12.2091 4 14 5.79086 14 8ZM8 8C8 9.10457 8.89543 10 10 10C11.1046 10 12 9.10457 12 8C12 6.89543 11.1046 6 10 6C8.89543 6 8 6.89543 8 8Z" }) })) })));
};

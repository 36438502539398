import { BOOLEAN_FLAG } from "../../fs/constants";
import { useFSCheckBooleanFlag } from "../../fs/useFSCheckBooleanFlag";
import { useFSDictionaryStorage } from "../hooks";
import { DICTIONARY_KEY } from "../types";
import { LOCATIONS_API, LOCATIONS_API_V2 } from "./constants";
import { locationDTOAdapter } from "./helpers";
var LocationsDictionary = function () {
    var useNewApi = useFSCheckBooleanFlag(BOOLEAN_FLAG.NEW_ACL_API_1, true);
    useFSDictionaryStorage(useNewApi ? LOCATIONS_API_V2 : LOCATIONS_API, DICTIONARY_KEY.LOCATIONS, locationDTOAdapter);
    return null;
};
export default LocationsDictionary;

import { union } from "lodash-es";
import { PLATFORM_ACCESS } from "../constants";
export var getUserLocations = function (json, email) {
    if (json.email !== email)
        return [];
    return json.locations.map(function (_a) {
        var id = _a.id;
        return id;
    });
};
export var getUserBrands = function (json, email) {
    if (json.email !== email)
        return [];
    return json.brands.map(function (_a) {
        var id = _a.id;
        return id;
    });
};
export var getUserBusinesses = function (json, email) {
    if (json.email !== email)
        return [];
    return json.businesses.map(function (_a) {
        var id = _a.id;
        return id;
    });
};
export var getDataFromBOUser = function (json, email) {
    var _a, _b, _c, _d;
    if (json.email !== email)
        return {
            user_type: "",
            permissionInfo: [],
            platform_access: PLATFORM_ACCESS.ADMIN_PANEL,
            server_time: "",
        };
    return {
        user_type: (_a = json.user_type) !== null && _a !== void 0 ? _a : "",
        permissionInfo: (_b = json.permissions) !== null && _b !== void 0 ? _b : [],
        platform_access: (_c = json.platform_access) !== null && _c !== void 0 ? _c : PLATFORM_ACCESS.ADMIN_PANEL,
        server_time: (_d = json.server_time) !== null && _d !== void 0 ? _d : "",
    };
};
export var combinePermissions = function (a, b) {
    return union(a, b);
};
export var is404Error = function (message) {
    return message ? message.indexOf("404") >= 0 : false;
};
export var getUserKPOSAssignments = function (json, email) {
    var _a;
    if (json.email !== email)
        return null;
    return (_a = json.kpos) !== null && _a !== void 0 ? _a : null;
};
export var getUserRoles = function (json, email) {
    if (json.email !== email)
        return [];
    return json.roles;
};

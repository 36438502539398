var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { OK_BUTTON } from "../constants";
import { hideAlerts } from "../helpers";
import AlertModal from "./AlertModal";
import { useMessageResponse } from "./hooks";
import "./styles.scss";
var Message = function (_a) {
    var param = _a.param;
    var buttons = param.buttons;
    var buttonData = useMessageResponse(hideAlerts, buttons && buttons.length ? buttons : [OK_BUTTON]);
    var onClick = buttonData[0].onClick;
    return _jsx(AlertModal, __assign({}, param, { hideModal: onClick, buttons: buttonData }));
};
export default Message;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { useContext, useEffect, useMemo } from "react";
import { FS_GATEWAY_HOST } from "../../api/fs/constants";
import { useAxiosPost } from "../../hooks/useAxiosPost";
import { announceFatalErrorMessage } from "../../modals/alerts/helpers";
import { AllContext } from "../constants";
import { useIdToken, usePlatform, useUserInfo } from "../hooks";
var BACKOFFICE_USER_LOGON = "".concat(FS_GATEWAY_HOST).concat((_a = process.env.BACKOFFICE_USER_LOGON) !== null && _a !== void 0 ? _a : "");
export var useUserLastLogonApi = function (useidtoken) {
    if (useidtoken === void 0) { useidtoken = true; }
    var email = useUserInfo().email;
    var platform = usePlatform();
    var idToken = useIdToken();
    var _a = useContext(AllContext.GeneralDataContext), setGeneralContext = _a[1];
    var config = useMemo(function () { return ({
        headers: {
            "X-AP-id-token": idToken,
        },
    }); }, [idToken]);
    var payload = useMemo(function () {
        if (!email)
            return {};
        if (!platform)
            return {};
        return {
            email: email,
            source: platform,
        };
    }, [email, platform]);
    var url = useMemo(function () {
        if (!email)
            return "";
        if (!platform)
            return "";
        return BACKOFFICE_USER_LOGON;
    }, [email, platform]);
    var _b = useAxiosPost(url, payload, useidtoken ? config : undefined), postData = _b.postData, response = _b.response, postError = _b.postError;
    useEffect(function () {
        postData();
    }, [postData]);
    useEffect(function () {
        if (!response)
            return;
        setGeneralContext(function (prev) { return (__assign(__assign({}, prev), { loggedon: true })); });
    }, [response]);
    useEffect(function () {
        if (!postError)
            return;
        if (!email)
            return;
        announceFatalErrorMessage({
            title: "LOGON FAILURE",
            content: "Failed to log in using ".concat(email),
        });
    }, [postError]);
};

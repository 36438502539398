var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useContext, useEffect } from "react";
import { AllContext } from "../../../context/constants";
export var useSetSideBarNewItemCountValue = function (check, value) {
    var _a = useContext(AllContext.RoutesListingContext), setRouteListings = _a[1];
    useEffect(function () {
        if (!check)
            return;
        setRouteListings(function (prev) {
            return prev.map(function (listing) {
                if (listing.new_item_check === check) {
                    return __assign(__assign({}, listing), { new_items: value });
                }
                return listing;
            });
        });
    }, [value, check]);
};

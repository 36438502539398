import { useCallback, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { trimTrailingSlash } from "./helpers";
export var useRouteTracker = function () {
    var history = useHistory();
    var lastPath = useRef("");
    var listenHandler = useCallback(function (evt) {
        var params = evt;
        var pathname = trimTrailingSlash(params.pathname);
        if (lastPath.current !== pathname) {
            lastPath.current = pathname;
        }
    }, []);
    useEffect(function () {
        var unlisten = history.listen(listenHandler);
        return function () {
            unlisten();
        };
    }, []);
};

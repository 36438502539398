var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EuiFlexGroup, EuiFlexItem, EuiPanel } from "@elastic/eui";
import Header from "../../../../../../components/table/Header";
import CatalogueHistoryTableRow from "./HistoryTableRow";
var CatalogueHistoryTable = function (_a) {
    var items = _a.items, columns = _a.columns;
    return (_jsx(EuiFlexGroup, __assign({ direction: "column" }, { children: _jsx(EuiFlexItem, __assign({ grow: null }, { children: _jsx("div", __assign({ className: "TableContainer CatalogsHistoryTable" }, { children: !!(items === null || items === void 0 ? void 0 : items.length) && (_jsxs(EuiPanel, __assign({ paddingSize: "none" }, { children: [_jsx("div", __assign({ className: "MenuItemCustimisationsTableHeader CatalogsHistoryTableHeader" }, { children: _jsx(Header, { columns: columns }) })), items === null || items === void 0 ? void 0 : items.map(function (item, ind) { return (_jsx(CatalogueHistoryTableRow, { item: item, index: ind }, "historyTableRow-".concat(item.id))); })] }))) })) })) })));
};
export default CatalogueHistoryTable;

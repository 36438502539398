import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import Modal from "../../../../pages/qc/components/modal/Modal";
import { useQCStateContext } from "../hooks";
var ModalController = function () {
    var state = useQCStateContext().state;
    var _a = state.modal, modalVisible = _a.visible, _b = _a.data, title = _b.title, confirmationText = _b.confirmationText, discardText = _b.discardText, message = _b.message, onConfirm = _b.onConfirm, onDiscard = _b.onDiscard, status = _b.status, tableData = _b.tableData, tableColumns = _b.tableColumns, type = _b.type, modalInfoType = _b.modalInfoType;
    return (_jsx(_Fragment, { children: modalVisible && (_jsx(Modal, { title: title, message: message, confirmationText: confirmationText, discardText: discardText, onConfirm: onConfirm, onDiscard: onDiscard, status: status, type: type, tableData: tableData, tableColumns: tableColumns, modalInfoType: modalInfoType })) }));
};
export default ModalController;

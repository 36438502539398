var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMemo } from "react";
import { useIsUserInfoChecked } from "../../../context/hooks";
import { useBackOfficeConfig } from "../../config/useBackOfficeConfig";
import { FS_CONFIG } from "./constants";
export var useFSConfigs = function () {
    var userChecked = useIsUserInfoChecked();
    var config = useBackOfficeConfig(userChecked ? FS_CONFIG.FEATURE_FLAGS : "");
    return useMemo(function () {
        var _a;
        var fsconfig = (_a = config.filter(function (_a) {
            var name = _a.name;
            return name === FS_CONFIG.FEATURE_FLAGS;
        })[0]) !== null && _a !== void 0 ? _a : null;
        if (fsconfig) {
            return __assign(__assign({}, fsconfig), { config: JSON.parse(fsconfig.config) });
        }
        return null;
    }, [config]);
};

import { ALPHANUMERIC_CHARSET, NUMERIC_CHARSET } from "./constants";
import { onlyAllowChars, onlyAllowStringOfLength, removeChars, } from "./stringutils";
var ILLEGAL_WORDS = [
    "drop table ",
    "<script>",
    "</script>",
    "javascript",
    "onerror",
    "delete *",
    "grant *",
    "window.",
    "document.",
];
var MAX_LENGTH;
(function (MAX_LENGTH) {
    MAX_LENGTH[MAX_LENGTH["NAME"] = 100] = "NAME";
    MAX_LENGTH[MAX_LENGTH["CONTACT_NO"] = 15] = "CONTACT_NO";
    MAX_LENGTH[MAX_LENGTH["CASH"] = 8] = "CASH";
    MAX_LENGTH[MAX_LENGTH["GL_ACCOUNT"] = 20] = "GL_ACCOUNT";
})(MAX_LENGTH || (MAX_LENGTH = {}));
export var removeIllegalWords = function (text) {
    return ILLEGAL_WORDS.reduce(function (processed, illegal) {
        return processed.replaceAll(new RegExp(illegal, "ig"), "");
    }, text + "");
};
export var allowOnlyText = function (text, additionalChars, maxlength) {
    if (additionalChars === void 0) { additionalChars = ""; }
    if (maxlength === void 0) { maxlength = 0; }
    var cleaned = onlyAllowChars(text, "".concat(ALPHANUMERIC_CHARSET).concat(additionalChars));
    var noIllegal = removeIllegalWords(cleaned);
    return maxlength ? onlyAllowStringOfLength(noIllegal, maxlength) : noIllegal;
};
export var sanitizeInputByExclusion = function (text, excludeChars, maxlength) {
    if (excludeChars === void 0) { excludeChars = ""; }
    if (maxlength === void 0) { maxlength = 0; }
    var noIllegal = removeIllegalWords(text);
    var cleaned = removeChars(noIllegal, excludeChars);
    return maxlength ? onlyAllowStringOfLength(cleaned, maxlength) : cleaned;
};
export var sanitizeName = function (text) {
    return sanitizeTextContent(text, MAX_LENGTH.NAME);
};
export var sanitizeContactNo = function (text) {
    var cleaned = onlyAllowChars(text, "".concat(NUMERIC_CHARSET, " .-+"));
    return onlyAllowStringOfLength(cleaned, MAX_LENGTH.CONTACT_NO);
};
export var sanitizeTextContent = function (text, maxlength) {
    if (maxlength === void 0) { maxlength = 0; }
    return sanitizeInputByExclusion(text, "", maxlength);
};
export var sanitizeCash = function (text) {
    var cleaned = onlyAllowChars(text, "".concat(NUMERIC_CHARSET, "-$."));
    return onlyAllowStringOfLength(cleaned, MAX_LENGTH.CASH);
};
export var sanitizeURL = function (text) {
    return allowOnlyText(text, " :_-.&=%/?(),[]#");
};
// https://www.uen.gov.sg/ueninternet/faces/pages/admin/aboutUEN.jspx
export var sanitizeUEN = function (text) {
    return allowOnlyText(text, "", 10);
};
export var sanitizeGLAccount = function (glaccount) {
    return allowOnlyText(glaccount.trim(), "", MAX_LENGTH.GL_ACCOUNT);
};

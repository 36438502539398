var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Fragment } from "react";
import { randString } from "../../../common/stringutils";
import { TAG_COMPONENT } from "./constants";
var TagTokenProcessor = function (_a) {
    var tokens = _a.tokens;
    return (_jsx("span", __assign({ className: "I18nTextWithTags" }, { children: tokens.map(function (_a) {
            var node = _a.node, tag = _a.tag, value = _a.value;
            var key = "".concat(tag).concat(JSON.stringify({ node: node })).concat(value).concat(randString(5));
            if (!!tag && TAG_COMPONENT[tag.toLowerCase()]) {
                var Component = TAG_COMPONENT[tag.toLowerCase()];
                return (_jsx(Component, __assign({ value: value }, { children: typeof node === "string" ? (node) : (_jsx(TagTokenProcessor, { tokens: node })) }), key));
            }
            return _jsx(Fragment, { children: node }, key);
        }) })));
};
export default TagTokenProcessor;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getI18nText } from "../../../i18n/helpers";
import { HIDDEN_ROLES } from "./constant";
export var filterOutHiddenRoles = function (roles) {
    return roles.filter(function (_a) {
        var name = _a.name;
        return !HIDDEN_ROLES.includes(name.toLowerCase());
    });
};
export var getI18nRoleNames = function (roles) {
    return roles.map(function (role) {
        var key = "role.".concat(role.name);
        var i18nName = getI18nText(key);
        var name = i18nName === key ? role.name : i18nName;
        return __assign(__assign({}, role), { name: name });
    });
};
export var getPermissionsOfRoles = function (roles, allRoles) {
    var roleIds = roles.map(function (id) { return id + ""; });
    var foundRoles = allRoles.filter(function (_a) {
        var id = _a.id;
        return roleIds.includes(id);
    });
    var permSet = new Set();
    var lastSize = 0;
    return foundRoles.reduce(function (perms, _a) {
        var permissions = _a.permissions;
        permissions.forEach(function (perm) {
            permSet.add(perm.id);
            if (permSet.size > lastSize) {
                perms.push(perm);
            }
            lastSize = permSet.size;
        });
        return perms;
    }, []);
};

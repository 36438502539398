import { useMemo } from "react";
import { useFSConfigJSON } from "../context/fs/useFSConfigJSON";
import { useIsUserInfoChecked } from "../context/hooks";
import { showOutdatedBuildModal } from "./helpers";
import { useUpdateLatestBuild } from "./useUpdateLatestBuild";
export var useCheckLatestBuild = function () {
    var config = useFSConfigJSON();
    var checked = useIsUserInfoChecked();
    var latestBuild = useMemo(function () {
        if (!(config === null || config === void 0 ? void 0 : config.latest_build))
            return 0;
        return parseInt(config.latest_build, 10);
    }, [config]);
    var updateBuild = useMemo(function () {
        if (!checked)
            return "";
        var currBuild;
        try {
            currBuild = parseInt(process.env.BUILD_VERSION, 10);
        }
        catch (e) {
            currBuild = 0;
        }
        if (!currBuild || currBuild === latestBuild)
            return "";
        if (currBuild > latestBuild)
            return currBuild + "";
        showOutdatedBuildModal();
        return "";
    }, [latestBuild, checked]);
    useUpdateLatestBuild(updateBuild);
};

export var OPERATIONS_PATH;
(function (OPERATIONS_PATH) {
    OPERATIONS_PATH["DAILY_CASH_DEPOSIT"] = "/fs/dailycashdeposit/listing";
    OPERATIONS_PATH["DAILY_CASH_DEPOSIT_ADD"] = "/fs/dailycashdeposit/add";
    OPERATIONS_PATH["DAILY_CASH_DEPOSIT_EDIT"] = "/fs/dailycashdeposit/details";
    OPERATIONS_PATH["KPOS_DAY_END"] = "/fs/kpos/dayend";
})(OPERATIONS_PATH || (OPERATIONS_PATH = {}));
export var DATE_SEPARATOR = "-";
export var DATE_FORMAT = "DD".concat(DATE_SEPARATOR, "MM").concat(DATE_SEPARATOR, "YYYY");
export var API_DATE_FORMAT = "DD-MM-YYYY";
export var KPOS_DAYEND_TRIGGER_EXPIRE = 1; // day end trigger command expires in X hour

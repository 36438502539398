var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useI18nToken } from "./hooks";
import I18nTextWithTags from "./tags/I18nTextWithTags";
var I18nText = function (_a) {
    var token = _a.token, values = _a.values, _b = _a.className, className = _b === void 0 ? "" : _b, _c = _a.noTags, noTags = _c === void 0 ? false : _c;
    var text = useI18nToken(token, values);
    var display = noTags ? text : _jsx(I18nTextWithTags, { children: text });
    if (className) {
        return _jsx("span", __assign({ className: className }, { children: display }));
    }
    return _jsx(_Fragment, { children: display });
};
export default I18nText;

import { useContext, useMemo } from "react";
import { AllContext } from "../../../context/constants";
import { decryptProps } from "./helpers";
export var useFSPropData = function () {
    var keys = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        keys[_i] = arguments[_i];
    }
    var fs_props = useContext(AllContext.GeneralDataContext)[0].fs_props;
    return useMemo(function () { return decryptProps(keys, fs_props !== null && fs_props !== void 0 ? fs_props : ""); }, [fs_props, keys]);
};

import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { DEFAULT_FS_BOOLEAN_FLAG_VALUE } from "./constants";
import { useFSBooleanFlag } from "./useFSBooleanFlag";
var FSFeatureFlag = function (_a) {
    var flag = _a.flag, children = _a.children, _b = _a.defaultValue, defaultValue = _b === void 0 ? DEFAULT_FS_BOOLEAN_FLAG_VALUE : _b, _c = _a.fallback, fallback = _c === void 0 ? null : _c;
    var checkFlag = useFSBooleanFlag();
    var featureflag = useMemo(function () {
        return checkFlag(flag, defaultValue);
    }, [checkFlag, defaultValue]);
    if (!featureflag)
        return _jsx(_Fragment, { children: fallback });
    return _jsx(_Fragment, { children: children });
};
export default FSFeatureFlag;

import { useCallback, useRef, useState } from "react";
import { useGlobalEvent } from "./useGlobalEvent";
export var useEventTriggeredTimeUpdate = function (event, maxCalls) {
    if (maxCalls === void 0) { maxCalls = 0; }
    var called = useRef(0);
    var _a = useState(0), time = _a[0], setTime = _a[1];
    var updateTime = useCallback(function () {
        called.current++;
        if (maxCalls > 0 && called.current > maxCalls)
            return;
        setTime(called.current);
    }, [maxCalls]);
    useGlobalEvent(event, updateTime);
    return time;
};

import { createContext } from "react";
/* istanbul ignore next */ // this is just a placeholder function, it will never be used
var initialSetter = function () {
    // EMPTY
};
export var makeContext = function (initial) {
    return createContext([initial, initialSetter]);
};
export var mapOnlyIDs = function (data) {
    return data.map(function (_a) {
        var id = _a.id;
        return id;
    });
};

import { arraysHaveMatchingItems } from "../../common/helpers";
import { PLATFORM_ACCESS } from "../context/constants";
import { PERMISSION } from "./permissions";
export var isSuperAdmin = function (permissions) {
    return permissions.includes(PERMISSION.SUPER_ADMIN);
};
export var isUserManager = function (permissions) {
    return permissions.includes(PERMISSION.USER_MANAGEMENT);
};
export var hasRequiredPermission = function (permissions, userpermissions) {
    if (isSuperAdmin(userpermissions))
        return true;
    return arraysHaveMatchingItems(permissions, userpermissions);
};
export var isSuperReporter = function (userpermissions) {
    return hasRequiredPermission([PERMISSION.ADMIN_PANEL_DSER_MANAGE], userpermissions);
};
export var hasAllFSLocationsAccess = function (userpermissions) {
    return hasRequiredPermission([PERMISSION.FS_ALL_LOCATIONS_ACCESS], userpermissions);
};
export var allowChangeLocalMenuItemsInMMI = function (userpermissions) {
    return hasRequiredPermission([PERMISSION.FS_CATALOGUE_MANAGE], userpermissions);
};
export var hasPermissionWithPrefix = function (userPermissions, prefix) {
    for (var _i = 0, userPermissions_1 = userPermissions; _i < userPermissions_1.length; _i++) {
        var permission = userPermissions_1[_i];
        if (permission.startsWith(prefix))
            return true;
    }
    return false;
};
export var hasPartnerCentreAccess = function (access) {
    if (access === PLATFORM_ACCESS.PARTNER_CENTRE)
        return true;
    return access === PLATFORM_ACCESS.ALL;
};

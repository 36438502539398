import { TAG_GROUP } from "./api/types";
export var RESTAURANTS_PATH;
(function (RESTAURANTS_PATH) {
    RESTAURANTS_PATH["MANAGE_RESTAURANTS"] = "/qc/restaurants";
    RESTAURANTS_PATH["VIEW_RESTAURANT"] = "/qc/restaurants/item";
    RESTAURANTS_PATH["ADD_POS_DEVICE"] = "/qc/restaurants/device/add";
})(RESTAURANTS_PATH || (RESTAURANTS_PATH = {}));
export var QC_RESTAURANTS_QUERY_PARAMS;
(function (QC_RESTAURANTS_QUERY_PARAMS) {
    QC_RESTAURANTS_QUERY_PARAMS["FOOD_SUPPLIER_ID"] = "food_supplier_id";
    QC_RESTAURANTS_QUERY_PARAMS["FOOD_SUPPLIER_NAME"] = "food_supplier_name";
})(QC_RESTAURANTS_QUERY_PARAMS || (QC_RESTAURANTS_QUERY_PARAMS = {}));
export var POS_DEVICES_TABLE_COLUMNS = [
    "restaurants.page.viewRestaurant.device.section.table.column.no",
    "restaurants.page.viewRestaurant.device.section.table.column.pos.name",
    "restaurants.page.viewRestaurant.device.section.table.column.serial.no",
    "",
];
export var TAGS = [TAG_GROUP.DIETARY, TAG_GROUP.CUISINE];
export var EMPTY_VALUE = "-";
export var MAX_PREP_TIME = 120;
export var MAX_TAGS_COUNT = 3;
export var BUSINESS_HOURS_TYPE;
(function (BUSINESS_HOURS_TYPE) {
    BUSINESS_HOURS_TYPE["OPENING"] = "opening";
    BUSINESS_HOURS_TYPE["ORDERING"] = "ordering";
})(BUSINESS_HOURS_TYPE || (BUSINESS_HOURS_TYPE = {}));

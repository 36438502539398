import { jsx as _jsx } from "react/jsx-runtime";
import "./modalstaticcontent.scss";
import StaticPage from "../private/StaticPage";
import { useGetStaticContent } from "./useGetStaticContent";
var ModalStaticContent = function (_a) {
    var id = _a.id;
    var _b = useGetStaticContent(id), data = _b.data, status = _b.status, page_name = _b.page_name;
    return _jsx(StaticPage, { data: data, status: status, pageName: page_name });
};
export default ModalStaticContent;

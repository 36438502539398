import { globalEvents } from "../../../common/GlobalEvents";
import { I18N_EVENT } from "../../i18n/constants";
import { replaceI18nToken } from "../../i18n/helpers";
import { ALERT_EVENT, ALERT_TYPE, TOAST_COLOR, TOAST_ICON, } from "../../modals/alerts/constants";
import { DICTIONARY_ERROR_ANNOUNCEMENT_DELAY } from "./constants";
var getDictionaryErrorTitle = function (key) {
    var text = globalEvents.request(I18N_EVENT.GET_TEXT, "error.dictionary.title");
    return replaceI18nToken(text, { dict: key });
};
export var announceDictionaryError = function (key, content) {
    setTimeout(function () {
        globalEvents.emit(ALERT_EVENT.SHOW, {
            type: ALERT_TYPE.TOAST,
            title: getDictionaryErrorTitle(key),
            content: content,
            color: TOAST_COLOR.ALERT,
            iconType: TOAST_ICON.ALERT,
        });
    }, DICTIONARY_ERROR_ANNOUNCEMENT_DELAY);
};
export var isInvalidData = function (key, dto) {
    var data = dto.data;
    if (data)
        return false;
    announceDictionaryError(key, JSON.stringify(dto));
    return true;
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState, } from "react";
import { ApprovalModuleIcon } from "../../../assets/svg/ApprovalModuleIcon";
import { ContentIcon } from "../../../assets/svg/ContentIcon";
import { DashboardIcon } from "../../../assets/svg/DashboardIcon";
import { FSIcon } from "../../../assets/svg/FSIcon";
import { HelpIcon } from "../../../assets/svg/HelpIcon";
import { LogoIcon } from "../../../assets/svg/LogoIcon";
import { QCIcon } from "../../../assets/svg/QCIcon";
import { SuperAdminIcon } from "../../../assets/svg/SuperAdminIcon";
import { UserManagementIcon } from "../../../assets/svg/UserManagementIcon";
import { COLORS, MENU_GROUP, PLATFORM } from "../../../common/constants";
import Bubble from "../../doodads/Bubble";
import { useBuildVersion } from "../../hooks/useBuildVersion";
import I18nText from "../../i18n/I18nText";
import { useSideNavContent } from "./hooks";
import { useSideBarCount } from "./navitemcount/useSideBarCount";
import styled from "./SideNavBar.styles";
import SideNavMenu from "./SideNavMenu";
var SIDENAVBAR_CONFIG = [
    {
        label: MENU_GROUP.DASHBOARD,
        text: "layout.menu.group.dashboard",
        icon: DashboardIcon,
    },
    {
        label: MENU_GROUP.CONTENT_MANAGEMENT,
        text: "layout.menu.group.contentmanagement",
        icon: ContentIcon,
    },
    {
        label: MENU_GROUP.SUPER_ADMIN,
        text: "layout.menu.group.superadmin",
        icon: SuperAdminIcon,
    },
    {
        label: MENU_GROUP.USER_MANAGEMENT,
        text: "layout.menu.group.usermanagement",
        icon: UserManagementIcon,
    },
    {
        label: MENU_GROUP.FOOD_SERVICES,
        text: "layout.menu.group.foodservices",
        icon: FSIcon,
    },
    {
        label: MENU_GROUP.APPROVAL,
        text: "layout.menu.group.approval",
        icon: ApprovalModuleIcon,
    },
    {
        label: MENU_GROUP.QUICK_COMMERCE,
        text: "layout.menu.group.quickcommerce",
        icon: QCIcon,
    },
    {
        label: MENU_GROUP.HELP,
        text: "layout.menu.group.help",
        icon: HelpIcon,
    },
];
var ALL_MENUS = __spreadArray([
    MENU_GROUP.PROFILE
], SIDENAVBAR_CONFIG.map(function (_a) {
    var label = _a.label;
    return label;
}), true);
export var SideNavBar = function (_a) {
    var className = _a.className;
    var navItems = useSideNavContent().navItems;
    var _b = useState(""), show = _b[0], setShow = _b[1];
    var build = useBuildVersion();
    var setIsShown = useCallback(function (key) { return function () { return setShow(key); }; }, []);
    var navItemCount = useSideBarCount();
    var renderNavBarItems = useMemo(function () {
        return SIDENAVBAR_CONFIG.map(function (_a, index) {
            var label = _a.label, text = _a.text, Icon = _a.icon;
            var isShown = show === label;
            var menuItems = navItems
                .filter(function (_a) {
                var group = _a.group;
                return group === label;
            })
                .filter(function (_a) {
                var items = _a.items, route = _a.route;
                return !!route || !!(items === null || items === void 0 ? void 0 : items.length);
            });
            if (menuItems.length === 0)
                return null;
            return (_jsxs("div", __assign({ className: "SideNavBarItem ".concat(isShown ? "Open" : "Close"), onMouseEnter: setIsShown(label) }, { children: [_jsx(Icon, { className: "SideNavBarItemIcon", size: 25, fillColor: isShown ? COLORS.MINESHAFT700 : COLORS.MINESHAFT300 }), _jsx(I18nText, { className: "SideNavBarItemText ".concat(isShown ? "Open" : "Close"), token: text }), !isShown && navItemCount[label].totalCount > 0 && (_jsx(Bubble, { className: "Count NoContent" }))] }), "".concat(index, " + ").concat(label)));
        }).filter(function (item) { return item !== null; });
    }, [show, navItems, navItemCount]);
    var menus = useMemo(function () {
        return ALL_MENUS.map(function (label) {
            var _a;
            return (_jsx(SideNavMenu, { current: label, changeMenu: setShow, navItemCountUpdater: (_a = navItemCount[label]) === null || _a === void 0 ? void 0 : _a.updateNavItemCount, disabled: show !== label }, label));
        });
    }, [navItemCount, show]);
    return (_jsx("div", __assign({ className: className }, { children: _jsxs("div", __assign({ className: "SideNavBar" }, { children: [_jsx("div", __assign({ className: "SideNavBarItem" }, { children: _jsx(LogoIcon, { className: "Logo" }) })), renderNavBarItems, _jsx("div", __assign({ className: "VersionNumber" }, { children: build })), process.env.NODE_ENV === PLATFORM.DEVELOPMENT && (_jsxs("div", __assign({ className: "CommitHash" }, { children: ["( ", __COMMIT_HASH__, " )"] }))), menus, show && (_jsx("div", { className: "Overlay", onMouseEnter: setIsShown(""), onClick: setIsShown("") }))] })) })));
};
export default styled(SideNavBar);

import { LANG } from "./types";
export var DEFAULT_LANG_OPTION = 0;
export var LANG_SELECTABLES = [
    {
        text: "EN",
        lang: LANG.EN,
        id: "layout.header.langselection.en",
    },
    {
        text: "中文",
        lang: LANG.CN,
        id: "layout.header.langselection.cn",
    },
];
export var LANG_SELECTOR_VALUES = LANG_SELECTABLES.map(function (_a) {
    var id = _a.id, lang = _a.lang;
    return ({
        value: lang,
        text: id,
    });
});

import { ALPHANUMERIC_CHARSET } from "./constants";
import { randomNum } from "./numberutils";
import { removeIllegalWords } from "./sanitizers";
export var contains = function (text) {
    var test = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        test[_i - 1] = arguments[_i];
    }
    for (var i = test.length - 1; i >= 0; i--) {
        if (text.indexOf(test[i]) >= 0)
            return true;
    }
    return false;
};
export var replaceToken = function (text, replace) {
    if (!replace)
        return text;
    var temptxt = text;
    for (var value in replace) {
        temptxt = temptxt.split(value).join(replace[value] + "");
    }
    return temptxt;
};
export var getSingularPluralTextOption = function (text, option) { return text.split("|")[option <= 1 ? 0 : 1] || text; };
var ILLEGAL_TEXT = [";", "--", "#", "'"];
export var removeIllegalText = function (text) {
    return ILLEGAL_TEXT.reduce(function (processed, illegal) {
        return processed.replaceAll(new RegExp(illegal, "ig"), "");
    }, removeIllegalWords(text + ""));
};
export var combineQueryStrings = function () {
    var params = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        params[_i] = arguments[_i];
    }
    var queries = [];
    for (var q = 0; q < params.length; q += 2) {
        if (params[q + 1]) {
            queries.push("".concat(params[q], "=").concat(encodeURIComponent(removeIllegalText(params[q + 1]))));
        }
    }
    return queries.join("&");
};
export var padZeros = function (value, digits) {
    var val = value + "";
    return val.length < digits ? "0".concat(padZeros(value, digits - 1)) : val;
};
export var centsToDollarsDisplay = function (cents, unsigned) {
    if (unsigned === void 0) { unsigned = false; }
    if (!cents)
        return (0).toFixed(2);
    return ((unsigned ? Math.abs(cents) : cents) / 100).toFixed(2);
};
export var dollarsToCents = function (dollars) {
    return Math.round(parseFloat(dollars) * 100) || 0;
};
export var onlyAllowChars = function (text, allow) {
    var s = "";
    for (var _i = 0, text_1 = text; _i < text_1.length; _i++) {
        var char = text_1[_i];
        if (allow.indexOf(char) >= 0)
            s += char;
    }
    return s;
};
export var removeChars = function (text, remove) {
    var s = "";
    for (var _i = 0, text_2 = text; _i < text_2.length; _i++) {
        var char = text_2[_i];
        if (remove.indexOf(char) < 0)
            s += char;
    }
    return s;
};
export var isNumber = function (str) {
    return !str.match(/\D/);
};
export var createFilename = function () {
    var str = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        str[_i] = arguments[_i];
    }
    return str.reduce(function (finalstr, text, idx) {
        if (idx < str.length - 1) {
            var add = !!text ? "".concat(text, " ") : "";
            return "".concat(finalstr).concat(add);
        }
        return "".concat(finalstr).concat(text);
    }, "");
};
export var displayCentsWithDollarSign = function (cents) {
    var sign = cents !== undefined && cents < 0 ? "-" : "";
    return "".concat(sign, "$").concat(centsToDollarsDisplay(cents, true));
};
export var randString = function (length, charset) {
    if (charset === void 0) { charset = ALPHANUMERIC_CHARSET; }
    var result = "";
    var charactersLength = charset.length;
    while (result.length < length) {
        result += charset.charAt(Math.floor(randomNum() * charactersLength));
    }
    return result;
};
export var doesAnyTextExist = function (text, list) {
    var checkText = text.toLowerCase();
    for (var _i = 0, list_1 = list; _i < list_1.length; _i++) {
        var item = list_1[_i];
        if (checkText.indexOf(item) >= 0)
            return true;
    }
    return false;
};
export var onlyAllowStringOfLength = function (str, len) {
    return str.substring(0, len);
};
export var compareStringAsc = function (a, b) {
    var alc = a.toLowerCase();
    var blc = b.toLowerCase();
    if (alc < blc)
        return -1;
    if (alc > blc)
        return 1;
    return 0;
};
export var sortByName = function (a, b) {
    var _a, _b;
    var aname = (_a = a.name) !== null && _a !== void 0 ? _a : "";
    var bname = (_b = b.name) !== null && _b !== void 0 ? _b : "";
    return compareStringAsc(aname, bname);
};
export var findCommonStringsToIndex = function (s1, s2, includeChars) {
    if (includeChars === void 0) { includeChars = ""; }
    var index = 0;
    var lastindex = 0;
    while (index < s1.length && index < s2.length && s1[index] === s2[index]) {
        index++;
        if (!includeChars.includes(s1[index - 1])) {
            lastindex = index;
        }
    }
    return lastindex;
};
export var findCommonWordsToIndex = function (s1, s2) {
    var s1split = s1.split(" ");
    var s2split = s2.split(" ");
    var index = 0;
    while (index < s1split.length &&
        index < s2split.length &&
        s1split[index] === s2split[index]) {
        index++;
    }
    if (index === 0)
        return 0;
    s1split.splice(index);
    return s1split.join(" ").length;
};
export var sortStringAlphabetically = function (a, b) {
    if (a.toLowerCase() < b.toLowerCase())
        return -1;
    if (a.toLowerCase() > b.toLowerCase())
        return 1;
    return 0;
};
export var sortByStringOrder = function (list, order) {
    var sorter = function (a, b) {
        var lcorder = order.map(function (name) { return name.toLowerCase(); });
        var indexA = lcorder.indexOf(a.toLowerCase());
        var indexB = lcorder.indexOf(b.toLowerCase());
        if (indexA < 0 && indexB < 0)
            return sortStringAlphabetically(a, b);
        if (indexA < 0)
            return 1;
        if (indexB < 0)
            return -1;
        return indexA - indexB;
    };
    return list.sort(sorter);
};
export var stringEnumsToArray = function (enums) {
    return Object.entries(enums).map(function (_a) {
        var value = _a[1];
        return value;
    });
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./SideNavLink.scss";
import { EuiCollapsibleNavGroup, EuiPinnableListGroup } from "@elastic/eui";
import { doNothing } from "../../../common/helpers";
import Bubble from "../../doodads/Bubble";
import I18nText from "../../i18n/I18nText";
import { useSPARoute } from "../../routing/hooks";
import { useIsFocus, useSideNavLink } from "./hooks";
import { useSideNavLinkCount } from "./navitemcount/useSideNavLinkCount";
import NavTags from "./NavTags";
var SideNavLink = function (_a) {
    var data = _a.data, updateParentCount = _a.updateParentCount;
    var _b = useSideNavLink(data), title = _b.title, route = _b.route, new_item_check = _b.new_item_check;
    var navigateToSPARoute = useSPARoute().navigateToSPARoute;
    var isFocus = useIsFocus(route);
    var totalCount = useSideNavLinkCount(title, new_item_check, updateParentCount);
    return (_jsxs(EuiCollapsibleNavGroup, __assign({ background: "none", className: "SideNavLink" }, { children: [_jsx(EuiPinnableListGroup, { listItems: [
                    {
                        label: _jsx(I18nText, { token: title }),
                        isActive: true,
                        pinnable: false,
                        onClick: navigateToSPARoute(route),
                    },
                ], onPinClick: doNothing, className: "SideNavLink ".concat(isFocus ? "IsFocus" : "") }), _jsx(NavTags, { permissions: data.permissions }), totalCount > 0 && _jsx(Bubble, { className: "Count NoContent" })] })));
};
export default SideNavLink;

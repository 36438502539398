var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./Modal.scss";
import { EuiButton, EuiIcon, EuiModal } from "@elastic/eui";
import React from "react";
import I18nText from "../../../../components/i18n/I18nText";
import CatalogueHistoryTable from "../../online-menus/views/menu/menu-items/view/historyTable/HistoryTable";
import { useModalClose } from "./hooks";
import { ModalInfoType, ModalStatus, ModalType } from "./types";
var Modal = function (_a) {
    var title = _a.title, confirmationText = _a.confirmationText, message = _a.message, discardText = _a.discardText, onConfirm = _a.onConfirm, onDiscard = _a.onDiscard, status = _a.status, tableData = _a.tableData, tableColumns = _a.tableColumns, modalInfoType = _a.modalInfoType, _b = _a.type, type = _b === void 0 ? ModalType.Alerts : _b;
    var handleClose = useModalClose(onDiscard, onConfirm);
    var renderModalInfo = function () {
        if (modalInfoType !== ModalInfoType.PriceHistory)
            return null;
        return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "OnlineCatalogsTableModalHeader" }, { children: [_jsx("h2", __assign({ className: "Title" }, { children: _jsx(I18nText, { token: title }) })), _jsx(I18nText, { token: "onlineCatalogs.page.menu.menuItems.catalogs.history.modal.subtitle", values: { items: tableData === null || tableData === void 0 ? void 0 : tableData.length } })] })), _jsx(CatalogueHistoryTable, { items: tableData, columns: tableColumns || [] })] }));
    };
    return (_jsx(EuiModal, __assign({ className: type === ModalType.Alerts
            ? "OnlineCatalogsModal"
            : "OnlineCatalogsTableModal", onClose: handleClose }, { children: type === ModalType.Alerts ? (_jsxs("div", __assign({ className: "OnlineCatalogsModalContent" }, { children: [status && (_jsxs("div", __assign({ className: "OnlineCatalogsModal" }, { children: [status === ModalStatus.Error && (_jsx(EuiIcon, { type: "/assets/icons/Alert.svg" })), status === ModalStatus.Success && (_jsx(EuiIcon, { type: "/assets/icons/Success.svg" }))] }))), _jsx("h2", __assign({ className: "OnlineCatalogsModalTitle" }, { children: _jsx(I18nText, { token: title }) })), message && (_jsx("p", __assign({ className: "OnlineCatalogsModalMessage" }, { children: _jsx(I18nText, { token: message }) }))), confirmationText && (_jsx(EuiButton, __assign({ className: "OnlineCatalogsModalButton OnlineCatalogsConfirmButton", color: "success", onClick: onConfirm, fill: true }, { children: _jsx(I18nText, { token: confirmationText }) }))), discardText && (_jsx(EuiButton, __assign({ className: "OnlineCatalogsModalButton OnlineCatalogsDiscardButton", color: "danger", onClick: onDiscard, fill: true }, { children: _jsx(I18nText, { token: discardText }) })))] }))) : (renderModalInfo()) })));
};
export default Modal;

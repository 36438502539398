import { lazy } from "react";
import { MENU_GROUP, PLATFORM } from "../../../common/constants";
import { globalEvents } from "../../../common/GlobalEvents";
import { PERMISSION } from "../../../components/rbac/permissions";
import { ROUTE_EVENTS } from "../../../components/routing/constants";
import { DOCUMENTS_PATH } from "./constants";
import { NEW_DOCUMENTS_ALERT } from "./tenant/alerts/constants";
/* istanbul ignore next */ // no need to test lazy loading
var LazyComponents = {
    DocumentsUpload: lazy(function () {
        return import(/* webpackChunkName: "fsdocuploads" */ "./upload/DocumentsUpload");
    }),
    Invoices: lazy(function () { return import(/* webpackChunkName: "fstenantinvoices" */ "./tenant/Invoices"); }),
};
export var documentsRoutes = [
    {
        label: "fs.documents.management",
        route: "",
        permissions: [],
        icon: "documents",
        platforms: [],
        group: MENU_GROUP.FOOD_SERVICES,
        subRoutes: [
            {
                label: "fs.documents.invoice",
                route: DOCUMENTS_PATH.INVOICES,
                component: LazyComponents.Invoices,
                permissions: [PERMISSION.FS_DOCUMENTS_VIEW],
                platforms: [PLATFORM.ADMIN_PANEL, PLATFORM.PARTNER_CENTER],
                new_item_check: NEW_DOCUMENTS_ALERT,
            },
            {
                label: "fs.documents.documentsupload",
                route: DOCUMENTS_PATH.UPLOAD,
                component: LazyComponents.DocumentsUpload,
                permissions: [PERMISSION.FS_DOCUMENTS_MANAGE],
                platforms: [PLATFORM.ADMIN_PANEL],
            },
        ],
    },
];
export var initDocumentsManagement = function () {
    globalEvents.emit(ROUTE_EVENTS.REGISTER, documentsRoutes);
};

import { jsx as _jsx } from "react/jsx-runtime";
import "./textwithtags.scss";
import TagTokenProcessor from "./TagTokenProcessor";
import { useI18nTextWithTags } from "./useI18nTextWithTags";
var I18nTextWithTags = function (_a) {
    var children = _a.children;
    var tokens = useI18nTextWithTags(children);
    return _jsx(TagTokenProcessor, { tokens: tokens });
};
export default I18nTextWithTags;

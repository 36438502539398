import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useMemo } from "react";
import { PLATFORM } from "../../../../common/constants";
import { AUTH_PATH } from "../../../../pages/auth/constants";
import { usePlatform } from "../../../context/hooks";
export var useLogout = function () {
    var logout = useAuth0().logout;
    var platform = usePlatform();
    var logoutPath = useMemo(function () {
        if (platform === PLATFORM.PARTNER_CENTER)
            return window.location.origin;
        return "".concat(window.location.origin).concat(AUTH_PATH.LOGOUT_PAGE);
    }, [platform]);
    return useCallback(function () {
        logout({ returnTo: logoutPath });
    }, [logout, logoutPath]);
};

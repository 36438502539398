var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EuiFlexGroup, EuiSpacer } from "@elastic/eui";
import I18nText from "../../../i18n/I18nText";
var SuccessModal = function (_a) {
    var text = _a.text;
    return (_jsxs(EuiFlexGroup, __assign({ direction: "column", gutterSize: "none", alignItems: "center" }, { children: [_jsx("div", { children: _jsx("img", { src: "/assets/svg/greentick.svg" }) }), _jsx(EuiSpacer, { size: "m" }), _jsx("div", { children: _jsx(I18nText, { token: text }) })] })));
};
export default SuccessModal;

import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Redirect } from "react-router";
import { useIsUserInfoChecked } from "../context/hooks";
import { ERROR_PATH } from "../errors/constants";
import { useRoutePermission } from "./hooks";
var RoutePermission = function (_a) {
    var routeProps = _a.routeProps, checkPermission = _a.checkPermission, children = _a.children;
    var pathname = routeProps.location.pathname;
    var allow = useRoutePermission(pathname);
    var checked = useIsUserInfoChecked();
    if (!checked)
        return null;
    if (!allow && checkPermission)
        return _jsx(Redirect, { to: ERROR_PATH.PAGE_UNAUTHORISED });
    return _jsx(_Fragment, { children: children });
};
export default RoutePermission;

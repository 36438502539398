var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useMemo } from "react";
import { encryptAESData } from "../../../common/encryption";
import { toLocalTime } from "../../../common/helpers";
import { groupKPOSAssignments } from "../../../pages/usermanagement/edit/context/helpers";
import { useFSPropData } from "../../api/fs/props/useFSPropData";
import { getPermissionsOfRoles } from "../../api/rbac/roles/helpers";
import { useRoleListing } from "../../api/rbac/roles/useRoleListing";
import { useUserInfo } from "../../context/hooks";
import { getQRValidityTiming } from "./helpers";
var USER_QR_CODE_PASS = "USER_QR_CODE_PASS";
var TIME_FORMAT = "DD MMMM YYYY, h:mmA";
export var useUserQRCodeData = function () {
    var key = useFSPropData(USER_QR_CODE_PASS);
    var _a = useUserInfo(), name = _a.name, email = _a.email, kpos = _a.kpos;
    var allRoles = useRoleListing();
    var access = useMemo(function () {
        var assignments = groupKPOSAssignments(kpos === null || kpos === void 0 ? void 0 : kpos.assignments);
        return assignments.reduce(function (data, _a) {
            var roles = _a.roles, business_branch_ids = _a.business_branch_ids, location_ids = _a.location_ids;
            if (business_branch_ids.length)
                return data;
            var perms = getPermissionsOfRoles(roles, allRoles);
            return __spreadArray(__spreadArray([], data, true), [
                {
                    perm: perms.map(function (_a) {
                        var id = _a.id;
                        return Number(id);
                    }),
                    locs: location_ids,
                },
            ], false);
        }, []);
    }, [kpos, allRoles]);
    var _b = useMemo(function () { return getQRValidityTiming(); }, []), createdAt = _b.createdAt, validUntil = _b.validUntil;
    var payload = useMemo(function () {
        var payload = {
            name: name,
            email: email,
            createdAt: createdAt,
            validUntil: validUntil,
            access: access,
        };
        return encryptAESData(JSON.stringify(payload), key[USER_QR_CODE_PASS]);
    }, [name, email, access, key, createdAt, validUntil]);
    return {
        payload: payload,
        validTill: toLocalTime(validUntil, TIME_FORMAT),
    };
};

import { lazy } from "react";
import { MENU_GROUP, PLATFORM } from "../../../common/constants";
import { globalEvents } from "../../../common/GlobalEvents";
import { PERMISSION } from "../../../components/rbac/permissions";
import { ROUTE_EVENTS } from "../../../components/routing/constants";
import { REPORTS_PATH } from "./constants";
/* istanbul ignore next */ // no need to test lazy loading
var LazyComponents = {
    DailySalesReportByStall: lazy(function () {
        return import(
        /* webpackChunkName: "fs-dserbystall" */ "./dserbystall/DSERByStall");
    }),
    ItemSalesReport: lazy(function () {
        return import(
        /* webpackChunkName: "qc-itemsalesreport" */ "./item-sales/views/ItemSalesReport");
    }),
};
export var reportsRoutes = [
    {
        label: "reportsQc.menu.label",
        route: "",
        permissions: [PERMISSION.QC_REPORTS_READ],
        icon: "documents",
        platforms: [],
        group: MENU_GROUP.QUICK_COMMERCE,
        subRoutes: [
            {
                label: "reports.menu.item.itemsalesreport.adminPanel",
                route: REPORTS_PATH.ITEM_SALES_REPORT_AP,
                component: LazyComponents.ItemSalesReport,
                permissions: [PERMISSION.QC_REPORTS_READ],
                platforms: [PLATFORM.ADMIN_PANEL],
            },
            {
                label: "reports.menu.item.itemsalesreport.partnerCenter",
                route: REPORTS_PATH.ITEM_SALES_REPORT_PC,
                component: LazyComponents.ItemSalesReport,
                permissions: [PERMISSION.QC_REPORTS_READ],
                platforms: [PLATFORM.PARTNER_CENTER],
            },
            {
                label: "reports.menu.item.dailysalesentrybystall",
                route: REPORTS_PATH.DAILY_SALES_REPORT_BY_STALL,
                component: LazyComponents.DailySalesReportByStall,
                permissions: [PERMISSION.QC_REPORTS_READ],
                platforms: [PLATFORM.ADMIN_PANEL],
            },
        ],
    },
];
export var initQCReports = function () {
    globalEvents.emit(ROUTE_EVENTS.REGISTER, reportsRoutes);
};

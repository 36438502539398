import moment from "moment";
import { COLORS } from "../../../../common/constants";
import { announceSimpleOptionsMessage } from "../../../modals/alerts/helpers";
import { ACCEPTABLE_SERVER_TIME_VARIANCE } from "./constants";
export var serverVsLocalTimeWithinVariance = function (servertime) {
    var servermmt = moment(servertime);
    var localmmt = moment();
    return (Math.abs(servermmt.diff(localmmt, "milliseconds")) <=
        ACCEPTABLE_SERVER_TIME_VARIANCE);
};
export var announceLogout = function (text, onClick) {
    announceSimpleOptionsMessage({
        text: text,
        buttons: [
            {
                label: "common.logout",
                buttonColor: COLORS.SHIRAZ500,
                onClick: onClick,
            },
        ],
    });
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./fploginsplash.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { EuiButton, EuiFlexGroup, EuiPage, EuiSpacer } from "@elastic/eui";
import I18nText from "../../../components/i18n/I18nText";
var LOGIN_FAQ_PATH = "/static.html?content=loginfaq";
var FPLoginSplash = function () {
    var loginWithRedirect = useAuth0().loginWithRedirect;
    return (_jsx(EuiPage, __assign({ className: "FPLoginSplash" }, { children: _jsxs(EuiFlexGroup, __assign({ direction: "column", gutterSize: "none", alignItems: "center", justifyContent: "center" }, { children: [_jsx("img", { src: "/assets/svg/kopitiamlogo.svg", className: "KopitiamLogo" }), _jsx(EuiSpacer, { size: "l" }), _jsx(I18nText, { token: "fplogin.splash.welcome.en", className: "Welcome" }), _jsx(EuiSpacer, { size: "s" }), _jsx(I18nText, { token: "fplogin.splash.welcome.cn", className: "Welcome" }), _jsx(EuiSpacer, { size: "xl" }), _jsx(I18nText, { token: "fplogin.splash.message.en" }), _jsx(EuiSpacer, { size: "s" }), _jsx(I18nText, { token: "fplogin.splash.message.cn" }), _jsx(EuiSpacer, { size: "l" }), _jsx(EuiButton, __assign({ className: "BlueButton Wide", onClick: loginWithRedirect }, { children: _jsx(I18nText, { token: "fplogin.splash.login" }) })), _jsx(EuiSpacer, { size: "xl" }), _jsx(I18nText, { token: "fplogin.splash.help.en", className: "Help Line" }), _jsx(EuiSpacer, { size: "s" }), _jsx(I18nText, { token: "fplogin.splash.help.cn", className: "Help" }), _jsx(EuiSpacer, { size: "xl" }), _jsxs("div", { children: [_jsx(I18nText, { token: "fplogin.splash.needhelp.en" }), _jsx("a", __assign({ href: LOGIN_FAQ_PATH, target: "loginfaqen", rel: "noreferrer" }, { children: _jsx(I18nText, { token: "fplogin.splash.readfaq.en" }) }))] }), _jsx(EuiSpacer, { size: "s" }), _jsxs("div", { children: [_jsx(I18nText, { token: "fplogin.splash.needhelp.cn" }), _jsx("a", __assign({ href: LOGIN_FAQ_PATH, target: "loginfaqcn", rel: "noreferrer" }, { children: _jsx(I18nText, { token: "fplogin.splash.readfaq.cn" }) }))] })] })) })));
};
export default FPLoginSplash;

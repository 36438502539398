import { lazy } from "react";
import { MENU_GROUP, PLATFORM } from "../../../common/constants";
import { globalEvents } from "../../../common/GlobalEvents";
import { PERMISSION } from "../../../components/rbac/permissions";
import { ROUTE_EVENTS } from "../../../components/routing/constants";
import { COMPANIES_PATH } from "./constants";
/* istanbul ignore next */ // no need to test lazy loading
var LazyComponents = {
    CompaniesListing: lazy(function () {
        return import(
        /* webpackChunkName: "fs-companieslisting" */ "./listing/CompaniesListing");
    }),
    CompanyDetails: lazy(function () {
        return import(
        /* webpackChunkName: "fs-companydetails" */ "./edit/CompanyDetails");
    }),
    NewBrandBusiness: lazy(function () {
        return import(
        /* webpackChunkName: "fs-newbrandbusiness" */ "./newbrandbusiness/NewBrandBusiness");
    }),
    NewBusiness: lazy(function () {
        return import(
        /* webpackChunkName: "fs-newbusiness" */ "./newbusiness/NewBusiness");
    }),
    AddCompany: lazy(function () { return import(/* webpackChunkName: "fs-addcompany" */ "./add/CompanyAdd"); }),
    // anchor - for code generator
};
var permissions = [PERMISSION.FS_COMPANIES_VIEW];
export var companiesRoute = [
    {
        label: "fs.companies.menu.label",
        route: "",
        permissions: permissions,
        icon: "",
        group: MENU_GROUP.FOOD_SERVICES,
        platforms: [],
        subRoutes: [
            {
                label: "fs.companies.listing",
                route: COMPANIES_PATH.COMPANY_LISTING,
                component: LazyComponents.CompaniesListing,
                permissions: permissions,
                platforms: [PLATFORM.ADMIN_PANEL],
            },
            {
                label: "",
                route: COMPANIES_PATH.COMPANY_DETAILS,
                component: LazyComponents.CompanyDetails,
                permissions: permissions,
                platforms: [PLATFORM.ADMIN_PANEL],
                hidden: true,
            },
            {
                label: "",
                route: COMPANIES_PATH.NEW_COMPANY,
                component: LazyComponents.AddCompany,
                permissions: permissions,
                platforms: [PLATFORM.ADMIN_PANEL],
                hidden: true,
            },
            {
                label: "",
                route: COMPANIES_PATH.NEW_BRAND_BUSINESS,
                component: LazyComponents.NewBrandBusiness,
                permissions: permissions,
                platforms: [PLATFORM.ADMIN_PANEL],
                hidden: true,
            },
            {
                label: "",
                route: COMPANIES_PATH.NEW_BUSINESS,
                component: LazyComponents.NewBusiness,
                permissions: permissions,
                platforms: [PLATFORM.ADMIN_PANEL],
                hidden: true,
            },
            // anchor - for code generator
        ],
    },
];
export var initFSCompanyManagement = function () {
    globalEvents.emit(ROUTE_EVENTS.REGISTER, companiesRoute);
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./maintenance.scss";
import { EuiFlexGroup, EuiFlexItem, EuiPage } from "@elastic/eui";
import { FS_ANNOUNCEMENTS } from "../../pages/superadmin/fsfeatures/announcements/constants";
import { useFSConfigMsg } from "../context/fs/useFSConfigMsg";
import I18nText from "../i18n/I18nText";
import { useInMaintenanceMode } from "./useInMaintenaceMode";
var MaintenanceCheck = function (_a) {
    var children = _a.children;
    var maintenance = useInMaintenanceMode();
    var msg = useFSConfigMsg(FS_ANNOUNCEMENTS.MAINTENANCE);
    if (!maintenance)
        return _jsx(_Fragment, { children: children });
    return (_jsx(EuiPage, __assign({ className: "Maintenance" }, { children: _jsxs(EuiFlexGroup, __assign({ direction: "column", alignItems: "center" }, { children: [_jsx(EuiFlexItem, { children: _jsx("img", { src: "/assets/svg/kopitiamlogo.svg", className: "KopitiamLogo" }) }), _jsx(EuiFlexItem, { children: _jsx(I18nText, { token: "maintenance.title", className: "Title" }) }), _jsx(EuiFlexItem, { children: _jsx(I18nText, { token: "maintenance.msg", className: "Message" }) }), _jsx(EuiFlexItem, { children: _jsx(I18nText, { token: msg, className: "Message" }) })] })) })));
};
export default MaintenanceCheck;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useCallback, useContext, useEffect, useMemo } from "react";
import { ACCESS_PERMISSION_PROP } from "../../api/rbac/accesspermissions/constants";
import { hasAllFSLocationsAccess } from "../../rbac/helpers";
import { DictionaryContext } from "../dictionaries/constants";
import { mapOnlyIDs } from "../helpers";
import { useUserInfo, useUserPermissions } from "../hooks";
import { filterDictionaryByBrandsBusiness, filterDictionaryByLocations, getAllIds, } from "./helpers";
export var useUserAccessibleStatus = function () {
    var permissions = useUserPermissions();
    var accessibles = useUserInfo().accessibles;
    var noPermissions = useMemo(function () { return !permissions.length; }, [permissions]);
    var allowAllLocations = useMemo(function () {
        return hasAllFSLocationsAccess(permissions);
    }, [permissions]);
    var filterByLocations = useMemo(function () {
        if (allowAllLocations)
            return false;
        return accessibles.locations.length > 0;
    }, [accessibles.locations, allowAllLocations]);
    var filterByBrandBusiness = useMemo(function () {
        if (allowAllLocations)
            return false;
        return !filterByLocations;
    }, [allowAllLocations, filterByLocations]);
    return {
        allowAllLocations: allowAllLocations,
        filterByBrandBusiness: filterByBrandBusiness,
        filterByLocations: filterByLocations,
        noPermissions: noPermissions,
    };
};
export var useUserAccessiblesFiltering = function () {
    var _a = useContext(DictionaryContext), raw = _a[0].raw, setContext = _a[1];
    var _b = useUserInfo().accessibles, locationFilter = _b.locations, brandFilter = _b.brands, businessFilter = _b.businesses;
    var _c = useUserAccessibleStatus(), noPermissions = _c.noPermissions, allowAllLocations = _c.allowAllLocations, filterByLocations = _c.filterByLocations, filterByBrandBusiness = _c.filterByBrandBusiness;
    var setDictionary = useCallback(function (_a) {
        var filteredCompanies = _a.filteredCompanies, filteredBrands = _a.filteredBrands, filteredBusinesses = _a.filteredBusinesses, filteredLocations = _a.filteredLocations;
        setContext(function (prev) { return (__assign(__assign({}, prev), { companies: filteredCompanies, brands: filteredBrands, businesses: filteredBusinesses, locations: filteredLocations })); });
    }, []);
    useEffect(function () {
        if (noPermissions)
            return;
        if (allowAllLocations)
            return;
        if (!filterByLocations)
            return;
        if (!locationFilter.length)
            return;
        setDictionary(filterDictionaryByLocations(__assign(__assign({}, raw), { locationFilter: locationFilter })));
    }, [
        allowAllLocations,
        filterByLocations,
        raw,
        noPermissions,
        locationFilter,
    ]);
    useEffect(function () {
        if (noPermissions)
            return;
        if (allowAllLocations)
            return;
        if (!filterByBrandBusiness)
            return;
        if (!brandFilter.length)
            return;
        setDictionary(filterDictionaryByBrandsBusiness(__assign(__assign({}, raw), { brandFilter: brandFilter, businessFilter: businessFilter })));
    }, [
        allowAllLocations,
        filterByBrandBusiness,
        raw,
        noPermissions,
        brandFilter,
        businessFilter,
    ]);
    useEffect(function () {
        if (noPermissions)
            return;
        if (!allowAllLocations)
            return;
        setDictionary(filterDictionaryByLocations(__assign(__assign({}, raw), { locationFilter: getAllIds(raw.locations) })));
    }, [allowAllLocations, raw, noPermissions]);
};
export var useExpandedAccessPermissionsConfigs = function (configs) {
    var raw = useContext(DictionaryContext)[0].raw;
    return useMemo(function () {
        return Object.keys(configs).reduce(function (expanded, key) {
            var _a, _b;
            var original = configs[key];
            var processed = filterDictionaryByBrandsBusiness(__assign(__assign({}, raw), { brandFilter: original[ACCESS_PERMISSION_PROP.BRAND_IDS], businessFilter: original[ACCESS_PERMISSION_PROP.BUSINESS_IDS] }));
            return __assign(__assign({}, expanded), (_a = {}, _a[key] = (_b = {},
                _b[ACCESS_PERMISSION_PROP.LOCATION_IDS] = [],
                _b[ACCESS_PERMISSION_PROP.BRAND_IDS] = mapOnlyIDs(processed.filteredBrands),
                _b[ACCESS_PERMISSION_PROP.BUSINESS_IDS] = mapOnlyIDs(processed.filteredBusinesses),
                _b[ACCESS_PERMISSION_PROP.BUSINESS_BRANCH_IDS] = [],
                _b), _a));
        }, {});
    }, [raw, configs]);
};

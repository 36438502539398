var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import CryptoJS from "crypto-js";
import Base64 from "crypto-js/enc-base64";
var AES_IV_LENGTH = 16; // fixed, do not change!
var AES_CONFIG = {
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
};
export var encryptAESData = function (text, pass) {
    var iv = CryptoJS.lib.WordArray.random(AES_IV_LENGTH);
    var key = CryptoJS.enc.Utf8.parse(pass);
    var encrypted = CryptoJS.AES.encrypt(text, key, __assign(__assign({}, AES_CONFIG), { iv: iv }));
    var combined = iv.concat(encrypted.ciphertext);
    return Base64.stringify(combined);
};
export var decryptAESData = function (text, pass) {
    if (!text)
        return "";
    var wordArray = CryptoJS.enc.Base64.parse(text);
    var randomIV = CryptoJS.lib.WordArray.create(wordArray.words.slice(0, 4), AES_IV_LENGTH);
    var decoded = CryptoJS.enc.Base64.parse(text);
    var remainingBytes = CryptoJS.lib.WordArray.create(decoded.words.slice(4));
    var key = CryptoJS.enc.Utf8.parse(pass);
    var decrypted = CryptoJS.AES.decrypt(Base64.stringify(remainingBytes), key, __assign(__assign({}, AES_CONFIG), { iv: randomIV }));
    return decrypted.toString(CryptoJS.enc.Utf8);
};

import { useAutoLogout } from "./useAutoLogout";
import { useSessionTracker } from "./useSessionTracker";
import { useTrackGlobalClicks } from "./useTrackGlobalClicks";
var SessionTracker = function () {
    var invalid_session = useSessionTracker();
    useAutoLogout(invalid_session);
    useTrackGlobalClicks();
    return null;
};
export default SessionTracker;

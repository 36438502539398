import { globalEvents } from "../../common/GlobalEvents";
import { replaceToken } from "../../common/stringutils";
import { LANG_SELECTABLES } from "../layout/header/constants";
import { I18N_EVENT } from "./constants";
export var LANG_CLASS_PREFIX = "lang-";
var html = function () { return document.querySelector("html"); };
var removeAllLangClasses = function () {
    var classes = [];
    var classList = html().classList;
    classList.forEach(function (classname) {
        if (classname.indexOf(LANG_CLASS_PREFIX) === 0)
            classes.push(classname);
    });
    classes.forEach(function (classname) { return html().classList.remove(classname); });
};
export var applyLangClassToHTML = function (lang) {
    removeAllLangClasses();
    html().classList.add("".concat(LANG_CLASS_PREFIX).concat(lang));
};
export var getSelectableLangData = function (lang) {
    return LANG_SELECTABLES.filter(function (_a) {
        var selectableLang = _a.lang;
        return selectableLang === lang;
    })[0];
};
var addMoustacheBrackets = function (value) { return "{".concat(value, "}"); };
export var replaceI18nToken = function (text, replace) {
    var newReplace = {};
    for (var key in replace) {
        newReplace[addMoustacheBrackets(key)] = replace[key];
    }
    return replaceToken(text, newReplace);
};
export var resolveLinkedMapping = function (oldmap) {
    var newmap = {};
    for (var key in oldmap) {
        var value = oldmap[key];
        while (oldmap[value.toLowerCase()] !== undefined &&
            key !== value.toLowerCase()) {
            value = oldmap[value.toLowerCase()];
        }
        newmap[key] = value;
    }
    return newmap;
};
export var getI18nText = function (key, replace) {
    var text = globalEvents.request(I18N_EVENT.GET_TEXT, key);
    return replaceI18nToken(text, replace);
};
export var toLowerCaseKeys = function (mapping) {
    var keys = Object.keys(mapping);
    return keys.reduce(function (newmapping, key) {
        newmapping[key.toLowerCase()] = mapping[key];
        return newmapping;
    }, {});
};

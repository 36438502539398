import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { PLATFORM } from "../../../common/constants";
import { AUTH_PATH } from "../../../pages/auth/constants";
import { AllContext } from "../../context/constants";
import { usePlatform, useUserPermissions } from "../../context/hooks";
import { getI18nText } from "../../i18n/helpers";
import { useIsLocalDevelopment } from "../../rbac/hooks/useIsLocalDevelopment";
import { routeNotMatch } from "../../routing/helpers";
import { useRouting } from "../../routing/hooks";
import { filterNavItemsByEnv, filterNavItemsByPermissions, filterNavItemsByPlatform, routesToNavItems, sortByMenuGroups, } from "./helpers";
export var useSideNavContent = function () {
    var routes = useContext(AllContext.RoutesContext)[0];
    var _a = useContext(AllContext.GeneralDataContext)[0], userInfo = _a.userInfo, picture = _a.auth0.picture;
    var permissions = useUserPermissions();
    var platform = usePlatform();
    var isLocal = useIsLocalDevelopment();
    return useMemo(function () {
        var navitems = routesToNavItems(routes);
        var sorteditems = sortByMenuGroups(navitems);
        var envItems = filterNavItemsByEnv(sorteditems, isLocal);
        var platform_navitems = filterNavItemsByPlatform(envItems, platform).filter(function (_a) {
            var hidden = _a.hidden;
            return !hidden;
        });
        return {
            navItems: filterNavItemsByPermissions(platform_navitems, permissions),
            userInfo: userInfo,
            picture: picture,
        };
    }, [permissions, routes, picture, platform, isLocal]);
};
export var useSideNavMenuContent = function (current) {
    var navItems = useSideNavContent().navItems;
    return useMemo(function () {
        var _a, _b, _c;
        if (current === "profile")
            return [];
        var res = navItems
            .filter(function (_a) {
            var group = _a.group;
            return group === current;
        })
            .filter(function (_a) {
            var hidden = _a.hidden;
            return !hidden;
        });
        var hasItems = !!((_b = (_a = res[0]) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.length);
        if (res.length === 1 && hasItems)
            return (_c = res[0].items) === null || _c === void 0 ? void 0 : _c.filter(function (_a) {
                var hidden = _a.hidden;
                return !hidden;
            });
        return res;
    }, [current, navItems]);
};
export var useSideNavLink = function (data) {
    var title = data.title, route = data.route, icon = data.icon, new_item_check = data.new_item_check;
    return {
        title: title,
        route: route,
        icon: icon,
        new_item_check: new_item_check,
    };
};
export var useIsNavGroupInFocus = function (items) {
    var pathname = useRouting().pathname;
    if (!items)
        return false;
    return !routeNotMatch(items, pathname);
};
export var useIsFocus = function (route) {
    var pathname = useRouting().pathname;
    return route === pathname;
};
export var useLogout = function () {
    var logout = useAuth0().logout;
    var platform = usePlatform();
    var logoutPath = useMemo(function () {
        if (platform === PLATFORM.PARTNER_CENTER)
            return window.location.origin;
        return "".concat(window.location.origin).concat(AUTH_PATH.LOGOUT_PAGE);
    }, [platform]);
    return useCallback(function () {
        logout({ returnTo: logoutPath });
    }, [logout, logoutPath]);
};
export var useUserTypeDisplay = function (user_type) {
    return useMemo(function () {
        if (!user_type)
            return "";
        var user_type_key = user_type.replace(" ", "_").toLowerCase();
        var key = "user.type.".concat(user_type_key);
        var display = getI18nText(key);
        return display === key ? user_type : display;
    }, [user_type]);
};
export var useIsNavGroupOpen = function (initial) {
    var _a = useState(initial), isOpen = _a[0], setIsOpen = _a[1];
    var setOpen = useCallback(function (open) {
        setIsOpen(open);
    }, []);
    useEffect(function () {
        setOpen(initial);
    }, [initial]);
    return {
        isOpen: isOpen,
        setOpen: setOpen,
    };
};
